import { ELEMENT_TYLE_INLINE, ELEMENT_TYLE_BLOCK } from 'components/editor/types/tyleTypes';
import { IconType, TylePreviewDataInterface } from './getTylePreviewData';
import { CustomElement } from '../types/slate';

interface TyleVisualizationInterface {
	icon: IconType;
	isShowLargeIconOnLeft: boolean;
	isShowLargeTextOnLeft: boolean;
	largeTextOnLeft: string;
	isShowSmallTextOnTopRight: boolean;
	smallTextOnTopRight: string;
	isShowSmallTextOnBottomLeft: boolean;
	smallTextOnBottomLeft: string;
	isShowSmallTextOnBottomRight: boolean;
	smallTextOnBottomRight: string;
	isShowSmallIconOnBottomLeft: boolean;
}

export const getContent = (contentElements: Array<CustomElement>): string => {
	let text = '';
	contentElements.map((contentElement) => {
		if (
			(contentElement.type === ELEMENT_TYLE_INLINE || contentElement.type === ELEMENT_TYLE_BLOCK) &&
			'tyle' in contentElement
		) {
			text += contentElement.tyle.title ?? 'Untitled';
			return contentElement;
		}

		if (contentElement.children && contentElement.children.length > 0) {
			if (text !== '') {
				text += ' · ';
			}
			text += getContent(contentElement.children);
			return contentElement;
		}

		if ('text' in contentElement) {
			text += contentElement.text;
		}
		return contentElement;
	});

	return text;
};

// IMPORTANT: PLEASE NOT SIMPLIFY THIS FUNCTION (shorten variable initializations)) TO MAKE THE LOGIC AS CLEAR AS POSSIBLE TO THE DEVELOPER!
const getTyleVisualization = (tyleData: TylePreviewDataInterface): TyleVisualizationInterface => {
	// decide if a large icon is shown on the left
	const isBookmarkWithoutTitle = tyleData.icon === 'bookmark-icon' && (!tyleData.title || tyleData.title === '');
	const isShowLargeIconOnLeft =
		tyleData.icon === 'document-icon' ||
		isBookmarkWithoutTitle ||
		(tyleData.icon === 'quote-icon' && (!tyleData.title || tyleData.title === ''));

	// large text on left is always shown
	const isShowLargeTextOnLeft = true;
	// small text on top right is always shown
	const isShowSmallTextOnTopRight = true;

	// find large text
	let largeTextOnLeft = '';
	if (tyleData.title && tyleData.title !== '') {
		largeTextOnLeft = tyleData.title;
	} else if (tyleData.icon === 'bookmark-icon' && tyleData.sourceTitle !== '') {
		// tyleData.title is not set (or equals '').
		largeTextOnLeft = tyleData.sourceTitle;
	} else if (tyleData.icon === 'quote-icon' && tyleData.content) {
		// tyleData.title is not set (or equals '').
		largeTextOnLeft = getContent(tyleData.content);
	} else {
		largeTextOnLeft = 'Untitled';
	}

	// decide if small text is shown
	let isShowSmallTextOnBottomLeft = false;
	if ((!tyleData.title || tyleData.title === '') && tyleData.icon === 'bookmark-icon' && tyleData.sourceTitle !== '') {
		isShowSmallTextOnBottomLeft = false;
	} else if (tyleData.icon === 'quote-icon' && (!tyleData.title || tyleData.title === '')) {
		isShowSmallTextOnBottomLeft = false;
	} else {
		isShowSmallTextOnBottomLeft = true;
	}

	// find small text
	let smallTextOnBottomLeft = '';
	if (tyleData.icon !== 'bookmark-icon' && isShowSmallTextOnBottomLeft && tyleData.content) {
		smallTextOnBottomLeft = getContent(tyleData.content);
	} else if (tyleData.icon === 'bookmark-icon') {
		if (tyleData.sourceTitle) {
			smallTextOnBottomLeft = tyleData.sourceTitle;
		}
	}

	// small text on the top right is always the creation date of the tyle
	const smallTextOnTopRight = tyleData.date;

	// decide if small text on bottom right is shown + find the correct text
	const isShowSmallTextOnBottomRight =
		tyleData.icon !== 'document-icon' && (tyleData.sourceUrl !== '' || tyleData.applicationName !== '');
	const smallTextOnBottomRight = tyleData.sourceUrl || tyleData.applicationName || '';

	// decide if small icon on bottom left is shown + find the correct icon
	let isShowSmallIconOnBottomLeft = false;
	if (tyleData.icon !== 'document-icon' && isShowSmallTextOnBottomLeft && !isShowLargeIconOnLeft) {
		isShowSmallIconOnBottomLeft = true;
	}
	const { icon } = tyleData;

	return {
		icon,
		isShowLargeIconOnLeft,
		isShowLargeTextOnLeft,
		largeTextOnLeft,
		isShowSmallTextOnTopRight,
		smallTextOnTopRight,
		isShowSmallTextOnBottomLeft,
		smallTextOnBottomLeft,
		isShowSmallTextOnBottomRight,
		smallTextOnBottomRight,
		isShowSmallIconOnBottomLeft,
	};
};

export default getTyleVisualization;
