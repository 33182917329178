import React, { ReactNode } from 'react';
import {
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_LINK,
	ELEMENT_OL,
	ELEMENT_PARAGRAPH,
	ELEMENT_TODO_LI,
	ELEMENT_UL,
	TComboboxItem,
} from '@udecode/plate';
import { TextT } from '@styled-icons/fluentui-system-regular/TextT';
import { TextBulletListLtr as BulletList } from '@styled-icons/fluentui-system-regular/TextBulletListLtr';
import { ListCheck } from '@styled-icons/bootstrap/ListCheck';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Link as Hyperlink } from '@styled-icons/bootstrap/Link';
import { FormatListNumbered } from '@styled-icons/material-sharp/FormatListNumbered';
import { TypeH1 } from '@styled-icons/bootstrap/TypeH1';
import { TypeH2 } from '@styled-icons/bootstrap/TypeH2';
import { TypeH3 } from '@styled-icons/bootstrap/TypeH3';
import { ChatQuote } from '@styled-icons/remix-line/ChatQuote';

const style = {
	marginRight: '10px',
	color: '#aaaaaa',
};

export type CommandItemProps = {
	icon: ReactNode;
	value: string;
	type: string;
};

export const EDITOR_COMMANDS: TComboboxItem<CommandItemProps>[] = [
	{
		key: 'p',
		text: 'Paragraph',
		data: {
			icon: <TextT size="13px" style={style} />,
			value: 'p',
			type: ELEMENT_PARAGRAPH,
		},
	},
	{
		key: 'h1',
		text: 'Header 1',
		data: {
			icon: <TypeH1 size="13px" style={style} />,
			value: 'h1',
			type: ELEMENT_H1,
		},
	},
	{
		key: 'h2',
		text: 'Header 2',
		data: {
			icon: <TypeH2 size="13px" style={style} />,
			value: 'h2',
			type: ELEMENT_H2,
		},
	},
	{
		key: 'h3',
		text: 'Header 3',
		data: {
			icon: <TypeH3 size="13px" style={style} />,
			value: 'h3',
			type: ELEMENT_H3,
		},
	},
	{
		key: 'ul',
		text: 'Bulleted list',
		data: {
			icon: <BulletList size="13px" style={style} />,
			value: 'ul',
			type: ELEMENT_UL,
		},
	},
	{
		key: 'ol',
		text: 'Numbered list',
		data: {
			icon: <FormatListNumbered size="13px" style={style} />,
			value: 'ol',
			type: ELEMENT_OL,
		},
	},
	{
		key: 'todo',
		text: 'Todo list ',
		data: {
			icon: <ListCheck size="13px" style={style} />,
			value: 'todo',
			type: ELEMENT_TODO_LI,
		},
	},
	{
		key: 'code',
		text: 'Code block',
		data: {
			icon: <CodeAlt size="13px" style={style} />,
			value: 'code-block',
			type: ELEMENT_CODE_BLOCK,
		},
	},
	{
		key: 'quote',
		text: 'Quote',
		data: {
			icon: <ChatQuote size="13px" style={style} />,
			value: 'quote',
			type: ELEMENT_BLOCKQUOTE,
		},
	},
	{
		key: 'link',
		text: 'Link',
		data: {
			icon: <Hyperlink size="13px" style={style} />,
			value: 'link',
			type: ELEMENT_LINK,
		},
	},
];
