import styled from 'styled-components';

export const StyledTyleCard = styled.div.attrs((props: { selected: boolean; isDragging: boolean }) => props)`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	padding: 5px 10px;
	margin-bottom: 10px;
	background-color: ${(props) => (props.selected ? props.theme.colors?.gray400 : 'rgba(246, 246, 246, 0.6)')};
	border: 1px solid ${(props) => props.theme.colors?.gray300};
	border-radius: ${(props) => props.theme.radius?.normal};
	height: auto;
	width: auto;
	max-height: 46px;
	position: relative;
	cursor: ${(props) => (props.isDragging ? 'grabbing' : 'pointer')};
`;

export const StyledSpan = styled.span`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	flex-direction: row-reverse;
	font-size: 13px;
	line-height: 17.5px;
	color: ${(props) => props.theme.colors?.lightHeading};
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	margin: 0;
`;

export const StyledSource = styled.a.attrs((props: { selected: boolean }) => props)`
	position: absolute;
	bottom: 5px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	color: ${(props) => props.theme.colors?.gray200};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	background-color: ${(props) => (props.selected ? props.theme.colors?.gray400 : 'rgb(250,249,249)')};
	mask-image: linear-gradient(to right, transparent 1%, rgb(250, 249, 249) 15%);
	mask-size: 200px 46px;
	mask-repeat: no-repeat;

	&:hover {
		color: ${(props) => props.theme.colors?.gray200};
	}
`;

export const StyledSourceLink = styled.span`
	font-size: 13px;
	line-height: 17.5px;
	max-width: 90px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledSourceIcon = styled.img`
	width: 12px;
	height: 12px;
	border-radius: 2px;
	margin-right: 3px;
	margin-left: 40px;
`;
