import styled from 'styled-components';
import dark from '../../themes/dark';
import Carousel from '../../components/Carousel';
import Icon from '../../components/icon';

export const StyledDesktopOnboardingContainer = styled.div.attrs((props: { showDots: boolean }) => props)`
	min-height: 100vh;
	width: 100%;
	border-radius: 9px;
	background-color: ${() => dark.colors.background};
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.pagination_dots {
		position: inherit;
		display: ${(props) => (props.showDots ? 'flex' : 'none')};
		cursor: pointer;
		margin-bottom: 21px;
	}
`;

export const StyledAnimationContainer = styled.div.attrs((props: { imageBackground: string }) => props)`
	height: 300px;
	width: 100%;
	background: url(${(props) => props.imageBackground ?? ''});
	background-size: cover;
	background-color: ${() => dark.colors.tagBg};
	border-radius: ${(props) => (props.imageBackground ? '32px' : '9px')};
`;

export const StyledAnimationVideoImageWrapper = styled.div`
	width: 100%;
	overflow: hidden;
	border-radius: 9px;
`;

export const StyledCarousel = styled(Carousel)`
	margin-bottom: 40px;
`;

export const StyledCarouselContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

export const StyledCarouselContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const StyledCarouselContentHeadline1 = styled.h1`
	color: ${() => dark.colors.softParagraph};
	font-weight: ${(props) => props.theme.fontWeights?.bold};
	font-size: ${(props) => props.theme.fontSizes?.headingBig};
`;

export const StyledCarouselContentHeadline2 = styled.span`
	color: ${() => dark.colors.heading};
`;

export const StyledArrowLeft = styled(Icon).attrs((props: { hidden: boolean }) => props)`
	visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
	margin: 0 30px;
`;

export const StyledArrowRight = styled(Icon).attrs((props: { hidden: boolean }) => props)`
	visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
	margin: 0 30px;
`;
