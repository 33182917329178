export enum ShortcutLabelEnum {
	NEW_NOTE = 'New note',
	NAVIGATE = 'Navigate',
	CLOSE = 'Close',
}

type ShortcutMapValue = {
	windows: string[];
	macOs: string[];
};

type ShortcutMapType = Map<ShortcutLabelEnum, ShortcutMapValue>;

export const ShortcutMap: ShortcutMapType = new Map([
	[
		ShortcutLabelEnum.NEW_NOTE,
		{
			windows: ['Alt', 'C'],
			macOs: ['⌥', 'C'],
		},
	],
	[
		ShortcutLabelEnum.NAVIGATE,
		{
			windows: ['Ctrl', 'Arrow'],
			macOs: ['⌘', 'Arrow'],
		},
	],
	[
		ShortcutLabelEnum.CLOSE,
		{
			windows: ['Alt', 'W'],
			macOs: ['⌥', 'W'],
		},
	],
]);
