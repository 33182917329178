import { TyleInterface } from '../interfaces/TyleInterface';
import getLinkList from './getLinkList';

const getTyleLinkDistinction = (
	tyle: TyleInterface,
): { linkIdsToAdd: Array<string>; linkIdsToRemove: Array<string> } => {
	// get all links from content elements
	const linkListFromContent: Array<string> = tyle.content ? getLinkList(tyle.content) : [];
	// get all the IDs of the links that are already persisted as connections
	const linkListFromConnections: Array<string> = !tyle.link
		? []
		: tyle.link.map((tyleConnection) => tyleConnection.backlinkId);
	const linkIdsToAdd: Array<string> = [];
	const linkIdsToRemove: Array<string> = [];

	// find which links to add
	linkListFromContent.map((linkId) => {
		// check if the given link id is already in the list of linked tyles.
		const found = linkListFromConnections.find((backlinkId) => backlinkId === linkId);
		// if it is or is added to the list of links to add, do nothing
		if (found || linkIdsToAdd.find((backlinkId) => backlinkId === found)) {
			return false;
		}
		// otherwise, add the linkId to the list of links to add
		linkIdsToAdd.push(linkId);
		return true;
	});

	// find which links to remove
	linkListFromConnections.map((linkId) => {
		// check if the given link id is already in the list of linked tyles.
		const found = linkListFromContent.find((backlinkId) => backlinkId === linkId);
		// if it is or is added to the list of links to remove, do nothing
		if (found || linkIdsToRemove.find((backlinkId) => backlinkId === found)) {
			return false;
		}
		linkIdsToRemove.push(linkId);
		return true;
	});

	return { linkIdsToAdd, linkIdsToRemove };
};

export default getTyleLinkDistinction;
