import { ReactElement } from 'react';
import styled from 'styled-components';
import { TimeFive } from '@styled-icons/boxicons-regular/TimeFive';
import { SearchQueryInterface } from 'interfaces/SearchQueryInterface';

const StyledSearchResult = styled.div.attrs((props: { selected: boolean }) => props)`
	display: flex;
	flex-flow: row;
	align-items: center;
	width: 100%;
	gap: 13px;
	font-size: 13px;
	color: ${(props) => props.theme.colors?.paragraph};
	background-color: ${(props) => (props.selected ? props.theme.colors?.gray500 : 'none')};
`;

const StyledTimeFiveIcon = styled(TimeFive)`
	width: 13px;
	height: 13px;
	color: ${(props) => props.theme.colors?.paragraph};
`;

export interface RecentSearchProps {
	tyle: SearchQueryInterface;
	selected: boolean;
}

const RecentSearch = ({ tyle, selected }: RecentSearchProps): ReactElement => {
	return (
		<StyledSearchResult selected={selected}>
			<StyledTimeFiveIcon />
			{tyle.text}
		</StyledSearchResult>
	);
};

export default RecentSearch;
