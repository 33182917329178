import React, { MouseEventHandler, ReactElement, useCallback, useEffect, useState } from 'react';
import { TyleInterface } from 'interfaces/TyleInterface';
import { subscribeKey } from 'valtio/utils';
import { nanoid } from 'nanoid';
import { DragItemTypeEnum } from 'interfaces/DragAndDrop/DragAndDropEnums';
import useTyleHooks from 'hooks/useTyleHooks';
import tylesState from 'state/TylesState';
import getTyleCardSource from 'helpers/getTyleCardSource';
import createTyleCardTextContent from 'helpers/createTyleCardTextContent';
import { getEmptyImage } from 'react-dnd-html5-backend';
import useTyleCardDragHooks from 'hooks/DragAndDropHooks/useTyleCardDragHooks';
import { StyledSource, StyledSourceIcon, StyledSourceLink, StyledSpan, StyledTyleCard } from './TyleCard.styled';

export interface TyleCardProps {
	tyle: TyleInterface;
	selected: boolean;
	onDoubleClick: () => void;
	onSingleClick?: () => void;
}

const TyleCard = ({ tyle, selected, onDoubleClick, onSingleClick }: TyleCardProps): ReactElement | null => {
	const [tyleToRender, setTyleToRender] = useState<TyleInterface>(
		tyle?.id && tylesState.tylesObjects[tyle.id] ? tylesState.tylesObjects[tyle.id] : tyle,
	);
	const { getTyle } = useTyleHooks();

	if (!tyle?.id) {
		return null;
	}

	const [{ isDragging }, drag, preview] = useTyleCardDragHooks({
		id: nanoid(),
		tyleId: tyle.id,
		type: DragItemTypeEnum.TYLE_CARD,
	});

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	const handleSourceClick: MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
		event.stopPropagation();
	}, []);

	useEffect(() => {
		if (!tyleToRender?.id) {
			return () => {};
		}

		/**
		 * Listen to the changes in the state to set the tyle
		 */
		const unsubscribe = subscribeKey(tylesState.tylesObjects, tyleToRender.id, (value: TyleInterface) => {
			if (!value && tyleToRender.id) {
				getTyle(tyleToRender.id).then((tyleFromBackend) => {
					if (!tyleFromBackend) {
						return;
					}
					setTyleToRender(tyleFromBackend);
				});
				return;
			}
			setTyleToRender(value);
		});

		return () => {
			unsubscribe();
		};
	}, [tyleToRender?.id]);

	return tyleToRender?.id ? (
		<StyledTyleCard
			selected={selected}
			isDragging={isDragging}
			data-testid="tyle-card"
			ref={drag}
			onDoubleClick={onDoubleClick}
			onClick={onSingleClick}
		>
			{tyleToRender?.source?.url && getTyleCardSource(tyleToRender.source) && (
				<StyledSource
					selected={selected}
					href={getTyleCardSource(tyleToRender.source)?.url ?? ''}
					target="_blank"
					onClick={handleSourceClick}
				>
					<StyledSourceIcon src={getTyleCardSource(tyleToRender.source)?.favicon} />
					<StyledSourceLink>{getTyleCardSource(tyleToRender.source)?.hostName ?? ''}</StyledSourceLink>
				</StyledSource>
			)}
			<StyledSpan>
				{tyleToRender?.title || createTyleCardTextContent(tyleToRender?.content ?? []) || 'Untitled'}
			</StyledSpan>
		</StyledTyleCard>
	) : null;
};

export default TyleCard;
