import TyleBlock from 'components/editor/components/TyleBlock';
import { MyPlatePlugin } from '../../types/plateTypes';
import ELEMENT_TYLE_BLOCK from './defaults';

/**
 * Enables support for tyle blocks.
 */
const createTyleBlockPlugin = (): MyPlatePlugin => ({
	key: ELEMENT_TYLE_BLOCK,
	type: ELEMENT_TYLE_BLOCK,
	isElement: true,
	isVoid: true,
	component: TyleBlock,
});

export default createTyleBlockPlugin;
