/**
 * Transform the content of a Tyle into a string to render in the Tyle Card into the sidebar
 * @param content
 */
const createTyleCardTextContent = (content: Array<any>): string => {
	if (!content) {
		return '';
	}
	let text = '';
	// Checks if first line is longer than two lines
	for (let i = 0; i < content.length; i++) {
		if ((!content[i]?.text && content[i].type === 'tyle-block') || content[i].type === 'tyle-inline') {
			text = content[i]?.tyle?.title ?? 'Untitled';
			break;
		}
		if (content[i].children.length && content[i].children[0].text) {
			text = content[i].children[0].text;
			break;
		}

		text = content[i].text;
	}
	return text;
};

export default createTyleCardTextContent;
