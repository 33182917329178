/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class PostSignUpRequest {
    'email'?: any;
    'loginType'?: any;
    'inviteCode'?: any;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "email",
            "baseName": "email",
            "type": "any",
            "format": ""
        },
        {
            "name": "loginType",
            "baseName": "loginType",
            "type": "any",
            "format": ""
        },
        {
            "name": "inviteCode",
            "baseName": "inviteCode",
            "type": "any",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PostSignUpRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

