import { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { TyleInterface } from 'interfaces/TyleInterface';
import { UserInterface } from 'interfaces/UserInterface';
import { NavigationBarActionDto } from 'interfaces/NavigationBarInterface';
import SearchSidebarHeader from 'components/SearchSidebarHeader/SearchSidebarHeader';
import SearchSidebarContent from 'components/SearchSidebarContent/SearchSidebarContent';
import UserMenu from 'components/user-menu/UserMenu';

const StyledSidebar = styled.nav`
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-width: 355px;
	max-width: 355px;
	background-color: ${(props) => props.theme.colors?.background};
	border-right: 1px solid ${(props) => props.theme.colors?.gray300};
	padding: 10px 20px 0 20px;
	overflow: auto;
`;

const StyledSearchSidebarFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 0;
	width: 315px;
	padding: 0;
	background-color: ${(props) => props.theme.colors?.background};
	border-top: 1px solid ${(props) => props.theme.colors?.gray300};
	min-height: 60px;
`;

export interface SearchSidebarProps {
	user: UserInterface;
	onSearch: (input: string) => void;
	createWindowInCanvas: (tyle: TyleInterface) => void;
	onUserMenuEvent: (event: NavigationBarActionDto) => void;
}

const SearchSidebar = ({ user, onSearch, createWindowInCanvas, onUserMenuEvent }: SearchSidebarProps): ReactElement => {
	const [inputText, setInputText] = useState<string>('');

	const handleInputChange = useCallback(
		(input: string) => {
			setInputText(input);
			onSearch(input);
		},
		[onSearch],
	);

	return (
		<StyledSidebar>
			<SearchSidebarHeader handleInputChange={handleInputChange} inputText={inputText} />
			<SearchSidebarContent createWindowInCanvas={createWindowInCanvas} changeInputText={setInputText} />
			<StyledSearchSidebarFooter>
				<UserMenu id="user-menu" user={user} onEvent={onUserMenuEvent} route="" />
			</StyledSearchSidebarFooter>
		</StyledSidebar>
	);
};

export default SearchSidebar;
