import { ReactElement, useCallback } from 'react';
import { TerminalViewEnum } from 'interfaces/TerminalMenuInterface';
import canvasState from 'state/CanvasState';
import terminalState from 'state/TerminalState';
import { useSnapshot } from 'valtio';
import Input from '../input';

interface Props {
	value?: string;
	focused?: boolean;
	onChange?: (value: string) => void;
	onSubmit?: (value: string) => void;
	style?: Record<string, any>;
	rows?: number;
}

const TyleTitle = ({ value, style, onChange, onSubmit, focused, rows }: Props): ReactElement => {
	const { navigation } = useSnapshot(terminalState);
	const { isPublicView } = useSnapshot(canvasState);

	const shouldFocus = useCallback(() => {
		switch (navigation) {
			case TerminalViewEnum.CREATE_TYLE:
				return true;
			case TerminalViewEnum.ADD_TO_NEW_TYLE:
				return true;
			default:
				return false;
		}
	}, [navigation]);

	return (
		<Input
			value={value}
			onChange={onChange}
			onSubmit={onSubmit}
			disabled={isPublicView}
			rows={rows}
			autogrow
			focus={focused || shouldFocus()}
			placeholder="Untitled"
			size="title"
			style={{
				...style,
				width: '100%',
			}}
		/>
	);
};

export default TyleTitle;
