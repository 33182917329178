import React, { ReactElement, ReactNode } from 'react';
import { Modal as BsModal } from 'react-bootstrap';

export interface FooterProps {
	children: ReactNode;
}
const Footer = ({ children }: FooterProps): ReactElement => {
	return (
		<BsModal.Footer>
			<div className="kum-modal-footer">{children}</div>
		</BsModal.Footer>
	);
};

export default Footer;
