import { CSSProperties, ReactElement, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import PuffLoader from 'react-spinners/PuffLoader';
import useTyleHooks from 'hooks/useTyleHooks';
import { TyleInterface } from 'interfaces/TyleInterface';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import AutocompleteDropdown from 'components/AutocompleteDropdown';
import { AutocompleteDropdownProps } from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { StyledContainer } from 'components/AutocompleteDropdown/AutocompleteDropdown.styled';
import light from 'themes/light';

const override: CSSProperties = {
	position: 'relative',
	left: '45%',
};

interface Props extends Omit<AutocompleteDropdownProps, 'items' | 'isLoading'> {
	tyleId: string | undefined;
	creationStatus: 'loading' | 'idle';
}

const AutocompleteDropdownContainer = ({
	tyleId,
	placeholder,
	defaultItem,
	onSelect,
	onSubmit,
	onNewOptionClick,
	creationStatus,
}: Props): ReactElement => {
	const { getSearchResultsByTitle } = useTyleHooks();
	const [items, setItems] = useState<SelectableItem[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const filterItems = (tyles: TyleInterface[], input: string): SelectableItem[] => {
		return tyles
			.filter((tyle) => {
				return tyle.title && tyle.id !== tyleId && tyle.title.toLowerCase().startsWith(input.toLowerCase());
			})
			.slice(0, 10)
			.map((tyle) => ({
				label: tyle.title || 'Untitled',
				value: tyle.id || '',
			}));
	};

	const handleChange = useCallback(
		debounce((input: string) => {
			if (!input) {
				setItems([]);
				return;
			}
			setIsLoading(true);
			getSearchResultsByTitle(input, {}, 8).then((results) => {
				setItems(filterItems(results, input));
				setIsLoading(false);
			});
		}, 200),
		[],
	);

	return creationStatus === 'loading' ? (
		<StyledContainer role="searchbox">
			<PuffLoader
				color={light.colors.tagText}
				loading
				cssOverride={override}
				size={25}
				aria-label="Loading Spinner"
				data-testid="loader"
			/>
		</StyledContainer>
	) : (
		<AutocompleteDropdown
			placeholder={placeholder || 'type a note'}
			items={items}
			prefix="Add to: "
			onSelect={onSelect}
			onSubmit={onSubmit}
			onChange={handleChange}
			onNewOptionClick={onNewOptionClick}
			defaultItem={defaultItem}
			isLoading={isLoading}
		/>
	);
};

export default AutocompleteDropdownContainer;
