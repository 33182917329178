import { useState } from 'react';
import { detectEnvironment } from './index';
import { EnvironmentEnum } from './detectEnvironment';

const useEnvironmentDetection = (): EnvironmentEnum => {
	const [environment] = useState(detectEnvironment());

	return environment;
};

export default useEnvironmentDetection;
