import { useRef } from 'react';
import useMergedRef from '@react-hook/merged-ref';
import { getDraggableStyles } from '@udecode/plate-ui-dnd';
import styled, { CSSProp } from 'styled-components';
import useDndBlockCustom from './hooks/useDndBlockCustom';
import './withDraggables.scss';

const StyledDiv = styled.div.attrs((props: { cssStyle: Array<CSSProp> }) => props)`
	${(props) => props.cssStyle}
`;

const DraggableCustom = (props: any) => {
	const { children, element, componentRef, onRenderDragHandle, editor } = props;

	const DragHandle = onRenderDragHandle;

	const blockRef = useRef<HTMLDivElement>(null);
	const rootRef = useRef<HTMLDivElement>(null);
	const dragWrapperRef = useRef(null);
	const multiRootRef = useMergedRef(componentRef, rootRef);

	const { dropLine, dragRef, isDragging } = useDndBlockCustom({
		id: element.id,
		type: element.type,
		blockRef: rootRef,
		editor,
	});

	const multiDragRef = useMergedRef(dragRef, dragWrapperRef);

	const styles = getDraggableStyles({
		...props,
		direction: dropLine,
		isDragging,
	});

	return (
		<StyledDiv cssStyle={styles.root.css} className={styles.root.className} ref={multiRootRef}>
			<StyledDiv ref={blockRef} cssStyle={[...(styles.blockAndGutter?.css ?? []), ...(styles.block?.css ?? [])]}>
				{children}

				{!!dropLine && (
					<StyledDiv cssStyle={styles.dropLine?.css} className={styles.dropLine?.className} contentEditable={false} />
				)}
			</StyledDiv>

			<StyledDiv
				cssStyle={[...(styles.blockAndGutter?.css ?? []), ...(styles.gutterLeft?.css ?? [])]}
				className={styles.gutterLeft?.className}
				contentEditable={false}
			>
				<StyledDiv cssStyle={styles.blockToolbarWrapper?.css} className={styles.blockToolbarWrapper?.className}>
					<StyledDiv cssStyle={styles.blockToolbar?.css} className={styles.blockToolbar?.className} ref={multiDragRef}>
						<DragHandle
							element={element}
							styles={styles.dragHandle?.css}
							className={styles.dragHandle?.className}
							onMouseDown={(e: any) => e.stopPropagation()}
							editor={editor}
						/>
					</StyledDiv>
				</StyledDiv>
			</StyledDiv>
		</StyledDiv>
	);
};

export default DraggableCustom;
