import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';

export interface MenuProps {
	label?: string;
	children?: any;
}

const StyledLabel = styled.div`
	color: ${(props) => props.theme.colors.gray100};
	font-size: ${(props) => props.theme.fontSizes.headingSmall};
	padding: 0 0 0 24px;
	margin: 0 0 3px 0;
	font-weight: ${(props) => props.theme.fontWeights.medium};

	&:first-child {
		margin-top: 15px;
	}
`;

const StyledMenuDiv = styled.div`
	&:not(:last-child) {
		margin: 0 0 5px 0;
	}

	.list-group-item {
		padding: 0 0 0 27px;
		color: ${(props) => props.theme.colors.gray100};
		font-size: ${(props) => props.theme.fontSizes.paragraph};
		border: 0;
		height: 46px;

		&:hover {
			background: ${(props) => props.theme.colors.gray300};
		}

		&.active {
			color: ${(props) => props.theme.colors.black100};
			background: ${(props) => props.theme.colors.gray300};
		}

		&:first-child,
		&:last-child {
			border-radius: 0;
		}
	}
`;

const Menu = ({ label, children }: MenuProps): ReactElement => {
	return (
		<StyledMenuDiv>
			{label && <StyledLabel>{label}</StyledLabel>}
			<ListGroup>{children}</ListGroup>
		</StyledMenuDiv>
	);
};

export default Menu;
