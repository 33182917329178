import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import SignInPage from 'pages/SignInPage';
import TokenValidationPage from '../pages/TokenValidationPage';
import IntroScreenWrapper from './IntroductionScreen';

const LoggedOutApp = (): ReactElement => {
	return (
		<div className="App theme-light">
			<Switch>
				<Route path="/signup">
					<IntroScreenWrapper>
						<SignInPage mode="signup" />
					</IntroScreenWrapper>
				</Route>
				<Route path="/login">
					<IntroScreenWrapper>
						<SignInPage mode="login" />
					</IntroScreenWrapper>
				</Route>
				<Route path="/:type/token/:token">
					<TokenValidationPage />
				</Route>
				<Route path="/">
					<IntroScreenWrapper>
						<SignInPage mode="login" />
					</IntroScreenWrapper>
				</Route>
			</Switch>
		</div>
	);
};

export default LoggedOutApp;
