import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { TyleInterface } from 'interfaces/TyleInterface';

const StyledLi = styled.li`
	width: 100%;
`;

const StyledSearchSidebarOption = styled.div`
	cursor: pointer;
	padding: 3px 10px;
	border-radius: 5px;
	width: 100%;
`;

interface SearchSidebarItemOptionProps {
	optionComponent: ReactNode; // todo define option
	value: any;
	selected: boolean;
	onSelect: (option: TyleInterface) => void; // todo define option
}

const SearchSidebarContentSectionOption = ({
	optionComponent,
	value,
	selected,
	onSelect,
}: SearchSidebarItemOptionProps): ReactElement => {
	const ref = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (selected) {
			ref.current?.focus();
			ref.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [selected]);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		onSelect(value);
	};

	return (
		<StyledLi ref={ref}>
			<StyledSearchSidebarOption
				key={nanoid()}
				onClick={handleClick}
				role="button"
				aria-pressed={selected}
				tabIndex={0}
			>
				{optionComponent}
			</StyledSearchSidebarOption>
		</StyledLi>
	);
};

export default SearchSidebarContentSectionOption;
