import React, { ReactElement } from 'react';
import './CodeBlock.scss';

export interface CodeProps {
	children?: any;
}

export const CodeBlock = ({ children }: CodeProps): ReactElement => {
	return (
		<pre className="kum-code-block">
			<code>{children}</code>
		</pre>
	);
};
