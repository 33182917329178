import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import Paragraph from 'components/paragraph';
import Button from 'components/button';
import ReactPlayer from 'react-player';
import {
	StyledDesktopOnboardingContainer,
	StyledAnimationContainer,
	StyledCarouselContentHeadline1,
	StyledCarouselContentHeadline2,
	StyledCarousel,
	StyledCarouselContent,
	StyledCarouselContentWrapper,
	StyledArrowLeft,
	StyledArrowRight,
	StyledAnimationVideoImageWrapper,
} from './DesktopOnBoarding.styled';
import dark from '../../themes/dark';
import { detectOperatingSystem } from '../../helpers';
import { OperatingSystemPlatformEnum } from '../../helpers/detectOperatingSystem';
import IPCChannel from '../../model/IPC/IPCChannel';
import useElectronEvents from '../../helpers/useElectronEvents';
import { ArrowLeftTestId, ArrowRightTestId, getSlides } from './helpers/desktopOnBoardingHelper';
import { ApiContext } from '../../components/App';
import userState from '../../state/UserState';

const DesktopOnBoarding = (): ReactElement => {
	const api = useContext(ApiContext);
	const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const { sendMessage } = useElectronEvents();

	const handleLeftArrowClick = useCallback(() => {
		setActiveIndex(activeIndex - 1);
	}, [activeIndex]);

	const handleRightArrowClick = useCallback(() => {
		if (!userState.user.desktopOnboarded) {
			// Set desktopOnboarded to true in the user account when the tutorial is shown
			api.updateUserAccount({ ...userState.user, desktopOnboarded: true }).then();
		}
		setActiveIndex(activeIndex + 1);
	}, [activeIndex]);

	const renderContent = (headline1: string, headline2: string, paragraph: string, index: number) => {
		return (
			<StyledCarouselContentWrapper>
				<div data-testid={ArrowLeftTestId + index}>
					<StyledArrowLeft type="arrow-left-line-big" onClick={handleLeftArrowClick} hidden={activeIndex === 0} />
				</div>
				<StyledCarouselContent>
					<StyledCarouselContentHeadline1>
						{headline1} <StyledCarouselContentHeadline2>{headline2}</StyledCarouselContentHeadline2>
					</StyledCarouselContentHeadline1>
					<Paragraph
						style={{
							color: dark.colors.paragraph,
							textAlign: 'center',
							margin: activeIndex === getSlides(isMacOS).length - 1 ? '15px 0 30px 0' : '15px 0 35px 0',
							width: '100%',
						}}
					>
						{paragraph}
					</Paragraph>
				</StyledCarouselContent>
				<div data-testid={ArrowRightTestId + index}>
					<StyledArrowRight
						type="arrow-right-line-big"
						onClick={handleRightArrowClick}
						hidden={activeIndex === getSlides(isMacOS).length - 1}
					/>
				</div>
			</StyledCarouselContentWrapper>
		);
	};
	const carouselSlides = () => {
		return getSlides(isMacOS).map((content, index) =>
			renderContent(content.headline1, content.headline2, content.paragraph, index),
		);
	};
	return (
		<StyledDesktopOnboardingContainer showDots={activeIndex !== getSlides(isMacOS).length - 1}>
			<StyledAnimationContainer
				imageBackground={activeIndex === getSlides(isMacOS).length - 1 ? 'assets/images/desktop_onboarding_3.png' : ''}
			>
				{getSlides(isMacOS)[activeIndex]?.videoPath && (
					<StyledAnimationVideoImageWrapper>
						<ReactPlayer
							muted
							url={getSlides(isMacOS)[activeIndex]?.videoPath}
							width="100%"
							height="100%"
							loop
							playing
						/>
					</StyledAnimationVideoImageWrapper>
				)}
			</StyledAnimationContainer>
			<StyledCarouselContent>
				<StyledCarousel
					currentIndex={activeIndex}
					slides={carouselSlides()}
					loop={false}
					updateActiveIndex={setActiveIndex}
				/>
				{activeIndex === getSlides(isMacOS).length - 1 && (
					<Button
						style={{
							backgroundColor: '#343434',
							border: 'none',
							color: '#ededed',
							marginBottom: '15px',
						}}
						label="Close Tutorial"
						onEvent={() =>
							sendMessage({
								subject: IPCChannel.CLOSE_TUTORIAL_ON_BOARDING,
								message: 'close',
							})
						}
					/>
				)}
			</StyledCarouselContent>
		</StyledDesktopOnboardingContainer>
	);
};

export default DesktopOnBoarding;
