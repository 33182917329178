import React, { ReactNode } from 'react';
import { TippyProps } from '@tippyjs/react';
import {
	ImageToolbarButton,
	MARK_BOLD,
	MARK_CODE,
	MARK_HIGHLIGHT,
	MARK_ITALIC,
	MARK_KBD,
	MARK_STRIKETHROUGH,
	MARK_UNDERLINE,
	MediaEmbedToolbarButton,
} from '@udecode/plate';
import { Keyboard } from '@styled-icons/fluentui-system-regular/Keyboard';
import { Highlight } from '@styled-icons/boxicons-regular/Highlight';
import { Code } from '@styled-icons/feather/Code';
import { Image } from '@styled-icons/material/Image';
import { OndemandVideo } from '@styled-icons/material/OndemandVideo';
import Tooltip from 'components/tooltip/Tooltip';
import { detectOperatingSystem } from 'helpers';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import styled from 'styled-components';

const StyledSpan = styled.span`
	width: 16px;
	height: 100%;
`;

const tooltip: TippyProps = {
	arrow: true,
	delay: 0,
	duration: [200, 0],
	hideOnClick: false,
	offset: [0, 17],
	placement: 'top',
};

const createTooltip = (label: string): TippyProps => {
	return {
		content: label,
		...tooltip,
	};
};

export type ToolbarProps = {
	type: string;
	icon: ReactNode;
	tooltip: TippyProps;
	hideMore?: boolean;
	label: string;
};

const isMacOrWindows = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS ? '⌘' : 'ctrl + ';

export const defaultTools: ToolbarProps[] = [
	{
		type: MARK_BOLD,
		icon: (
			<Tooltip tip={`Bold (${isMacOrWindows}B)`}>
				<strong>B</strong>
			</Tooltip>
		),
		tooltip: createTooltip('Bold (⌘B)'),
		label: 'Bold (⌘B)',
	},
	{
		type: MARK_ITALIC,
		icon: (
			<Tooltip tip={`Italic (${isMacOrWindows}I)`}>
				<i>i</i>
			</Tooltip>
		),
		tooltip: createTooltip('Italic (⌘I)'),
		label: 'Italic (⌘I)',
	},
	{
		type: MARK_UNDERLINE,
		icon: (
			<Tooltip tip={`Underlined (${isMacOrWindows}U)`}>
				<span style={{ textDecoration: 'underline' }}>U</span>
			</Tooltip>
		),
		tooltip: createTooltip('Underline (⌘U)'),
		label: 'Underline (⌘U)',
	},
	{
		type: MARK_STRIKETHROUGH,
		icon: (
			<Tooltip tip="Strikethrough">
				<s>S</s>
			</Tooltip>
		),
		tooltip: createTooltip('Strikethrough'),
		label: 'Strikethrough',
	},
	{
		type: MARK_CODE,
		icon: (
			<Tooltip tip="Inline code">
				<StyledSpan>
					<Code width="16px !important" />
				</StyledSpan>
			</Tooltip>
		),
		tooltip: createTooltip('Inline code'),
		label: 'Inline code',
	},
	{
		type: MARK_KBD,
		icon: (
			<Tooltip tip="Keyboard input">
				<StyledSpan>
					<Keyboard width="16px !important" />
				</StyledSpan>
			</Tooltip>
		),
		tooltip: createTooltip('Keyboard Input'),
		label: 'Keyboard input',
		hideMore: true,
	},
	{
		type: MARK_HIGHLIGHT,
		icon: (
			<Tooltip tip="Highlight">
				<StyledSpan>
					<Highlight size="16px" width="16px !important" />
				</StyledSpan>
			</Tooltip>
		),
		tooltip: createTooltip('Highlight'),
		label: 'Highlight',
		hideMore: true,
	},
	/* 	{
		type: 'Image',
		icon: <ImageToolbarButton icon={<Image />} />,
		tooltip: createTooltip('Image'),
		label: 'Image',
	},
	{
		type: 'Video',
		icon: <MediaEmbedToolbarButton icon={<OndemandVideo />} />,
		tooltip: createTooltip('Video'),
		label: 'Video',
	}, */
];
