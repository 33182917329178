import { detectOperatingSystem } from './index';
import { OperatingSystemPlatformEnum } from './detectOperatingSystem';
import { DesktopAppTypeEnum } from '../interfaces/UserSettingsInterface';

const getDownloadLink = (type?: DesktopAppTypeEnum): string => {
	const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
	const isWindows = detectOperatingSystem() === OperatingSystemPlatformEnum.WINDOWS;

	if (!type && isMacOS) {
		const isIntel = navigator.userAgent.match(/OS X 10_([56789]|1[01234])/);

		if (isIntel) {
			return `download/${DesktopAppTypeEnum.APPLE_INTEL}/mac`;
		}
		return `download/${DesktopAppTypeEnum.APPLE_SILICON}/mac`;
	}

	if (!type && isWindows) {
		return `download/${DesktopAppTypeEnum.WINDOWS_64}/win`;
	}

	return isMacOS ? `download/${type}/mac` : `download/${type}/win`;
};

export default getDownloadLink;
