export enum OperatingSystemPlatformEnum {
	WINDOWS = 'windows',
	MAC_OS = 'macOs',
	ANDROID = 'android',
	IOS = 'iOs',
	OTHER = 'other',
}

interface OsInformation {
	s: string;
	p?: OperatingSystemPlatformEnum;
	r: RegExp;
}

const detectOperatingSystem = (): OperatingSystemPlatformEnum => {
	const nAgt = navigator.userAgent;

	const clientStrings: Array<OsInformation> = [
		{ s: 'Windows 10', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows 10.0|Windows NT 10.0)/ },
		{ s: 'Windows 8.1', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows 8.1|Windows NT 6.3)/ },
		{ s: 'Windows 8', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows 8|Windows NT 6.2)/ },
		{ s: 'Windows 7', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows 7|Windows NT 6.1)/ },
		{ s: 'Windows Vista', p: OperatingSystemPlatformEnum.WINDOWS, r: /Windows NT 6.0/ },
		{ s: 'Windows Server 2003', p: OperatingSystemPlatformEnum.WINDOWS, r: /Windows NT 5.2/ },
		{ s: 'Windows XP', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows NT 5.1|Windows XP)/ },
		{ s: 'Windows 2000', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows NT 5.0|Windows 2000)/ },
		{ s: 'Windows ME', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Win 9x 4.90|Windows ME)/ },
		{ s: 'Windows 98', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows 98|Win98)/ },
		{ s: 'Windows 95', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows 95|Win95|Windows_95)/ },
		{ s: 'Windows NT 4.0', p: OperatingSystemPlatformEnum.WINDOWS, r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ s: 'Windows 3.11', p: OperatingSystemPlatformEnum.WINDOWS, r: /Win16/ },
		{ s: 'Android', p: OperatingSystemPlatformEnum.ANDROID, r: /Android/ },
		{ s: 'Open BSD', r: /OpenBSD/ },
		{ s: 'Sun OS', r: /SunOS/ },
		{ s: 'Chrome OS', r: /CrOS/ },
		{ s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
		{ s: 'iOS', p: OperatingSystemPlatformEnum.IOS, r: /(iPhone|iPad|iPod)/ },
		{ s: 'Mac OS X', p: OperatingSystemPlatformEnum.MAC_OS, r: /Mac OS X/ },
		{ s: 'Mac OS', p: OperatingSystemPlatformEnum.MAC_OS, r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ s: 'QNX', r: /QNX/ },
		{ s: 'UNIX', r: /UNIX/ },
		{ s: 'BeOS', r: /BeOS/ },
		{ s: 'OS/2', r: /OS\/2/ },
		{ s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
	];
	const os = clientStrings.find((cs) => {
		return cs.r.test(nAgt);
	});

	if (!os) {
		return OperatingSystemPlatformEnum.OTHER;
	}

	return os.p || OperatingSystemPlatformEnum.OTHER;
};

export default detectOperatingSystem;
