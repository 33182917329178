import { useDrag } from 'react-dnd';
import { DragTyleCardInterface } from 'interfaces/DragAndDrop/DragAndDropInterfaces';
import tylesState from 'state/TylesState';

const useTyleCardDragHooks = ({ id, tyleId, type }: DragTyleCardInterface) => {
	return useDrag(
		() => ({
			type,
			item(): DragTyleCardInterface {
				return {
					id,
					tyleId,
					type,
				};
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
				opacity: monitor.isDragging() ? 0.4 : 1,
			}),
			end(_, monitor) {
				tylesState.dropResult = monitor.getDropResult();
			},
		}),
		[id, tyleId, type],
	);
};

export default useTyleCardDragHooks;
