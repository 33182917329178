import React, { ReactElement, useEffect, useState } from 'react';
import { TyleInterface } from 'interfaces/TyleInterface';
import { TyleWindowActionEnum, TyleWindowViews } from 'interfaces/TyleWindowInterface';
import IndeterminateProgressBar from 'components/IndeterminateProgressBar';
import { useSnapshot } from 'valtio';
import StyledTyleWindowContent from './TyleWindowContent.styled';
import { BacklinksView, EditorView, LinksView } from './Views';
import newCanvasState from '../../../state/NewCanvas';

interface TyleWindowContentProps {
	columns: number;
	tyle: TyleInterface | undefined;
	view: TyleWindowViews;
	changeView: (action: TyleWindowActionEnum) => void;
	windowId: string;
	scrollTop: number;
	onTitleChange: (title: string) => void;
	createWindowForTyle: (tyle: TyleInterface) => void;
	updateScrollTop: (scrollTop: number) => void;
	tyleUrl?: string;
}

const TyleWindowContent = ({
	columns,
	tyle,
	view,
	windowId,
	scrollTop,
	changeView,
	onTitleChange,
	createWindowForTyle,
	updateScrollTop,
	tyleUrl,
}: TyleWindowContentProps): ReactElement | null => {
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
	const { loadingWindowId } = useSnapshot(newCanvasState);

	useEffect(() => {
		if (newCanvasState.loadingWindowId === windowId) {
			setShowProgressBar(true);
		}
		setShowProgressBar(false);
	}, [loadingWindowId, windowId]);

	return (
		<StyledTyleWindowContent
			key={tyle?.id}
			columns={columns}
			initial={{ opacity: 0, x: 20 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.5, opacity: { stiffness: 1000 } }}
		>
			{showProgressBar && <IndeterminateProgressBar />}
			{view === TyleWindowViews.EDITOR && (
				<EditorView
					tyleId={tyle?.id}
					tyleUrl={tyleUrl}
					tyleTitle={tyle?.title}
					tyleSourceName={tyle?.source?.applicationName}
					windowId={windowId}
					onTitleChange={onTitleChange}
					scrollTop={scrollTop}
					updateScrollTop={updateScrollTop}
				/>
			)}
			{view === TyleWindowViews.LINKS && tyle && (
				<LinksView
					windowId={windowId}
					publicTyle={tyle}
					linksConnections={tyle.link}
					tyleId={tyle?.id}
					createWindowForTyle={createWindowForTyle}
					changeView={changeView}
				/>
			)}
			{view === TyleWindowViews.BACKLINKS && tyle && (
				<BacklinksView
					windowId={windowId}
					tyleId={tyle?.id}
					backlinkConnections={tyle?.backlink || []}
					createWindowForTyle={createWindowForTyle}
					changeView={changeView}
				/>
			)}
		</StyledTyleWindowContent>
	);
};

export default TyleWindowContent;
