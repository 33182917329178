import { TyleInterface } from '../interfaces/TyleInterface';
import { CustomElement } from '../types/slate';

const findText = (content: Array<CustomElement>) => {
	let text = '';

	content.map((child: CustomElement) => {
		// todo: if child = image, text = "Image"

		if ('text' in child && !!child.text) {
			text += child.text;
		}

		if ('children' in child && !!child.children) {
			text += findText(child.children);
		}

		return child;
	});

	return text;
};

const tyleContentPreview = (tyle: TyleInterface | null): string => {
	if (!tyle) {
		return '';
	}

	const { content } = tyle;
	const contentCount = content?.length;

	if (content && contentCount && contentCount > 0) {
		let contentAsString = '';
		for (let count = 0; count < contentCount; count++) {
			contentAsString += findText([content[count]]);
			if (count < contentCount - 1 && findText([content[count]]) === '') {
				contentAsString += ' · ';
			}
		}
		return contentAsString;
	}

	if (tyle.source && tyle.source.title) {
		return tyle.source.title;
	}

	// todo?
	return 'Tyle';
};

const tylePreview = (tyle: TyleInterface | null): string => {
	if (!tyle) {
		return '';
	}

	if (tyle?.title && tyle?.title !== '') {
		return tyle.title;
	}

	return tyleContentPreview(tyle);
};

export default { tyleContentPreview, getTylePreview: tylePreview };
