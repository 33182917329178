import { useContext } from 'react';
import { ApiContext } from 'components/App';
import useElectronEvents from '../helpers/useElectronEvents';
import { EnvironmentEnum } from '../helpers/detectEnvironment';

const useLogin = () => {
	const api = useContext(ApiContext);
	const { sendMessage } = useElectronEvents();

	const login = async ({ email, type }: { email: string; type: EnvironmentEnum }) => {
		await api.sendLoginRequest(email, type).then((data) => {
			sendMessage({ subject: 'user', message: 'login' });
			return data;
		});
	};

	const loggedIn = () => {
		sendMessage({ subject: 'user', message: 'loggedIn' });
	};

	return { login, loggedIn };
};

export default useLogin;
