import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { TylesState } from '../models/State';
import { TyleInterface } from '../interfaces/TyleInterface';

const tylesState = proxy<TylesState>({
	status: 'idle',
	tylesObjects: {},
	inboxTyles: [],
	searchResultsTyles: [],
	favoriteTyles: [],
	recentTyles: [],
	cmdOrCtrlClickedTyle: '',
	clickedTyle: undefined,
	selectedTyle: undefined,
	receivingTyle: undefined,
	tyleToAdd: null,
	unsavedTyle: true,
	justCreatedTyle: null,
	isTyleTransformed: false,
	isTitleSubmit: false,
	isDeleteBlock: false,
	isDuplicateBlock: false,
	dropResult: null,
});

export const tylesObjectActions = {
	addTyleObject({ id, tyle }: { id: string; tyle: TyleInterface }) {
		if (tylesState.tylesObjects[id]) {
			this.updateTyleObject({ id, newTyleProperties: tyle });
			return;
		}
		tylesState.tylesObjects[id] = tyle;
	},
	getTyleWithIdFromState(id: string): TyleInterface {
		if (!tylesState.tylesObjects[id]) {
			throw new Error('Tyle not found');
		}
		return tylesState.tylesObjects[id];
	},
	updateTyleObject({ id, newTyleProperties }: { id: string; newTyleProperties: Partial<TyleInterface> }) {
		const tyleToUpdate = tylesState.tylesObjects[id];
		// If inbox is false, remove element from inbox tyles
		if (!newTyleProperties.inbox && tylesState.inboxTyles.find((t) => t?.id === id)) {
			tylesState.inboxTyles = tylesState.inboxTyles.filter((inboxTyle) => inboxTyle?.id !== id);
		}
		if (tyleToUpdate) tylesState.tylesObjects[id] = { ...tyleToUpdate, ...newTyleProperties };
	},
	deleteTyleObject({ id }: { id: string }) {
		delete tylesState.tylesObjects[id];
		tylesState.recentTyles = tylesState.recentTyles.filter((tyle: TyleInterface) => tyle?.id !== id);
		tylesState.favoriteTyles = tylesState.favoriteTyles.filter((tyle: TyleInterface) => tyle?.id !== id);
		tylesState.searchResultsTyles = tylesState.searchResultsTyles.filter((tyle: TyleInterface) => tyle?.id !== id);
		tylesState.inboxTyles = tylesState.inboxTyles.filter((tyle: TyleInterface) => tyle?.id !== id);
		if (tylesState.selectedTyle?.id === id) {
			tylesState.selectedTyle = undefined;
		}
	},
	clearInboxTyles() {
		Object.keys(tylesState.tylesObjects).forEach((tyleId) => {
			this.updateTyleObject({ id: tyleId, newTyleProperties: { inbox: false } });
		});
		tylesState.inboxTyles = [];
	},
};

devtools(tylesState, { name: 'Tyles' });

export default tylesState;
