/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Tyle } from '../models/Tyle';
import { HttpFile } from '../http/http';

export class EditorBlock {
    'id'?: string;
    'position'?: number;
    'type'?: string;
    'url'?: string;
    'children'?: Array<any>;
    'checked'?: boolean;
    'tyle'?: Tyle;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "number",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string",
            "format": ""
        },
        {
            "name": "children",
            "baseName": "children",
            "type": "Array<any>",
            "format": ""
        },
        {
            "name": "checked",
            "baseName": "checked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "tyle",
            "baseName": "tyle",
            "type": "Tyle",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return EditorBlock.attributeTypeMap;
    }

    public constructor() {
    }
}

