import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import Tag from 'components/Tags/Tag';
import { RefObject } from 'react';
import sharedState from 'state/SharedState';
import tagsState from 'state/TagsState';
import styled from 'styled-components';

export const StyledAutocomplete = styled.ul`
	position: fixed;
	z-index: 1;
	display: flex;
	flex-flow: column;
	gap: 5px;
	list-style-type: none;
	width: 100%;
	background-color: ${(props) => props.theme.colors?.background};
	border: 1px solid ${(props) => props.theme.colors?.stroke};
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 5px 5px;
	width: 215px;
	max-height: 164px;
	overflow-y: scroll;
`;

export const StyledLiElement = styled.div.attrs((props: { selected: boolean }) => props)`
	display: flex;
	align-items: center;
	border-radius: 8px;
	color: ${(props) => props.theme.colors?.paragraph};
	background-color: ${({ selected, theme }) => (selected ? theme.colors?.selected : 'none')};
	font-size: 14px;
	width: 100%;
	cursor: pointer;
`;

export const StyledListItem = styled.span`
	padding: 0px 5px;
`;

export const setChange = (selectRef: RefObject<HTMLUListElement>) => {
	if (selectRef?.current) {
		const selected = selectRef?.current?.querySelector('.active');
		if (selected) {
			selected?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}
};

export const renderItem = (item: SelectableItem) => {
	switch (sharedState.searchTrigger) {
		case '@': // Users
			return <StyledListItem>{item.label}</StyledListItem>;
		case '#': // Tags
			return <Tag tag={item} selected={tagsState.selectedTagIds.has(item.value)} />;
		case ':': // Emojis
			return <StyledListItem>{item.label}</StyledListItem>;
		default:
			return <StyledListItem>No Results</StyledListItem>;
	}
};
