import InvalidDataException from 'exceptions/InvalidDataException';
import { Tag } from 'models/Tag';
import { Tag as ApiTag } from '../api/generated/client';

const adaptTag = (tag: ApiTag): Tag => {
	if (!tag.id || !tag.label) {
		throw new InvalidDataException('Tag not valid.');
	}

	return new Tag({
		id: tag.id,
		label: tag.label,
		createdAt: new Date(`${tag.createdAt}`),
	});
};

export default adaptTag;
