/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { subscribe, useSnapshot } from 'valtio';
import { debounce } from 'lodash';
import { onMouseWheel } from 'hooks/ScrollHooks';
import useCanvasHooks from 'hooks/useCanvasHooks';
import newCanvasState from 'state/NewCanvas';
import Paragraph from 'components/paragraph';
import { StorageKeys } from 'services/StorageService';
import Column from './Column';
import { reorder } from './helpers';
import { Container, ContainerPlaceholder } from './HorizontalCanvas.styled';

const HorizontalCanvas = () => {
	const { userCanvasTyles } = useSnapshot(newCanvasState);
	const { reorderTyles, addTyleToUserCanvasTyles, autoSaveUserTyles, getUserAccount } = useCanvasHooks();
	const mutationResult = useMutation(autoSaveUserTyles);

	// Autosave canvas state to backend every 5s
	const handleDebouncedAutoSave = useMemo(
		() =>
			debounce(() => {
				mutationResult.mutateAsync();
			}, 100 * 60 * 5),
		[mutationResult.mutateAsync],
	);

	// Save new canvas state to local storage when it changes
	useEffect(() => {
		const unsubscribeFromCanvasState = subscribe(newCanvasState, () => {
			localStorage.setItem(StorageKeys.USER_CANVAS_TYLES, JSON.stringify(newCanvasState.userCanvasTyles)); // Update local storage with new canvas state
			handleDebouncedAutoSave();
		});

		// Add event listener to storage, if canvas state is empty, load from api
		const checkCanvasData = () => {
			const userCanvasTylesFromStorage = localStorage.getItem(StorageKeys.USER_CANVAS_TYLES);
			if (!userCanvasTylesFromStorage) {
				useQuery({
					queryKey: ['userAccount'],
					queryFn: getUserAccount,
					refetchOnWindowFocus: true,
				});
			}
		};

		window.addEventListener('storage', checkCanvasData);

		return () => {
			unsubscribeFromCanvasState();
			window.removeEventListener('storage', checkCanvasData);
		};
	}, []);

	const onDragEnd = async (result: any) => {
		if (result.combine) {
			if (result.type === 'COLUMN') {
				const shallow = [...newCanvasState.userCanvasTyles.order];
				shallow.splice(result.source.index, 1);
				await reorderTyles({ newOrder: shallow, selectedWindowId: result.draggableId });
				return;
			}
			return;
		}

		// dropped nowhere
		if (!result.destination) {
			return;
		}

		const { source, destination } = result;

		// did not move anywhere - can bail early
		if (source.droppableId === destination.droppableId && source.index === destination.index) {
			return;
		}

		// reordering column
		if (result.type === 'COLUMN') {
			await reorderTyles({
				newOrder: reorder(userCanvasTyles.order, source.index, destination.index),
				selectedWindowId: result.draggableId,
			});
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="canvas" type="COLUMN" direction="horizontal">
				{(provided, snapshot) => (
					<Container
						id="horizontal-canvas"
						ref={provided.innerRef}
						isDragging={snapshot.isDraggingOver}
						{...provided.droppableProps}
						onWheel={onMouseWheel}
					>
						{userCanvasTyles?.order?.map((key, index) => {
							return (
								<Column
									key={key}
									index={index}
									windowId={key}
									columns={userCanvasTyles.order.length > 1 ? 2 : 1}
									createWindowForTyle={(tyle) => {
										if (!tyle.id) {
											return;
										}
										addTyleToUserCanvasTyles({ tyleId: tyle.id, windowId: key });
									}}
								/>
							);
						})}
						{provided.placeholder}
						{!userCanvasTyles?.order?.length && (
							<ContainerPlaceholder>
								<Paragraph>Open up a note to start writing</Paragraph>
							</ContainerPlaceholder>
						)}
					</Container>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default HorizontalCanvas;
