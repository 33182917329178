import { NavigationBarItemInterface } from './NavigationBarItemInterface';
import { SpaceInterface } from './SpaceInterface';

export interface NavigationBarInterface {
	items: Array<NavigationBarItemInterface>;
}

export interface NavigationBarActionDto {
	space?: SpaceInterface;
	action: NavigationBarClickActionEnum;
	data?: any;
}

export enum NavigationBarClickActionEnum {
	NEW_CARD = 'plus-icon',
	ADD = 'add',
	CONFIRM_ADD = 'confirm_add',
	OPEN = 'open',
	CLONE = 'clone',
	RENAME = 'rename',
	CHANGE_OR_CREATE = 'changeOrCreate',
	CLOSE = 'close',
	DELETE = 'delete',
	CONFIRM_DELETE = 'confirm_delete',
	SETTINGS = 'settings',
	GET_APPLE_SILICON_APP = 'get_apple_silicon_app',
	GET_APPLE_INTEL_APP = 'get_apple_intel_app',
	GET_WINDOWS_APP = 'get_windows_app',
	TUTORIALS = 'tutorials',
	GUIDE = 'guide',
	CONTACT_US = 'contact_us',
	GIVE_FEEDBACK = 'give_feedback',
	REFERRAL = 'referral',
	WHATS_NEW = 'whats_new',
	LOGOUT = 'logout',
	TOGGLE_SEARCH_SIDEBAR = 'toggle_search_sidebar',
	CHANGE_THEME = 'change_theme',
}
