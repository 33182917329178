/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { CustomElement } from 'types/slate';
import TransformToBlockTyleButton from './TransformToBlockTyleButton';

export interface MultiBlockToolbarProps {
	selectedBlocks: Array<CustomElement>;
}

const MultiBlockToolbar = ({ selectedBlocks }: MultiBlockToolbarProps): ReactElement => {
	if (selectedBlocks.length <= 1) {
		return <></>;
	}

	return (
		<>
			<TransformToBlockTyleButton classNames={{ root: 'w-100 ph', active: 'isActive' }} icon="Group into Tyle" />
		</>
	);
};

export default MultiBlockToolbar;
