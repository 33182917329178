import { PromiseMiddleware } from './generated/client/middleware';
import { RequestContext, ResponseContext } from './generated/client';
import { ApiInterface } from './Api';
import { AuthenticationServiceInterface } from '../services/AuthenticationService';

class ApiMiddleware implements PromiseMiddleware {
	private lastRequest?: RequestContext;

	private api: ApiInterface;

	private authenticationService: AuthenticationServiceInterface;

	private refreshInProgress = false;

	constructor(api: ApiInterface, authenticationService: AuthenticationServiceInterface) {
		this.api = api;
		this.authenticationService = authenticationService;
	}

	post(context: ResponseContext): Promise<ResponseContext> {
		return new Promise<ResponseContext>((resolve, reject) => {
			if (context.httpStatusCode === 401 && !this.refreshInProgress) {
				this.refreshInProgress = true;
				this.api
					.refreshToken()
					.then(() => {
						this.refreshInProgress = false;
						resolve(context);
						// @TODO: execute the last request again and resolve the Promise
					})
					.catch((error) => {
						this.refreshInProgress = false;
						this.authenticationService.logout();
						// @TODO: Redirect to login page
						reject(error);
					});
			} else {
				this.refreshInProgress = false;
				resolve(context);
			}
		});
	}

	pre(context: RequestContext): Promise<RequestContext> {
		return new Promise<RequestContext>((resolve) => {
			this.lastRequest = context;
			resolve(context);
		});
	}
}

export default ApiMiddleware;
