import React, { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { usePlateEditorState } from '@udecode/plate';
import { CustomEditor } from 'types/slate';
import sharedState from 'state/SharedState';
import { PopoverContent } from 'components/popover-menu';
import upsertLinkAtSelection from 'components/editor/utils/upsertLinkAtSelection';
import PopoverToggle from 'components/popover-toggle';
import LinkPrompt from './LinkPrompt';
import useDeleteLink from './useDeleteLink';

const StyledSpan = styled.span`
	background: ${(props) => props.theme.colors.gray400};
`;

interface EmptyLinkPopoverProps {
	children: ReactNode;
	editorId: string;
	element: any;
}

const EmptyLinkPopover = ({ children, element, editorId }: EmptyLinkPopoverProps): ReactElement => {
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(true);
	const { deleteLink } = useDeleteLink({ editorId });
	const editor = usePlateEditorState(editorId);
	const { url } = element;

	if (!editor) {
		return <></>;
	}

	const onCancel = () => {
		sharedState.editHyperlink = '';
		setIsPopoverOpen(false);
		if (!url) {
			deleteLink(element);
		}
	};
	const handleCancel = () => {
		sharedState.editHyperlink = '';
		setIsPopoverOpen(false);
		if (!url) {
			deleteLink(element);
		}
	};

	return (
		<span onBlur={onCancel}>
			<PopoverContent
				isOpen={isPopoverOpen}
				positions={['bottom', 'top', 'right', 'left']}
				reposition
				align="center"
				onClickOutside={onCancel}
				content={
					<LinkPrompt
						url={element.url}
						text={element.children[0].text}
						onSubmit={(event) => {
							const { isValid, value, text } = event;
							if (value === '') {
								deleteLink(element);
								return;
							}

							if (!isValid) {
								return;
							}

							Transforms.select(editor as CustomEditor, ReactEditor.findPath(editor as CustomEditor, element));
							upsertLinkAtSelection(editor, { url: value, text });
							sharedState.editHyperlink = '';
						}}
						onCancel={handleCancel}
					/>
				}
			>
				<PopoverToggle onClick={() => setIsPopoverOpen(true)}>
					<StyledSpan>{children}</StyledSpan>
				</PopoverToggle>
			</PopoverContent>
		</span>
	);
};

export default EmptyLinkPopover;
