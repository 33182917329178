import styled from 'styled-components';

export const MobileViewContainer = styled.div`
	background-color: #161616;
	position: relative;
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const MobileViewContent = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 319px;
	max-width: 550px;
	padding: 0 40px;
`;

export const MobileGradient = styled.img`
	position: absolute;
	top: 25%;
	left: 10%;
`;
export const TabletGradient = styled.img`
	position: absolute;
	top: 25%;
	left: 30%;
`;
