import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import { Configuration} from '../configuration'

import { CreateSpace } from '../models/CreateSpace';
import { EditorBlock } from '../models/EditorBlock';
import { EditorBlocksToRemove } from '../models/EditorBlocksToRemove';
import { GenerateTagsForTyle } from '../models/GenerateTagsForTyle';
import { GenericError } from '../models/GenericError';
import { GenericSuccess } from '../models/GenericSuccess';
import { GetTylesFavoritesRequest } from '../models/GetTylesFavoritesRequest';
import { GetUserTopTagsParams } from '../models/GetUserTopTagsParams';
import { InviteCode } from '../models/InviteCode';
import { InviteCodeCreateManyParams } from '../models/InviteCodeCreateManyParams';
import { PostLoginRequest } from '../models/PostLoginRequest';
import { PostSignUpRequest } from '../models/PostSignUpRequest';
import { PostTyleRequest } from '../models/PostTyleRequest';
import { SearchQuery } from '../models/SearchQuery';
import { SearchQueryParams } from '../models/SearchQueryParams';
import { SearchQueryParamsFilters } from '../models/SearchQueryParamsFilters';
import { SearchTylesParams } from '../models/SearchTylesParams';
import { SearchTylesParamsFilters } from '../models/SearchTylesParamsFilters';
import { Source } from '../models/Source';
import { Space } from '../models/Space';
import { Tag } from '../models/Tag';
import { TagCreateManyParams } from '../models/TagCreateManyParams';
import { TagCreateOneParams } from '../models/TagCreateOneParams';
import { ToggleGeneratedUrl } from '../models/ToggleGeneratedUrl';
import { ToggleGeneratedUrlParams } from '../models/ToggleGeneratedUrlParams';
import { Token } from '../models/Token';
import { TokenPair } from '../models/TokenPair';
import { TrackObject } from '../models/TrackObject';
import { TrackObjectTrack } from '../models/TrackObjectTrack';
import { Tyle } from '../models/Tyle';
import { TyleConnection } from '../models/TyleConnection';
import { TyleLink } from '../models/TyleLink';
import { TyleRelation } from '../models/TyleRelation';
import { TylesCount } from '../models/TylesCount';
import { TylesUpdatedCount } from '../models/TylesUpdatedCount';
import { UpdateSpace } from '../models/UpdateSpace';
import { UpdateSpaceRequest } from '../models/UpdateSpaceRequest';
import { UpdateTyleRequest } from '../models/UpdateTyleRequest';
import { User } from '../models/User';
import { UserDelete } from '../models/UserDelete';
import { ObservableAccountApi } from './ObservableAPI';

import { AccountApiRequestFactory, AccountApiResponseProcessor} from "../apis/AccountApi";
export class PromiseAccountApi {
    private api: ObservableAccountApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AccountApiRequestFactory,
        responseProcessor?: AccountApiResponseProcessor
    ) {
        this.api = new ObservableAccountApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Endpoint to delete user account
     * @param obj Deleting user.
     */
    public deleteAccount(obj?: UserDelete, _options?: Configuration): Promise<User> {
        const result = this.api.deleteAccount(obj, _options);
        return result.toPromise();
    }

    /**
     */
    public getUserAccount(_options?: Configuration): Promise<User> {
        const result = this.api.getUserAccount(_options);
        return result.toPromise();
    }

    /**
     * @param obj The track object to send.
     */
    public postTrackEvent(obj?: TrackObject, _options?: Configuration): Promise<GenericSuccess> {
        const result = this.api.postTrackEvent(obj, _options);
        return result.toPromise();
    }

    /**
     * @param obj Update user account.
     */
    public updateUserAccount(obj?: User, _options?: Configuration): Promise<User> {
        const result = this.api.updateUserAccount(obj, _options);
        return result.toPromise();
    }


}



import { ObservableAuthenticationApi } from './ObservableAPI';

import { AuthenticationApiRequestFactory, AuthenticationApiResponseProcessor} from "../apis/AuthenticationApi";
export class PromiseAuthenticationApi {
    private api: ObservableAuthenticationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthenticationApiRequestFactory,
        responseProcessor?: AuthenticationApiResponseProcessor
    ) {
        this.api = new ObservableAuthenticationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Endpoint for signup via invite code
     * @param code 
     */
    public getInviteCodeSignup(code: string, _options?: Configuration): Promise<TokenPair> {
        const result = this.api.getInviteCodeSignup(code, _options);
        return result.toPromise();
    }

    /**
     * Endpoint for login
     * @param obj 
     */
    public postLogin(obj?: PostLoginRequest, _options?: Configuration): Promise<any> {
        const result = this.api.postLogin(obj, _options);
        return result.toPromise();
    }

    /**
     * Endpoint to refresh a token
     */
    public postRefreshToken(_options?: Configuration): Promise<Token> {
        const result = this.api.postRefreshToken(_options);
        return result.toPromise();
    }

    /**
     * Endpoint for signup
     * @param obj 
     */
    public postSignUp(obj?: PostSignUpRequest, _options?: Configuration): Promise<any> {
        const result = this.api.postSignUp(obj, _options);
        return result.toPromise();
    }

    /**
     * Endpoint to receive a token pair
     */
    public postToken(_options?: Configuration): Promise<TokenPair> {
        const result = this.api.postToken(_options);
        return result.toPromise();
    }


}



import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public getIndex(_options?: Configuration): Promise<any> {
        const result = this.api.getIndex(_options);
        return result.toPromise();
    }

    /**
     */
    public healthcheckGet(_options?: Configuration): Promise<void> {
        const result = this.api.healthcheckGet(_options);
        return result.toPromise();
    }


}



import { ObservableInviteCodesApi } from './ObservableAPI';

import { InviteCodesApiRequestFactory, InviteCodesApiResponseProcessor} from "../apis/InviteCodesApi";
export class PromiseInviteCodesApi {
    private api: ObservableInviteCodesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: InviteCodesApiRequestFactory,
        responseProcessor?: InviteCodesApiResponseProcessor
    ) {
        this.api = new ObservableInviteCodesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param invitations Array of invitations.
     */
    public createInviteCodes(invitations?: InviteCodeCreateManyParams, _options?: Configuration): Promise<Array<InviteCode>> {
        const result = this.api.createInviteCodes(invitations, _options);
        return result.toPromise();
    }

    /**
     */
    public getMyInviteCodes(_options?: Configuration): Promise<Array<InviteCode>> {
        const result = this.api.getMyInviteCodes(_options);
        return result.toPromise();
    }


}



import { ObservablePublicApi } from './ObservableAPI';

import { PublicApiRequestFactory, PublicApiResponseProcessor} from "../apis/PublicApi";
export class PromisePublicApi {
    private api: ObservablePublicApi

    public constructor(
        configuration: Configuration,
        requestFactory?: PublicApiRequestFactory,
        responseProcessor?: PublicApiResponseProcessor
    ) {
        this.api = new ObservablePublicApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param url 
     */
    public getPublicTyleByUrl(url: string, _options?: Configuration): Promise<Tyle> {
        const result = this.api.getPublicTyleByUrl(url, _options);
        return result.toPromise();
    }

    /**
     * @param obj The child id and parent id.
     */
    public postGenerateTyleUrlForChild(obj?: TyleRelation, _options?: Configuration): Promise<Tyle> {
        const result = this.api.postGenerateTyleUrlForChild(obj, _options);
        return result.toPromise();
    }


}



import { ObservableSearchApi } from './ObservableAPI';

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class PromiseSearchApi {
    private api: ObservableSearchApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.api = new ObservableSearchApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param obj Get latest search queries. Arguments are all optional.
     */
    public getLatestSearchQueries(obj?: SearchQueryParams, _options?: Configuration): Promise<Array<SearchQuery>> {
        const result = this.api.getLatestSearchQueries(obj, _options);
        return result.toPromise();
    }

    /**
     * @param obj The search phrase and the limit
     */
    public searchForTags(obj?: SearchQueryParams, _options?: Configuration): Promise<Array<Tag>> {
        const result = this.api.searchForTags(obj, _options);
        return result.toPromise();
    }

    /**
     * @param obj The search phrase and the limit
     */
    public searchForTyles(obj?: SearchQueryParams, _options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.searchForTyles(obj, _options);
        return result.toPromise();
    }

    /**
     * @param obj The search phrase and the limit
     */
    public searchForTylesByTitle(obj?: SearchQueryParams, _options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.searchForTylesByTitle(obj, _options);
        return result.toPromise();
    }


}



import { ObservableSpacesApi } from './ObservableAPI';

import { SpacesApiRequestFactory, SpacesApiResponseProcessor} from "../apis/SpacesApi";
export class PromiseSpacesApi {
    private api: ObservableSpacesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SpacesApiRequestFactory,
        responseProcessor?: SpacesApiResponseProcessor
    ) {
        this.api = new ObservableSpacesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param obj Creating space.
     */
    public createSpace(obj?: CreateSpace, _options?: Configuration): Promise<Space> {
        const result = this.api.createSpace(obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public deleteSpace(id: string, _options?: Configuration): Promise<Space> {
        const result = this.api.deleteSpace(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getSpace(id: string, _options?: Configuration): Promise<Space> {
        const result = this.api.getSpace(id, _options);
        return result.toPromise();
    }

    /**
     */
    public getSpaces(_options?: Configuration): Promise<Array<Space>> {
        const result = this.api.getSpaces(_options);
        return result.toPromise();
    }

    /**
     * @param id 
     * @param obj 
     */
    public updateSpace(id: string, obj?: UpdateSpaceRequest, _options?: Configuration): Promise<Space> {
        const result = this.api.updateSpace(id, obj, _options);
        return result.toPromise();
    }


}



import { ObservableTagsApi } from './ObservableAPI';

import { TagsApiRequestFactory, TagsApiResponseProcessor} from "../apis/TagsApi";
export class PromiseTagsApi {
    private api: ObservableTagsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TagsApiRequestFactory,
        responseProcessor?: TagsApiResponseProcessor
    ) {
        this.api = new ObservableTagsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param obj Automatically generate tags based on Tyle content.
     */
    public generateTagsForTyle(obj?: GenerateTagsForTyle, _options?: Configuration): Promise<Array<Tag>> {
        const result = this.api.generateTagsForTyle(obj, _options);
        return result.toPromise();
    }

    /**
     * @param limit Get most used tags for logged in user.
     */
    public getUserTopTags(limit?: number, _options?: Configuration): Promise<Array<Tag>> {
        const result = this.api.getUserTopTags(limit, _options);
        return result.toPromise();
    }

    /**
     * @param obj Create multiple tags.
     */
    public postMultipleTags(obj?: TagCreateManyParams, _options?: Configuration): Promise<Array<Tag>> {
        const result = this.api.postMultipleTags(obj, _options);
        return result.toPromise();
    }

    /**
     * @param obj Create a tag from a given label.
     */
    public postTag(obj?: TagCreateOneParams, _options?: Configuration): Promise<Tag> {
        const result = this.api.postTag(obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     * @param obj Create multiple tags and connect to a given tyle id.
     */
    public postTagsByTyleId(id: string, obj?: TagCreateManyParams, _options?: Configuration): Promise<Array<Tag>> {
        const result = this.api.postTagsByTyleId(id, obj, _options);
        return result.toPromise();
    }


}



import { ObservableTylesApi } from './ObservableAPI';

import { TylesApiRequestFactory, TylesApiResponseProcessor} from "../apis/TylesApi";
export class PromiseTylesApi {
    private api: ObservableTylesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TylesApiRequestFactory,
        responseProcessor?: TylesApiResponseProcessor
    ) {
        this.api = new ObservableTylesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public clearInboxTyles(_options?: Configuration): Promise<Array<string>> {
        const result = this.api.clearInboxTyles(_options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public deleteFavoriteTyle(id: string, _options?: Configuration): Promise<TyleLink> {
        const result = this.api.deleteFavoriteTyle(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     * @param obj The id of the tyle to be unlinked.
     */
    public deleteLinkToExistingTyle(id: string, obj?: TyleLink, _options?: Configuration): Promise<Tyle> {
        const result = this.api.deleteLinkToExistingTyle(id, obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public deleteTyle(id: string, _options?: Configuration): Promise<Tyle> {
        const result = this.api.deleteTyle(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getBacklinkedTyles(id: string, _options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.getBacklinkedTyles(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getGeneratedTyleUrl(id: string, _options?: Configuration): Promise<ToggleGeneratedUrl> {
        const result = this.api.getGeneratedTyleUrl(id, _options);
        return result.toPromise();
    }

    /**
     */
    public getInboxTyles(_options?: Configuration): Promise<Array<string>> {
        const result = this.api.getInboxTyles(_options);
        return result.toPromise();
    }

    /**
     */
    public getLastUpdatedTyle(_options?: Configuration): Promise<Tyle> {
        const result = this.api.getLastUpdatedTyle(_options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getLinkedTyles(id: string, _options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.getLinkedTyles(id, _options);
        return result.toPromise();
    }

    /**
     */
    public getRecentlyCreatedTyles(_options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.getRecentlyCreatedTyles(_options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getTagsByTyleId(id: string, _options?: Configuration): Promise<Array<Tag>> {
        const result = this.api.getTagsByTyleId(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getTyle(id: string, _options?: Configuration): Promise<Tyle> {
        const result = this.api.getTyle(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getTyleInstances(id: string, _options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.getTyleInstances(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getTyleOrigin(id: string, _options?: Configuration): Promise<Tyle> {
        const result = this.api.getTyleOrigin(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getTylePrevious(id: string, _options?: Configuration): Promise<Array<string>> {
        const result = this.api.getTylePrevious(id, _options);
        return result.toPromise();
    }

    /**
     * @param obj 
     */
    public getTylesFavorites(obj?: GetTylesFavoritesRequest, _options?: Configuration): Promise<Array<Tyle>> {
        const result = this.api.getTylesFavorites(obj, _options);
        return result.toPromise();
    }

    /**
     * @param tyleId 
     * @param obj The editor block object.
     */
    public insertEditorBlock(tyleId: string, obj?: EditorBlock, _options?: Configuration): Promise<GenericSuccess> {
        const result = this.api.insertEditorBlock(tyleId, obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public postFavoriteTyle(id: string, _options?: Configuration): Promise<TyleLink> {
        const result = this.api.postFavoriteTyle(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     * @param obj The id of the new tyle to link.
     */
    public postLinkToExistingTyle(id: string, obj?: TyleLink, _options?: Configuration): Promise<Tyle> {
        const result = this.api.postLinkToExistingTyle(id, obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public postNewInstanceFromTyle(id: string, _options?: Configuration): Promise<Tyle> {
        const result = this.api.postNewInstanceFromTyle(id, _options);
        return result.toPromise();
    }

    /**
     * @param obj 
     */
    public postTyle(obj?: PostTyleRequest, _options?: Configuration): Promise<Tyle> {
        const result = this.api.postTyle(obj, _options);
        return result.toPromise();
    }

    /**
     * @param tyleId 
     * @param obj The editor block object.
     */
    public removeEditorBlock(tyleId: string, obj?: EditorBlocksToRemove, _options?: Configuration): Promise<GenericSuccess> {
        const result = this.api.removeEditorBlock(tyleId, obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public softDeleteTyle(id: string, _options?: Configuration): Promise<Tyle> {
        const result = this.api.softDeleteTyle(id, _options);
        return result.toPromise();
    }

    /**
     * @param obj The generated url properties.
     */
    public toggleGeneratedTyleUrl(obj?: ToggleGeneratedUrlParams, _options?: Configuration): Promise<ToggleGeneratedUrl> {
        const result = this.api.toggleGeneratedTyleUrl(obj, _options);
        return result.toPromise();
    }

    /**
     * @param tyleId 
     * @param obj The editor block object.
     */
    public updateEditorBlock(tyleId: string, obj?: EditorBlock, _options?: Configuration): Promise<GenericSuccess> {
        const result = this.api.updateEditorBlock(tyleId, obj, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     * @param obj 
     */
    public updateTyle(id: string, obj?: UpdateTyleRequest, _options?: Configuration): Promise<Tyle> {
        const result = this.api.updateTyle(id, obj, _options);
        return result.toPromise();
    }


}



import { ObservableUsersApi } from './ObservableAPI';

import { UsersApiRequestFactory, UsersApiResponseProcessor} from "../apis/UsersApi";
export class PromiseUsersApi {
    private api: ObservableUsersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: UsersApiRequestFactory,
        responseProcessor?: UsersApiResponseProcessor
    ) {
        this.api = new ObservableUsersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public getUser(_options?: Configuration): Promise<User> {
        const result = this.api.getUser(_options);
        return result.toPromise();
    }


}



