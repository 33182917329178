import React, { ReactElement, useEffect, useState } from 'react';
import Input from 'components/input';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import getDomain from 'helpers/getDomain';
import { PopoverMenu } from 'components/popover-menu';

interface LinkPromptProps {
	url?: string;
	text?: string;
	onSubmit: ({ value, isValid, text }: { value: string; isValid: boolean; text: string }) => void;
	onCancel: () => void;
}

const LinkPrompt = ({ url, text, onSubmit, onCancel }: LinkPromptProps): ReactElement => {
	const [items, setItems] = useState<Array<PopoverMenuItemInterface>>([]);
	const [userInput, setUserInput] = useState({ url: url ?? '', text: text ?? '' });
	const [isHover, setIsHover] = useState<boolean>(false);

	const submitValue = () => {
		const value = userInput.url;
		onSubmit({ value, isValid: !!getDomain({ value }), text: userInput.text });
	};

	const popoverItems: Array<PopoverMenuItemInterface> = [
		{
			custom: (
				<div>
					<Input
						placeholder="Paste link"
						value={userInput.url}
						onSubmit={submitValue}
						size="small"
						onChange={(e) => setUserInput({ ...userInput, url: e })}
						cancelOnBlur={!isHover}
						focus
					/>
					<Input
						placeholder="Text to display"
						value={userInput.text}
						onSubmit={submitValue}
						size="small"
						onChange={(e) => setUserInput({ ...userInput, text: e })}
						cancelOnBlur={!isHover}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		const value = userInput.url;

		if (!getDomain({ value })) {
			setItems(popoverItems);
			return;
		}

		popoverItems.push(
			{
				sectionTitle: 'Link to web page',
			},
			{
				icon: 'external-link-icon',
				label: value,
			},
		);
		setItems(popoverItems);
	}, [userInput]);

	return <PopoverMenu align="center" setIsHover={setIsHover} items={items} onEvent={submitValue} />;
};

export default LinkPrompt;
