interface CarouselSlideInterface {
	headline1: string;
	headline2: string;
	paragraph: string;
	videoPath?: string;
}

export const getSlides = (isMacOs: boolean): Array<CarouselSlideInterface> => {
	return [
		{
			headline1: 'Step 1:',
			headline2: 'Highlight text',
			paragraph: 'Visit any website, app or PDF and select with your cursor the part that you want to save',
			videoPath: 'assets/videos/desktop_onboarding_1.mp4',
		},
		{
			headline1: 'Step 2:',
			headline2: isMacOs ? 'Press ⌘ E to save' : 'Press Ctrl E to save',
			paragraph: 'Save the highlight to an existing note or let Tyles save it automatically for you after 5 seconds.',
			videoPath: 'assets/videos/desktop_onboarding_2.mp4',
		},
		{
			headline1: '',
			headline2: "That's it! Make your first highlight",
			paragraph: isMacOs
				? 'Go ahead and make your first highlight and press ⌘ E. You can always find Tyles in your menu bar.'
				: 'Go ahead and make your first highlight and press Ctrl E. You can always find Tyles in your menu bar.',
		},
	];
};

export const ArrowRightTestId = 'onboarding-test-arrow-right';
export const ArrowLeftTestId = 'onboarding-test-arrow-left';
