export enum TyleWindowActionEnum {
	VIEW_LINKS = 'viewLinks',
	VIEW_BACKLINKS = 'viewBacklinks',
	VIEW_TYLE = 'viewTyle',
}

export interface TyleWindowQueue {
	windowId: string; // window id
	selectedIndex: number;
	tylesQueue: Array<string | undefined>; // array of tyle ids
}

export enum TyleWindowViews {
	EDITOR = 'editor',
	LINKS = 'links',
	BACKLINKS = 'backlinks',
}

export interface AddTyleToUserCanvasTylesParams {
	windowId: string;
	tyleId: string;
}

export interface NavigateTyleParams {
	windowId: string;
	destinationTyleId: string | undefined;
	isPublicTyle?: boolean;
}

export interface ReorderTylesParams {
	newOrder: string[];
	selectedWindowId: string;
}

export interface NavigateBackForwardParams {
	windowId: string;
	isPublicTyle?: boolean;
}

export interface UpdateScrollTopParams {
	windowId: string;
	scrollTop: number;
}
