import { useContext } from 'react';
import { ApiContext } from 'components/App';
import { StorageKeys } from 'services/StorageService';
import useElectronEvents from '../helpers/useElectronEvents';

const useLogout = () => {
	const api = useContext(ApiContext);
	const { sendMessage } = useElectronEvents();

	const logout = () => {
		api.logout();
		sendMessage({ subject: 'close-terminal', message: 'close' });
		sendMessage({ subject: 'user', message: 'logout' });
		setTimeout(() => {
			localStorage.removeItem(StorageKeys.USER_CANVAS_TYLES);
			window.location.reload();
		}, 1000);
	};

	return { logout };
};

export default useLogout;
