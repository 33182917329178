import React, { ReactElement, ReactNode } from 'react';
import { ListGroup } from 'react-bootstrap';
import classNames from 'classnames';
import styled from 'styled-components';
import Icon from 'components/icon';
import { ShortcutButton } from '../../interfaces/TerminalMenuInterface';

const StyledContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: ${(props) => props.theme.fontSizes.medium};
	color: ${(props) => props.theme.colors.lightHeading};

	.kum-icon {
		color: ${(props) => props.theme.colors.gray100};
		display: flex;
		align-items: center;
		height: 1rem;
	}

	.shortcuts-container {
		margin-left: auto;
		margin-right: 27px;

		.terminal_shortcuts {
			padding: 0;
			justify-content: flex-end;
		}
	}
`;

export interface MenuItemProps {
	label: string;
	active?: boolean;
	disabled?: boolean;
	onEvent?: any;
	icon?: string;
	shortcutItems?: ShortcutButton[];
}

const MenuItem = ({ label, disabled, active, onEvent, icon, shortcutItems }: MenuItemProps): ReactElement => {
	return (
		<ListGroup.Item
			action
			disabled={disabled}
			onClick={onEvent}
			className={classNames({
				active: !!active,
			})}
		>
			<StyledContainer>
				{icon && <Icon type={icon} style={{ fontSize: '1rem', paddingRight: '1.5em' }} />}
				{label}
			</StyledContainer>
		</ListGroup.Item>
	);
};

export default MenuItem;
