import styled, { keyframes } from 'styled-components';
import { tada } from 'react-animations';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';

const tadaAnimation = keyframes`${tada}`;

export const StyledHeader = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors?.clipperHeader};
	font-weight: ${(props) => props.theme.fontWeights?.bold};
	font-size: ${(props) => props.theme.fontSizes?.headingXSmall};
	padding: 0 0 0 0;
`;

export const StyledContentLabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors?.background};
	border-radius: ${(props) => props.theme.radius?.base};
	padding: 0 5px;
	p {
		margin: 0;
	}
`;

export const StyledCheckCircle = styled(CheckCircle)`
	animation: 0.5s ${tadaAnimation};
	animation-iteration-count: 2;
	color: ${(props) => props.theme.colors?.clipperHeader}; ;
`;

export const StyledParagraph = styled.span`
	display: flex;
	color: ${(props) => props.theme.colors?.clipperHeader};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-weight: ${(props) => props.theme.fontWeights?.bold};
	font-size: ${(props) => props.theme.fontSizes?.headingXSmall};
`;

export const StyledContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 6px;
	background: rgba(28, 21, 27, 0.1);
	backdrop-filter: blur(10px);
	height: 100vh;
`;
