import React, { CSSProperties, ReactElement, useState } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { PopoverMenuItemInterface } from '../../interfaces/PopoverMenuItemInterface';
import Icon from '../icon/Icon';
import './PopoverMenu.scss';
import PopoverToggle from '../popover-toggle';
import { PopoverContent } from './index';

export interface PopoverMenuProps {
	items: Array<PopoverMenuItemInterface>;
	onEvent: (data: PopoverMenuItemInterface) => void;
	onCancel?: (arg: any) => void;
	setIsHover?: (arg: boolean) => void;
	style?: CSSProperties;
	align?: string | undefined;
	fixedMaxHeight?: boolean | undefined;
}

const PopoverMenu = React.forwardRef<HTMLDivElement | null, PopoverMenuProps>(
	({ items, onEvent, onCancel, setIsHover, style, align, fixedMaxHeight }: PopoverMenuProps, ref): ReactElement => {
		const [submenuOpened, setSubmenuOpened] = useState<string | undefined>();

		const handleClick = (item: PopoverMenuItemInterface) => {
			onCancel?.(false);
			onEvent({
				action: item.action,
				data: item.data,
				id: item.id,
				event: item.event,
			});
			setSubmenuOpened(undefined);
		};

		const subMenuItems = (subItems: any, alignSubItem?: string) => {
			return <PopoverMenu key="subMenu" items={subItems} onEvent={handleClick} align={alignSubItem} />;
		};

		const subMenuContent = (content: string[]) => {
			return (
				content && (
					<div className="kum-dropdown-menu">
						<div key="subMenu" className="submenu-content">
							{content.map((item: string) => {
								return <p key={nanoid()}>{item}</p>;
							})}
						</div>
					</div>
				)
			);
		};

		return (
			<div
				ref={ref}
				onBlur={onCancel}
				onFocus={() => setIsHover?.(true)}
				onMouseOver={() => setIsHover?.(true)}
				onMouseLeave={() => setIsHover?.(false)}
				className={classNames({
					'kum-dropdown-menu': true,
					submenu: true,
					[`submenu-${align}`]: true,
					'fixed-max-height': !!fixedMaxHeight,
				})}
				style={style}
			>
				{items.map((item) => {
					if (item.hidden) return <></>;

					if (item.custom) {
						return <React.Fragment key="custom">{item.custom}</React.Fragment>;
					}

					if (item.header)
						return (
							<>
								<Dropdown.Item
									key={`${JSON.stringify(item)}_1`}
									className="dropdown-header disabled"
									onClick={(event) => event.stopPropagation()}
								>
									{item.icon && <Icon type={item.icon} />}
									<div className="label">
										{item.strongLabel && <strong>{item.strongLabel}</strong>} {item.label}
									</div>
								</Dropdown.Item>
								<Dropdown.Divider key={`${JSON.stringify(item)}_2`} />
							</>
						);
					if (item.divider) return <Dropdown.Divider key={JSON.stringify(item)} />;
					if (item.sectionTitle)
						return (
							<div className="section-title" key={JSON.stringify(item)}>
								{item.sectionTitle}
							</div>
						);

					if (item.submenu && item.id)
						return (
							<PopoverContent
								isOpen={submenuOpened === item.id}
								positions={['right', 'left', 'top', 'bottom']}
								reposition
								align="start"
								onClickOutside={() => setSubmenuOpened(undefined)}
								content={
									item.submenuItems
										? subMenuItems(item.submenuItems, item.alignSubItems)
										: subMenuContent(item.submenuContent || [])
								}
								containerStyle={{ marginLeft: '9px', marginRight: '9px', marginTop: '-5px', zIndex: '9999999' }}
							>
								<PopoverToggle
									onClick={() => setSubmenuOpened(submenuOpened ? undefined : item.id)}
									onMouseEnter={() => setSubmenuOpened(item.id)}
								>
									<Dropdown.Item
										key={JSON.stringify(item)}
										onClick={(event: any) => {
											event.stopPropagation();
											setSubmenuOpened(undefined);
											handleClick({ ...item, event });
										}}
										className="dropdown-item"
									>
										{item.icon && <Icon key="icon" type={item.icon} />}
										<div className="label" key="label">
											{item.label}
										</div>
										{item.shortcut && (
											<div key="shortcut" className="shortcut">
												{item.shortcut}
											</div>
										)}
										<Icon key="arrowRight" className="sub-menu-icon" type="arrow-right" />
									</Dropdown.Item>
								</PopoverToggle>
							</PopoverContent>
						);

					return (
						<Dropdown.Item
							key={JSON.stringify(item)}
							onMouseEnter={() => setSubmenuOpened(undefined)}
							onClick={(event: any) => {
								event.stopPropagation();
								handleClick({ ...item, event });
							}}
							className="dropdown-item"
						>
							{item.icon && (
								<Icon
									style={{ paddingLeft: `${item.icon === 'quote-icon-inline' && '0.3rem'}` }}
									key="icon"
									type={item.icon}
								/>
							)}
							<div className="label" key="label">
								{item.label}
							</div>
							{item.shortcut && (
								<div key="shortcut" className="shortcut">
									{item.shortcut}
								</div>
							)}
						</Dropdown.Item>
					);
				})}
			</div>
		);
	},
);

export default PopoverMenu;
