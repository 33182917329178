import React, { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';
import { EditAlt as EditLinkIcon } from '@styled-icons/boxicons-regular/EditAlt';

import {
	renderPageIcon,
	renderArrowDownIcon,
	renderInlineDropdownIcon,
	renderMoreIcon,
	renderInstancesIcon,
	renderDragIndicator,
	renderDropdownArrow,
	renderEditorCodeIcon,
	renderEditorMoreIcon,
	IconTypes,
	renderSpaceIcon,
	renderCanvasIcon,
	renderDoubleChevronIcon,
	renderNewCardIcon,
	renderCloseIcon,
	renderPlusIcon,
	renderAddSpaceIcon,
	renderCloseGrayIcon,
	renderSlimArrowRightIcon,
	renderInboxIcon,
	renderInboxIconWithCircle,
	renderTyleWindowIcon,
	renderBookmarkIcon,
	renderDocumentIcon,
	renderDotSeparator,
	renderDocumentIconInline,
	renderBookmarkIconInline,
	renderBookmarkIconBlack,
	renderMenuLine,
	renderArrowDownSmall,
	renderSearch,
	renderNewSpaceIcon,
	renderParagraphIcon,
	renderHeading1Icon,
	renderHeading2Icon,
	renderHeading3Icon,
	renderBulletedListIcon,
	renderNumberedListIcon,
	renderToDoListIcon,
	renderCodeBlockIcon,
	renderQuoteIcon,
	renderTylesLogo,
	renderNewTyleInlineIcon,
	renderTyleLittleIcon,
	renderExternalLink,
	renderExternalLinkIcon,
	deleteLinkIcon,
	copyLinkIcon,
	renderArrowRightIcon,
	renderSaveToNewTyleIcon,
	renderArrowLeftIcon,
	renderArrowRightIconBig,
	renderQuoteIconSmall,
	renderTylesLogo16,
	renderCheckMarkIcon,
	renderVerticalArrows,
} from './helpers';

import './styles.scss';

export interface IconProps {
	type: string | IconTypes;
	style?: CSSProperties;
	className?: string;
	dropdownRef?: any;
	onClick?: (e: any) => void;
}

const Icon = ({ type, style, className, dropdownRef, onClick }: IconProps): ReactElement => {
	const childClassNames = classNames('kum-icon', className);

	const renderIcon = () => {
		switch (type) {
			case 'page':
				return renderPageIcon();
			case 'star-outline-icon':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" className="star-outline" viewBox="0 0 24 24" width="15" height="15">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26zm0-2.292l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.949 4.773L12 15.968z" />
					</svg>
				);
			case 'arrow-left-line-big':
				return (
					<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M3.11025 5.66431L7.4685 1.18167L6.31962 0L0 6.5L6.31962 13L7.4685 11.8183L3.11025 7.33569H13V5.66431H3.11025Z"
							fill="#EDEDED"
						/>
					</svg>
				);
			case 'arrow-right-line-big':
				return (
					<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.88975 5.66431L5.5315 1.18167L6.68038 0L13 6.5L6.68038 13L5.5315 11.8183L9.88975 7.33569H0V5.66431H9.88975Z"
							fill="#EDEDED"
						/>
					</svg>
				);
			case 'arrow-left-line':
				return (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="arrow-line left"
						viewBox="0 0 24 24"
						width="15"
						height="15"
					>
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
					</svg>
				);
			case 'arrow-right-line':
				return (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="arrow-line right"
						viewBox="0 0 24 24"
						width="15"
						height="15"
					>
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
					</svg>
				);
			case 'arrow-point-right':
				return (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="arrow-point right"
						viewBox="0 0 24 24"
						width="18"
						height="18"
					>
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
					</svg>
				);
			case 'arrow-right-slim':
				return renderSlimArrowRightIcon();
			case 'arrow-right':
				return renderArrowRightIcon();
			case 'arrow-right-big':
				return renderArrowRightIconBig();
			case 'arrow-left-big':
				return renderArrowLeftIcon();
			case 'chevron-left':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(255,255,255,1)" />
					</svg>
				);
			case 'arrow-right-small':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M16 12l-6 6V6z" />
					</svg>
				);
			case 'arrow-left-small':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M8 12l6-6v12z" />
					</svg>
				);
			case 'arrow-down':
				return renderArrowDownIcon();
			case 'quote-icon':
				return (
					<svg width="1" height="18" viewBox="0 0 1 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="18" stroke="#AAAAAA" />
					</svg>
				);
			case 'quote-icon-inline':
				return renderQuoteIconSmall();
			case 'chevron-down':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" className="chevron-down" viewBox="0 0 24 24" width="15" height="15">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
					</svg>
				);
			case 'arrow-down-small':
				return renderArrowDownSmall();
			case 'menu-line':
				return renderMenuLine();
			case 'external-link':
				return renderExternalLink();
			case 'link':
				return (
					<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7.66919 2.276L1.93119 8.014L0.988525 7.07133L6.72586 1.33333H1.66919V0H9.00253V7.33333H7.66919V2.276V2.276Z"
							fill="#666666"
						/>
					</svg>
				);
			case 'backlink':
				return (
					<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M1.99984 6.05978L7.73784 0.321777L8.6805 1.26444L2.9425 7.00244H7.99984V8.33578H0.666504V1.00244H1.99984V6.05911V6.05978Z"
							fill="#666666"
						/>
					</svg>
				);
			case 'inbox':
				return renderInboxIcon();
			case 'inbox-with-tyles':
				return renderInboxIconWithCircle();
			case 'inline-dropdown':
				return renderInlineDropdownIcon();
			case 'more':
				return renderMoreIcon();
			case 'instances':
				return renderInstancesIcon();
			case 'drag-indicator':
				return renderDragIndicator();
			case 'dropdown-arrow':
				return renderDropdownArrow();
			case 'Editor-code':
				return renderEditorCodeIcon();
			case 'Editor-more':
				return renderEditorMoreIcon();
			case 'space':
				return renderSpaceIcon();
			case 'add-space':
				return renderAddSpaceIcon();
			case 'new_card':
				return renderNewCardIcon();
			case 'canvas':
				return renderCanvasIcon();
			case 'double-chevron':
				return renderDoubleChevronIcon();
			case 'close':
				return renderCloseIcon();
			case 'close-gray':
				return renderCloseGrayIcon();
			case 'three-dots':
				return <i className="ri-more-fill" ref={dropdownRef} />;
			case 'plus':
				return renderPlusIcon();
			case 'tylewindow-icon':
				return renderTyleWindowIcon();
			case 'bookmark-icon':
				return renderBookmarkIcon();
			case 'bookmark-icon-inline':
				return renderBookmarkIconInline();
			case 'bookmark-icon-black':
				return renderBookmarkIconBlack();
			case 'document-icon':
				return renderDocumentIcon();
			case 'document-icon-inline':
				return renderDocumentIconInline();
			case 'dot-separator':
				return renderDotSeparator();
			case 'search':
				return renderSearch();
			case 'new-space':
				return renderNewSpaceIcon();
			case 'paragraph':
				return renderParagraphIcon();
			case 'heading1':
				return renderHeading1Icon();
			case 'heading2':
				return renderHeading2Icon();
			case 'heading3':
				return renderHeading3Icon();
			case 'bulleted-list':
				return renderBulletedListIcon();
			case 'numbered-list':
				return renderNumberedListIcon();
			case 'to-do-list':
				return renderToDoListIcon();
			case 'code-block':
				return renderCodeBlockIcon();
			case 'quote':
				return renderQuoteIcon();
			case 'tyles-logo':
				return renderTylesLogo();
			case 'tyles-logo-16':
				return renderTylesLogo16();
			case 'new-tyle-inline':
				return renderNewTyleInlineIcon();
			case 'tyle-little':
				return renderTyleLittleIcon();
			case 'external-link-icon':
				return renderExternalLinkIcon();
			case 'copy-link':
				return copyLinkIcon();
			case 'delete-link':
				return deleteLinkIcon();
			case 'edit-link':
				return <EditLinkIcon />;
			case 'save-to-new-tyle':
				return renderSaveToNewTyleIcon();
			case 'checkmark':
				return renderCheckMarkIcon();
			case 'vertical-arrows':
				return renderVerticalArrows();
			default:
				return <i className={type} />;
		}
	};

	return (
		<span
			className={classNames(childClassNames)}
			style={style}
			onClick={onClick}
			onKeyDown={onClick}
			aria-hidden="true"
		>
			{renderIcon()}
		</span>
	);
};

export default Icon;
