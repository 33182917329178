import {
	ShortcutButtonMap,
	ShortcutLabelEnum,
	TerminalLabelsEnum,
	TerminalViewEnum,
} from 'interfaces/TerminalMenuInterface';
import { TerminalMenuType } from '../../models/State';
import { Tyle } from '../../models/Tyle';

export const defaultMenuItems: Array<TerminalMenuType> = [
	{
		label: TerminalLabelsEnum.TYLE,
		items: [{ label: TerminalLabelsEnum.CREATE_TYLE, icon: 'ri-add-line', view: TerminalViewEnum.CREATE_TYLE }],
	},
	{
		label: TerminalLabelsEnum.NAVIGATION,
		items: [
			{ label: TerminalLabelsEnum.RECENT_TYLES, icon: 'ri-search-line', view: TerminalViewEnum.RECENT_TYLES },
			{ label: TerminalLabelsEnum.FAVOURITE_TYLES, icon: 'ri-star-line', view: TerminalViewEnum.FAVOURITE_TYLES },
		],
	},
	/* {
    label: TerminalLabelsEnum.HELP ,
    items: [{ label: TerminalLabelsEnum.OPEN_TYLE_GUIDE, icon: 'ri-book-2-line', view: TerminalViewEnum.DEFAULT }],
  }, */
	{
		label: TerminalLabelsEnum.ACCOUNT,
		items: [{ label: TerminalLabelsEnum.LOG_OUT, icon: 'ri-logout-box-line', view: TerminalViewEnum.DEFAULT }],
	},
];
const menuItems: TerminalMenuType[] = [
	{
		label: TerminalLabelsEnum.SELECTION,
		items: [
			{
				label: TerminalLabelsEnum.SAVE_TO_INBOX,
				icon: 'inbox',
				view: TerminalViewEnum.DEFAULT,
				shortcutItems: [ShortcutButtonMap.get(ShortcutLabelEnum.SAVE_TO_INBOX)!],
			},
			{
				label: TerminalLabelsEnum.SAVE_TO_EXISTING_TYLE,
				icon: 'arrow-point-right',
				view: TerminalViewEnum.RECENT_TYLES,
			},
			{ label: TerminalLabelsEnum.SAVE_TO_NEW_TYLE, icon: 'save-to-new-tyle', view: TerminalViewEnum.CREATE_TYLE },
		],
	},
	...defaultMenuItems,
];

export const tyleMenuItems = [
	{
		label: '',
		items: [
			{
				label: TerminalLabelsEnum.SAVE_TO_INBOX,
				icon: 'inbox',
				view: TerminalViewEnum.DEFAULT,
				shortcutItems: [ShortcutButtonMap.get(ShortcutLabelEnum.SAVE_TO_INBOX)!],
			},
			{
				label: TerminalLabelsEnum.SAVE_TO_EXISTING_TYLE,
				icon: 'arrow-point-right',
				view: TerminalViewEnum.RECENT_TYLES,
			},
			{ label: TerminalLabelsEnum.SAVE_TO_NEW_TYLE, icon: 'save-to-new-tyle', view: TerminalViewEnum.CREATE_TYLE },
		],
	},
];

export const emptyTyle = new Tyle({
	title: '',
	content: [
		{
			type: 'p',
			children: [
				{
					text: '',
				},
			],
		},
	],
	updatedAt: new Date(),
	createdAt: new Date(),
	deleted: false,
});

export const testTyle = new Tyle({
	id: 'test-id',
	title: 'Test title',
	content: [
		{
			type: 'p',
			children: [
				{
					text: '',
				},
			],
		},
	],
	updatedAt: new Date(),
	createdAt: new Date(),
	deleted: false,
});

export const testTyleWithLinks = new Tyle({
	id: 'test-id',
	title: 'Test title',
	content: [
		{
			type: 'p',
			children: [
				{
					text: 'Test content',
				},
			],
		},
	],
	updatedAt: new Date(),
	createdAt: new Date(),
	deleted: false,
	link: [
		{
			backlinkId: 'test-id',
			linkId: 'test-link-id',
			createdAt: new Date(),
		},
	],
});

export default menuItems;
