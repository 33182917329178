/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react';
import SignupScreen from 'components/SignupScreen';
import Spacing from 'components/spacing';
import { ApiContext } from 'components/App';
import { PermissionsModal } from 'components/modal';
import { renderTylesBetaHeadlineLogo } from 'components/icon/helpers';
import SkipPermissionsModal from 'components/SkipPermissionsModal';
import Content from './Content';
import Setup from './Setup';
import {
	Background,
	Container,
	ContainerLeft,
	ContainerRight,
	ContentContainter,
	Logo,
	Navbar,
	Wrapper,
} from './styles';
import IPCChannel from '../../model/IPC/IPCChannel';
import 'react-step-progress-bar/styles.css';
import userState from '../../state/UserState';
import LearnWhyModal from '../../components/LearnWhyModal';
import ErrorPermissionsModal from '../../components/ErrorPermissionsModal';
import useAnalytics, { TrackingEvent } from '../../hooks/useAnalytics';
import { UserInterface } from '../../interfaces/UserInterface';

type UserInfo = {
	firstName?: string;
	lastName?: string;
	occupation?: string;
};

export interface Props {
	user: UserInterface;
	loadSupport: (load: boolean) => void;
}

const PermissionsPage = ({ user, loadSupport }: Props) => {
	const api = useContext(ApiContext);
	const [percent, setPercent] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [buttonActive, setButtonActive] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalType, setErrorModalType] = useState<'accessibility' | 'screen-recording'>('accessibility');
	const [learnWhyModalType, setLearnWhyModalType] = useState<'screen-recording' | 'accessibility'>('accessibility');
	const [showLearnWhyScreenRecordingModal, setShowLearnWhyScreenRecordingModal] = useState(false);
	const [showLearnWhyAccessibilityModal, setShowLearnWhyAccessibilityModal] = useState(false);
	const [showSkipPermissionsModal, setShowSkipPermissionsModal] = useState(false);
	const { track } = useAnalytics();

	const [{ firstName, lastName, occupation }, setUpdatedUserInfo] = useState<UserInfo>({});

	const handleClose = () => {
		setShowModal(false);
	};

	const handleCloseSkipPermissions = () => {
		setShowSkipPermissionsModal(false);
	};

	const handleCloseLearnWhyModal = () => {
		setShowLearnWhyAccessibilityModal(false);
		setShowLearnWhyScreenRecordingModal(false);
	};

	const handleCloseErrorPermissionsModal = () => {
		setShowErrorModal(false);
	};

	const onOkayClicked = () => {
		setShowErrorModal(false);
		handleClick(percent, currentIndex - 1);
	};

	const onSkipPermission = () => {
		track(TrackingEvent.SIGNUP);
		setShowSkipPermissionsModal(false);
		if (process.env.NODE_ENV === 'development') {
			window.location.href = '/';
			return;
		}
		if (window.ipc) {
			window.ipc.send(IPCChannel.APP_RELOAD, 'reload');
		}
		loadSupport(true);
	};

	const openModal = () => {
		setShowModal(true);
	};

	const openErrorModal = (type: 'accessibility' | 'screen-recording') => {
		setErrorModalType(type);
		setShowErrorModal(true);
	};

	const openLearnWhyModal = (type: 'screen-recording' | 'accessibility') => {
		setLearnWhyModalType(type);
		if (type === 'screen-recording') {
			setShowLearnWhyScreenRecordingModal(true);
		}
		if (type === 'accessibility') {
			setShowLearnWhyAccessibilityModal(true);
		}
	};

	const openSkipPermissionsModal = () => {
		setShowSkipPermissionsModal(true);
	};

	const [show, setShow] = useState(showModal);

	useEffect(() => {
		setShow(!show);
	}, [showModal]);

	useEffect(() => {
		if (firstName && lastName && occupation) {
			setButtonActive(false);
			return;
		}

		setButtonActive(true);
	}, [firstName, lastName, occupation]);

	const handleSubmit = () => {
		api
			.updateUserAccount({
				...user,
				firstName,
				lastName,
				occupation,
			})
			.then((userAccount) => {
				userState.user = { ...userAccount };
				userState.showOnBoarding = true;
			});
	};

	const updateUserInfo = (userInfo: Record<string, string>) => {
		setUpdatedUserInfo(userInfo);
	};

	const handleClick = (currentPercent: number, nextIndex: number) => {
		if (currentIndex === 0) {
			handleSubmit();
		}

		if (currentIndex === 3) {
			window.ipc.send(IPCChannel.LOGGED_IN, 'logged-in');
		}

		setPercent(currentPercent);
		setCurrentIndex(nextIndex);
	};

	const backPressHandler = () => {
		if (showLearnWhyAccessibilityModal) {
			handleClick(percent, 1);
			handleCloseLearnWhyModal();
		}
		if (showLearnWhyScreenRecordingModal) {
			handleClick(percent, 2);
			handleCloseLearnWhyModal();
		}
	};

	const content = () => {
		return (
			<ContentContainter>
				<ContainerLeft>
					{/* <ProgressBar
						width={320}
						percent={percent}
						hasStepZero
						unfilledBackground="rgba(0,0,0,0.1)"
						filledBackground="linear-gradient(to right, #8E78A3, #B67167)"
					>
						<Step transition="scale">{({ accomplished, index }: any) => <StyledStep>{index + 1}</StyledStep>}</Step>
						<Step transition="scale">{({ accomplished, index }: any) => <StyledStep>{index + 1}</StyledStep>}</Step>
						<Step transition="scale">{({ accomplished, index }: any) => <StyledStep>{index + 1}</StyledStep>}</Step>
						<Step transition="scale">{({ accomplished, index }: any) => <StyledStep>{index + 1}</StyledStep>}</Step>
					</ProgressBar> */}
					<Content
						index={currentIndex}
						showModal={showModal}
						openLearnWhyModal={openLearnWhyModal}
						openSkipPermissionsModal={openSkipPermissionsModal}
						openErrorModal={openErrorModal}
						handleClick={handleClick}
						buttonActive={buttonActive}
						openModal={openModal}
						loadSupport={loadSupport}
					/>
					<Spacing size="small" />
				</ContainerLeft>
				<ContainerRight>
					<Setup index={currentIndex} user={user} setUserInfo={updateUserInfo} />
				</ContainerRight>
			</ContentContainter>
		);
	};
	return (
		<Wrapper>
			<Navbar />
			<Background>
				<PermissionsModal showModal={showModal} handleClose={handleClose} />
				<ErrorPermissionsModal
					showModal={showErrorModal}
					type={errorModalType}
					handleClose={handleCloseErrorPermissionsModal}
					okayClicked={onOkayClicked}
				/>
				<SkipPermissionsModal
					showModal={showSkipPermissionsModal}
					handleClose={handleCloseSkipPermissions}
					onSkipPermission={onSkipPermission}
				/>
				<LearnWhyModal
					showModal={showLearnWhyAccessibilityModal || showLearnWhyScreenRecordingModal}
					handleClose={handleCloseLearnWhyModal}
					backPress={backPressHandler}
					type={learnWhyModalType}
				/>
				<Container>
					<Logo>{renderTylesBetaHeadlineLogo()}</Logo>
					<SignupScreen content={content()} />
				</Container>
			</Background>
		</Wrapper>
	);
};

export default PermissionsPage;
