import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { useContext, useState } from 'react';
import { UserInterface } from '../interfaces/UserInterface';
import useConfigHelper from '../helpers/useConfigHelper';
import userState from '../state/UserState';
import { ApiContext } from '../components/App';
import { GenericError, GenericSuccess } from '../api/generated/client';

export enum TrackingEvent {
	SIGNUP = 'signUp',
	SIGNUP_REFERRAL = 'signUpReferral',
	TYLE_DELETE = 'tyleDelete',
	UPDATE_USER_ACCOUNT = 'updateUserAccount',
	TYLE_WINDOW_CREATE = 'tyleWindowCreate',
	TYLE_WINDOW_VIEW_CHANGE_LINKS = 'tyleWindowViewChangeLinks',
	TYLE_WINDOW_VIEW_CHANGE_BACKLINKS = 'tyleWindowViewChangeBacklinks',
	TYLE_WINDOW_VIEW_CHANGE_EDITOR = 'tyleWindowViewChangeEditor',
	TYLE_CONNECTION_CREATE = 'tyleConnectionCreate',
	TYLE_CONNECTION_DELETE = 'tyleConnectionDelete',
	SEARCH = 'executeSearch',
	SEARCH_TAGS = 'executeTagSearch',
	APP_ERROR_AND_REFRESH = 'appErrorAndRefresh',
	GENERATE_TAGS_FOR_TYLE = 'generateTagsForTyle',
	HIGHLIGHT_CREATED = 'highlightCreated',
	AI_FILTER_USED = 'aiFilterUsed',
	TERMINAL_DOWNLOADED = 'terminalDownloaded',
	CLOSED_WEB_ONBOARDING = 'closedWebOnboarding',
	IS_MOBILE = 'isMobile',
	BACKEND_WELCOME_VIDEO_STARTED_PLAYING = 'backend_WelcomeVideoStartedPlaying',
}

interface AnalyticsInterface {
	track: (event: TrackingEvent, properties?: Record<string, unknown>) => void;
	pageview: () => void;
	identify: (user: UserInterface) => void;
	trackBackendUserEvent: (event: TrackingEvent) => Promise<GenericSuccess | GenericError>;
}

const useAnalytics = (): AnalyticsInterface => {
	const api = useContext(ApiContext);
	const [analytics, setAnalytics] = useState<Analytics>();
	const { analyticsWriteKey } = useConfigHelper();
	const [writeKey] = useState<string | undefined>(analyticsWriteKey);
	const isTrackingEnabled = process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test';

	const loadTracking = async (key: string) => {
		const [response] = await AnalyticsBrowser.load({ writeKey: key });
		return response;
	};

	const track = (event: TrackingEvent, properties?: Record<string, unknown>) => {
		if (!isTrackingEnabled) {
			return;
		}
		const trackProperties = {
			userId: userState.user.id,
			name: `${userState.user.firstName} ${userState.user.lastName}`,
			email: userState.user.email,
			...properties,
		};
		if (!analytics) {
			if (!writeKey) {
				return;
			}
			loadTracking(writeKey).then((response: Analytics) => {
				setAnalytics(response);
				response.track(event, trackProperties).then();
			});
			return;
		}
		analytics.track(event, trackProperties).then();
	};

	const pageview = () => {
		if (!isTrackingEnabled) {
			return;
		}
		if (!analytics) {
			if (!writeKey) {
				return;
			}
			loadTracking(writeKey).then((response: Analytics) => {
				setAnalytics(response);
				response.page().then();
			});
			return;
		}
		analytics.page().then();
	};

	const identify = (user: UserInterface) => {
		if (!isTrackingEnabled) {
			return;
		}
		// Don't identify if we don't have a name
		if (!user || (!user.firstName && !user.lastName)) {
			return;
		}
		const properties = {
			userId: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			createdAt: user.createdAt,
			occupation: user.occupation,
			email: user.email,
			name: `${user.firstName} ${user.lastName}`,
		};
		if (!analytics) {
			if (!writeKey) {
				return;
			}
			loadTracking(writeKey).then((response: Analytics) => {
				setAnalytics(response);
				if (response?.user().id() !== user.id) {
					response.identify(user.id, properties).then();
				}
			});
			return;
		}
		if (analytics?.user()?.id() !== user.id) {
			analytics.identify(user.id, properties).then();
		}
	};

	const trackBackendUserEvent = (event: TrackingEvent): Promise<GenericSuccess | GenericError> => {
		return api.trackEvent({
			track: {
				name: event,
			},
		});
	};

	return { track, pageview, identify, trackBackendUserEvent };
};

export default useAnalytics;
