/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { KeyboardEvent, createRef, FormEvent, ReactElement, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import ClearIcon from '../../styles/themes/light/icons/clear.svg';
import Button from '../button';
import './Input.scss';

export interface InputProps {
	label?: string;
	placeholder?: string;
	size?: 'default' | 'fullWidth' | 'title' | 'small';
	disabled?: boolean;
	icon?: string;
	clear?: boolean;
	onChange?: (value: string) => void;
	onSubmit?: (value: string) => void;
	onCancel?: (value: string) => void;
	value?: string;
	focus?: boolean;
	style?: Record<string, any>;
	actionIcon?: any;
	autogrow?: boolean;
	cancelOnBlur?: boolean;
	validationMessage?: string;
	required?: boolean;
	copyButton?: boolean;
	onCopyButtonClicked?: () => void;
	rows?: number;
}

const Input = ({
	label,
	placeholder,
	size,
	disabled,
	icon,
	clear,
	onChange,
	onSubmit,
	onCancel,
	value,
	focus,
	actionIcon,
	style,
	autogrow,
	cancelOnBlur,
	validationMessage,
	required,
	copyButton,
	rows,
	onCopyButtonClicked,
}: InputProps): ReactElement => {
	const [inputValue, setInputValue] = useState<string>(value ?? '');
	const inputRef = createRef<HTMLInputElement>();
	const textAreaRef = createRef<HTMLTextAreaElement>();
	const MIN_TEXTAREA_HEIGHT = 37;

	useEffect(() => {
		if (!value) {
			setInputValue('');
			return;
		}

		setInputValue(value);
	}, [value]);

	// Autosize text area
	useEffect(() => {
		if (!textAreaRef?.current?.style?.height) {
			return;
		}
		textAreaRef.current.style.height = '0px';
		const { scrollHeight } = textAreaRef.current;
		textAreaRef.current.style.height = `${scrollHeight}px`;
	}, [inputValue, textAreaRef]);

	useEffect(() => {
		if (focus) {
			inputRef?.current?.focus();
			textAreaRef?.current?.focus();
		}

		if (!textAreaRef || !textAreaRef.current) {
			return;
		}
		// Reset height - important to shrink on delete
		textAreaRef.current.style.height = '0';
		// Set height
		const { scrollHeight } = textAreaRef.current;
		textAreaRef.current.style.height = `${Math.max(scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
	}, [focus]);

	const handleSubmit = (event: KeyboardEvent | FormEvent) => {
		event.preventDefault();
		onSubmit?.(inputValue);
	};

	const handleOnChange = () => {
		if (!onChange || inputValue === value) {
			return;
		}

		onChange(inputValue);
	};

	useEffect(() => {
		handleOnChange();
	}, [inputValue]);

	const handleChange = (event: any): void => {
		if (textAreaRef?.current) {
			// Reset height - important to shrink on delete
			textAreaRef.current.style.height = '0';
			// Set height
			const { scrollHeight } = textAreaRef.current;
			textAreaRef.current.style.height = `${Math.max(scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
		}
		setInputValue(event.target.value);
	};

	const handleClear = () => {
		setInputValue('');

		if (!inputRef.current) return;
		inputRef.current.focus();
	};

	const handleCancel = (event: KeyboardEvent | FormEvent) => {
		event.preventDefault();
		onCancel?.(inputValue);
	};

	return (
		<Form noValidate onSubmit={handleSubmit}>
			<InputGroup className={`kum-input size-${size || 'default'} ${clear ? 'clear-enabled' : ''}`} hasValidation>
				<div className="input-header">
					<div className="label">{label}</div>
					<div className="error">
						{validationMessage && (
							<Form.Control.Feedback type="invalid" className="validation-error_message">
								{validationMessage}
							</Form.Control.Feedback>
						)}
					</div>
				</div>
				{icon && <img className="icon" src={icon} alt={label} />}
				{!autogrow && inputRef && (
					<Form.Control
						style={style}
						placeholder={placeholder}
						required={required}
						disabled={disabled}
						value={inputValue}
						autoFocus={focus}
						onChange={handleChange}
						ref={inputRef}
						onBlur={(event: any) => {
							if (!cancelOnBlur) {
								return;
							}
							handleCancel(event);
						}}
						onKeyDown={(event: KeyboardEvent) => {
							if (event.key === 'Enter') {
								handleSubmit(event);
							}
							if (event.key === 'Escape') {
								handleCancel(event);
							}
						}}
						isInvalid={!!validationMessage}
					/>
				)}
				{autogrow && textAreaRef && (
					<Form.Control
						as="textarea"
						className={rows ? 'hide-overflow' : ''}
						rows={rows}
						style={{ ...style, minHeight: MIN_TEXTAREA_HEIGHT, resize: 'none' }}
						placeholder={placeholder}
						disabled={disabled}
						required={required}
						value={inputValue}
						autoFocus={focus}
						onChange={handleChange}
						ref={textAreaRef}
						onKeyDown={(event: KeyboardEvent) => {
							if (event.key === 'Enter') {
								handleSubmit(event);
							}
						}}
						isInvalid={!!validationMessage}
					/>
				)}
				{clear && <img onClick={handleClear} className="clear-icon" src={ClearIcon} alt="clear" />}
				{actionIcon}
				{copyButton && (
					<div className="copy-button-container">
						<Button label="Copy" variant="primary" onEvent={onCopyButtonClicked} />
					</div>
				)}
			</InputGroup>
		</Form>
	);
};

export default Input;
