/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TippyProps } from '@tippyjs/react';
import {
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_H4,
	ELEMENT_H5,
	ELEMENT_H6,
	ELEMENT_IMAGE,
	ELEMENT_MEDIA_EMBED,
	ELEMENT_OL,
	ELEMENT_PARAGRAPH,
	ELEMENT_TABLE,
	ELEMENT_TODO_LI,
	ELEMENT_UL,
} from '@udecode/plate';
import { ELEMENT_CLOUD_IMAGE } from '@udecode/plate-cloud';
import PlusIcon from '../components/PlusIcon';
import { ELEMENT_TYLE_BLOCK } from '../types/tyleTypes';
import { withDraggablesCustom } from './withDraggables/withDraggablesCustom';
import Draghandle from '../components/Draghandle';

const styles = {
	grabber: {
		fontSize: 12,
		color: 'rgb(238, 238, 238)',
		backgroundColor: '#939393d2',
		borderRadius: '5px',
		padding: '5px',
	},
	grabberText: { color: 'rgba(255, 255, 255, 0.759)' },
	dragButton: {
		width: 18,
		height: 18,
		color: 'rgba(55, 53, 47, 0.3)',
	},
};

const GrabberTooltipContent = () => (
	<div style={styles.grabber}>
		<div>
			Drag to move
			<br />
			Click to open menu
		</div>
	</div>
);

export const grabberTooltipProps: TippyProps = {
	content: <GrabberTooltipContent />,
	placement: 'bottom',
	arrow: false,
	offset: [0, 0],
	delay: [300, 0],
	duration: [0, 0],
	hideOnClick: true,
	theme: 'small',
};

export const withStyledDraggables = (components: any) => {
	return withDraggablesCustom(components, [
		{
			keys: [ELEMENT_PARAGRAPH, ELEMENT_UL, ELEMENT_OL],
			level: 0,
		},
		{
			keys: [
				ELEMENT_PARAGRAPH,
				ELEMENT_BLOCKQUOTE,
				ELEMENT_TODO_LI,
				ELEMENT_H1,
				ELEMENT_H2,
				ELEMENT_H3,
				ELEMENT_H4,
				ELEMENT_H5,
				ELEMENT_H6,
				ELEMENT_IMAGE,
				ELEMENT_OL,
				ELEMENT_UL,
				ELEMENT_TABLE,
				ELEMENT_MEDIA_EMBED,
				ELEMENT_CODE_BLOCK,
				ELEMENT_TYLE_BLOCK,
				ELEMENT_IMAGE,
				ELEMENT_CLOUD_IMAGE,
			],
			onRenderDragHandle: ({ ...props }) => {
				return (
					<div className="drag-handle">
						<PlusIcon element={props.element} />
						<Draghandle element={props.element} />
					</div>
				);
			},
		},
		{
			key: ELEMENT_H1,
			styles: {
				gutterLeft: {
					padding: '0.8em 0 4px',
					fontSize: '1.875em',
				},
				blockToolbarWrapper: {
					height: '1.3em',
				},
			},
		},
		{
			key: ELEMENT_H2,
			styles: {
				gutterLeft: {
					padding: '0.7em 0 1px',
					fontSize: '1.5em',
				},
				blockToolbarWrapper: {
					height: '1.3em',
				},
			},
		},
		{
			key: ELEMENT_H3,
			styles: {
				gutterLeft: {
					padding: '0.75em 0 1px',
					fontSize: '1.25em',
				},
				blockToolbarWrapper: {
					height: '1.3em',
				},
			},
		},
		{
			keys: [ELEMENT_H4, ELEMENT_H5, ELEMENT_H6],
			styles: {
				gutterLeft: {
					padding: '0.75em 0 0',
					fontSize: '1.1em',
				},
				blockToolbarWrapper: {
					height: '1.3em',
				},
			},
		},
		{
			keys: [
				ELEMENT_PARAGRAPH,
				ELEMENT_IMAGE,
				ELEMENT_CLOUD_IMAGE,
				ELEMENT_UL,
				ELEMENT_OL,
				ELEMENT_BLOCKQUOTE,
				ELEMENT_CODE_BLOCK,
			],
			styles: {
				gutterLeft: {
					padding: '1px 0 0 0',
				},
			},
		},
		{
			key: ELEMENT_TYLE_BLOCK,
			styles: {
				gutterLeft: {
					padding: '1.2em 0 0 0',
				},
			},
		},
	]);
};
