import { ELEMENT_PARAGRAPH } from '@udecode/plate';
import { ELEMENT_TYLE_BLOCK, ELEMENT_TYLE_INLINE } from '../components/editor/types/tyleTypes';
import { CustomElement } from '../types/slate';

const getLinkList = (elements: Array<CustomElement>): Array<string> => {
	const linkList: Array<string> = [];

	elements.map((contentElement) => {
		if (contentElement.type === ELEMENT_PARAGRAPH && contentElement.children) {
			linkList.push(...getLinkList(contentElement.children));
			return contentElement;
		}
		if (
			(contentElement.type !== ELEMENT_TYLE_BLOCK && contentElement.type !== ELEMENT_TYLE_INLINE) ||
			!('tyleId' in contentElement) ||
			!contentElement.tyleId
		) {
			return contentElement;
		}

		linkList.push(contentElement.tyleId);
		return contentElement;
	});

	return linkList;
};

export default getLinkList;
