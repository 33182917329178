import { useState } from 'react';

type ConfigHelperData = {
	port: string | undefined;
	traceSampleRate: string | undefined;
	sentryDsn: string | undefined;
	apiUrl: string | undefined;
	env: string;
	analyticsWriteKey: string | undefined;
};

export const appPort = process.env.REACT_APP_PORT;
export const traceSampleRate = process.env.REACT_APP_TRACE_SAMPLE_RATE;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const apiUrl = process.env.REACT_APP_API_URL;
export const env = process.env.NODE_ENV;
export const analyticsWriteKey = process.env.REACT_APP_ANALYTICS_WRITE_KEY;

const useConfigHelper = (): ConfigHelperData => {
	const [appPortState] = useState<string | undefined>(appPort);
	const [traceSampleRateState] = useState<string | undefined>(traceSampleRate);
	const [sentryDsnState] = useState<string | undefined>(sentryDsn);
	const [apiUrlState] = useState<string | undefined>(apiUrl);
	const [envState] = useState<string>(env);
	const [analyticsWriteKeyState] = useState<string | undefined>(analyticsWriteKey);

	if (!process.env.REACT_APP_API_URL) {
		throw new Error('No config accessible!');
	}

	return {
		port: appPortState,
		traceSampleRate: traceSampleRateState,
		sentryDsn: sentryDsnState,
		apiUrl: apiUrlState,
		env: envState,
		analyticsWriteKey: analyticsWriteKeyState,
	};
};

export default useConfigHelper;
