import { ReactElement } from 'react';
import { TyleWindowActionEnum } from 'interfaces/TyleWindowInterface';
import Item from 'components/item';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/button';

interface TyleBacklinksActionProps {
	backlinkLength: number;
	selected: boolean;
	onEvent: (action: TyleWindowActionEnum) => void;
}

export const BacklinksButtonTestId = 'back-links-button-test-id';
const TyleBacklinksAction = ({ backlinkLength, selected, onEvent }: TyleBacklinksActionProps): ReactElement | null => {
	if (backlinkLength === 0) {
		return null;
	}

	return (
		<Item>
			<Tooltip tip="Backlinks" offset={{ top: 3 }}>
				<Button
					testId={BacklinksButtonTestId}
					variant="backlink"
					label={`${backlinkLength} `}
					selected={selected}
					onEvent={() => {
						onEvent(TyleWindowActionEnum.VIEW_BACKLINKS);
					}}
					iconPosition="left"
				/>
			</Tooltip>
		</Item>
	);
};

export default TyleBacklinksAction;
