import { useEffect, useState } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { TEditor } from '@udecode/plate';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { useCustomDragBlock } from 'hooks/DragAndDropHooks/useCustomDrag';
import useCustomDropEditor from 'hooks/DragAndDropHooks/useCustomDrop/useCustomDropEditor';
import canvasState from '../../../../../state/CanvasState';

const useDndBlockCustom = ({
	id,
	type,
	blockRef,
	removePreview,
	editor,
}: {
	id: string;
	type: string;
	blockRef: any;
	removePreview?: boolean;
	editor: TEditor;
}) => {
	const [dropLine, setDropLine] = useState<'' | 'top' | 'bottom'>('');
	const [onDragHoverEditor$] = useState<Subject<boolean>>(() => new Subject());
	const [isActive, setIsActive] = useState<boolean>(false);

	const [{ isDragging }, dragRef, preview] = useCustomDragBlock(editor, id, type);
	const [{ isOver }, drop] = useCustomDropEditor(editor, {
		id,
		blockRef,
		dropLine,
		setDropLine,
	});

	useEffect(() => {
		onDragHoverEditor$
			.pipe(
				debounce((valueDebounced: boolean) => timer(valueDebounced ? 250 : 0)),
				distinctUntilChanged(),
			)
			.subscribe((value: boolean) => {
				canvasState.tyleWindowPreview = !value;
			});
	}, []);

	// Unsubscribe when component unmount
	useEffect(
		() => () => {
			onDragHoverEditor$.unsubscribe();
		},
		[],
	);

	// Set isActive to render the border when some tyle is dragged above
	useEffect(() => {
		setIsActive(isOver);
	}, [isOver]);

	// Catch dragging to set the drag preview
	useEffect(() => {
		onDragHoverEditor$.next(isActive);
	}, [isActive]);

	// TODO: previewElement option
	if (removePreview) {
		drop(blockRef);
		preview(getEmptyImage(), { captureDraggingState: true });
	} else {
		preview(drop(blockRef));
	}

	if (!isOver && dropLine) {
		setDropLine('');
	}

	return {
		isDragging,
		dropLine,
		dragRef,
	};
};

export default useDndBlockCustom;
