import { Editor, Transforms } from 'slate';
import {
	comboboxStore,
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_LI,
	ELEMENT_MENTION_INPUT,
	ELEMENT_OL,
	ELEMENT_PARAGRAPH,
	ELEMENT_TODO_LI,
	ELEMENT_UL,
	PlateEditor,
} from '@udecode/plate';
import { CustomEditor } from 'types/slate';
import { isEmptyContent } from 'components/tyle/helpers';
import transformToList from '../../transforms/transformToList';
import transformGeneric from '../../transforms/transformGeneric';

const handleSelectCommand = (editor: PlateEditor, item: any): void => {
	const targetRange = editor.selection?.focus;
	if (!targetRange) return;
	const element: any = editor.children[targetRange.path[0]];
	editor.insertText('/');

	Transforms.removeNodes(editor as CustomEditor, {
		// TODO: replace any
		match: (node: any) => node.type === ELEMENT_MENTION_INPUT,
	});

	if (!isEmptyContent([element]))
		Transforms.insertNodes(editor as CustomEditor, {
			type: ELEMENT_PARAGRAPH,
			children: [{ text: '' }],
		});

	switch (item.key) {
		case 'p':
			transformGeneric(editor, ELEMENT_PARAGRAPH);
			break;
		case 'ul':
			transformToList(ELEMENT_UL, editor);
			break;
		case 'ol':
			transformToList(ELEMENT_OL, editor);
			break;
		case 'li':
			transformToList(ELEMENT_LI, editor);
			break;
		case 'todo':
			transformGeneric(editor, ELEMENT_TODO_LI);
			break;
		case 'code-block':
			transformGeneric(editor, ELEMENT_CODE_BLOCK);
			break;
		case 'quote':
			transformGeneric(editor, ELEMENT_BLOCKQUOTE);
			break;
		case 'h1':
			transformGeneric(editor, ELEMENT_H1);
			break;
		case 'h2':
			transformGeneric(editor, ELEMENT_H2);
			break;
		case 'h3':
			transformGeneric(editor, ELEMENT_H3);
			break;
		case 'image':
			// TODO: Open url paste window on select
			break;
		case 'video':
			// TODO: Open url paste window on select
			break;
		default:
			break;
	}
};

export default handleSelectCommand;
