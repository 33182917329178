import React, { ReactElement } from 'react';
import { getPluginType, MarkToolbarButton, useEditorState } from '@udecode/plate';
import { CustomElement } from 'types/slate';
import Divider from 'components/divider';
import { ToolbarProps, defaultTools } from './Tools';
import ToolbarDropdown from './ToolbarDropdown';
import LinkToolbarButton from './LinkToolbarButton';
import './BalloonToolbar.scss';

export interface Props {
	selectedBlocks: Array<CustomElement>;
}

const BlockToolbar = ({ selectedBlocks }: Props): ReactElement => {
	const editor = useEditorState();
	const selectedBlock = selectedBlocks[0] ?? null;
	if (!selectedBlock || !('type' in selectedBlock)) {
		return <></>;
	}

	return (
		<>
			<ToolbarDropdown selectedBlock={selectedBlock} />
			<Divider direction="vertical" />
			{defaultTools.map((tool: ToolbarProps) => {
				return (
					<MarkToolbarButton
						key={tool.type}
						type={getPluginType(editor, tool.type)}
						icon={tool.icon}
						actionHandler="onMouseDown"
					/>
				);
			})}
			<Divider direction="vertical" />
			<LinkToolbarButton />
		</>
	);
};

export default BlockToolbar;
