/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useMemo } from 'react';
import { Path } from 'slate';
import { ReactEditor, useReadOnly } from 'slate-react';
import { createNodesWithHOC, DraggableProps, PlateEditor, TEditor, PlateRenderElementProps } from '@udecode/plate';
import { CustomEditor } from 'types/slate';
import DraggableCustom from './DraggableCustom';

export interface WithDraggableOptions extends Pick<DraggableProps<any>, 'onRenderDragHandle' | 'styles'> {
	level?: number;
	filter?: (editor: TEditor, path: Path) => boolean;
	allowReadOnly?: boolean;
}

export const withDraggableCustom = (
	Component: any,
	{ styles, level, filter, allowReadOnly = false, onRenderDragHandle }: WithDraggableOptions = {},
) => {
	return forwardRef((props: PlateRenderElementProps, ref) => {
		const { attributes, element, editor } = props;
		const readOnly = useReadOnly();
		const path = useMemo(() => ReactEditor.findPath(editor as CustomEditor, element), [editor, element]);

		const filteredOut = useMemo(
			() => (Number.isInteger(level) && level !== path.length - 1) || (filter && filter(editor, path)),
			[path, editor],
		);

		if (filteredOut || (!allowReadOnly && readOnly)) {
			return <Component {...props} />;
		}

		return (
			<DraggableCustom
				editor={editor}
				attributes={attributes}
				element={element}
				componentRef={ref}
				styles={styles}
				onRenderDragHandle={onRenderDragHandle}
			>
				<Component {...props} />
			</DraggableCustom>
		);
	});
};

export const withDraggablesCustom = createNodesWithHOC(withDraggableCustom);
