import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import './LoadingSpinner.scss';

export interface LoadingSpinnerProps {
	size?: 'sm' | undefined;
}

const LoadingSpinner = ({ size }: LoadingSpinnerProps): ReactElement => {
	return (
		<div
			className={classNames({
				'kum-loading-spinner': true,
			})}
		>
			<Spinner animation="border" role="status" size={size}>
				<span className="visually-hidden">
					<div className="spinner-border" />
				</span>
			</Spinner>
		</div>
	);
};

export default LoadingSpinner;
