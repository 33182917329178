import { TEditableProps } from '@udecode/plate';
import { MyValue } from '../../types/plateTypes';

const editableProps: TEditableProps<MyValue> = {
	spellCheck: false,
	autoFocus: false,
	readOnly: false,
};

export default editableProps;
