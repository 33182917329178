import { ComponentType } from 'react';

export enum SearchSidebarItemTypeEnum {
	RECENTLY_SEARCHED = 'RECENTLY_SEARCHED',
	FAVORITES = 'FAVORITES',
	SEARCH_RESULTS = 'SEARCH_RESULTS',
}

export enum SearchSidebarShortcutsEnum {
	ENTER = 'Enter',
	ARROW_DOWN = 'ArrowDown',
	ARROW_UP = 'ArrowUp',
	ARROW_RIGHT = 'ArrowRight',
	ARROW_LEFT = 'ArrowLeft',
	KEY_1 = 'Digit1',
	KEY_2 = 'Digit2',
	KEY_3 = 'Digit3',
	KEY_SLASH = '/',
	KEY_QUESTION_MARK = '?',
}

export interface SearchSidebarItemInterface {
	title: string;
	type: SearchSidebarItemTypeEnum;
	optionComponent: ComponentType<any>;
	options: any[];
	hidden: boolean;
}

export interface SourceTyleCardInterface {
	hostName: string;
	url: string;
	favicon: string;
}
