import { Editor, Transforms } from 'slate';
import {
	ELEMENT_LINK,
	insertNodes,
	isCollapsed,
	unwrapNodes,
	wrapNodes,
	getPluginType,
	PlateEditor,
	TElement,
} from '@udecode/plate';
import { CustomEditor } from 'types/slate';

/**
 * Unwrap link at a location (default: selection).
 * Then, the focus of the location is set to selection focus.
 * Then, wrap the link at the location.
 */
const upsertLinkAtSelection = (
	editor: PlateEditor,
	{
		url,
		text,
		wrap,
	}: {
		url?: string;
		text?: string;
		/**
		 * If true, wrap the link at the location (default: selection) even if the selection is collapsed.
		 */
		wrap?: boolean;
	},
): void => {
	if (!editor.selection) return;
	const type = getPluginType(editor, ELEMENT_LINK);

	if (!wrap && isCollapsed(editor.selection)) {
		if (url) {
			insertNodes<TElement>(editor, {
				type,
				url,
				children: [{ text: url }],
			});
		}
		return;
	}

	// if our cursor is inside an existing link, but don't have the text selected, select it now
	if (wrap && isCollapsed(editor.selection)) {
		const linkLeaf = Editor.leaf(editor as CustomEditor, editor.selection);
		const [, inlinePath] = linkLeaf;
		Transforms.select(editor as CustomEditor, inlinePath);
	}

	unwrapNodes(editor, { at: editor.selection, match: { type } });
	if (text && url) {
		insertNodes<TElement>(editor, {
			type,
			url,
			children: [{ text }],
		});
	}
	wrapNodes(
		editor,
		{
			type,
			url,
			children: [],
		},
		{ at: editor.selection, split: true },
	);

	Transforms.collapse(editor as CustomEditor, { edge: 'end' });
};

export default upsertLinkAtSelection;
