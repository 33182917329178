/**
 * Allows you to use hotkeys to exit the current block.
 * mod+enter: Exit block
 * mod+shift+enter: Exit to previous block and creates a new line
 * enter: Exit block
 */
import {
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_CODE_LINE,
	ELEMENT_CODE_SYNTAX,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_H4,
	ELEMENT_H5,
	ELEMENT_H6,
	ELEMENT_LINK,
	ELEMENT_PARAGRAPH,
	ExitBreakPlugin,
} from '@udecode/plate';
import { MyPlatePlugin } from '../types/plateTypes';

const exitBreakPlugin: Partial<MyPlatePlugin<ExitBreakPlugin>> = {
	options: {
		rules: [
			{
				hotkey: 'mod+enter',
			},
			{
				hotkey: 'mod+shift+enter',
				before: true,
			},
			{
				hotkey: 'enter',
				query: {
					start: true,
					end: true,
					allow: [
						ELEMENT_PARAGRAPH,
						ELEMENT_BLOCKQUOTE,
						ELEMENT_CODE_LINE,
						ELEMENT_CODE_BLOCK,
						ELEMENT_H1,
						ELEMENT_H2,
						ELEMENT_H3,
						ELEMENT_H4,
						ELEMENT_H5,
						ELEMENT_H6,
						ELEMENT_LINK,
						ELEMENT_CODE_SYNTAX,
					],
				},
			},
		],
	},
};

export default exitBreakPlugin;
