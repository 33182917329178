import React, { ReactElement } from 'react';
import { Modal as BsModal } from 'react-bootstrap';
import classNames from 'classnames';
import { TerminalViewEnum } from 'interfaces/TerminalMenuInterface';
import './Modal.scss';
import { useSnapshot } from 'valtio';
import terminalState from '../../state/TerminalState';

export interface ModalProps {
	children: any;
	show: boolean;
	handleClose: any;
	variant?: 'default' | 'prompt' | 'form' | 'terminal';
	stacked?: number;
	style?: Record<string, any>;
	size?: 'small';
	showToast?: boolean;
}

const Modal = ({ children, show, handleClose, variant, stacked, style, size, showToast }: ModalProps): ReactElement => {
	const { navigation } = useSnapshot(terminalState);

	return (
		<BsModal
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			animation={false}
			onEscapeKeyDown={(e: any) => {
				if (variant === 'terminal') {
					e.preventDefault();
					if (navigation === TerminalViewEnum.DEFAULT) {
						handleClose();
					}
				}
			}}
			data-backdrop="static"
			onHide={handleClose}
			backdropClassName={`${classNames({
				'kum-backdrop': true,
			})} stacked-${stacked}`}
			className={`${classNames({
				'kum-modal': true,
				terminal: variant === 'terminal',
				prompt: variant === 'prompt',
				form: variant === 'form',
				default: variant !== 'prompt',
				small: size === 'small',
				'no-margin-top': !showToast,
			})} stacked-${stacked}`}
		>
			<BsModal.Body style={style}>{children}</BsModal.Body>
		</BsModal>
	);
};

export default Modal;
