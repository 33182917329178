import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import FormGroup from '../components/form-group';
import { InviteCode } from '../api/generated/client';
import { ApiContext } from '../components/App';
import Headline from '../components/headline';
import Divider from '../components/divider';
import Input from '../components/input';
import Button from '../components/button';

const StyledTable = styled.table`
	user-select: text;
`;

const InviteCodeSettings = (): ReactElement => {
	const [inviteCodes, setInviteCodes] = useState<InviteCode[]>([]);
	const [inviteCodeForm, setInviteCodeForm] = useState<InviteCode>({});
	const api = useContext(ApiContext);

	useEffect(() => {
		api
			.getMyInviteCodes()
			.then((foundInviteCodes) => {
				setInviteCodes(foundInviteCodes);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handleInviteCodeFormChange = useCallback(
		(newInviteCodeData: Partial<InviteCode>) => {
			setInviteCodeForm({
				...inviteCodeForm,
				...newInviteCodeData,
			});
		},
		[inviteCodeForm],
	);

	return (
		<>
			<Headline style={{ marginTop: 0 }}>Your invite codes</Headline>
			{inviteCodes.length !== 0 && <Divider />}
			{inviteCodes.length !== 0 && (
				<StyledTable>
					<thead>
						<tr>
							<th>User Details & Link</th>
							<th>Email</th>
							<th>Used</th>
							<th>Used at</th>
						</tr>
					</thead>
					<tbody>
						{inviteCodes.map((inviteCode) => {
							return (
								<tr key={inviteCode.code}>
									<td>
										<b>Name:</b> {inviteCode.firstName} {inviteCode.lastName} <br />
										<b>Occupation:</b> {inviteCode.occupation} <br />
										<b>Link:</b> <a href={`/inviteCode/${inviteCode.code}`}>{inviteCode.code}</a>
									</td>
									<td>{inviteCode.email}</td>
									<td>{inviteCode.used ? 'Yes' : 'No'}</td>
									<td>{inviteCode.usedAt}</td>
								</tr>
							);
						})}
					</tbody>
				</StyledTable>
			)}
			<Divider />
			<FormGroup label="Create invite code">
				<Input
					label="First Name"
					value={inviteCodeForm.firstName || ''}
					onChange={(value) => handleInviteCodeFormChange({ firstName: value })}
				/>
				<Input
					label="Last Name"
					value={inviteCodeForm.lastName || ''}
					onChange={(value) => handleInviteCodeFormChange({ lastName: value })}
				/>
				<Input
					label="Occupation"
					value={inviteCodeForm.occupation || ''}
					onChange={(value) => handleInviteCodeFormChange({ occupation: value })}
				/>
				<Input
					label="Email"
					value={inviteCodeForm.email || ''}
					onChange={(value) => handleInviteCodeFormChange({ email: value })}
				/>
				<Button
					label="Create"
					onEvent={() => {
						api
							.createInviteCode(inviteCodeForm)
							.then((createdInviteCode) => {
								setInviteCodes([...inviteCodes, createdInviteCode]);
								setInviteCodeForm({});
							})
							.catch((error) => {
								console.error(error);
							});
					}}
				/>
			</FormGroup>
		</>
	);
};

export default InviteCodeSettings;
