/* eslint-disable react/jsx-props-no-spreading */
import { TodoListElement } from '@udecode/plate';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledTodoList = styled(TodoListElement)`
	font-size: ${(props) => props.theme.fontSizes.paragraph};
	line-height: ${(props) => props.theme.lineHeights.default};
	font-weight: ${(props) => props.theme.fontWeights.base};
	letter-spacing: ${(props) => props.theme.letterSpacings.minimum};
	margin: ${(props) => props.theme.margins.paragraph};
	color: ${(props) => props.theme.colors.gray100};
	padding: 0;

	div {
		align-items: start;
		margin-top: 3px;
	}
`;

const TodoList = (props: any): ReactElement => {
	return <StyledTodoList {...props} />;
};

export default TodoList;
