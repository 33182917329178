import { MouseEventHandler } from 'react';
import { StyledLabel, ShortcutContainer, StyledShortcut, Container, MotionContainer } from './FloatingButton.styled';

export interface Props {
	label: string;
	shortcuts?: string[];
	onClick: MouseEventHandler<HTMLDivElement>;
}

const FloatingButton = ({ label, shortcuts = [], onClick }: Props) => {
	return (
		<MotionContainer whileHover={{ scale: 1.03 }} transition={{ type: 'spring', stiffness: 400, damping: 20 }}>
			<Container onClick={onClick}>
				<StyledLabel>{label}</StyledLabel>
				<ShortcutContainer>
					{shortcuts.map((shortcut) => (
						<StyledShortcut key={shortcut}>{shortcut}</StyledShortcut>
					))}
				</ShortcutContainer>
			</Container>
		</MotionContainer>
	);
};

export default FloatingButton;
