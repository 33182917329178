import { ReactElement } from 'react';
import styled from 'styled-components';

const StyledSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: ${(props) => props.theme.radius?.big};
	background-color: ${(props) => props.theme.colors?.background};
	padding: 14px;
`;
export const StyledSectionHeader = styled.span.attrs((props: { header: string }) => props)`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	width: 100%;
	text-transform: uppercase;
	color: ${({ header, theme }) => (header === 'Captured' ? theme.colors?.clipperHeader : theme.colors?.tagText)};
	font-weight: ${(props) => props.theme.fontWeights?.bold};
	font-size: ${(props) => props.theme.fontSizes?.headingXSmall};
	padding: 0 0 10px 0;
`;
const StyledContent = styled.div`
	width: 100%;
`;

export interface SectionProps {
	header?: string;
	icon?: ReactElement;
	content: ReactElement;
}

const Section = ({ header, icon, content }: SectionProps) => {
	return (
		<StyledSection>
			{header && (
				<StyledSectionHeader header={header}>
					{header}
					{icon}
				</StyledSectionHeader>
			)}
			<StyledContent>{content}</StyledContent>
		</StyledSection>
	);
};

export default Section;
