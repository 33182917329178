import { useContext } from 'react';
import userState from 'state/UserState';
import newCanvasState, { newCanvasStateService } from 'state/NewCanvas';
import { ApiContext } from 'components/App';
import { UserInterface } from 'interfaces/UserInterface';
import tylesState, { tylesObjectActions } from 'state/TylesState';
import { TyleConnectionInterface, TyleInterface } from 'interfaces/TyleInterface';
import {
	AddTyleToUserCanvasTylesParams,
	NavigateBackForwardParams,
	NavigateTyleParams,
	ReorderTylesParams,
	UpdateScrollTopParams,
} from 'interfaces/TyleWindowInterface';
import { StorageKeys } from 'services/StorageService';
import { adaptUser } from 'adapters';
import useTyleHooks from './useTyleHooks';
import sharedState from '../state/SharedState';
import useAnalytics, { TrackingEvent } from './useAnalytics';

const useCanvasHooks = () => {
	const api = useContext(ApiContext);
	const { getTyle } = useTyleHooks();
	const { track } = useAnalytics();

	const selectWindow = (windowId: string) => {
		// Select the navigated window and deselect others
		newCanvasStateService.selectWindow(windowId);
	};
	const addTyleToUserCanvasTyles = ({ windowId, tyleId }: AddTyleToUserCanvasTylesParams): void => {
		track(TrackingEvent.TYLE_WINDOW_CREATE);
		newCanvasStateService.addTyleWindow(tyleId, windowId);
		newCanvasStateService.selectWindow(windowId);
		localStorage.setItem(StorageKeys.USER_CANVAS_TYLES, JSON.stringify(newCanvasState.userCanvasTyles));
	};

	const getUserAccount = async (): Promise<void> => {
		return api
			.getUserAccount()
			.then((userAccount) => {
				userState.user = adaptUser(userAccount);
				if (Object.keys(userAccount.canvasTyles).length === 0) {
					newCanvasStateService.resetCanvasState();
					return;
				}
				newCanvasStateService.initialiseCanvasState(userAccount.canvasTyles);
			})
			.catch(() => {
				userState.user = {};
				newCanvasStateService.resetCanvasState();
			});
	};

	const reorderTyles = ({ newOrder, selectedWindowId }: ReorderTylesParams): void => {
		newCanvasStateService.setWindowOrderAndSelectWindow(newOrder, selectedWindowId);
	};

	const refreshTyle = async (tyleId: string): Promise<TyleInterface> => {
		let tyle: TyleInterface;
		if (!tylesState.tylesObjects[tyleId]) {
			tyle = await getTyle(tyleId);
			newCanvasStateService.unsetLoadingWindowId();
			return tyle;
		}
		tyle = tylesState.tylesObjects[tyleId];
		newCanvasStateService.unsetLoadingWindowId();
		return tyle;
	};

	const autoSaveUserTyles = async (): Promise<UserInterface | null> => {
		const canvasTylesStateString = localStorage.getItem(StorageKeys.USER_CANVAS_TYLES);
		if (!canvasTylesStateString) {
			return null;
		}
		return api
			.updateUserAccount({
				...userState.user,
				canvasTyles: JSON.parse(canvasTylesStateString),
			})
			.then((updatedUser) => {
				userState.user = <UserInterface>updatedUser;
				newCanvasStateService.showSaveNotificationAndHideAfterTimer(3000);
				// Reset timer running
				newCanvasStateService.updateClearSaveTimer(true);
				return adaptUser(updatedUser);
			});
	};
	const saveUserTyles = async (): Promise<UserInterface | null> => {
		const canvasTylesStateString = localStorage.getItem(StorageKeys.USER_CANVAS_TYLES);
		if (!canvasTylesStateString) {
			return null;
		}
		return api
			.updateUserAccount({
				...userState.user,
				canvasTyles: JSON.parse(canvasTylesStateString),
			})
			.then((updatedUser) => {
				userState.user = <UserInterface>updatedUser;
				return adaptUser(updatedUser);
			});
	};

	const addTyleToNavigation = ({ windowId, destinationTyleId, isPublicTyle }: NavigateTyleParams) => {
		if (!destinationTyleId || !windowId) {
			return;
		}
		const entity = isPublicTyle ? sharedState.publicWindowEntity : newCanvasState.userCanvasTyles.windows[windowId];
		if (!entity) {
			return;
		}
		const { queue, navigationIndex } = entity;

		// In case of public tyle window, manage navigation in shared state
		if (isPublicTyle && sharedState.publicWindowEntity) {
			// If the next index has a tyle already, remove it and all the tyles after it
			if (queue[navigationIndex + 1]) {
				sharedState.publicWindowEntity.queue = queue.slice(0, navigationIndex + 1);
				sharedState.publicWindowEntity.queue.push(destinationTyleId);
			} else {
				// If the next index is empty, add the tyle to the queue
				sharedState.publicWindowEntity.queue.push(destinationTyleId);
			}

			sharedState.publicWindowEntity.scrollTop = 0;
			sharedState.publicWindowEntity.navigationIndex = navigationIndex + 1; // Increase index
			sharedState.publicWindowEntity.tyleId = destinationTyleId;
			return;
		}

		// If the next index has a tyle already, remove it and all the tyles after it
		if (queue[navigationIndex + 1]) {
			const { queue: oldQueue } = newCanvasState.userCanvasTyles.windows[windowId];
			newCanvasStateService.updateWindow(windowId, {
				queue: [...oldQueue.slice(0, navigationIndex + 1), destinationTyleId],
				navigationIndex: navigationIndex + 1,
			});
		} else {
			// If the next index is empty, add the tyle to the queue
			const { queue: oldQueue } = newCanvasState.userCanvasTyles.windows[windowId];
			newCanvasStateService.updateWindow(windowId, {
				queue: [...oldQueue, destinationTyleId],
				navigationIndex: navigationIndex + 1,
			});
		}

		newCanvasStateService.setLoadingWindowId(windowId); // Trigger progress bar on tyle window
		newCanvasStateService.updateWindow(windowId, {
			scrollTop: 0,
			navigationIndex: navigationIndex + 1,
			tyleId: destinationTyleId,
		});
		// Select the navigated window and deselect others
		selectWindow(windowId);
	};

	const makeAllLinksPublicForPublicTyle = async ({ tyleLinks }: { tyleLinks: TyleConnectionInterface[] }) => {
		if (!tyleLinks) {
			return;
		}
		const promises = tyleLinks.map(async (link) => {
			const newTyle = await api.postGenerateTyleUrlForChild({
				childTyleId: link.backlinkId,
				parentTyleId: link.linkId,
			});
			if (newTyle.id) {
				tylesObjectActions.addTyleObject({ id: newTyle.id, tyle: newTyle });
				return newTyle;
			}
			return newTyle;
		});
		await Promise.all(promises);
	};

	const navigateBack = ({ windowId, isPublicTyle }: NavigateBackForwardParams) => {
		const { queue, navigationIndex } =
			isPublicTyle && sharedState.publicWindowEntity
				? sharedState.publicWindowEntity
				: newCanvasState.userCanvasTyles.windows[windowId];
		if (!queue[navigationIndex - 1]) {
			return;
		}

		// In case of public tyle window, manage navigation in shared state
		if (isPublicTyle && sharedState.publicWindowEntity) {
			sharedState.publicWindowEntity.scrollTop = 0;
			sharedState.publicWindowEntity.tyleId = queue[navigationIndex - 1];
			sharedState.publicWindowEntity.navigationIndex -= 1;
			return;
		}
		newCanvasStateService.updateWindow(windowId, {
			scrollTop: 0,
			tyleId: queue[navigationIndex - 1],
			navigationIndex: newCanvasState.userCanvasTyles.windows[windowId].navigationIndex - 1,
		});
		selectWindow(windowId);
	};

	const navigateForward = ({ windowId, isPublicTyle }: NavigateBackForwardParams) => {
		const { queue, navigationIndex } =
			isPublicTyle && sharedState.publicWindowEntity
				? sharedState.publicWindowEntity
				: newCanvasState.userCanvasTyles.windows[windowId];
		if (!queue[navigationIndex + 1]) {
			return;
		}

		if (isPublicTyle && sharedState.publicWindowEntity) {
			sharedState.publicWindowEntity.scrollTop = 0;
			sharedState.publicWindowEntity.tyleId = queue[navigationIndex + 1];
			sharedState.publicWindowEntity.navigationIndex += 1;
			return;
		}

		newCanvasStateService.updateWindow(windowId, {
			scrollTop: 0,
			tyleId: queue[navigationIndex + 1],
			navigationIndex: newCanvasState.userCanvasTyles.windows[windowId].navigationIndex + 1,
		});
		selectWindow(windowId);
	};

	const updateScrollTopForWindow = ({ windowId, scrollTop }: UpdateScrollTopParams) => {
		newCanvasStateService.updateWindow(windowId, { scrollTop });
	};

	return {
		addTyleToUserCanvasTyles,
		reorderTyles,
		getUserAccount,
		addTyleToNavigation,
		navigateBack,
		navigateForward,
		autoSaveUserTyles,
		saveUserTyles,
		refreshTyle,
		updateScrollTopForWindow,
		makeAllLinksPublicForPublicTyle,
	};
};

export default useCanvasHooks;
