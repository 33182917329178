import { SearchQuery as ApiSearchQuery } from 'api/generated/client';
import InvalidDataException from 'exceptions/InvalidDataException';
import { SearchQuery } from 'models/SearchQuery';

const adaptSearchQuery = (searchQuery: ApiSearchQuery): SearchQuery => {
	if (!searchQuery.id) {
		throw new InvalidDataException('No SearchQuery id found');
	}

	return new SearchQuery({
		id: searchQuery.id,
		text: searchQuery.text ?? '',
		filters: searchQuery.filters,
		createdAt: new Date(`${searchQuery.createdAt}`),
	});
};

export default adaptSearchQuery;
