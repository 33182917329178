import styled from 'styled-components';
import LoginCheck from 'components/LoginCheck';
import SupportChat from 'components/support-chat/SupportChat';
import FloatingButtonContainer from 'containers/FloatingButtonContainer';
import HorizontalCanvas from 'containers/HorizontalCanvas';
import ReferralToast from 'containers/ReferralToast';
import SearchSidebarContainer from 'containers/SearchSidebar/SearchSidebarContainer';
import HorizontalCanvasNotification from 'components/HorizontalCanvasNotification/HorizontalCanvasNotification';
import CustomDragLayer from 'components/CustomDragLayer';
import WebOnboarding from 'containers/WebOnboarding';
import useShortcutHooks from 'hooks/ShortcutHooks/useShortcutHooks';
import { UserInterface } from 'interfaces/UserInterface';
import useUserHooks from 'hooks/useUserHooks';
import { useEffect, useState } from 'react';
import LoadingScreen from 'components/LoadingScreen';
import userState from 'state/UserState';

const StyledContainer = styled.div`
	display: flex;
	flex-flow: row;
	width: 100vw;
`;

export interface Props {
	loadSupportChat: boolean;
}

const AppContentContainer = ({ loadSupportChat }: Props) => {
	const [user, setUser] = useState<UserInterface>();
	const { getUserAccount } = useUserHooks();
	useShortcutHooks();

	useEffect(() => {
		getUserAccount().then((userAccount) => {
			setUser(userAccount);
		});
	}, []);
	return (
		<>
			{!userState.showOnBoarding && user ? (
				<>
					<LoginCheck />
					{loadSupportChat && <SupportChat />}
					<StyledContainer>
						<SearchSidebarContainer />
						<HorizontalCanvas />
						<FloatingButtonContainer />
						<HorizontalCanvasNotification />
						<CustomDragLayer />
					</StyledContainer>
				</>
			) : (
				<LoadingScreen />
			)}
			<ReferralToast />
		</>
	);
};

export default AppContentContainer;
