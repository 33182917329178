import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { CanvasState } from '../models/State';

const canvasState = proxy<CanvasState>({
	canvasContainerProperties: { width: 0, height: 0 },
	windowDraggedOverEditor: false,
	tyleWindowPreview: true,
	dragAndSnapData: null,
	windowsQueues: [],
	currentWindowId: '',
	loadingWindow: null,
	isPublicView: false,
});

devtools(canvasState, { name: 'Canvas' });

export default canvasState;
