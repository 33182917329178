import { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ApiContext } from 'components/App';
import ResponseStatus from '../interfaces/ResponseStatus';
import { EnvironmentEnum } from '../helpers/detectEnvironment';
import useAuthentication from '../helpers/useAuthentication';
import { useEnvironmentDetection } from '../helpers';
import useLogin from '../hooks/useLogin';
import IPCChannel from '../model/IPC/IPCChannel';
import ValidationPage from './ValidationPage';
import './Pages.scss';

const TokenValidationPage = (): ReactElement | null => {
	const [tokenToSave, setTokenToSave] = useState<string>('');
	const [typeToSave, setTypeToSave] = useState<string>('');
	const [status, setStatus] = useState<ResponseStatus>(ResponseStatus.INITIAL);
	const history = useHistory();
	const { updateToken } = useAuthentication();
	const detectedEnvironment = useEnvironmentDetection();
	const { loggedIn } = useLogin();
	const { token, type } = useParams<{ token: string; type: EnvironmentEnum }>();

	const api = useContext(ApiContext);

	const forceRedirect = () => {
		// In development, redirect to /
		if (process.env.NODE_ENV === 'development') {
			window.location.href = '/';
			return;
		}
		window.location.href = '/';
		// In desktop app, reload app
		if (detectedEnvironment === EnvironmentEnum.DESKTOP) {
			if (window.ipc) {
				window.ipc.send(IPCChannel.APP_RELOAD, 'reload');
			}
		}
	};

	const goToTylesDesktop = () => {
		window.location.href = `tyles:///${typeToSave}/token/${tokenToSave}`;
	};

	useEffect(() => {
		// Use browser router params in development or production webApp and hash params in production desktop app
		if (process.env.NODE_ENV === 'development' || detectedEnvironment === EnvironmentEnum.WEB) {
			setTypeToSave(type);
			setTokenToSave(token);
			return;
		}
		const newToken = window.location.hash.split('/');
		setTypeToSave(newToken[1]);
		setTokenToSave(newToken[3]);
	}, [type, token, detectedEnvironment]);

	useEffect(() => {
		if (status !== ResponseStatus.INITIAL) {
			return;
		}
		if (!tokenToSave) {
			return;
		}

		updateToken(tokenToSave);
		api.setAuthentication(tokenToSave);
		setStatus(ResponseStatus.LOADING);
		api
			.getAuthentication()
			.then(() => {
				if (typeToSave === EnvironmentEnum.DESKTOP && detectedEnvironment !== EnvironmentEnum.DESKTOP) {
					goToTylesDesktop();
					return;
				}
				forceRedirect();
				loggedIn();
				setStatus(ResponseStatus.SUCCESS);
			})
			.catch(() => {
				setStatus(ResponseStatus.ERROR);
				history.push('/login', {
					from: 'token',
				});
			});
	}, [tokenToSave, typeToSave]);

	return tokenToSave ? <ValidationPage loginLink={`tyles:///${typeToSave}/token/${tokenToSave}`} /> : null;
};

export default TokenValidationPage;
