import { GeneratedTyleUrlInterface } from '../interfaces/TyleInterface';

class TyleGeneratedUrl implements GeneratedTyleUrlInterface {
	url: string;

	disabled: boolean;

	tyleId: string;

	constructor(generatedUrl: GeneratedTyleUrlInterface) {
		this.url = generatedUrl.url;
		this.disabled = generatedUrl.disabled;
		this.tyleId = generatedUrl.tyleId;
	}
}

export default TyleGeneratedUrl;
