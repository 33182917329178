import { SearchSidebarItemInterface, SearchSidebarItemTypeEnum } from '../../interfaces/SearchSidebarInterface';
import RecentSearch from '../RecentSearch';
import TyleCard from '../TyleCard/TyleCard';

const initialSections: SearchSidebarItemInterface[] = [
	{
		type: SearchSidebarItemTypeEnum.RECENTLY_SEARCHED,
		title: 'Recently searched',
		optionComponent: RecentSearch,
		options: [],
		hidden: false,
	},
	{
		type: SearchSidebarItemTypeEnum.FAVORITES,
		title: 'Favorites',
		optionComponent: TyleCard,
		options: [],
		hidden: false,
	},
	{
		type: SearchSidebarItemTypeEnum.SEARCH_RESULTS,
		title: 'Results',
		optionComponent: TyleCard,
		options: [],
		hidden: true,
	},
];

export default initialSections;
