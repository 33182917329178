/* eslint-disable no-multi-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';
import Icon from '../icon';

export interface UserAvatarInterface {
	firstName: string;
	lastName: string;
	size: any;
}

const StyledAvatar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledAvatarLabel = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	font-weight: ${(props) => props.theme.fontWeight?.medium};
	color: ${(props) => props.theme.colors.gray100};
	margin-left: 7px;
	max-width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	.user-menu-arrows {
		font-size: inherit;
		margin-left: 6px;
	}
`;

const StyledInnerAvatar = styled.div.attrs((props: { size: number }) => props)`
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-radius: ${(props) => props.theme.radius.base};
	height: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
	text-align: center;
	background-color: ${(props) => props.theme.colors.gray400};
	line-height: ${(props) => props.size}px;
	font-size: ${(props) => props.size / 1.8}px;
	font-weight: bold;
	border: 1px solid ${(props) => props.theme.colors.gray300};
	color: ${(props) => props.theme.colors.gray100};
`;

const UserAvatar = ({ firstName, lastName, size }: UserAvatarInterface): ReactElement => {
	if (!firstName && !lastName) throw new Error('UserAvatar requires a name');

	const innerStyle: CSSProperties | undefined = {
		height: 'auto',
		textAlign: 'center',
	};
	const innerFirstName = firstName.charAt(0).toUpperCase();
	const innerLastName = lastName.charAt(0).toUpperCase();

	return (
		<StyledAvatar aria-label={`${firstName}_${lastName}`}>
			<StyledInnerAvatar style={innerStyle} size={size}>
				{innerFirstName}
				{innerLastName}
			</StyledInnerAvatar>
			<StyledAvatarLabel>
				<div>
					{firstName} {lastName}
				</div>
				<Icon className="user-menu-arrows" type="vertical-arrows" />
			</StyledAvatarLabel>
		</StyledAvatar>
	);
};

export default UserAvatar;
