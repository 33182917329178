import { CSSProperties, ReactElement, useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { flash } from 'react-animations';
import PuffLoader from 'react-spinners/PuffLoader';
import light from 'themes/light';
import Tag from './Tag';
import { SelectableItem } from '../AutocompleteDropdown/DropdownItem/DropdownItem';

const flashAnimation = keyframes`${flash}`;

export const testIdLoader = 'loader';

const StyledLoading = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;

	.ellipsis {
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		-webkit-animation: ellipsis 1s infinite;
		-moz-animation: ellipsis 1s infinite;
		animation: ellipsis 1s infinite;
	}

	@keyframes ellipsis {
		from {
			width: 0px;
		}
		to {
			width: 12px;
		}
	}
	@-webkit-keyframes ellipsis {
		from {
			width: 0px;
		}
		to {
			width: 12px;
		}
	}
	@-moz-keyframes ellipsis {
		from {
			width: 0px;
		}
		to {
			width: 12px;
		}
	}
`;
const StyledMessage = styled.div`
	// animation: 5s ${flashAnimation};
	// animation-iteration-count: infinite;
	color: ${(props) => props.theme.colors?.tagText};
	font-size: ${(props) => props.theme.fontSizes?.paragraphSmall};
`;

const StyledContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 5px;
	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 0.2rem;
		border-radius: ${({ theme }) => theme.radius?.big};
	}

	::-webkit-scrollbar-thumb {
		border-radius: ${({ theme }) => theme.radius?.big};
		background-color: ${({ theme }) => theme.colors?.stroke}90;
		transition: background-color ease 0.2s;

		&:hover {
			cursor: default;
			background-color: ${({ theme }) => theme.colors?.softParagraph};
		}
	}
`;

interface Props {
	items: SelectableItem[];
	onItemClick: (item: SelectableItem) => void;
	onCloseTag: (item: SelectableItem) => void;
	selectedItems?: Set<string>;
	showCloseIcon?: boolean;
	style?: CSSProperties;
	errorMessage?: string;
	loading?: boolean;
}

export const TagsTestId = 'tags-section';

const Tags = ({
	loading,
	items,
	onItemClick,
	onCloseTag,
	selectedItems,
	style,
	showCloseIcon = true,
	errorMessage,
}: Props): ReactElement => {
	const [isLoading, setIsLoading] = useState(loading);
	const isSelected = useCallback(
		(item: SelectableItem) => selectedItems?.has(item.value),

		[selectedItems],
	);

	useEffect(() => {
		setIsLoading(loading);
	}, [loading]);

	return (
		<StyledContainer style={style} data-testid={TagsTestId}>
			{loading && (
				<StyledLoading>
					<PuffLoader
						color={light.colors.tagText}
						loading={isLoading}
						size={20}
						aria-label="Loading Spinner"
						data-testid={testIdLoader}
					/>
					<StyledMessage>
						Generating tags<span className="ellipsis">...</span>
					</StyledMessage>
				</StyledLoading>
			)}
			{items.map((item) => (
				<Tag
					key={item.value}
					tag={item}
					onClick={onItemClick}
					onClear={onCloseTag}
					selected={isSelected(item)}
					clearIcon={showCloseIcon}
				/>
			))}
			{!loading && !!errorMessage && <StyledMessage>{errorMessage}</StyledMessage>}
		</StyledContainer>
	);
};

export default Tags;
