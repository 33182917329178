import React, { ReactElement, useEffect, useState } from 'react';
import { StyledCloseIcon, StyledCloseIconWrap } from './TyleCloseButton.styled';

interface TyleCloseButtonProps {
	show: boolean;
	handleClose: () => void;
}

const TyleCloseButton = ({ show, handleClose }: TyleCloseButtonProps): ReactElement | null => {
	const [showCloseIcon, setShowCloseIcon] = useState(show);

	useEffect(() => {
		setShowCloseIcon(show);
	}, [show]);

	return show ? (
		<StyledCloseIconWrap
			hidden={!showCloseIcon}
			onMouseEnter={() => setShowCloseIcon(true)}
			onClick={handleClose}
			onKeyDown={handleClose}
			aria-hidden="true"
		>
			<StyledCloseIcon type="close" className="close-icon" />
		</StyledCloseIconWrap>
	) : null;
};

export default TyleCloseButton;
