import { MouseEventHandler, ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { CloseCircle } from '@styled-icons/remix-fill/CloseCircle';
import { SelectableItem } from '../../AutocompleteDropdown/DropdownItem/DropdownItem';

const StyledSpan = styled.span.attrs((props: { selected?: boolean }) => props)`
	position: relative;
	font-size: ${(props) => props.theme.fontSizes?.headingSmall};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	padding: 4px 8px;
	height: max-content;
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.colors?.tagOutline};
	background: ${(props) =>
		props.selected
			? `linear-gradient(90deg, ${props.theme.colors?.tagBg}30 0%, ${props.theme.colors?.background} 100%)`
			: 'transparent'};
	text-align: center;
	text-transform: lowercase;
	transition: 0.5s;
	background-size: 200% auto;
	color: ${(props) => props.theme.colors?.tagText};
	border-radius: 10px;
	display: block;

	&:hover {
		background-position: right center;
		color: ${(props) => props.theme.colors?.tagText};
		text-decoration: none;
	}
`;

const StyledCloseIcon = styled(CloseCircle).attrs((props: { showicon: string }) => props)`
	position: absolute;
	visibility: ${({ showicon }) => (showicon === 'true' ? 'visible' : 'hidden')};
	color: ${(props) => props.theme.colors?.softParagraph};
	top: -4px;
	right: -5px;
`;

interface Props {
	tag: SelectableItem;
	selected?: boolean;
	clearIcon?: boolean;
	onClick?: (item: SelectableItem) => void;
	onClear?: (item: SelectableItem) => void;
}

export const TagTestId = 'tag-test-id';
export const CloseTagTestId = 'close-tag-test-id';

const Tag = ({ tag, selected, clearIcon, onClick, onClear }: Props): ReactElement => {
	const [showClear, setShowClear] = useState<boolean>(false);
	const onClickHandler = useCallback<MouseEventHandler<HTMLSpanElement>>(
		(event) => {
			event.preventDefault();
			onClick?.(tag);
		},
		[onClick],
	);
	return (
		<StyledSpan
			onClick={onClickHandler}
			selected={selected}
			onMouseOver={() => setShowClear(true)}
			onMouseLeave={() => setShowClear(false)}
			data-testid={TagTestId}
		>
			#{tag.label}
			{clearIcon && (
				<StyledCloseIcon
					showicon={showClear.toString()}
					data-testid={CloseTagTestId}
					size={12}
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						onClear?.(tag);
					}}
				/>
			)}
		</StyledSpan>
	);
};

export default Tag;
