/* eslint-disable import/prefer-default-export */
import { TyleConnectionInterface, TyleInterface } from '../interfaces/TyleInterface';
import { CustomElement } from '../types/slate';
import { SourceInterface } from '../interfaces/SourceInterface';

export class Tyle implements TyleInterface {
	id?: string;

	createdAt: Date | undefined;

	updatedAt?: Date;

	source?: SourceInterface;

	title?: string;

	content?: CustomElement[];

	link?: TyleConnectionInterface[];

	backlink?: TyleConnectionInterface[];

	previousInstance?: TyleInterface;

	derivedInstances?: Array<TyleInterface>;

	deleted: boolean | undefined;

	favorite?: boolean;

	inbox?: boolean;

	constructor(tyle: TyleInterface) {
		this.id = tyle.id;
		this.createdAt = tyle.createdAt;
		this.updatedAt = tyle.updatedAt;
		this.source = tyle.source;
		this.title = tyle.title;
		this.content = tyle.content;
		this.link = tyle.link;
		this.backlink = tyle.backlink;
		this.previousInstance = tyle.previousInstance;
		this.derivedInstances = tyle.derivedInstances;
		this.deleted = tyle.deleted;
		this.favorite = tyle.favorite;
		this.inbox = tyle.inbox || false;
	}
}
