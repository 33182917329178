/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { useSnapshot } from 'valtio';
import { TyleWindowActionEnum, TyleWindowViews } from 'interfaces/TyleWindowInterface';
import ThreeDotsMenu from 'components/TyleWindow/ThreeDotsMenu';
import { TyleInterface } from 'interfaces/TyleInterface';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import canvasState from 'state/CanvasState';
import TyleLinksAction from 'components/TyleWindow/TyleLinksAction';
import TyleBacklinksAction from 'components/TyleWindow/TyleBacklinksAction';
import Icon from 'components/icon';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import TylesLogoButton from 'components/TyleWindow/TylesLogoButton';
import TyleCloseButton from 'components/TyleWindow/TyleCloseButton';
import TyleShareAction from './TyleShareAction';
import {
	StyledSeparator,
	StyledTyleWindowHeader,
	StyledTyleWindowHeaderActions,
	StyledTyleWindowHeaderFavoriteIconWrapper,
	StyledTyleWindowHeaderLeft,
	StyledTyleWindowHeaderTitle,
} from './TyleWindowHeader.styled';
import NavigationArrows from '../../../components/NavigationArrows';

interface TyleWindowHeaderProps {
	windowId: string;
	tyle: TyleInterface | undefined;
	selected: boolean;
	showNavigation: boolean;
	isDragging: boolean;
	dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
	view: TyleWindowViews;
	onThreeDotsMenuSelect: (item: PopoverMenuItemInterface) => void;
	onChangeView: (view: TyleWindowActionEnum) => void;
	navigateBack: () => void;
	navigateForward: () => void;
}

const TyleWindowHeader = ({
	windowId,
	tyle,
	selected,
	showNavigation,
	isDragging,
	dragHandleProps,
	view,
	onThreeDotsMenuSelect,
	onChangeView,
	navigateBack,
	navigateForward,
}: TyleWindowHeaderProps): ReactElement | null => {
	const { isPublicView } = useSnapshot(canvasState);

	if (!tyle?.id) {
		return null;
	}
	return (
		<StyledTyleWindowHeader {...dragHandleProps} isDragging={isDragging} selected={selected}>
			<StyledTyleWindowHeaderLeft>
				<NavigationArrows
					windowId={windowId}
					showNavigation={showNavigation}
					handleBackArrowClick={navigateBack}
					handleForwardArrowClick={navigateForward}
				/>
				<StyledTyleWindowHeaderTitle aria-label={`${tyle.title}`}>
					{tyle.title || 'Untitled'}
				</StyledTyleWindowHeaderTitle>
			</StyledTyleWindowHeaderLeft>

			{!isPublicView && (
				<StyledTyleWindowHeaderActions>
					<TyleLinksAction
						linkLength={tyle.link?.length || 0}
						selected={view === TyleWindowViews.LINKS}
						onEvent={onChangeView}
					/>
					<TyleBacklinksAction
						backlinkLength={tyle.backlink?.length || 0}
						selected={view === TyleWindowViews.BACKLINKS}
						onEvent={onChangeView}
					/>
					<StyledTyleWindowHeaderFavoriteIconWrapper hide={!tyle.favorite}>
						<Icon type="ri-star-fill" style={{ fontSize: '12px' }} />
					</StyledTyleWindowHeaderFavoriteIconWrapper>
					<TyleShareAction tyle={tyle} />
					<ThreeDotsMenu tyle={tyle} onSelect={onThreeDotsMenuSelect} />
				</StyledTyleWindowHeaderActions>
			)}
			{isPublicView && (
				<StyledTyleWindowHeaderActions>
					<TyleLinksAction
						linkLength={tyle.link?.length || 0}
						selected={view === TyleWindowViews.LINKS}
						onEvent={onChangeView}
					/>
					<StyledSeparator />
					<TylesLogoButton />
				</StyledTyleWindowHeaderActions>
			)}
		</StyledTyleWindowHeader>
	);
};

export default TyleWindowHeader;
