const getDomain = ({ value }: { value: string }): string | null => {
	const match = value.match(/^(?!:\/\/)(http(s)*:\/\/)*([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?/i);

	if (!match) {
		return null;
	}

	return match[0];
};

export default getDomain;
