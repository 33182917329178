const light = {
	colors: {
		black100: '#111111',
		lightHeading: '#111111',
		gray100: 'hsl(0, 0%, 40%)',
		gray200: '#AAAAAA',
		gray300: 'rgba(206, 206, 205, 0.5)',
		gray400: 'hsl(0, 0%, 95%)',
		gray500: 'rgba(246, 246, 246, 0.6)',
		white100: 'hsl(0, 0%, 100%)',
		white200: '#FDFDFD',
		background: '#FFFFFF',
		backgroundActive: '#FAFAFA',
		paragraph: '#666666',
		softParagraph: '#AAAAAA',
		selected: '#eff0f0',
		stroke: '#e8e8e8',
		inlineBg: '#f6f6f6',
		heading: '#111111',
		scrollThumb: '#c1c1c1',
		overlay: 'rgba(0, 0, 0, 0.65)',
		tagBg: '#7247FF',
		tagOutline: '#E2C9ED',
		tagText: '#5914A3',
		clipperBackground: 'rgba(28, 21, 27, 0.1)',
		clipperHeader: '#1467A3',
	},
	newNote: {
		stroke: '#E8E8E8',
		fill: '#521A9D',
		textFill: '#FFFFFF',
		shortcutFill: '#FFFFFF',
		shortcutTextFill: '#666666',
	},
	radius: {
		base: '4px',
		big: '10px',
		normal: '8px',
		medium: '6px',
		ms: '5px',
		small: '2px',
		modal: '30px',
	},
	fontSizes: {
		h3: '0.9rem',
		paragraph: '0.9rem',
		medium: '0.8rem',
		headingSmall: '12px',
		paragraphSmall: '0.688rem',
		headingXSmall: '0.588rem',
		headingBig: '20px',
		modalHeading: '1.2rem',
	},
	lineHeights: {
		default: '1.55',
	},
	fontWeights: {
		base: '400',
		medium: '500',
		semiBold: '600',
		bold: '700',
	},
	letterSpacings: {
		minimum: '0.01em',
	},
	margins: {
		paragraph: '0 0 6px 0',
		list: '0 0 6px 16px',
	},
	paddings: {
		input: '15px 14px 14px 14px',
		tag: '0px 5px 0px 8px',
	},
	shadows: {
		light: '0px 1px 9px rgba(0, 0, 0, 0.07)',
		'drag-and-drop': '0px 1px 59px rgba(17, 17, 17, 0.11)',
		strong: '2px 5px 12px rgba(17, 17, 17, 0.26)',
		soft: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
	},
};

export default light;
