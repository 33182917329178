import { createRef, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import fastCaptureState from 'state/FastCaptureState';
import './TextArea.scss';

const StyledContainer = styled.div.attrs((props: { border: boolean }) => props)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-left: 1.5px solid ${(props: any) => props.theme.colors?.softParagraph};
`;

const StyledTextArea = styled.textarea`
	width: 100%;
	outline: none;
	border: none;
	resize: none;
	padding: 0px 10px;
	font-size: ${(props) => props.theme.fontSizes?.paragraph};
	color: ${(props) => props.theme.colors?.heading};
	background-color: ${(props) => props.theme.colors?.background};
	overflow-y: scroll;

	&::placeholder {
		color: ${(props) => props.theme.colors?.softParagraph};
		font-size: ${(props) => props.theme.fontSizes?.medium};
	}

	::-webkit-scrollbar {
		width: 0.2rem;
		border-radius: ${({ theme }) => theme.radius?.big};
	}

	::-webkit-scrollbar-thumb {
		border-radius: ${({ theme }) => theme.radius?.big};
		background-color: ${({ theme }) => theme.colors?.stroke};
		transition: background-color ease 0.2s;

		&:hover {
			cursor: default;
			background-color: ${({ theme }) => theme.colors?.softParagraph};
		}
	}
`;

export interface TextAreaProps {
	value: string;
	onChange: (value: string) => void;
	type?: 'default' | 'capture' | 'quote';
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	focused?: boolean;
	rowsHeight?: number;
}

const TextArea = ({
	value,
	type = 'default',
	onChange,
	label,
	placeholder,
	disabled,
	focused,
	rowsHeight,
}: TextAreaProps): ReactElement => {
	const [inputValue, setInputValue] = useState<string>(value ?? '');
	const textAreaRef = createRef<HTMLTextAreaElement>();

	useEffect(() => {
		if (!value) {
			setInputValue('');
			return;
		}

		setInputValue(value);
	}, [value]);

	useEffect(() => {
		if (focused) {
			textAreaRef?.current?.focus();
		}
	}, [focused]);

	useEffect(() => {
		if (!onChange || inputValue === value) {
			return;
		}

		onChange(inputValue);
	}, [inputValue]);

	const handleChange = (event: any): void => {
		setInputValue(event.target.value);
	};

	return (
		<StyledContainer border={type === 'quote'}>
			<StyledTextArea
				ref={textAreaRef}
				placeholder="Empty note..."
				onMouseDown={(e) => {
					fastCaptureState.cancelAutoSave = true;
				}}
				disabled={disabled}
				value={inputValue}
				onChange={handleChange}
				rows={rowsHeight || 4}
			/>
		</StyledContainer>
	);
};

export default TextArea;
