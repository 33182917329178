/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useState } from 'react';
import Icon from 'components/icon';
import Label from 'components/label';
import './CustomAccordion.scss';

export interface Props {
	title: string;
	content: any;
}

const CustomAccordion = ({ title, content }: Props): ReactElement => {
	const [isActive, setIsActive] = useState<boolean>(false);

	return (
		<div className="accordion_item">
			<div className="accordion_title" onClick={() => setIsActive(!isActive)}>
				<div className="accordion_icon">{isActive ? <Icon type="arrow-down" /> : <Icon type="arrow-right" />}</div>
				<Label label={title} size="small" />
			</div>
			{isActive && <div className="accordion_content">{content}</div>}
		</div>
	);
};

export default CustomAccordion;
