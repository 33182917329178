/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, ReactNode, MouseEvent, useState, useEffect } from 'react';
import { Range } from 'slate';
import { ReactEditor } from 'slate-react';
import { usePlateEditorState } from '@udecode/plate';
import styled from 'styled-components';
import { PopoverContent } from 'components/popover-menu';
import PopoverToggle from 'components/popover-toggle';
import LinkPopoverContent from './LinkPopoverContent';
import useDeleteLink from './useDeleteLink';

const StyledSpan = styled.span`
	text-decoration: underline;
	color: ${(props) => props.theme.colors.gray200};
`;

interface LinkPopoverProps {
	children: ReactNode;
	element: any;
	attributes: any;
	nodeProps: { url: string };
	editorId: string;
}

const LinkPopover = ({ children, element, attributes, nodeProps, editorId }: LinkPopoverProps): ReactElement => {
	const { url } = element;
	const { deleteLink } = useDeleteLink({ editorId });
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const handleClickOutside = (close: boolean) => {
		setIsPopoverOpen(close);
	};

	return (
		<StyledSpan {...attributes} data-slate-value={element.value}>
			<PopoverContent
				isOpen={isPopoverOpen}
				positions={['bottom', 'top', 'right', 'left']}
				reposition
				align="center"
				onClickOutside={() => {
					setIsPopoverOpen(false);
				}}
				content={
					<LinkPopoverContent
						id={element.id}
						url={url}
						onCancel={handleClickOutside}
						onDelete={() => deleteLink(element)}
					/>
				}
			>
				<PopoverToggle
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						setIsPopoverOpen(!isPopoverOpen);
					}}
				>
					{children}
				</PopoverToggle>
			</PopoverContent>
		</StyledSpan>
	);
};

export default LinkPopover;
