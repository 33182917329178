import React, { ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuthentication from '../helpers/useAuthentication';

const LoginCheck = (): ReactElement => {
	const location = useLocation();
	const { isLoggedIn } = useAuthentication();

	return !isLoggedIn() ? (
		<Redirect
			to={{
				pathname: '/login',
				state: { from: location },
			}}
		/>
	) : (
		<></>
	);
};

export default LoginCheck;
