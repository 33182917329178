import { ReactElement } from 'react';
import { StyledIndeterminateProgressBar, StyledIndeterminateProgressBarFill } from './IndeterminateProgressBar.styled';

const IndeterminateProgressBar = (): ReactElement => {
	return (
		<StyledIndeterminateProgressBar>
			<StyledIndeterminateProgressBarFill />
		</StyledIndeterminateProgressBar>
	);
};

export default IndeterminateProgressBar;
