/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/aria-role */
import { CSSProperties, ReactElement, ReactNode, useEffect } from 'react';
import { useDragLayer } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragTyleInterface } from 'interfaces/DragAndDrop/DragAndDropInterfaces';
import { useCustomDragCard, useCustomDragShared } from 'hooks/DragAndDropHooks/useCustomDrag';

export interface Props extends DragTyleInterface {
	children: ReactNode;
}

function getStyles(zIndex: number, isDragging: boolean): CSSProperties {
	return {
		opacity: isDragging ? 0 : 1,
		height: isDragging ? 0 : '',
		zIndex,
	};
}

const DragCard = ({ id, tyleId, from, to, type, width, height, zIndex, children }: Props): ReactElement => {
	const { handleDrag } = useCustomDragShared();
	const [{ isDragging }, drag, preview] = useCustomDragCard({
		id,
		tyleId,
		from,
		to,
		type,
		width,
		height,
		zIndex,
	});
	const { clientOffset } = useDragLayer((monitor) => ({
		clientOffset: monitor.getClientOffset(),
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	// During window dragging
	useEffect(() => {
		if (isDragging) {
			handleDrag({ isDragging: true, clientOffset, from });
		}
	}, [isDragging, clientOffset]);

	return (
		<div className="drag-card-container" role="DraggableBox" style={getStyles(zIndex, isDragging)} ref={drag}>
			{children}
		</div>
	);
};

export default DragCard;
