import { useEffect, useState } from 'react';
import { Result } from 'active-win';
import useWindowFocus from 'use-window-focus';
import terminalState from 'state/TerminalState';
import fastCaptureState from 'state/FastCaptureState';
import { ApiStatusEnum } from 'interfaces/ApiInterface';
import useLogout from 'hooks/useLogout';
import { EnvironmentEnum } from './detectEnvironment';
import { useEnvironmentDetection } from './index';
import { NotificationMessage } from './useGetNotificationFromElectron';

export interface DataFromElectronInterface {
	content: string;
	source: Result;
}

export interface ElectronDataExchangeInterface {
	dataFromElectron: DataFromElectronInterface | null;
	notificationMessage: NotificationMessage | undefined;
}

const useElectronDataExchange = (): ElectronDataExchangeInterface => {
	const [status, setStatus] = useState<ApiStatusEnum>(ApiStatusEnum.INITIALIZING);
	const [initialized, setInitialized] = useState<boolean>(false);
	const [data, setData] = useState<null | DataFromElectronInterface>(null);
	const [notificationMessage, setNotificationMessage] = useState<NotificationMessage | undefined>(undefined);
	const environment = useEnvironmentDetection();
	const windowFocused = useWindowFocus();
	const { logout } = useLogout();

	const { ipcRenderer } = window.require
		? window.require('electron')
		: {
				ipcRenderer: {
					send: (message: string) => {
						// void
					},
					on: (message: string) => {
						// void
					},
					removeListener: (message: string) => {
						// void
					},
				},
		  };

	useEffect(() => {
		// this useEffect handles a reopening of the desktop application (window gets focused again)
		// if this happens, a new request for getting tyle data is sent because it is implied that the user
		// made a new highlight and opened the terminal (via shortcut).
		if (!windowFocused || environment !== EnvironmentEnum.DESKTOP) {
			return;
		}
		setStatus(ApiStatusEnum.IS_LOADING);
		ipcRenderer.send('tyle-request');
	}, [windowFocused]);

	useEffect(() => {
		if (environment !== EnvironmentEnum.DESKTOP) {
			return () => {
				// void;
			};
		}
		ipcRenderer.on('logout', () => {
			logout();
		});

		const listener = (event: any, dataFromElectron: DataFromElectronInterface) => {
			setStatus(ApiStatusEnum.RESPONDED);
			setData(dataFromElectron);
		};

		ipcRenderer.on('tyle-reply', listener);

		ipcRenderer.on('notification-message', (e: any, notification: any) => {
			const { message } = notification;
			setStatus(ApiStatusEnum.RESPONDED);
			setNotificationMessage({ id: message.id, type: message.type, message: message.message });
		});
		ipcRenderer.on('blur', (e: any, msg: any) => {
			setStatus(ApiStatusEnum.RESPONDED);
			terminalState.showTerminal = false;
		});
		ipcRenderer.on('open-fastCapture', () => {
			setStatus(ApiStatusEnum.RESPONDED);
			fastCaptureState.showFastCapture = true;
			fastCaptureState.isFastCaptureClosed = false;
		});
		ipcRenderer.on('close-fastCapture', () => {
			setStatus(ApiStatusEnum.RESPONDED);
			fastCaptureState.isFastCaptureClosed = true;
			fastCaptureState.showFastCapture = false;
		});
		ipcRenderer.on('cancel-auto-save', () => {
			setStatus(ApiStatusEnum.RESPONDED);
			fastCaptureState.cancelAutoSave = true;
		});

		return () => {
			ipcRenderer.removeListener('tyle-reply', listener);
		};
	}, [ipcRenderer]);

	useEffect(() => {
		// initialize the component and send initial request to electron
		if (initialized || status === ApiStatusEnum.IS_LOADING || environment !== EnvironmentEnum.DESKTOP) {
			return;
		}

		setInitialized(true);
		setStatus(ApiStatusEnum.IS_LOADING);

		ipcRenderer.send('tyle-request');
	}, [status, initialized, ipcRenderer]);

	if (environment !== EnvironmentEnum.DESKTOP) {
		return { dataFromElectron: null, notificationMessage: undefined };
	}

	return { dataFromElectron: data, notificationMessage };
};

export default useElectronDataExchange;
