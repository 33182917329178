import { ReactElement, useState } from 'react';
import { nanoid } from 'nanoid';
import TooltipInitialization, { Offset } from './TooltipInitialization';
import './Tooltip.scss';

export enum TooltipPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
}

export interface TooltipProps {
	children: any;
	tip: string;
	open?: TooltipPosition;
	offset?: Offset;
	fontSize?: string;
	maxWidth?: string;
}

const Tooltip = ({
	children,
	tip,
	open = TooltipPosition.TOP,
	offset,
	fontSize,
	maxWidth,
}: TooltipProps): ReactElement => {
	const [id] = useState<string>(`tooltip-${nanoid()}`);

	return (
		<>
			<span data-tip={tip} data-for={id} className="tooltip-wrapper">
				{children}
			</span>
			<TooltipInitialization position={open} id={id} offset={offset} fontSize={fontSize} maxWidth={maxWidth} />
		</>
	);
};

export default Tooltip;
