import { UserCanvasData } from 'interfaces/CanvasInterface';
import { StorageKeys } from 'services/StorageService';

// Retrieves the ID of the selected window from the given UserCanvasData object
export const getSelectedWindowId = (userCanvasData: UserCanvasData): string | undefined => {
	return Object.entries(userCanvasData.windows).find(([_, windowData]) => windowData.selected)?.[0];
};

// Helper function to parse UserCanvasData from localStorage
export const getUserCanvasDataFromLocalStorage = (): UserCanvasData | undefined => {
	const storedStateStringCanvasTyle = localStorage.getItem(StorageKeys.USER_CANVAS_TYLES);
	return storedStateStringCanvasTyle ? (JSON.parse(storedStateStringCanvasTyle) as UserCanvasData) : undefined;
};
