import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import Headline from 'components/headline';
import './Pages.scss';
import Paragraph from 'components/paragraph';
import { detectOperatingSystem } from 'helpers';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import LoadingScreen from 'components/LoadingScreen';

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100vh;
	background-color: ${(props) => props.theme.colors?.black100};
`;

const StyledContainer = styled.div`
	padding: 20px;
	font-size: 14px;
	line-height: 14px;
`;
const StyledTextarea = styled.textarea`
	width: 100%;
`;

export interface ValidationPageProps {
	loginLink?: string;
}

const ValidationPage = ({ loginLink }: ValidationPageProps) => {
	const win32 = detectOperatingSystem() === OperatingSystemPlatformEnum.WINDOWS;
	const isDev = process.env.NODE_ENV === 'development';
	return (
		<StyledWrapper>
			<StyledContainer>
				{win32 && isDev && (
					<>
						<Headline variant="h1" style={{ color: 'white' }}>
							You can close this window after opening the app.
						</Headline>
						<Paragraph>Please copy and paste this link into the desktop app to sign in:</Paragraph>
						<StyledTextarea rows={10}>{loginLink}</StyledTextarea>
					</>
				)}
			</StyledContainer>
		</StyledWrapper>
	);
};

export default ValidationPage;
