const truncateString = (string: string | undefined, length: number): string => {
	if (!string) {
		return '';
	}

	if (string.length <= length) {
		return string;
	}

	return `${string.substring(0, length - 1)}…`;
};

export default truncateString;
