import React, { ReactElement, useEffect, useState } from 'react';
import { TyleInterface } from '../interfaces/TyleInterface';
import PublicTyleWindow from '../components/PublicTyleWindow';
import DocumentRemovedPage from './DocumentRemovedPage';
import canvasState from '../state/CanvasState';
import useTyleHooks from '../hooks/useTyleHooks';

const PublicTylePage = (): ReactElement => {
	const { getTyle } = useTyleHooks();
	const [tyle, setTyle] = useState<TyleInterface | undefined>();
	const [found, setFound] = useState<boolean>(true);

	useEffect(() => {
		canvasState.isPublicView = true;
		getTyle(window.location.pathname.split('/tyle-url/')[1])
			.then((tyleFound) => {
				setTyle(tyleFound);
				setFound(true);
			})
			.catch(() => {
				setFound(false);
			});
	}, []);

	return found ? (
		<PublicTyleWindow tyleFromUrl={tyle} url={window.location.pathname.split('/tyle-url/')[1]} />
	) : (
		<DocumentRemovedPage />
	);
};

export default PublicTylePage;
