import { detectOperatingSystem } from '.';
import { OperatingSystemPlatformEnum } from './detectOperatingSystem';

const cmdOrCtrl = (): string => {
	switch (detectOperatingSystem()) {
		case OperatingSystemPlatformEnum.WINDOWS:
			return 'CTRL';
		case OperatingSystemPlatformEnum.MAC_OS:
			return 'CMD';
		default:
			return 'CTRL';
	}
};

export default cmdOrCtrl;
