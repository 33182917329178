import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useElectronDataExchange from './useElectronDataExchange';

export enum NotificationTypeEnum {
	ADD_TO = 'added',
	CREATE_NEW = 'created',
	CAPTURE = 'captured',
}

export interface NotificationMessage {
	message: string;
	type?: NotificationTypeEnum;
	id: string;
}

export interface NotificationFromElectron {
	notification: NotificationMessage | undefined;
}

const useGetNotificationFromElectron = (): NotificationFromElectron => {
	const { notificationMessage } = useElectronDataExchange();
	const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined);
	const previousData = useRef<NotificationMessage | undefined>(undefined);

	const createMessage = (message: string, type: NotificationTypeEnum | undefined) => {
		switch (type) {
			case NotificationTypeEnum.CAPTURE:
				setNotification({ type, message: 'Captured' } as NotificationMessage);
				break;
			case NotificationTypeEnum.CREATE_NEW:
				setNotification({ type, message: `${message} created` } as NotificationMessage);
				break;
			case NotificationTypeEnum.ADD_TO:
				setNotification({ type, message: `Added to ${message}` } as NotificationMessage);
				break;
			default:
				setNotification(notificationMessage);
				break;
		}
	};

	useEffect(() => {
		if (!notificationMessage) {
			setNotification(undefined);
			return;
		}
		if (_.isEqual(notificationMessage, previousData.current)) {
			return;
		}

		previousData.current = notificationMessage;
		createMessage(notificationMessage.message, notificationMessage.type);
	}, [notificationMessage]);

	return { notification };
};

export default useGetNotificationFromElectron;
