import { DropTargetMonitor, useDrop } from 'react-dnd';
import { ELEMENT_PARAGRAPH, findNode, isExpanded } from '@udecode/plate';
import { Editor, Path, Transforms } from 'slate';
import { getHoverDirection, getNewDirection } from '@udecode/plate-dnd';
import { ReactEditor } from 'slate-react';
import { ELEMENT_TYLE_BLOCK, ELEMENT_TYLE_INLINE } from 'components/editor/types/tyleTypes';
import tylesState from 'state/TylesState';
import { TyleInterface } from '../../../interfaces/TyleInterface';
import { DragItemTypeEnum, DragAndDropAreaEnum } from '../../../interfaces/DragAndDrop/DragAndDropEnums';
import { DragTyleInterface, DragItemInterface } from '../../../interfaces/DragAndDrop/DragAndDropInterfaces';
import useTyleHooks from '../../useTyleHooks';
import canvasState from '../../../state/CanvasState';
import { InlineTypes } from '../../../components/editor/transforms/transformations';

export interface Props {
	blockRef: any;
	id: string;
	dropLine: string;
	setDropLine: any;
}

const useCustomDropEditor = (editor: any, { blockRef, id, dropLine, setDropLine }: Props) => {
	const insertNodes = (tyle: TyleInterface, at: any) => {
		const tyleInline = {
			type: ELEMENT_TYLE_INLINE,
			view: InlineTypes.TITLE,
			tyleId: tyle.id,
			children: [{ text: '' }],
		};

		return Transforms.insertNodes(
			editor,
			[
				{
					type: ELEMENT_PARAGRAPH,
					children: [{ text: '' }, tyleInline, { text: ' ' }],
				},
			],
			{ at },
		);
	};

	return useDrop({
		accept: Object.values(DragItemTypeEnum),
		canDrop: (item: DragTyleInterface) => {
			if (item) {
				return item?.tyleId !== editor?.id; // TODO SWITCH TO item.windowId
			}
			return true;
		},
		drop: (dragItem: DragTyleInterface | DragItemInterface, monitor: DropTargetMonitor): any => {
			const dragObject = { ...dragItem, to: DragAndDropAreaEnum.EDITOR };

			console.log('DRAG OBJECT', dragObject);

			const direction = getHoverDirection(dragItem, monitor, blockRef, id);
			if (!direction) return dragObject;

			if ('tyleId' in dragItem) {
				// coming from outside, so insert as TyleBlock
				let dropPath: Path | undefined;
				if (direction === 'bottom') {
					dropPath = findNode(editor, { at: [], match: { id } })?.[1];
					if (!dropPath) return dragObject;
				}

				if (direction === 'top') {
					const nodePath = findNode(editor, { at: [], match: { id } })?.[1];

					if (!nodePath) return dragObject;
					dropPath = [...nodePath.slice(0, -1), nodePath[nodePath.length - 1] - 1];
				}
				const at = Path.next(dropPath as Path);
				const foundPosition = Editor.hasPath(editor, at);

				const tyle = tylesState.tylesObjects[dragItem.tyleId];
				if (foundPosition) {
					insertNodes(tyle, at);
					return dragObject;
				}
				insertNodes(tyle, Editor.end(editor, []));
				if (editor.children[editor.children.length - 1].type === ELEMENT_TYLE_BLOCK) {
					Transforms.insertNodes(
						editor,
						[
							{
								type: ELEMENT_PARAGRAPH,
								children: [{ text: '' }],
							},
						],
						{ at: Editor.end(editor, []) },
					);
				}
				return dragObject;
			}
			const dragEntry = findNode(editor, {
				at: [],
				match: { id: dragItem.id },
			});
			if (!dragEntry) return dragObject;
			const [, dragPath] = dragEntry;

			ReactEditor.focus(editor);

			let dropPath: Path | undefined;
			if (direction === 'bottom') {
				dropPath = findNode(editor, { at: [], match: { id } })?.[1];
				if (!dropPath) return dragObject;

				if (Path.equals(dragPath, Path.next(dropPath))) return dragObject;
			}

			if (direction === 'top') {
				const nodePath = findNode(editor, { at: [], match: { id } })?.[1];

				if (!nodePath) return dragObject;
				dropPath = [...nodePath.slice(0, -1), nodePath[nodePath.length - 1] - 1];

				if (Path.equals(dragPath, dropPath)) return dragObject;
			}

			const dropPathCustom = dropPath as Path;

			const before = Path.isBefore(dragPath, dropPathCustom) && Path.isSibling(dragPath, dropPathCustom);
			const to = before ? dropPathCustom : Path.next(dropPathCustom);

			Transforms.moveNodes(editor, {
				at: dragPath,
				to,
			});
			if (editor.children[editor.children.length - 1].type === ELEMENT_TYLE_BLOCK) {
				Transforms.insertNodes(
					editor,
					[
						{
							type: ELEMENT_PARAGRAPH,
							children: [{ text: '' }],
						},
					],
					{ at: Editor.end(editor, []) },
				);
			}
			tylesState.isTyleTransformed = true;

			return dragObject;
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			dropResult: monitor.getDropResult(),
		}),
		hover: (item: any, monitor: DropTargetMonitor) => {
			if (item?.tyleId !== editor?.id) {
				// TODO SWITCH TO item.windowId
				canvasState.windowDraggedOverEditor = true;
				const direction = getHoverDirection(item, monitor, blockRef, id);
				const dropLineDir = getNewDirection(dropLine, direction);
				if (dropLineDir) setDropLine(dropLineDir);
				if (direction && isExpanded(editor.selection)) {
					ReactEditor.focus(editor);
					Transforms.collapse(editor);
				}
			}
		},
	});
};

export default useCustomDropEditor;
