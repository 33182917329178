import { Role, UserInterface } from 'interfaces/UserInterface';
import { UserCanvasData } from '../interfaces/CanvasInterface';

class User implements UserInterface {
	public id?: string;

	public email?: string;

	public password?: string;

	public firstName?: string;

	public lastName?: string;

	public occupation?: string;

	public deleted?: boolean;

	public temporaryEmail?: string;

	public referralCode?: string;

	public theme?: string;

	public role?: Role;

	public canvasTyles?: UserCanvasData;

	public webOnboarded?: boolean;

	public desktopOnboarded?: boolean;

	constructor(user: UserInterface) {
		this.id = user.id;
		this.email = user.email;
		this.password = user.password;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.occupation = user.occupation;
		this.deleted = user.deleted;
		this.temporaryEmail = user.temporaryEmail;
		this.referralCode = user.referralCode;
		this.theme = user.theme;
		this.role = user.role;
		this.canvasTyles = user.canvasTyles;
		this.webOnboarded = user.webOnboarded;
		this.desktopOnboarded = user.desktopOnboarded;
	}
}

export default User;
