import { useEffect, useState } from 'react';
import {
	ELEMENT_TODO_LI,
	useEventPlateId,
	ELEMENT_IMAGE,
	ELEMENT_PARAGRAPH,
	usePlateEditorState,
} from '@udecode/plate';
import { useSnapshot } from 'valtio';
import { PlateCloudEditor } from '@udecode/plate-cloud';
import { subscribeKey } from 'valtio/utils';
import editorState from 'state/EditorState';
import { Editor as SlateEditor, Transforms } from 'slate';
import { ReactEditor, useFocused } from 'slate-react';
import { TyleBlockInterface } from 'interfaces/TyleInterface';
import canvasState from 'state/CanvasState';
import tylesState from 'state/TylesState';
import { CustomEditor } from 'types/slate';
import useTyleHooks from 'hooks/useTyleHooks';
import sharedState from 'state/SharedState';
import { MyRootBlock, useMyPlateEditorRef } from 'components/editor/types/plateTypes';
import useRemoveTyleBlock from 'components/editor/utils/useRemoveTyleBlock';
import { newCanvasStateService } from 'state/NewCanvas';

interface GetNodeProps {
	initialValue?: MyRootBlock[];
	windowId: string | undefined;
	tyleId?: string;
	updateBlock: (block: TyleBlockInterface) => void;
}

const GetNode = ({ initialValue, windowId, tyleId = '', updateBlock }: GetNodeProps) => {
	const editor = usePlateEditorState();
	const cloudEditor = useMyPlateEditorRef(useEventPlateId()) as unknown as PlateCloudEditor;
	const [positionFocused, setPositionFocused] = useState<number | undefined>(undefined);
	const { isTitleSubmit, isDeleteBlock, isDuplicateBlock } = useSnapshot(tylesState);
	const { currentWindowId } = useSnapshot(canvasState);
	const { removeTyleBlocks } = useTyleHooks();
	const { removeFirstTyleBlock } = useRemoveTyleBlock();
	// Slate hook to check focused state of editor. Only used for terminal for now due to possible conflicts of multiple editors in canvas
	const isEditorFocused = useFocused();
	useEffect(() => {
		// Handle paste of images
		const unsubscribeImageUploadProgress = subscribeKey(editorState, 'imageUploadProgress', async (state) => {
			if (state === 'success') {
				await cloudEditor.cloud.finishUploads();
				const position = editor?.selection?.focus.path[0];
				if (!position) return;
				const cloudImage = cloudEditor.cloud.getSaveValue()[position];
				// Transform from cloud image to image
				Transforms.setNodes(editor as CustomEditor, {
					url: cloudImage.url as string,
					type: ELEMENT_IMAGE,
				});
				// Update block with image url
				updateBlock({
					...cloudEditor.cloud.getSaveValue()[position],
					url: cloudImage.url as string,
					type: ELEMENT_IMAGE,
					position,
				});
				// Add empty line under image
				Transforms.insertNodes(editor as CustomEditor, {
					type: ELEMENT_PARAGRAPH,
					children: [{ text: '' }],
				});
				// Reset image upload progress
				editorState.imageUploadProgress = 'idle';
				editorState.cloudImage = undefined;
			}
		});

		return () => {
			unsubscribeImageUploadProgress();
		};
	}, [editorState.imageUploadProgress]);
	// Focus editor when pressing enter from title
	useEffect(() => {
		if (!editor) return;

		if (tylesState.isTitleSubmit && windowId && newCanvasStateService.isWindowSelected(windowId)) {
			ReactEditor.focus(editor as ReactEditor);
			Transforms.select(editor as CustomEditor, SlateEditor.start(editor as CustomEditor, []));
			tylesState.isTitleSubmit = false;
		}
	}, [isTitleSubmit]);

	// Delete block
	useEffect(() => {
		if (tylesState.isDeleteBlock && positionFocused && canvasState.currentWindowId === windowId) {
			Transforms.removeNodes(editor as CustomEditor);
			const blockId = (editor.children[positionFocused]?.id as string) ?? editor.children[0]?.id;
			removeTyleBlocks({ tyleId, blockIds: [blockId] });
			tylesState.isDeleteBlock = false;
		}
	}, [isDeleteBlock, positionFocused]);

	// Duplicate block
	useEffect(() => {
		if (tylesState.isDuplicateBlock && positionFocused && canvasState.currentWindowId === windowId) {
			const block = editor.children[positionFocused];
			Transforms.insertNodes(editor as CustomEditor, block);
			tylesState.isDuplicateBlock = false;
		}
	}, [isDuplicateBlock, positionFocused]);

	// Update content
	useEffect(() => {
		if (tylesState.isDeleteBlock || tylesState.isDuplicateBlock) {
			return;
		}
		if (editor.children) {
			// handle removal of tyleblock if it is the first block in the editor and was removed
			removeFirstTyleBlock(tyleId, initialValue as unknown as TyleBlockInterface[], editor.children);
		}

		if (
			editor?.children?.length !== initialValue?.length ||
			tylesState.isTyleTransformed ||
			(!positionFocused && editor.children.some((block) => block.type === ELEMENT_TODO_LI))
			// save the whole content when the node focused is a to-do item without focus (first click on the box)
		) {
			// Don't update if the block is a cloud image and is still uploading
			if (editorState.imageUploadProgress !== 'idle') {
				return;
			}

			// If the length of the content is different, update the whole content
			// call api to update the whole content based on editor.children
			if (!initialValue) return;

			if (!isEditorFocused && initialValue.length > editor.children.length) {
				editor.children = initialValue as any;
			}

			if (positionFocused === undefined) {
				return;
			}

			if (tylesState.isDeleteBlock || tylesState.isDuplicateBlock) {
				return;
			}
			updateBlock({
				...editor.children[positionFocused],
				position: positionFocused,
			});

			tylesState.isTyleTransformed = false;
		}
	}, [positionFocused, editor?.children, initialValue?.length, currentWindowId, windowId, tyleId]);

	useEffect(() => {
		sharedState.isEditorFocused = isEditorFocused;
		setPositionFocused(editor?.selection?.focus.path[0]);
	}, [editor?.selection?.focus.path[0], isEditorFocused]);

	return null;
};

export default GetNode;
