import React, { ReactElement } from 'react';
import { TyleInterface } from 'interfaces/TyleInterface';
import { PopoverMenuItemInterface } from '../../interfaces/PopoverMenuItemInterface';
import { hasLinksOrBacklinks, tyleCollection, withNestedTyles } from './helpers';
import Tyle from './Tyle';
import { TylePreviewDataInterface } from '../../helpers/getTylePreviewData';

export interface TyleProps extends TyleInterface {
	selected?: boolean;
	hideLinks?: boolean;
	showLinkedTyles: boolean;
	tyleData: TylePreviewDataInterface;
	setShowLinkedTyles?: any;
	linkedTyles?: Array<TyleInterface>;
	hideInstances?: boolean;
	disabled?: boolean;
	showDerivedInstances?: boolean;
	setShowDerivedInstances?: any;
	hasDerivedInstances?: boolean;
	onEvent: any;
	onSelect?: (arg: string) => void;
	onDoubleClick?: (arg: string) => void;
	children?: any;
	currentView?: string;
	hideOptions?: boolean;
	dropdownMenu: Array<PopoverMenuItemInterface>;
}

const TyleWrapper = ({
	id,
	currentView,
	onSelect,
	onDoubleClick,
	onEvent,
	hideOptions,
	tyleData,
	favorite,
	dropdownMenu,
	link,
	backlink,
	hasDerivedInstances,
	hideInstances,
	hideLinks,
	showDerivedInstances,
	setShowLinkedTyles,
	setShowDerivedInstances,
	showLinkedTyles,
	children,
	selected,
}: TyleProps): ReactElement => {
	const tyle = (
		<Tyle
			id={id ?? 'anonymous'}
			onSelect={onSelect}
			onDoubleClick={onDoubleClick}
			onEvent={onEvent}
			selected={selected}
			hideOptions={hideOptions}
			tyleData={tyleData}
			favorite={favorite}
			dropdownMenu={dropdownMenu}
			link={link}
			backlink={backlink}
		/>
	);

	if (hasDerivedInstances && hideInstances && showDerivedInstances !== undefined) {
		return tyleCollection(
			withNestedTyles(tyle, setShowDerivedInstances, showDerivedInstances),
			showDerivedInstances,
			children,
		);
	}
	if (hasLinksOrBacklinks(link) && !hideLinks && currentView !== 'instances') {
		return tyleCollection(withNestedTyles(tyle, setShowLinkedTyles, showLinkedTyles), showLinkedTyles, children);
	}
	return tyleCollection(tyle, false);
};

export default TyleWrapper;
