/**
 * Helper function to add https:// protocol if it's missing in the URL
 * @param url
 */
const addProtocolIfNeeded = (url: string): string => {
	if (!/^https?:\/\//i.test(url)) {
		return `https://${url}`;
	}
	return url;
};

export default addProtocolIfNeeded;
