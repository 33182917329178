import React, { ReactNode } from 'react';
import { ArrowUpRight as LinkIcon } from '@styled-icons/feather/ArrowUpRight';
import styled from 'styled-components';
import Tooltip from 'components/tooltip/Tooltip';
import {
	BlockToolbarButton,
	ELEMENT_LINK,
	getAboveNode,
	getPluginType,
	isCollapsed,
	LinkToolbarButton as PlateLinkToolbarButton,
	usePlateEditorRef,
} from '@udecode/plate';
import upsertLinkAtSelection from 'components/editor/utils/upsertLinkAtSelection';

const StyledLink = styled.div`
	font-size: 13px;
	&:hover {
		text-decoration: underline;
	}
`;

export interface Props {
	icon?: ReactNode | null;
}

const LinkToolbarButton = ({ icon }: Props) => {
	const editor = usePlateEditorRef();
	return (
		<div className="toolbar-link-button">
			<BlockToolbarButton
				variant="plain"
				type=""
				icon={
					icon || (
						<Tooltip tip="External link">
							<span style={{ display: 'flex', flexFlow: 'row' }}>
								<LinkIcon style={{ width: '16px' }} color="#AAAAAA" />
								<StyledLink>Link</StyledLink>
							</span>
						</Tooltip>
					)
				}
				onMouseDown={() => {
					const pluginType = getPluginType(editor, ELEMENT_LINK);

					const linkNode = getAboveNode(editor, {
						match: { type: pluginType },
					});

					const shouldWrap: boolean = linkNode !== undefined && isCollapsed(editor.selection);
					upsertLinkAtSelection(editor, { wrap: shouldWrap });
				}}
			/>
		</div>
	);
};

export default LinkToolbarButton;
