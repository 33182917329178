/* eslint-disable import/prefer-default-export */
import { TagInterface } from 'interfaces/TagInterface';

export class Tag implements TagInterface {
	id: string;

	label: string;

	createdAt: Date;

	type?: string;

	constructor(tag: TagInterface) {
		this.id = tag.id;
		this.label = tag.label;
		this.createdAt = tag.createdAt;
		this.type = tag.type;
	}
}
