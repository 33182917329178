import { RootStyles, ComboboxStyles } from '@udecode/plate';
import { useEffect, useState } from 'react';
import userState from 'state/UserState';
import { css } from 'styled-components';
import dark from 'themes/dark';
import light from 'themes/light';
import { subscribeKey } from 'valtio/utils';

const getComboboxStyles = (): Partial<RootStyles> & Partial<ComboboxStyles> => {
	const [userTheme, setUserTheme] = useState(userState.user.theme);
	const theme = userTheme === 'dark' ? dark : light;

	useEffect(() => {
		const unsubscribe = subscribeKey(userState, 'user', (user) => {
			setUserTheme(user.theme);
		});
		return unsubscribe;
	}, []);

	const item = [
		css`
			font-size: 12px;
			border-radius: 0;
			min-height: 22px;
			user-select: none;
			color: ${theme.colors.paragraph};
		`,
	];

	return {
		root: [
			css`
				width: 200px;
				max-height: 220px;
				overflow: auto;
				border-radius: ${theme.radius.big};
				box-shadow: ${theme.shadows.light};
				border: 1px solid ${theme.colors.stroke};
				animation: slideUpAndFade 300ms;
				transform-origin: 0% top;
				padding: 10px;
				z-index: 500;
				scrollbar-gutter: stable;
				::-webkit-scrollbar {
					width: 0.2rem;
					border-radius: ${theme.radius?.big};
				}

				::-webkit-scrollbar-thumb {
					border-radius: ${theme.radius?.big};
					background-color: ${theme.colors?.stroke};
					transition: background-color ease 0.2s;
					height: 30%;

					&:hover {
						cursor: default;
						background-color: ${theme.colors?.softParagraph}80;
					}
				}
			`,
		],
		item: [
			...item,
			css`
				background: ${theme.colors.background};
				border-radius: ${theme.radius.base};
				:hover {
					background: ${theme.colors.selected}90;
				}
			`,
		],
		highlightedItem: [
			...item,
			css`
				background: ${theme.colors.selected};
				border-radius: ${theme.radius.base};
				:hover {
					background: ${theme.colors.selected}90;
				}
			`,
		],
	};
};

export default getComboboxStyles;
