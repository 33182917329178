import { useContext } from 'react';
import { EnvironmentEnum } from 'helpers/detectEnvironment';
import { ApiContext } from 'components/App';
import useAnalytics, { TrackingEvent } from '../useAnalytics';

interface SignupInterface {
	email: string;
	environment: EnvironmentEnum;
	inviteCode: string;
	referral: boolean;
}

interface UseSignupInterface {
	signup: ({ email, environment, inviteCode, referral }: SignupInterface) => Promise<any>;
}

const useSignup = (): UseSignupInterface => {
	const { track } = useAnalytics();
	const api = useContext(ApiContext);

	const signup = ({ email, environment, inviteCode, referral }: SignupInterface) => {
		return api.sendSignUpRequest(email, environment, inviteCode).then((response) => {
			if (referral) {
				track(TrackingEvent.SIGNUP_REFERRAL);
			}
			return response;
		});
	};

	return { signup };
};

export default useSignup;
