import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledHorizontaleCanvasNotification = styled(motion.div)`
	position: absolute;
	bottom: 41.5px;
	right: 90px;
	width: auto;
	height: 23px;
	background: transparent;
	text-align: right;
	z-index: 1;
`;

export const StyledHorizontalCanvasContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledHorizontalCanvasNotificationLabel = styled.div`
	margin-left: 5px;
	color: ${(props) => props.theme.colors?.paragraph};
	font-size: ${(props) => props.theme.fontSizes?.medium};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
`;
