import type { CSSProperties, FC, ReactElement } from 'react';
import { memo, useEffect, useState } from 'react';
import TyleCard from './TyleCard';
import tylesState from '../../state/TylesState';
import useTyleHooks from '../../hooks/useTyleHooks';

const styles: CSSProperties = {
	display: 'inline-block',
	transform: 'rotate(-1deg)',
	WebkitTransform: 'rotate(-1deg)',
	cursor: 'grabbing',
};

const stylesPreview: CSSProperties = {
	cursor: 'grabbing',
	marginRight: '-150px',
	maxWidth: '300px',
};

export interface BoxDragPreviewProps {
	tyleId: string;
}

export const TyleCardPreview = ({ tyleId }: BoxDragPreviewProps): ReactElement | null => {
	if (!tyleId) {
		return null;
	}

	return (
		<div style={styles}>
			<div style={{ ...stylesPreview }}>
				<TyleCard tyle={tylesState.tylesObjects[tyleId]} selected onDoubleClick={() => null} />
			</div>
		</div>
	);
};
