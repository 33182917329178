import { useCallback, useEffect, useState } from 'react';

const preventDefault = (e: Event) => {
	if (e.preventDefault) {
		e.preventDefault();
	}
};

const enableBodyScroll = () => {
	const canvas = document.getElementById('horizontal-canvas');

	if (canvas) {
		return canvas.removeEventListener('wheel', preventDefault, false);
	}
	return document && document.removeEventListener('wheel', preventDefault, false);
};
const disableBodyScroll = () => {
	const canvas = document.getElementById('horizontal-canvas');

	if (canvas) {
		return canvas.addEventListener('wheel', preventDefault, {
			passive: false,
		});
	}
	return (
		document &&
		document.addEventListener('wheel', preventDefault, {
			passive: false,
		})
	);
};

const usePreventBodyScroll = () => {
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		if (hidden) {
			disableBodyScroll();
		}
		if (!hidden) {
			enableBodyScroll();
		}
	}, [hidden]);

	const disableScroll = useCallback(() => setHidden(true), []);
	const enableScroll = useCallback(() => setHidden(false), []);
	return { disableScroll, enableScroll };
};

export default usePreventBodyScroll;
