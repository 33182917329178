import React, { useEffect, useState } from 'react';
import Icon from 'components/icon';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';
import newCanvasState from 'state/NewCanvas';
import { subscribeKey } from 'valtio/utils';
import useCanvasHooks from '../../hooks/useCanvasHooks';
import canvasState from '../../state/CanvasState';
import sharedState from '../../state/SharedState';

const StyledArrowWrapper = styled.div.attrs((props: { hide: boolean }) => props)`
	display: ${(props) => (props.hide ? 'none' : 'flex')};
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 4px 0;
	min-width: 35px;
	visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
`;

export interface Props {
	windowId: string;
	handleBackArrowClick: () => void;
	handleForwardArrowClick: () => void;
	showNavigation: boolean;
}

const NavigationArrows = ({ windowId, handleBackArrowClick, handleForwardArrowClick, showNavigation }: Props) => {
	const { userCanvasTyles } = useSnapshot(newCanvasState);
	const { publicWindowEntity } = useSnapshot(sharedState);
	const [leftArrowActive, setLeftArrowActive] = useState(false);
	const [rightArrowActive, setRightArrowActive] = useState(false);

	useEffect(() => {
		if (!canvasState.isPublicView) {
			setLeftArrowActive(
				!!newCanvasState.userCanvasTyles.windows[windowId]?.queue?.[
					newCanvasState.userCanvasTyles.windows[windowId].navigationIndex - 1
				],
			);
			setRightArrowActive(
				!!newCanvasState.userCanvasTyles.windows[windowId]?.queue?.[
					newCanvasState.userCanvasTyles.windows[windowId].navigationIndex + 1
				],
			);
			return;
		}
		setLeftArrowActive(!!sharedState.publicWindowEntity?.queue?.[sharedState.publicWindowEntity.navigationIndex - 1]);
		setRightArrowActive(!!sharedState.publicWindowEntity?.queue?.[sharedState.publicWindowEntity.navigationIndex + 1]);
	}, [userCanvasTyles, publicWindowEntity]);

	return (
		<StyledArrowWrapper hide={!showNavigation} role="navigation">
			<Icon
				onClick={(event) => {
					event.stopPropagation();
					handleBackArrowClick();
				}}
				className={`${leftArrowActive ? 'active-arrow' : 'disabled-arrow'}`}
				type="arrow-left-line"
			/>
			<Icon
				onClick={(event) => {
					event.stopPropagation();
					handleForwardArrowClick();
				}}
				className={`${rightArrowActive ? 'active-arrow' : 'disabled-arrow'}`}
				type="arrow-right-line"
				style={{ paddingLeft: '5px' }}
			/>
		</StyledArrowWrapper>
	);
};

export default NavigationArrows;
