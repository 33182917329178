import styled from 'styled-components';
import backgroundUrl from '../../assets/images/background_gradient_signup.png';

export const Wrapper = styled.div`
	background-color: #000000;
	width: 100%;
	height: 100vh;
`;

export const Navbar = styled.div`
	position: absolute;
	width: 100%;
	height: 30px;
	-webkit-app-region: drag;
`;

export const Background = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: url(${backgroundUrl});
	height: 100vh;
`;

export const Logo = styled.div`
	width: 100%;
	height: 40px;
	margin: 0 0 1rem 0.5rem;
`;

export const StyledStep = styled.div`
	font-family: Inter;
	font-weight: 500;
	font-size: 9px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background-color: #eeeeee;
`;

export const Container = styled.div`
	display: flex;
	flex-flow: column;
`;

export const ContentContainter = styled.div`
	display: flex;\
	flex-flow: row;
	justify-content: space-between;
	width: 100%;
`;

export const ContainerLeft = styled.div`
	width: 320px;
`;

export const ContainerRight = styled.div`
	width: 425px;
	height: 356px;
`;
