import styled from 'styled-components';

export const StyledContainer = styled.div`
	width: 100%;
`;

export const StyledLoader = styled.div`
	width: 100%;
	height: 30px;
`;

export const StyledInput = styled.input`
	padding: 0 0 0 0;
	font-size: 14px;
	border: none;
	outline: none;

	&:focus {
		outline: none;
		border: none;
	}
	&::placeholder {
		color: ${(props) => props.theme.colors?.softParagraph};
	}
`;

export const StyledList = styled.ul`
	display: flex;
	flex-flow: column;
	list-style-type: none;
	padding: 0 0 0 0;
	margin-bottom: 0;
	position: relative;
	max-height: 60px;
	overflow-y: scroll;
	border-bottom: 1px solid ${(props) => props.theme.colors?.gray400};

	.active {
		background-color: ${(props) => props.theme.colors?.gray400};
		width: 100%;
		border-radius: 4px;
	}

	.dropdown-item {
		&:hover {
			background-color: ${(props) => props.theme.colors?.selected};
			border-radius: 4px;
		}
	}
`;

export const StyledNewNote = styled.ul`
	display: flex;
	flex-flow: column;
	list-style-type: none;
	padding: 0 0 0 0;
	margin: 0;
	position: relative;
	height: auto;
	overflow-y: hidden;

	&:hover {
		background-color: ${(props) => props.theme.colors?.selected};
		border-radius: 4px;
	}

	.active {
		background-color: ${(props) => props.theme.colors?.gray400};
		border-radius: 4px;
	}
`;

export const StyledInputContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 5px;
	position: relative;
`;

export const StyledPrefix = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
	padding-right: 3px;
	font-size: 14px;
	color: ${(props) => props.theme.colors?.paragraph};
`;

export const StyledPlaceholder = styled.span.attrs((props: { inputWidth: number }) => props)`
	color: ${(props) => props.theme.colors?.gray100};
	font-size: 14px;
	position: absolute;
	left: calc(56.5px + ${(props) => props.inputWidth}px);
	bottom: 5px;
	pointer-events: none;
	opacity: 0.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 70%;
`;
