import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomEditor, CustomElement } from 'types/slate';

const selectByElement = ({ editor, element }: { editor: CustomEditor; element: CustomElement }): void => {
	Transforms.select(editor, ReactEditor.findPath(editor, element));
	Transforms.collapse(editor, { edge: 'start' });
};

export default selectByElement;
