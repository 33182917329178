import { useContext, useEffect, useState, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import userState from 'state/UserState';
import { sharedStateActions } from 'state/SharedState';
import FastCapturePage from 'pages/FastCapturePage';
import { ApiContext } from 'components/App';
import useAuthentication from 'helpers/useAuthentication';
import { detectOperatingSystem } from 'helpers';
import detectEnvironment, { EnvironmentEnum } from 'helpers/detectEnvironment';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import useLogout from 'hooks/useLogout';
import PermissionsPage from 'containers/PermissionsPage/PermissionsPage';
import IPCChannel from 'model/IPC/IPCChannel';
import RegistrationPage from 'containers/RegistrationPage';
import AppContent from 'containers/AppContent';
import ClipperNotificationPage from 'pages/ClipperNotificationPage';
import { getUserCanvasDataFromLocalStorage } from 'state/helpers';
import newCanvasState from 'state/NewCanvas';
import useDetectDeviceType from 'hooks/useDetectDeviceType';
import useAnalytics, { TrackingEvent } from 'hooks/useAnalytics';
import LoadingScreen from './LoadingScreen';
import './LoggedInApp.scss';
import MobileView from './MobileView';

const LoggedInApp = () => {
	const api = useContext(ApiContext);
	const [loading, setLoading] = useState<boolean>(false);
	const { isLoggedIn, isLoggedInWithName } = useAuthentication();
	const { user, showOnBoarding } = useSnapshot(userState);
	const [loadSupportChat, setLoadSupportChat] = useState(false);
	const { logout } = useLogout();
	const { track } = useAnalytics();
	const { isMobile } = useDetectDeviceType();

	const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
	const isDesktop = detectEnvironment() === EnvironmentEnum.DESKTOP;

	// User has switched back to the tab
	const onFocus = useCallback(() => {
		sharedStateActions.triggerAppFocused().then();
	}, []);

	const loadSupport = (load: boolean) => {
		setLoadSupportChat(load);
	};

	useEffect(() => {
		window.addEventListener('focus', onFocus);
		// Calls onFocus when the window first loads
		onFocus();
		// Cleanup
		return () => {
			window.removeEventListener('focus', onFocus);
		};
	}, [onFocus]);

	useEffect(() => {
		if (!isLoggedIn()) {
			logout();
			return;
		}
		// Get user
		api
			.getUserAccount()
			.then((userAccount) => {
				userState.user = { ...userAccount };
				const storageCanvasData = getUserCanvasDataFromLocalStorage();

				if (userState.user.canvasTyles && storageCanvasData && !storageCanvasData.order.length) {
					newCanvasState.userCanvasTyles = userState.user.canvasTyles;
				}

				if (!userAccount.firstName) {
					userState.showOnBoarding = true;
				}
			})
			.catch((err) => {
				userState.user = {};
				userState.showOnBoarding = true;
			});
	}, []);

	useEffect(() => {
		if (isLoggedInWithName() && !userState.showOnBoarding && isDesktop) {
			/**
			 * After login submit, check user permissions, if all or one of them is not granted, redirect to permissions page
			 */
			if (window.ipc && !isMacOS) {
				window.ipc.send(IPCChannel.LOGGED_IN, 'logged_in');
				if (!userState.user.desktopOnboarded && isDesktop) {
					window.ipc.send(IPCChannel.OPEN_ONBOARDING, 'open');
				}
			}
			if (window.ipc && isMacOS) {
				setLoading(true);
				window.ipc.invoke(IPCChannel.REPLY_ACCESSIBILITY_PERMISSION).then((resultAccessibility: string) => {
					switch (resultAccessibility) {
						case 'authorized':
							window.ipc.invoke(IPCChannel.REPLY_SCREEN_RECORDING_PERMISSION).then((resultScreenRecording: string) => {
								switch (resultScreenRecording) {
									case 'authorized':
										window.ipc.send(IPCChannel.LOGGED_IN, 'logged_in');
										if (!userState.user.desktopOnboarded && isDesktop) {
											window.ipc.send(IPCChannel.OPEN_ONBOARDING, 'open');
										}
										setLoadSupportChat(true);
										setTimeout(() => {
											setLoading(false);
										}, 2000);
										break;
									case 'denied':
										userState.showOnBoarding = true;
										setTimeout(() => {
											setLoading(false);
										}, 2000);
										break;
									default:
										// TODO Put error notification here
										logout();
										break;
								}
							});
							break;
						case 'denied':
							userState.showOnBoarding = true;
							setTimeout(() => {
								setLoading(false);
							}, 2000);
							break;
						default:
							// TODO Put error notification here
							logout();
							break;
					}
				});
			}
		}
	}, [user]);

	if (isMobile) {
		track(TrackingEvent.IS_MOBILE);
		return <MobileView />;
	}

	return (
		<>
			{!user || loading ? (
				<LoadingScreen />
			) : (
				showOnBoarding && (
					<Route path="/">
						{isMacOS && isDesktop ? (
							<PermissionsPage user={user} loadSupport={loadSupport} />
						) : (
							<RegistrationPage user={user} loadSupport={loadSupport} />
						)}
					</Route>
				)
			)}
			{!showOnBoarding && (
				<Switch>
					<Route path="/fastCapture">
						<FastCapturePage />
					</Route>
					<Route path="/clipperNotification">
						<ClipperNotificationPage />
					</Route>
					<Route path="/">
						{!user || isDesktop ? <LoadingScreen /> : <AppContent loadSupportChat={loadSupportChat} />}
					</Route>
				</Switch>
			)}
		</>
	);
};

export default LoggedInApp;
