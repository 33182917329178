enum UserSettingsTabsEnum {
	MY_ACCOUNT = 'my_account',
	INVITE_CODES = 'invite_codes',
}

export enum DesktopAppTypeEnum {
	APPLE_SILICON = 'apple_silicon',
	APPLE_INTEL = 'apple_intel',
	WINDOWS_64 = 'windows_64',
}

export default UserSettingsTabsEnum;
