/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PlatePluginComponent } from '@udecode/plate';
import sharedState from 'state/SharedState';
import EmptyLinkPopover from './EmptyLinkPopover';
import LinkPopover from './LinkPopover';

const ExternalUrl: PlatePluginComponent = ({ attributes, children, element, nodeProps, editorId }: any) => {
	const { url } = element;

	if (!url || sharedState.editHyperlink === element.id) {
		return (
			<EmptyLinkPopover element={element} editorId={editorId}>
				{children}
			</EmptyLinkPopover>
		);
	}

	return (
		<LinkPopover element={element} attributes={attributes} nodeProps={nodeProps} editorId={editorId}>
			{children}
		</LinkPopover>
	);
};

export default ExternalUrl;
