import React, { MutableRefObject, useEffect } from 'react';
import { useResetPlateEditor, useEditorState, useOnClickOutside } from '@udecode/plate';
import { subscribeKey } from 'valtio/utils';
import { MyValue } from 'components/editor/types/plateTypes';
import sharedState from 'state/SharedState';

const ResetEditorOnValueChange = ({
	isFirstRender,
	value,
}: {
	isFirstRender: MutableRefObject<boolean>;
	value?: MyValue;
}) => {
	const resetPlateEditor = useResetPlateEditor();
	const isFirst = isFirstRender;
	const editor = useEditorState();

	useEffect(() => {
		if (isFirst && isFirst.current) {
			resetPlateEditor();
			isFirst.current = false;
		}
		const unsubscribe = subscribeKey(sharedState, 'appFocused', () => {
			resetPlateEditor();
			editor.selection = null;
		});
		return () => {
			unsubscribe();
		};
	}, [value, resetPlateEditor, sharedState]);

	return null;
};

export default ResetEditorOnValueChange;
