import newCanvasState, { newCanvasStateService } from 'state/NewCanvas';
import tylesState from 'state/TylesState';
import { StorageKeys } from '../services/StorageService';

const useSharedHooks = () => {
	const removeTyleIdAndNextElementsFromEveryQueue = (tyleId: string): void => {
		newCanvasStateService.removeTyleFromCanvas(tyleId);
	};

	const removeWindowFromUserCanvasTyles = (windowId: string): void => {
		newCanvasStateService.removeWindow(windowId);
		localStorage.setItem(StorageKeys.USER_CANVAS_TYLES, JSON.stringify(newCanvasState.userCanvasTyles)); // Update local storage with new canvas state
		tylesState.clickedTyle = undefined; // Reset clicked tyle
	};

	return {
		removeTyleIdAndNextElementsFromEveryQueue,
		removeWindowFromUserCanvasTyles,
	};
};

export default useSharedHooks;
