import styled from 'styled-components';
import Icon from 'components/icon';

export const StyledCloseIconWrap = styled.div.attrs((props: { hidden: boolean }) => props)`
	position: absolute;
	top: -5px;
	right: 0;
	display: ${(props) => (props.hidden ? 'none' : 'flex')};
	align-items: center;
	justify-content: center;
	height: 14px;
	width: 14px;
	background-color: ${(props) => props.theme.colors?.white100};
	border-radius: 50%;
	box-shadow: ${(props) => props.theme.shadows?.light};
`;

export const StyledCloseIcon = styled(Icon)`
	max-height: 8px;

	svg {
		float: left;
	}
`;
