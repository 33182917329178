import React, { FunctionComponent } from 'react';

interface Props {
	children: any;
}

// eslint-disable-next-line react/function-component-definition
const ElectronIPCContext: FunctionComponent<Props> = ({ children }) => {
	const isElectronIPC = !!window.ipc;

	return isElectronIPC ? (
		<div>{children}</div>
	) : (
		// WEB APP, TODO Add something like a little text in the navbar to distinguish the web app from the desktop app
		<div>{children}</div>
	);
};

export default ElectronIPCContext;
