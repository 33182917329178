/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { BalloonToolbar, RootClassName, usePlateEditorState } from '@udecode/plate';
import BlockToolbar from './BlockToolbar';
import MultiBlockToolbar from './MultiBlockToolbar';
import './BalloonToolbar.scss';

const BalloonToolbarMarks = (): ReactElement => {
	const editor = usePlateEditorState();
	const [selectedBlocks, setSelectedBlocks] = useState(editor?.getFragment() ?? []);

	useEffect(() => {
		setSelectedBlocks(editor?.getFragment() ?? []);
	}, [editor?.selection]);

	return (
		<BalloonToolbar
			direction="top"
			theme="light"
			arrow={false}
			/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
			// @ts-ignore
			classNames={
				{
					root: 'balloon-toolbar',
				} as RootClassName
			}
		>
			{selectedBlocks.length === 1 ? (
				<BlockToolbar selectedBlocks={selectedBlocks} />
			) : (
				<MultiBlockToolbar selectedBlocks={selectedBlocks} />
			)}
		</BalloonToolbar>
	);
};

export default BalloonToolbarMarks;
