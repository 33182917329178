/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import './PaginationDots.scss';

export interface Props {
	items: number[];
	setActiveIndex: (index: number) => void;
	loop?: boolean;
	duration?: number;
	currentIndex?: number;
}

const PaginationDots = ({ items, loop, duration, setActiveIndex, currentIndex }: Props) => {
	const [stopInterval, setStopInterval] = useState(false);

	const startCycle = () => {
		const inputs = document.getElementsByName('dot');

		for (let i = 0; i < inputs.length; ++i) {
			const input = inputs[i] as HTMLInputElement;
			if (input.checked) {
				if (i === inputs.length - 1) {
					const firstInput = inputs[0] as HTMLInputElement;
					firstInput.checked = true;
					setActiveIndex(0);
				} else {
					const nextInput = inputs[i + 1] as HTMLInputElement;
					nextInput.checked = true;
					setActiveIndex(i + 1);
				}
				break;
			}
		}
	};

	const selectInput = () => {
		if (currentIndex === undefined) return;
		const inputs = document.getElementsByName('dot');
		const input = inputs[currentIndex] as HTMLInputElement;
		if (!input) {
			return;
		}
		input.checked = true;
	};

	useEffect(() => {
		selectInput();
	}, [currentIndex]);

	useEffect(() => {
		if (!stopInterval && loop) {
			const interval = setInterval(() => {
				startCycle();
			}, duration);
			return () => clearInterval(interval);
		}
		return () => console.log('unmount');
	}, [stopInterval]);

	return (
		<div className="pagination_dots">
			{items.map((item, i) => {
				return (
					<div
						key={item}
						onClick={() => {
							setStopInterval(true);
							setActiveIndex(i);
						}}
					>
						<input className="pagination_input" defaultChecked={i === 0} type="radio" name="dot" id={item.toString()} />
						<label className="pagination_label" htmlFor={item.toString()}>
							<i className="pagination_element" />
						</label>
					</div>
				);
			})}
		</div>
	);
};

export default PaginationDots;
