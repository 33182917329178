import { TyleWindowActionEnum, TyleWindowViews } from 'interfaces/TyleWindowInterface';
import useAnalytics, { TrackingEvent } from 'hooks/useAnalytics';

const tyleWindowHelper = () => {
	const { track } = useAnalytics();
	const changeView = (viewAction: TyleWindowActionEnum, currentView: TyleWindowViews): TyleWindowViews => {
		// When links, backlinks or instances are already selected, the view is changed to the editor
		if (
			(currentView === TyleWindowViews.LINKS && viewAction === TyleWindowActionEnum.VIEW_LINKS) ||
			(currentView === TyleWindowViews.BACKLINKS && viewAction === TyleWindowActionEnum.VIEW_BACKLINKS)
		) {
			track(TrackingEvent.TYLE_WINDOW_VIEW_CHANGE_EDITOR);
			return TyleWindowViews.EDITOR;
		}
		switch (viewAction) {
			case TyleWindowActionEnum.VIEW_LINKS:
				// When the links icon is pressed, the view is changed to the links
				track(TrackingEvent.TYLE_WINDOW_VIEW_CHANGE_LINKS);
				return TyleWindowViews.LINKS;
			case TyleWindowActionEnum.VIEW_BACKLINKS:
				// When the backlinks icon is pressed, the view is changed to the backlinks
				track(TrackingEvent.TYLE_WINDOW_VIEW_CHANGE_BACKLINKS);
				return TyleWindowViews.BACKLINKS;
			case TyleWindowActionEnum.VIEW_TYLE:
				track(TrackingEvent.TYLE_WINDOW_VIEW_CHANGE_EDITOR);
				return TyleWindowViews.EDITOR;
			default:
				throw new Error(`Invalid view: ${viewAction}.`);
		}
	};

	return { changeView };
};

export default tyleWindowHelper;
