import React, { useEffect, useState } from 'react';
import Input from 'components/input';
import Spacing from 'components/spacing';
import CustomDropdown from 'components/custom-dropdown';
import { UserInterface } from '../../interfaces/UserInterface';

export interface Props {
	user: UserInterface;
	setUserInfo: (user: Record<string, string>) => void;
}

const Signup = ({ user, setUserInfo }: Props) => {
	const [firstName, setFirstName] = useState(user.firstName ?? '');
	const [lastName, setLastName] = useState(user.lastName ?? '');
	const [occupation, setOccupation] = useState(user.occupation ?? '');

	const handleChange = (value: string, set: (val: string) => void, show: (bool: boolean) => void) => {
		if (value === 'Other') {
			show(true);
		} else {
			show(false);
		}

		set(value);
		setOccupation(value);
	};

	const handleInputChange = (value: string) => {
		setOccupation(value);
	};

	useEffect(() => {
		if (firstName && lastName && occupation) {
			setUserInfo({ firstName, lastName, occupation });
		}
	}, [firstName, lastName, occupation]);

	return (
		<div className="signup-screen">
			<Input
				style={{
					backgroundColor: '#1E1E1E',
					borderColor: '#343434',
					color: '#ededed',
					width: '100%',
					fontSize: '13px',
				}}
				value={firstName}
				onChange={(value: string) => setFirstName(value)}
				label="First Name"
				placeholder="First Name"
			/>{' '}
			<Input
				style={{
					backgroundColor: '#1E1E1E',
					borderColor: '#343434',
					color: '#ededed',
					width: '100%',
					fontSize: '13px',
				}}
				value={lastName}
				onChange={(value: string) => setLastName(value)}
				label="Last Name"
				placeholder="Last Name"
			/>
			<CustomDropdown
				onChange={handleChange}
				onInputChange={handleInputChange}
				label="Occupation"
				darkmode
				initialValue={occupation}
				placeholder="What kind of work do you do?"
				items={[
					'Consultant',
					'Finance Professional',
					'Journalist',
					'Research Analyst',
					'Professor',
					'PhD Student',
					'Bachelor/Graduate',
					'Other',
				]}
			/>
			<Spacing />
		</div>
	);
};

export default Signup;
