import { Tyle as ApiTyle } from '../api/generated/client';
import InvalidDataException from '../exceptions/InvalidDataException';
import { Tyle } from '../models/Tyle';
import adaptTyle from './adaptTyle';

const adaptTyles = (tyles: Array<ApiTyle>): Array<Tyle> => {
	return tyles.map((tyle) => {
		if (!tyle.id) {
			throw new InvalidDataException('broken');
		}
		return adaptTyle(tyle);
	});
};

export default adaptTyles;
