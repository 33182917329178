import { ReactElement } from 'react';
import fastCaptureState from 'state/FastCaptureState';
import IPCChannel from 'model/IPC/IPCChannel';
import useElectronEvents from 'helpers/useElectronEvents';
import FastCapture from 'containers/FastCapture';
import useGetTyleFromElectron from 'helpers/useGetTyleFromElectron';
import styled from 'styled-components';
import { NotificationMessage } from 'helpers/useGetNotificationFromElectron';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: ${(props) => props.theme.radius?.big};
	background: ${(props) => props.theme.colors?.clipperBackground};
	backdrop-filter: blur(10px);
	gap: 6px;
	padding: 6px;
`;

const FastCapturePage = (): ReactElement => {
	const { tyle } = useGetTyleFromElectron();
	const { sendMessage } = useElectronEvents();

	const handleCloseMini = (message: NotificationMessage) => {
		fastCaptureState.showFastCapture = false;
		sendMessage({ subject: IPCChannel.UNFOCUS_FASTCAPTURE, message: 'unfocus' });
		setTimeout(() => {
			sendMessage({ subject: IPCChannel.OPEN_CLIPPER_NOTIFICATION, message });
		}, 100);
	};

	return tyle ? <FastCapture key={tyle.id} tyle={tyle} onCloseDesktop={handleCloseMini} /> : <StyledContainer />;
};

export default FastCapturePage;
