/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './PopoverToggle.scss';
import React, { KeyboardEvent, MouseEvent } from 'react';

interface PopoverToggleProps extends React.ComponentPropsWithoutRef<'div'> {
	onClick: (event: MouseEvent) => void;
	onMouseEnter?: (event: MouseEvent) => void;
	onMouseLeave?: (event: MouseEvent) => void;
	children?: any;
}

const handleKeyPress = (event: KeyboardEvent) => {
	if (event.key === 'Enter') {
		console.log(`Enter key pressed!: ${event.target}`);
	}
};

const PopoverToggle = React.forwardRef<HTMLDivElement, PopoverToggleProps>((props, ref) => (
	<div
		ref={ref}
		className="kum-popover-toggle"
		onMouseEnter={props.onMouseEnter}
		onMouseLeave={props.onMouseLeave}
		onClick={props.onClick}
		onKeyDown={handleKeyPress}
	>
		{props.children}
	</div>
));

export default PopoverToggle;
