import Link from 'components/link/Link';
import Paragraph from 'components/paragraph';

export interface Props {
	style?: Record<string, string | number>;
}

const TermsAndService = ({ style }: Props) => {
	return (
		<Paragraph
			variant="small"
			style={{ ...style, color: '#aaaaaa', position: 'fixed', bottom: '30px', textAlign: 'center', zIndex: 1 }}
		>
			By continuing you agree to the{' '}
			<Link href="https://www.tyles.io/legal" target="_blank" style={{ color: '#AAAAAA' }}>
				Terms and Service,
			</Link>
			<br />
			<Link href="https://www.tyles.io/legal" target="_blank" style={{ color: '#AAAAAA' }}>
				Privacy Policy
			</Link>{' '}
			and{' '}
			<Link href="https://www.tyles.io/legal" target="_blank" style={{ color: '#AAAAAA' }}>
				Beta Program Conditions
			</Link>
			.
		</Paragraph>
	);
};

export default TermsAndService;
