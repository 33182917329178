import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import User from 'models/User';
import Signup from './Signup';
import { UserInterface } from '../../interfaces/UserInterface';

const PlayerWrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
`;

export interface Props {
	index: number;
	user: UserInterface;
	setUserInfo: (user: Record<string, string>) => void;
}

const Setup = ({ index, user, setUserInfo }: Props) => {
	switch (index) {
		case 0:
			return <Signup user={user} setUserInfo={setUserInfo} />;
		case 1:
			return (
				<PlayerWrapper>
					<ReactPlayer url="local-video://assets/videos/Accessibility_AdobeExpress.mp4" width="425px" loop playing />
				</PlayerWrapper>
			);
		case 2:
			return (
				<PlayerWrapper>
					<ReactPlayer url="local-video://assets/videos/Screen_Recording_AdobeExpress.mp4" width="425px" loop playing />
				</PlayerWrapper>
			);
		case 3:
			return (
				<PlayerWrapper>
					<ReactPlayer url="local-video://assets/videos/Screen_Recording_AdobeExpress.mp4" width="425px" loop playing />
				</PlayerWrapper>
			);
		case 4:
			return (
				<PlayerWrapper>
					<ReactPlayer url="local-video://assets/videos/Screen_Recording_AdobeExpress.mp4" width="425px" loop playing />
				</PlayerWrapper>
			);

		default:
			return <></>;
	}
};

export default Setup;
