import { ELEMENT_PARAGRAPH } from '@udecode/plate';
import { adaptSpace, adaptSpaces, adaptTokenPair, adaptTyle, adaptTyles, adaptUser } from 'adapters';
import { GenericSuccess } from 'api/generated/client/models/GenericSuccess';
import { EnvironmentEnum } from 'helpers/detectEnvironment';
import { nanoid } from 'nanoid';
import adaptTags from 'adapters/adaptTags';
import adaptSearchQueries from 'adapters/adaptSearchQueries';
import { ApiInterface } from 'api/Api';
import { TokenPairInterface } from 'interfaces/TokenPairInterface';
import { Tyle } from 'models/Tyle';
import { GenericError, InviteCode, TrackObject, Tyle as ApiTyle, TyleLink, User } from 'api/generated/client';
import { Space } from 'models/Space';
import {
	GeneratedTyleUrlInterface,
	TyleBlockInterface,
	TyleBlockToRemoveInterface,
	TyleInterface,
	TyleRelationInterface,
} from 'interfaces/TyleInterface';
import adaptGeneratedUrl from 'adapters/adaptGeneratedUrl';
import { SearchQuery } from 'models/SearchQuery';
import { Tag } from 'models/Tag';
import { AuthenticationServiceInterface } from './AuthenticationService';

export class ApiService implements ApiServiceInterface {
	private api: ApiInterface;

	private authenticationService: AuthenticationServiceInterface;

	constructor(api: ApiInterface, authenticationService: AuthenticationServiceInterface) {
		this.api = api;
		this.authenticationService = authenticationService;

		this.init();
	}

	public sendLoginRequest(email: string, loginType: EnvironmentEnum): Promise<any> {
		return this.api.getAuthenticationApi().postLogin({
			email,
			loginType,
		});
	}

	public sendSignUpRequest(email: string, loginType: EnvironmentEnum, inviteCode: string): Promise<any> {
		return this.api.getAuthenticationApi().postSignUp({
			email,
			loginType,
			inviteCode,
		});
	}

	public setAuthentication(token: string) {
		this.api.setAuthentication(token);
	}

	public async logout() {
		this.authenticationService.logout();
	}

	public async getAuthentication(): Promise<TokenPairInterface> {
		return this.api
			.getAuthenticationApi()
			.postToken()
			.then((apiTokenPair) => {
				const tokenPair = adaptTokenPair(apiTokenPair);

				this.setAuthentication(tokenPair.token);

				this.authenticationService.authenticate(tokenPair);

				return tokenPair;
			});
	}

	public async deleteUserAccount(email: string): Promise<User> {
		return this.api.getAccountApi().deleteAccount({ email });
	}

	public async getUserAccount(): Promise<User> {
		return this.api
			.getAccountApi()
			.getUserAccount()
			.then((user) => {
				return adaptUser(user);
			});
	}

	public async trackEvent(trackObject: TrackObject): Promise<GenericSuccess | GenericError> {
		return this.api.getAccountApi().postTrackEvent(trackObject);
	}

	public async updateUserAccount(user: User): Promise<User> {
		return this.api
			.getAccountApi()
			.updateUserAccount(user)
			.then((updatedUser) => {
				return adaptUser(updatedUser);
			});
	}

	public searchTyles(searchPhrase: string, limit?: number, filters?: Record<string, any>): any {
		return this.api
			.getSearchApi()
			.searchForTyles({ searchPhrase, limit, filters })
			.then((tyles) => adaptTyles(tyles));
	}

	public searchTylesByTitle(searchPhrase: string, limit?: number, filters?: Record<string, string[]>): any {
		return this.api
			.getSearchApi()
			.searchForTylesByTitle({ searchPhrase, limit, filters })
			.then((tyles) => adaptTyles(tyles));
	}

	public searchTags(searchPhrase: string, limit?: number, filters?: Record<string, any>): Promise<Tag[]> {
		return this.api
			.getSearchApi()
			.searchForTags({ searchPhrase, limit, filters })
			.then((tags) => adaptTags(tags));
	}

	public getTopTags(limit: number): Promise<Tag[]> {
		return this.api
			.getTagsApi()
			.getUserTopTags(limit)
			.then((tags) => adaptTags(tags));
	}

	public getTagsByTyleId(id: string): Promise<Tag[]> {
		return this.api
			.getTylesApi()
			.getTagsByTyleId(id)
			.then((tags) => adaptTags(tags));
	}

	public generateTagsForTyle(tyleId: string): Promise<Tag[]> {
		return this.api
			.getTagsApi()
			.generateTagsForTyle({ tyleId })
			.then((tags) => adaptTags(tags));
	}

	public getTagsFromSearch(searchPhrase?: string, limit?: number, filters?: Record<string, any>): Promise<Tag[]> {
		return this.api
			.getSearchApi()
			.searchForTags({ searchPhrase, limit, filters })
			.then((tags) => adaptTags(tags));
	}

	public getRecentSearchQueries(
		searchPhrase?: string,
		limit?: number,
		filters?: Record<string, any>,
	): Promise<SearchQuery[]> {
		return this.api
			.getSearchApi()
			.getLatestSearchQueries({ searchPhrase, limit, filters })
			.then((searchQueries) => adaptSearchQueries(searchQueries));
	}

	public createTyle(tyleObj: ApiTyle): Promise<Tyle> {
		return this.api
			.getTylesApi()
			.postTyle(tyleObj)
			.then((tyle) => adaptTyle(tyle));
	}

	public createEmptyTyle(): Promise<Tyle> {
		return this.api
			.getTylesApi()
			.postTyle({
				title: '',
				content: [{ id: nanoid(), type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }],
			})
			.then((tyle) => adaptTyle(tyle));
	}

	public updateTyle(id: string, data: TyleInterface): Promise<TyleInterface> {
		return this.api
			.getTylesApi()
			.updateTyle(id, data)
			.then((tyle) => adaptTyle(tyle));
	}

	public insertTyleBlock(id: string, data: TyleBlockInterface): Promise<GenericSuccess | GenericError> {
		return this.api
			.getTylesApi()
			.insertEditorBlock(id, data)
			.then((success) => success);
	}

	public updateTyleBlock(id: string, data: TyleBlockInterface): Promise<GenericSuccess | GenericError> {
		return this.api
			.getTylesApi()
			.updateEditorBlock(id, data)
			.then((success) => success);
	}

	public removeTyleBlocks(id: string, data: TyleBlockToRemoveInterface): Promise<GenericSuccess | GenericError> {
		return this.api
			.getTylesApi()
			.removeEditorBlock(id, data)
			.then((success) => success);
	}

	public softDeleteTyle = async (id: string): Promise<any> => {
		return this.api
			.getTylesApi()
			.softDeleteTyle(id)
			.then(() => ({ id }));
	};

	public deleteTyle = async (id: string): Promise<any> => {
		return this.api
			.getTylesApi()
			.deleteTyle(id)
			.then(() => ({ id }));
	};

	public addFavoriteTyle(id: string): Promise<TyleLink> {
		return this.api.getTylesApi().postFavoriteTyle(id);
	}

	public removeFavoriteTyle(id: string): Promise<TyleLink> {
		return this.api.getTylesApi().deleteFavoriteTyle(id);
	}

	public getTyle = async (id: string) => {
		const loading = 0;
		return this.api
			.getTylesApi()
			.getTyle(id)
			.then((tyle) => {
				return adaptTyle(tyle);
			});
	};

	public getLastUpdatedTyle = async (): Promise<TyleInterface> => {
		return this.api
			.getTylesApi()
			.getLastUpdatedTyle()
			.then((tyle) => {
				return adaptTyle(tyle);
			});
	};

	public getLinkedTyles(id: string): Promise<TyleInterface[]> {
		return this.api
			.getTylesApi()
			.getLinkedTyles(id)
			.then((tyles) => adaptTyles(tyles));
	}

	public getBacklinkedTyles(id: string): Promise<TyleInterface[]> {
		return this.api
			.getTylesApi()
			.getBacklinkedTyles(id)
			.then((tyles) => adaptTyles(tyles));
	}

	public getPublicTyle(url: string): Promise<TyleInterface> {
		return this.api
			.getPublicApi()
			.getPublicTyleByUrl(url)
			.then((tyle) => adaptTyle(tyle));
	}

	public postGenerateTyleUrlForChild(tyleRelationObj: TyleRelationInterface): Promise<TyleInterface> {
		return this.api
			.getPublicApi()
			.postGenerateTyleUrlForChild(tyleRelationObj)
			.then((tyle) => adaptTyle(tyle));
	}

	public getGeneratedTyleUrl(tyleId: string): Promise<GeneratedTyleUrlInterface> {
		return this.api
			.getTylesApi()
			.getGeneratedTyleUrl(tyleId)
			.then((generatedUrl) => adaptGeneratedUrl(generatedUrl));
	}

	public toggleGeneratedTyleURL(tyleId: string, disabled: boolean): Promise<GeneratedTyleUrlInterface> {
		return this.api
			.getTylesApi()
			.toggleGeneratedTyleUrl({ tyleId, disabled })
			.then((generatedUrl) => adaptGeneratedUrl(generatedUrl));
	}

	public postLinkTyle(id: string, tyleToAddObj: TyleInterface | { id: string }) {
		return this.api
			.getTylesApi()
			.postLinkToExistingTyle(id, tyleToAddObj)
			.then((linkedTyle) => adaptTyle(linkedTyle));
	}

	public deleteLinkTyle(id: string, tyleToUnlink: TyleInterface | { id: string }) {
		return this.api
			.getTylesApi()
			.deleteLinkToExistingTyle(id, tyleToUnlink)
			.then((linkedTyle) => adaptTyle(linkedTyle));
	}

	public clearInboxTyles() {
		return this.api
			.getTylesApi()
			.clearInboxTyles()
			.then((cleared) => cleared);
	}

	public createNewInstanceFromTyle(id: string): Promise<TyleInterface> {
		return this.api
			.getTylesApi()
			.postNewInstanceFromTyle(id)
			.then((tyle) => adaptTyle(tyle));
	}

	public getPreviousInstance(id: string): Promise<string[]> {
		return this.api
			.getTylesApi()
			.getTylePrevious(id)
			.then((tyles) => tyles);
	}

	public getOriginTyle(id: string): Promise<TyleInterface> {
		return this.api
			.getTylesApi()
			.getTyleOrigin(id)
			.then((tyle) => adaptTyle(tyle));
	}

	public getTyleInstancesById(id: string): Promise<TyleInterface[]> {
		return this.api
			.getTylesApi()
			.getTyleInstances(id)
			.then((tyles) => adaptTyles(tyles));
	}

	public getRecentlyCreatedTyles = async () => {
		return this.api
			.getTylesApi()
			.getRecentlyCreatedTyles()
			.then((tyles) => adaptTyles(tyles));
	};

	public getFavoriteTyles = async () => {
		return this.api
			.getTylesApi()
			.getTylesFavorites()
			.then((tyles) => adaptTyles(tyles));
	};

	public getInboxTyles = async () => {
		return this.api
			.getTylesApi()
			.getInboxTyles()
			.then((tyles) => {
				return tyles;
			});
	};

	public getSpaces = async () => {
		return this.api
			.getSpacesApi()
			.getSpaces()
			.then((spaces) => {
				return adaptSpaces(spaces);
			});
	};

	public getSpace = async (id: string) => {
		return this.api
			.getSpacesApi()
			.getSpace(id)
			.then((space) => {
				return adaptSpace(space);
			});
	};

	public updateSpace = async (data: Space) => {
		return this.api
			.getSpacesApi()
			.updateSpace(data.id, data)
			.then((space) => {
				return adaptSpace(space);
			});
	};

	public createSpace = async (label: string) => {
		return this.api
			.getSpacesApi()
			.createSpace({ label })
			.then((space) => {
				return adaptSpace(space);
			});
	};

	public deleteSpace = async (id: string) => {
		return this.api
			.getSpacesApi()
			.deleteSpace(id)
			.then((space) => {
				return space;
			});
	};

	public closeSpace = async (id: string) => {
		return this.api
			.getSpacesApi()
			.updateSpace(id, { closed: true })
			.then((space) => {
				return adaptSpace(space);
			});
	};

	public exchangeInviteCodeForToken = async (inviteCode: string) => {
		return this.api.getAuthenticationApi().getInviteCodeSignup(inviteCode);
	};

	public getMyInviteCodes = async () => {
		return this.api.getInviteCodesApi().getMyInviteCodes();
	};

	public createInviteCode = async (inviteCode: InviteCode) => {
		return this.api
			.getInviteCodesApi()
			.createInviteCodes({ invitations: [inviteCode] })
			.then((inviteCodes) => inviteCodes[0]);
	};

	private init() {
		const authentication = this.authenticationService.getAuthentication();
		if (!authentication) {
			return;
		}

		this.setAuthentication(authentication.token);
	}
}

export interface ApiServiceInterface {
	setAuthentication(token: string): void;

	getAuthentication(): Promise<TokenPairInterface>;

	sendLoginRequest(email: string, loginType: EnvironmentEnum): Promise<any>;

	sendSignUpRequest(email: string, loginType: EnvironmentEnum, inviteCode: string): Promise<any>;
}
