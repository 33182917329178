import React, { ReactElement, useEffect, useState } from 'react';
import newCanvasState from 'state/NewCanvas';
import { subscribeKey } from 'valtio/utils';
import {
	StyledHorizontalCanvasContent,
	StyledHorizontalCanvasNotificationLabel,
	StyledHorizontaleCanvasNotification,
} from './HorizontalCanvasNotification.styled';

const HorizontalCanvasNotification = (): ReactElement | null => {
	const [showSavedNotification, setShowSavedNotification] = useState(false);

	useEffect(() => {
		const unsubscribe = subscribeKey(newCanvasState, 'showSavedNotification', (show) => {
			setShowSavedNotification(show);
		});
		return unsubscribe;
	});

	return showSavedNotification ? (
		<StyledHorizontaleCanvasNotification
			initial={{ opacity: 0.8, x: 0 }}
			animate={{ opacity: 1, x: -100 }}
			transition={{
				duration: 1,
			}}
		>
			<StyledHorizontalCanvasContent>
				<StyledHorizontalCanvasNotificationLabel>✓ Saved</StyledHorizontalCanvasNotificationLabel>
			</StyledHorizontalCanvasContent>
		</StyledHorizontaleCanvasNotification>
	) : null;
};

export default HorizontalCanvasNotification;
