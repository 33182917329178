/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { PlatePluginComponent, usePlateEditorState } from '@udecode/plate';
import Paragraph from 'components/paragraph';

const EditorParagraph: PlatePluginComponent = ({ children, nodeProps }: any) => {
	const editor = usePlateEditorState();
	const [focused, setFocused] = useState<boolean>(false);

	useEffect(() => {
		if (editor?.selection !== null && editor?.selection.anchor !== null) {
			setFocused(children[0]?.props?.parent?.id === editor.children[editor?.selection?.anchor?.path[0]]?.id);
			// selected = editor.children[editor?.selection.anchor.path[0]];
		} else {
			setFocused(false);
			// selected = null;
		}
	}, [editor?.selection?.focus?.path[0], children]);

	return (
		<Paragraph classN={`focused-${focused}`} {...nodeProps}>
			{children}
		</Paragraph>
	);
};

export default EditorParagraph;
