import React from 'react';
import { flash } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { renderTylesBetaHeadlineLogo } from 'components/icon/helpers';
import Paragraph from 'components/paragraph/Paragraph';

const flashAnimation = keyframes`${flash}`;

const FlashDiv = styled.div`
	animation: 3s ${flashAnimation};
	animation-iteration-count: infinite;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	background: #1e1e1e;
`;

const RadialDiv = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	background: radial-gradient(black 30%, #1e1e1e 100%);
	width: 100%;
	height: 100%;
`;

export interface Props {
	text?: string;
}

const LoadingScreen = ({ text }: Props) => {
	return (
		<Wrapper>
			<RadialDiv>
				<FlashDiv>{renderTylesBetaHeadlineLogo()}</FlashDiv>
				{text && <Paragraph style={{ color: '#A0A0A0', opacity: '50%', paddingTop: '1.5rem' }}>{text}</Paragraph>}
			</RadialDiv>
		</Wrapper>
	);
};

export default LoadingScreen;
