import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { FastCaptureState } from 'models/State';

const fastCaptureState = proxy<FastCaptureState>({
	showFastCapture: false,
	isFastCaptureClosed: false,
	cancelAutoSave: false,
	notificationMessage: '',
});

devtools(fastCaptureState, { name: 'FastCapture' });

export default fastCaptureState;
