import React, { ReactElement } from 'react';
import './Item.scss';

export interface ItemProps {
	children: any;
}

const Item = ({ children }: ItemProps): ReactElement => {
	return <span className="kum-item">{children}</span>;
};

export default Item;
