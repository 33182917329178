/* eslint-disable import/prefer-default-export */
import { SpaceInterface } from 'interfaces/SpaceInterface';
import { DragWindowInterface } from '../interfaces/DragAndDrop/DragAndDropInterfaces';

export class Space implements SpaceInterface {
	id: string;

	createdAt?: string | null;

	updatedAt?: string | null;

	userId?: string;

	label: string;

	tylesData?: DragWindowInterface[];

	closed?: boolean;

	deleted?: boolean;

	selected?: boolean;

	constructor(space: SpaceInterface) {
		this.id = space.id;
		this.createdAt = space.createdAt;
		this.updatedAt = space.updatedAt;
		this.userId = space.userId;
		this.label = space.label;
		this.tylesData = space.tylesData;
		this.closed = space.closed;
		this.deleted = space.deleted;
		this.selected = space.selected;
	}
}
