import {
	createPlateUI,
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_LI,
	ELEMENT_LINK,
	ELEMENT_OL,
	ELEMENT_PARAGRAPH,
	ELEMENT_TODO_LI,
	ELEMENT_UL,
	withProps,
} from '@udecode/plate';
import { CloudAttachmentElement, CloudImageElement } from '@udecode/plate-ui-cloud';
import { ELEMENT_CLOUD_ATTACHMENT, ELEMENT_CLOUD_IMAGE } from '@udecode/plate-cloud';
import Blockquote from 'components/blockquote/blockquote';
import { CodeBlock } from 'components/code-block/CodeBlock';
import { ELEMENT_TYLE_BLOCK, ELEMENT_TYLE_INLINE } from 'components/editor/types/tyleTypes';
import Headline from 'components/headline';
import List from 'components/list/List';
import ListItem from 'components/list/ListItem';
import EditorParagraph from '../EditorParagraph';
import ExternalUrl from '../ExternalUrl/ExternalUrl';
import TodoList from '../TodoList';
import TyleBlock from '../TyleBlock';
import TyleInline from '../TyleInline';

const plateUI = createPlateUI({
	[ELEMENT_PARAGRAPH]: withProps(EditorParagraph, {}),
	[ELEMENT_H1]: withProps(Headline, { variant: 'h1' }),
	[ELEMENT_H2]: withProps(Headline, { variant: 'h2' }),
	[ELEMENT_H3]: withProps(Headline, { variant: 'h3' }),
	[ELEMENT_CODE_BLOCK]: withProps(CodeBlock, {}),
	[ELEMENT_TYLE_BLOCK]: withProps(TyleBlock, {}),
	[ELEMENT_TYLE_INLINE]: withProps(TyleInline, {}),
	[ELEMENT_LINK]: withProps(ExternalUrl, {}),
	[ELEMENT_UL]: withProps(List, { variant: 'ul' }),
	[ELEMENT_OL]: withProps(List, { variant: 'ol' }),
	[ELEMENT_LI]: withProps(ListItem, {}),
	[ELEMENT_TODO_LI]: withProps(TodoList, {}),
	[ELEMENT_BLOCKQUOTE]: withProps(Blockquote, {}),
	[ELEMENT_CLOUD_ATTACHMENT]: CloudAttachmentElement,
	[ELEMENT_CLOUD_IMAGE]: CloudImageElement,
});

export default plateUI;
