import styled from 'styled-components';
import Icon from 'components/icon';

export const StyledShareTyleContent = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
`;

export const StyledShareTyleContentLeft = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledShareTyleContentLeftHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const StyledShareTylePopoverIcon = styled(Icon)`
	font-size: 10px;
	margin-right: 5px;
`;

export const StyledShareTyleUrlContainer = styled.div`
	margin-top: 5px;

	.kum-input.input-group.size-fullWidth {
		margin: 0 !important;
	}
`;
