import { proxy } from 'valtio';
import { NewCanvasState } from 'models/State';
import { StorageKeys } from 'services/StorageService';
import { UserCanvasData, UserCanvasTyle } from 'interfaces/CanvasInterface';
import { getUserCanvasDataFromLocalStorage, getSelectedWindowId } from './helpers';
import canvasState from './CanvasState';
import sharedState from './SharedState';

const userCanvasData = getUserCanvasDataFromLocalStorage();
const selectedWindowId = userCanvasData ? getSelectedWindowId(userCanvasData) : undefined;

const initialState = {
	userCanvasTyles: userCanvasData || {
		order: [],
		windows: {},
	},
	showSavedNotification: false,
	clearSaveTimer: false,
	loadingWindowId: undefined,
	selectedWindowId,
};

if (!getUserCanvasDataFromLocalStorage()) {
	localStorage.setItem(StorageKeys.USER_CANVAS_TYLES, JSON.stringify(initialState.userCanvasTyles));
}
const newCanvasState = proxy<NewCanvasState>(initialState);

function newCanvasStateServiceFactory() {
	if (!newCanvasState.userCanvasTyles.order) {
		newCanvasState.userCanvasTyles.order = [];
	}
	if (!newCanvasState.userCanvasTyles.windows) {
		newCanvasState.userCanvasTyles.windows = {};
	}
	const addTyleToWindow = (tyleId: string, windowId: string): UserCanvasTyle => {
		newCanvasState.userCanvasTyles.windows[windowId] = {
			navigationIndex: 0,
			queue: [tyleId],
			scrollTop: 0,
			selected: false,
			tyleId,
		};
		newCanvasState.userCanvasTyles.order = [...newCanvasState.userCanvasTyles.order, windowId];
		return newCanvasState.userCanvasTyles.windows[windowId];
	};
	const getWindow = (windowId: string): UserCanvasTyle | undefined => {
		if (!newCanvasState.userCanvasTyles.windows) {
			return undefined;
		}
		return newCanvasState.userCanvasTyles.windows[windowId];
	};
	const setWindowOrder = (order: string[]): void => {
		newCanvasState.userCanvasTyles.order = order;
	};
	const setSelectFalseForAllWindows = (): void => {
		Object.keys(newCanvasState.userCanvasTyles.windows).forEach((windowId) => {
			newCanvasState.userCanvasTyles.windows[windowId].selected = false;
		});
		newCanvasState.selectedWindowId = undefined;
	};
	const setWindowSelectTrue = (windowId: string): void => {
		if (!newCanvasState.userCanvasTyles.windows[windowId]) {
			return;
		}
		newCanvasState.userCanvasTyles.windows[windowId].selected = true;
		newCanvasState.selectedWindowId = windowId;
	};
	const setShowSaveNotification = (show: boolean): void => {
		newCanvasState.showSavedNotification = show;
	};
	const removeWindow = (windowId: string): void => {
		delete newCanvasState.userCanvasTyles.windows[windowId];
	};
	const setLoadingWindowId = (windowId: string): void => {
		newCanvasState.loadingWindowId = windowId;
	};
	const unsetLoadingWindowId = (): void => {
		newCanvasState.loadingWindowId = undefined;
	};
	const setWindowData = (windowId: string, windowData: Partial<UserCanvasTyle>): void => {
		if (canvasState.isPublicView && sharedState.publicWindowEntity) {
			sharedState.publicWindowEntity = {
				...sharedState.publicWindowEntity,
				...windowData,
			};
			return;
		}
		newCanvasState.userCanvasTyles.windows[windowId] = {
			...newCanvasState.userCanvasTyles.windows[windowId],
			...windowData,
		};
	};
	const setClearSaveTimer = (clear: boolean): void => {
		newCanvasState.clearSaveTimer = clear;
	};
	const setWindows = (windows: Record<string, UserCanvasTyle>): void => {
		newCanvasState.userCanvasTyles.windows = windows;
	};
	const getWindowsWithTyleInQueue = (tyleId: string): string[] => {
		const foundWindows: string[] = [];
		Object.keys(newCanvasState.userCanvasTyles.windows).forEach((windowId) => {
			if (newCanvasState.userCanvasTyles.windows[windowId].queue.includes(tyleId)) {
				foundWindows.push(windowId);
			}
		});
		return foundWindows;
	};
	const getSelectedWindow = (): string | undefined => {
		return Object.keys(newCanvasState.userCanvasTyles.windows).find(
			(windowId) => newCanvasState.userCanvasTyles.windows[windowId].selected,
		);
	};
	const getWindowForTyleId = (tyleId: string): string | undefined => {
		const windowKeys = Object.keys(newCanvasState.userCanvasTyles.windows);
		const windowIndex = windowKeys.findIndex(
			(windowId) => newCanvasState.userCanvasTyles.windows[windowId].tyleId === tyleId,
		);
		return Object.keys(newCanvasState.userCanvasTyles.windows)[windowIndex];
	};
	return {
		selectWindow: (windowId: string): void => {
			setSelectFalseForAllWindows();
			setWindowSelectTrue(windowId);
		},
		isWindowSelected: (windowId: string): boolean => {
			if (!newCanvasState.selectedWindowId) {
				return false;
			}
			return newCanvasState.selectedWindowId === windowId;
		},
		addTyleWindow: (tyleId: string, windowId: string): void => {
			const foundWindow = getWindow(windowId);
			if (!foundWindow) {
				addTyleToWindow(tyleId, windowId);
			}
		},
		removeWindow: (windowId: string): void => {
			setWindowOrder(newCanvasState.userCanvasTyles.order.filter((id) => id !== windowId));
			removeWindow(windowId);
			localStorage.setItem(StorageKeys.USER_CANVAS_TYLES, JSON.stringify(newCanvasState.userCanvasTyles));
		},
		setWindowOrderAndSelectWindow: (order: string[], windowId: string): void => {
			setWindowOrder(order);
			setSelectFalseForAllWindows();
			setWindowSelectTrue(windowId);
		},
		getSelectedWindowTyleId: (): string | undefined => {
			const selectedWindow = getSelectedWindow();
			if (!selectedWindow) {
				return undefined;
			}
			return newCanvasState.userCanvasTyles.windows[selectedWindow].tyleId;
		},
		getWindowForTyle: (tyleId: string): string | undefined => {
			const windowKeys = Object.keys(newCanvasState.userCanvasTyles.windows);
			const windowIndex = windowKeys.findIndex(
				(windowId) => newCanvasState.userCanvasTyles.windows[windowId].tyleId === tyleId,
			);
			return Object.keys(newCanvasState.userCanvasTyles.windows)[windowIndex];
		},
		showSaveNotificationAndHideAfterTimer: (hideTimer: number): void => {
			setShowSaveNotification(true);
			setTimeout(() => {
				setShowSaveNotification(false);
			}, hideTimer);
		},
		setLoadingWindowId: (windowId: string): void => {
			setLoadingWindowId(windowId);
		},
		unsetLoadingWindowId: (): void => {
			unsetLoadingWindowId();
		},
		updateWindow: (windowId: string, windowData: Partial<UserCanvasTyle>): void => {
			const foundWindow = getWindow(windowId);
			if (!foundWindow) {
				return;
			}
			setWindowData(windowId, windowData);
		},
		updateClearSaveTimer: (clear: boolean): void => {
			setClearSaveTimer(clear);
		},
		resetCanvasState: (): void => {
			setWindowOrder([]);
			setWindows({});
		},
		initialiseCanvasState: (canvasStateInit: UserCanvasData): void => {
			setWindowOrder(canvasStateInit.order);
			setWindows(canvasStateInit.windows);
		},
		removeTyleFromCanvas: (tyleId: string): void => {
			const tyleWindowId = getWindowForTyleId(tyleId);
			if (tyleWindowId) {
				removeWindow(tyleWindowId);
			}
			setWindowOrder(newCanvasState.userCanvasTyles.order.filter((id) => id !== tyleWindowId));
			const windowIds = getWindowsWithTyleInQueue(tyleId).filter((id) => id !== tyleWindowId);
			windowIds.forEach((windowId) => {
				const { queue, navigationIndex } = newCanvasState.userCanvasTyles.windows[windowId];
				const tyleIndex = queue.findIndex((id) => id === tyleId);
				const newQueue = queue.slice(0, tyleIndex);
				if (newQueue.length > 0) {
					setWindowData(windowId, {
						queue: newQueue,
						navigationIndex: navigationIndex >= tyleIndex ? tyleIndex - 1 : navigationIndex,
					});
				} else {
					setWindowOrder(newCanvasState.userCanvasTyles.order.filter((id) => id !== windowId));
					removeWindow(windowId);
				}
			});
		},
	};
}

const newCanvasStateService = newCanvasStateServiceFactory();

export { newCanvasStateService };

export default newCanvasState;
