import { DragAndDropAreaEnum, DragItemTypeEnum } from './DragAndDropEnums';
import { TyleWindowViews } from '../TyleWindowInterface';

// In pixels
export const defaultWindowWidth = 517;
export const defaultWindowHeight = 580;
export const defaultWindowZIndex = 10;
export const defaultGap = 20;

export interface DragItemInterface {
	id: string; // nanoid generated and unique identifier
	type: DragItemTypeEnum;
	from: DragAndDropAreaEnum;
	to: DragAndDropAreaEnum;
	width: number; // in pixels
	height: number; // in pixels
	zIndex: number; // for window placement purposes
}

export interface DragTyleInterface extends DragItemInterface {
	tyleId: string;
}

export interface DragWindowInterface extends DragTyleInterface {
	left: number; // in pixels
	top: number; // in pixels
	view: TyleWindowViews;
	to: DragAndDropAreaEnum;
	dragDisabled?: boolean;
}

export interface DragTyleCardInterface {
	id: string; // nanoid generated and unique identifier
	tyleId: string;
	type: DragItemTypeEnum;
}

export interface CanvasSize {
	width: number; // in pixels
	height: number; // in pixels
}

export interface LastTyleProperties {
	left: number; // in pixels
	top: number; // in pixels
}
