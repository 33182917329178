import TyleInline from 'components/editor/components/TyleInline';
import { MyPlatePlugin } from '../../types/plateTypes';
import ELEMENT_TYLE_INLINE from './defaults';

/**
 * Enables support for inline tyles.
 */
const createTyleInlinePlugin = (): MyPlatePlugin => ({
	key: ELEMENT_TYLE_INLINE,
	isElement: true,
	isInline: true,
	isVoid: true,
	component: TyleInline,
});

export default createTyleInlinePlugin;
