import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const StyledListItem = styled.li`
	margin: ${(props) => props.theme.margins.paragraph};
`;

interface ListItemProps {
	children: ReactNode;
}
const ListItem = ({ children }: ListItemProps): ReactElement => {
	return <StyledListItem>{children}</StyledListItem>;
};

export default ListItem;
