import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { DragAndDropAreaEnum, DragAndSnapAreasEnum, DragItemTypeEnum } from 'interfaces/DragAndDrop/DragAndDropEnums';
import { nanoid } from 'nanoid';
import { XYCoord } from 'react-dnd';
import {
	CanvasSize,
	defaultWindowHeight,
	defaultWindowWidth,
	defaultWindowZIndex,
	DragItemInterface,
	DragTyleInterface,
	DragWindowInterface,
} from '../../../interfaces/DragAndDrop/DragAndDropInterfaces';
import canvasState from '../../../state/CanvasState';
import sharedState from '../../../state/SharedState';
import terminalState from '../../../state/TerminalState';
import { TyleWindowViews } from '../../../interfaces/TyleWindowInterface';

interface HandleDragInterface {
	isDragging: boolean;
	clientOffset: XYCoord | null;
	from: DragAndDropAreaEnum;
	canvasSize?: CanvasSize;
}

interface TransformToDragElementsInterface {
	items: string[]; // List of IDs
	from: DragAndDropAreaEnum;
	to: DragAndDropAreaEnum;
	toType: DragItemTypeEnum;
}

interface UseCustomDragSharedInterface {
	handleDrag: ({ isDragging, clientOffset, from, canvasSize }: HandleDragInterface) => void;
	adaptToDragElements: ({ items, from, toType }: TransformToDragElementsInterface) => Array<any>;
	adaptToDragWindow: (
		itemId: string,
		from: DragAndDropAreaEnum,
		to: DragAndDropAreaEnum,
		left: number,
		top: number,
	) => any;
}

const useCustomDragShared = (): UseCustomDragSharedInterface => {
	const [onDragAndSnapChange$] = useState<Subject<DragAndSnapAreasEnum | null>>(() => new Subject());

	useEffect(() => {
		// Emits values only after an interval when user drag and snap
		onDragAndSnapChange$.pipe(throttleTime(10)).subscribe((value: DragAndSnapAreasEnum | null) => {
			canvasState.dragAndSnapData = value;
		});

		return () => {
			onDragAndSnapChange$.unsubscribe();
		};
	}, []);

	const checkDragAndSnap = (clientOffset: XYCoord, canvasSize: CanvasSize) => {
		if (!clientOffset) {
			onDragAndSnapChange$.next(null);
			return;
		}
		const columnBottom = canvasSize.width / 3;
		const navbarHeight = 45;
		const top = clientOffset.y - navbarHeight;
		const left = clientOffset.x;
		const margin = 10;
		const sideAndBottomSpan = 60;

		switch (true) {
			case top < 0 || left < 0 || left > canvasSize.width:
				onDragAndSnapChange$.next(null);
				break;
			case left < sideAndBottomSpan && top < sideAndBottomSpan && top >= 0:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.CORNER_TOP_LEFT);
				break;
			case left > canvasSize.width - sideAndBottomSpan && top < sideAndBottomSpan && top >= 0:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.CORNER_TOP_RIGHT);
				break;
			case left < sideAndBottomSpan && top > canvasSize.height - margin * 2:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.CORNER_BOTTOM_LEFT);
				break;
			case left > canvasSize.width - sideAndBottomSpan && top > canvasSize.height - margin:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.CORNER_BOTTOM_RIGHT);
				break;
			case top > canvasSize.height - sideAndBottomSpan:
				if (left < columnBottom) {
					onDragAndSnapChange$.next(DragAndSnapAreasEnum.BOTTOM_LEFT_COLUMN);
				} else if (left > columnBottom && left < columnBottom * 2) {
					onDragAndSnapChange$.next(DragAndSnapAreasEnum.BOTTOM_CENTER_COLUMN);
				} else if (left > columnBottom * 2 && left < columnBottom * 3) {
					onDragAndSnapChange$.next(DragAndSnapAreasEnum.BOTTOM_RIGHT_COLUMN);
				}
				break;
			case left < sideAndBottomSpan:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.LEFT);
				break;
			case left > canvasSize.width - sideAndBottomSpan:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.RIGHT);
				break;
			case top < 25:
				onDragAndSnapChange$.next(DragAndSnapAreasEnum.TOP);
				break;
			default:
				onDragAndSnapChange$.next(null);
				break;
		}
	};

	const handleDrag = ({ isDragging, clientOffset, from, canvasSize }: HandleDragInterface): void => {
		// If not dragging, reset drag and snap
		if (!isDragging) {
			onDragAndSnapChange$.next(null);
		}
		if (clientOffset && canvasSize) {
			checkDragAndSnap(clientOffset, canvasSize);
		}

		switch (from) {
			case DragAndDropAreaEnum.INBOX:
				sharedState.showInboxPanel = !isDragging;
				break;
			case DragAndDropAreaEnum.TERMINAL:
				terminalState.showTerminal = !isDragging;
				break;
			default:
				break;
		}
	};

	const adaptToDragWindow = (
		tyleId: string,
		from: DragAndDropAreaEnum,
		to: DragAndDropAreaEnum,
		left: number,
		top: number,
	): DragWindowInterface => {
		return {
			...adaptToDragTyleCard(tyleId, from, to),
			type: DragItemTypeEnum.TYLE_WINDOW,
			view: TyleWindowViews.EDITOR,
			left,
			top,
		};
	};

	const adaptToDragTyleCard = (
		tyleId: string,
		from: DragAndDropAreaEnum,
		to: DragAndDropAreaEnum,
	): DragTyleInterface => {
		return {
			...adaptToDragItem(tyleId, from, to, DragItemTypeEnum.TYLE_CARD),
			tyleId,
		};
	};

	// TODO Change element from any to some type
	const adaptToDragItem = (
		element: any,
		from: DragAndDropAreaEnum,
		to: DragAndDropAreaEnum,
		type: DragItemTypeEnum,
	): DragItemInterface => {
		return {
			id: nanoid(),
			type,
			from,
			to,
			width: defaultWindowWidth,
			height: defaultWindowHeight,
			zIndex: defaultWindowZIndex,
		};
	};

	const adaptToDragElements = ({ items, from, to, toType }: TransformToDragElementsInterface): DragItemInterface[] => {
		switch (toType) {
			case DragItemTypeEnum.TYLE_WINDOW:
				return items.map((item: string) => adaptToDragWindow(item, from, to, 0, 0));
			case DragItemTypeEnum.TYLE_CARD:
			case DragItemTypeEnum.ELEMENT_TYLE_BLOCK:
				return items.map((item: string) => adaptToDragTyleCard(item, from, to));
			default:
				return items.map(() => adaptToDragItem({}, from, to, toType));
		}
	};

	return { handleDrag, adaptToDragElements, adaptToDragWindow };
};

export default useCustomDragShared;
