/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class InviteCode {
    'id'?: string;
    'createdAt'?: any;
    'updatedAt'?: any;
    'code'?: string;
    'createdById'?: string;
    'used'?: boolean;
    'usedAt'?: any;
    'sent'?: boolean;
    'sentAt'?: any;
    'email'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'occupation'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdById",
            "baseName": "createdById",
            "type": "string",
            "format": ""
        },
        {
            "name": "used",
            "baseName": "used",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "usedAt",
            "baseName": "usedAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "sent",
            "baseName": "sent",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "sentAt",
            "baseName": "sentAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "occupation",
            "baseName": "occupation",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InviteCode.attributeTypeMap;
    }

    public constructor() {
    }
}

