import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Search } from '@styled-icons/feather/Search';
import { Clear } from '@styled-icons/material-outlined/Clear';
import useLayoutEffect from 'use-isomorphic-layout-effect';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import ComboboxContainer from 'containers/AutocompleteComboboxContainer';
import {
	getAnchorRect,
	getSearchValue,
	getTrigger,
	getTriggerOffset,
	replaceSubstring,
	getReplaceSubstringFn,
} from 'components/SearchCombobox/helpers';
import sharedState from 'state/SharedState';
import { SearchSidebarShortcutsEnum } from 'interfaces/SearchSidebarInterface';

const StyledSearchComboboxContainer = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	flex: 1 0 auto;
	border-radius: 10px;
	padding: ${(props) => props.theme.paddings?.input};
	background-color: ${(props) => props.theme.colors?.background};
	border: 1px solid ${(props) => props.theme.colors?.stroke};
	height: 40px;
	max-height: 40px;
	width: 100%;
	position: relative;

	&:has(input:focus) {
		box-shadow: 0 0 0 1px ${(props) => props.theme.colors?.stroke};
	}
`;

const StyledSearchCombobox = styled.input`
	flex: 1 0 auto;
	padding: 0 10px;
	font-size: 14px;
	line-height: 2;
	resize: none;
	height: 25px;
	overflow-x: hidden;
	color: ${(props) => props.theme.colors?.lightHeading};
	background-color: ${(props) => props.theme.colors?.background};
	border: none;
	outline: none;

	&:focus {
		outline: none !important;
	}

	&:focus + .searchinput_clear {
		visibility: visible;
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: ${(props) => props.theme.colors?.softParagraph};
		opacity: 1; /* Firefox */
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${(props) => props.theme.colors?.softParagraph};
	}
`;

const StyledSearchIcon = styled(Search)`
	width: 15px;
	height: 15px;
	color: ${(props) => props.theme.colors?.paragraph};
`;
const StyledClearIcon = styled(Clear)`
	width: 10px;
	height: 10px;
	color: ${(props) => props.theme.colors?.paragraph};
	visibility: hidden;
	cursor: pointer;
`;

export interface SearchComboboxProps {
	onChange: (text: string) => void;
	defaultValue?: string;
	placeholder?: string;
	items: SelectableItem[];
	onTriggerChange: (value: string) => void;
	onSelect: (item: SelectableItem) => void;
}

export const SearchComboboxTestId = 'sidebar-search-input';

const SearchCombobox = ({
	items,
	onChange,
	onTriggerChange,
	onSelect,
	defaultValue = '',
	placeholder = 'Search',
}: SearchComboboxProps) => {
	const ref = useRef<HTMLInputElement | null>(null);
	const [value, setValue] = useState(defaultValue);
	const [showAutocomplete, setShowAutocomplete] = useState(false);
	const [autocompleteValue, setAutocompleteValue] = useState<string>('');
	const [autocompletePosition, setAutocompletePosition] = useState({ x: 65, y: 56 });
	const [trigger, setTrigger] = useState<string | null>(null);
	const [caretOffset, setCaretOffset] = useState<number | null>(null);

	// Listen to input text changes from parent components (like recent searches selection) and submit
	useEffect(() => {
		if (!defaultValue) {
			return;
		}
		setValue(defaultValue);
		onChange(defaultValue);
		ref.current?.focus();
	}, [defaultValue]);

	const handleClear = useCallback(() => {
		setValue('');
		onChange('');
		// TODO: Remove setTimeout when sidebar is refactored for accesibility
		setTimeout(() => {
			ref.current?.focus();
		}, 10);
	}, []);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const isTrigger = getTrigger(event.target);
		const searchValue = getSearchValue(event.target);
		if (isTrigger) {
			setTrigger(isTrigger);
			setShowAutocomplete(true);
		} else if (!searchValue) {
			setTrigger(null);
			setShowAutocomplete(false);
		}
		setValue(event.target.value);
		onChange(event.target.value);
		setAutocompleteValue(searchValue);
		onTriggerChange(searchValue);
	}, []);

	// Re-calculates the position of the combobox popover in case the changes on
	// the textarea value have shifted the trigger character.
	useEffect(() => {
		const textarea = ref.current;
		if (!textarea) return;
		setAutocompletePosition(getAnchorRect(textarea));
	}, [value]);

	useEffect(() => {
		if (trigger) {
			sharedState.searchTrigger = trigger;
		}
		onTriggerChange(autocompleteValue);
	}, [trigger]);

	const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === SearchSidebarShortcutsEnum.ARROW_LEFT || event.key === SearchSidebarShortcutsEnum.ARROW_RIGHT) {
			setShowAutocomplete(false);
		}
		if (event.key === SearchSidebarShortcutsEnum.ARROW_DOWN || event.key === SearchSidebarShortcutsEnum.ARROW_UP) {
			event.preventDefault();
		}
	}, []);

	useLayoutEffect(() => {
		if (caretOffset != null) {
			ref.current?.setSelectionRange(caretOffset, caretOffset);
		}
	}, [caretOffset]);

	const handleSelectItem = useCallback((item: SelectableItem) => {
		const textarea = ref.current;
		if (!textarea) return;
		const offset = getTriggerOffset(textarea);
		const searchValue = autocompleteValue;

		setShowAutocomplete(false);
		if (sharedState.searchTrigger !== '#') {
			setValue(
				getReplaceSubstringFn(
					offset,
					searchValue,
					sharedState.searchTrigger === ':' ? `${item.value}` : `${sharedState.searchTrigger}${item.label}`,
				),
			);
			const nextCaretOffset = offset + item.label.length + 1;
			setCaretOffset(nextCaretOffset);
		}

		if (sharedState.searchTrigger === '#') {
			setValue(getReplaceSubstringFn(offset, searchValue, '', true));
			sharedState.searchSidebarInputText = replaceSubstring(offset, searchValue, '', value, true);
			onSelect(item);
		}
		ref.current?.focus();
	}, []);

	return (
		<StyledSearchComboboxContainer>
			<StyledSearchIcon />
			<StyledSearchCombobox
				id="search-input"
				data-testid={SearchComboboxTestId}
				ref={ref}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				onKeyDown={onKeyDown}
				autoComplete="off"
			/>
			{showAutocomplete && (
				<ComboboxContainer items={items} onSelect={handleSelectItem} position={autocompletePosition} />
			)}
			<StyledClearIcon className="searchinput_clear" title="clear" onMouseDown={handleClear} />
		</StyledSearchComboboxContainer>
	);
};

export default SearchCombobox;
