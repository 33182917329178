import React, { ReactElement } from 'react';
import { Popover } from 'react-tiny-popover';

export interface PopoverContentProps {
	isOpen: boolean;
	onClickOutside: () => void;
	positions: Array<'left' | 'right' | 'top' | 'bottom'>;
	containerClassName?: string;
	containerStyle?: Partial<CSSStyleDeclaration>;
	align: 'start' | 'center' | 'end';
	children: ReactElement;
	content: ReactElement;
	reposition?: boolean;
}
const PopoverContent = ({
	isOpen,
	onClickOutside,
	positions,
	containerClassName,
	containerStyle,
	align,
	children,
	content,
	reposition,
}: PopoverContentProps): ReactElement => {
	if (!content || !children) {
		return <></>;
	}
	return (
		<Popover
			containerStyle={containerStyle || { zIndex: '999999' }}
			containerClassName={containerClassName}
			isOpen={isOpen}
			positions={positions}
			align={align}
			onClickOutside={onClickOutside}
			content={content}
			reposition={reposition}
		>
			{children}
		</Popover>
	);
};

export default PopoverContent;
