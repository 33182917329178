import { Tag } from 'models/Tag';
import { Tag as ApiTag } from '../api/generated/client';
import adaptTag from './adaptTag';

const adaptTags = (tags: Array<ApiTag>): Array<Tag> => {
	return tags.map((tag) => {
		return adaptTag(tag);
	});
};

export default adaptTags;
