import { ReactElement, useContext, useEffect, useState } from 'react';
import Item from 'components/item';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/button';
import Icon from 'components/icon';
import Label from 'components/label';
import SwitchToggle from 'components/SwitchToggle';
import Input from 'components/input';
import Popover from 'components/Popover';
import { TyleInterface } from '../../../../interfaces/TyleInterface';
import { ApiContext } from '../../../../components/App';
import {
	StyledShareTyleContent,
	StyledShareTyleContentLeft,
	StyledShareTyleContentLeftHeader,
	StyledShareTylePopoverIcon,
	StyledShareTyleUrlContainer,
} from './TyleShareAction.styled';

interface TyleShareActionProps {
	tyle: TyleInterface | undefined;
}

const TyleShareAction = ({ tyle }: TyleShareActionProps): ReactElement => {
	const api = useContext(ApiContext);
	const [tyleUrlToShare, setTyleUrlToShare] = useState<string>('');
	const [showShareTylePopover, setShowShareTylePopover] = useState<boolean>(false);
	const [switchToggleChecked, setSwitchToggleChecked] = useState<boolean>(false);

	// Check if there's an url generated for the tyle and switch the toggle accordingly
	useEffect(() => {
		if (!tyle?.id || !showShareTylePopover) {
			return;
		}
		api
			.getGeneratedTyleUrl(tyle.id)
			.then((generatedUrl) => {
				// If the url doesn't exist or is disabled, reset the url in the popover and switch the toggle
				if (!generatedUrl || generatedUrl.disabled) {
					setSwitchToggleChecked(false);
					setTyleUrlToShare('');
					return;
				}
				// If the url exists, switch the toggle and set the url
				setSwitchToggleChecked(true);
				setTyleUrlToShare(generatedUrl?.url ? `${window.location.origin}/tyle-url/${generatedUrl.url}` : '');
			})
			.catch(() => {
				setSwitchToggleChecked(false);
				setTyleUrlToShare('');
			});
	}, [showShareTylePopover]);

	const handleGenerateTyleUrlToShare = (checked: boolean) => {
		if (!tyle?.id) {
			return;
		}
		api.toggleGeneratedTyleURL(tyle.id, !checked).then((result) => {
			setTyleUrlToShare(checked ? `${window.location.origin}/tyle-url/${result.url}` : '');
			setSwitchToggleChecked(checked);
		});
	};

	const copyTyleUrlToShare = async () => {
		await navigator.clipboard.writeText(tyleUrlToShare);
	};

	return (
		<Popover
			trigger={
				<Item>
					<Tooltip tip="Share Tyle">
						<Button
							variant="share"
							label="Share"
							selected={showShareTylePopover}
							style={{
								fontSize: '12px',
							}}
							iconPosition="left"
						/>
					</Tooltip>
				</Item>
			}
			content={
				<div>
					<StyledShareTyleContent>
						<StyledShareTyleContentLeft>
							<StyledShareTyleContentLeftHeader>
								<StyledShareTylePopoverIcon type="bookmark-icon-black" />
								Share to
							</StyledShareTyleContentLeftHeader>
							<Label label="Publish and share link with anyone" size="small" />
						</StyledShareTyleContentLeft>
						<SwitchToggle checked={switchToggleChecked} onCheckedChange={handleGenerateTyleUrlToShare} />
					</StyledShareTyleContent>
					{tyleUrlToShare && (
						<StyledShareTyleUrlContainer>
							<Input size="fullWidth" value={tyleUrlToShare} copyButton onCopyButtonClicked={copyTyleUrlToShare} />
						</StyledShareTyleUrlContainer>
					)}
				</div>
			}
			sideOffset={0}
			width={300}
			onOpenChange={setShowShareTylePopover}
		/>
	);
};

export default TyleShareAction;
