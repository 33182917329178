import { UserInterface } from 'interfaces/UserInterface';

/* Installation Guide:
https://developers.intercom.com/installing-intercom/docs/basic-javascript
*/

const addChatToDom = (user: UserInterface) => {
	// Intercom App ID
	const APP_ID = 'wkc06ywu';

	// User information
	const currentUserEmail = user.email ?? '';
	const currentUserName = `${user.firstName} ${user.lastName}`;
	const currentUserId = user.id ?? '';

	window.intercomSettings = {
		api_base: 'https://api-iam.intercom.io',
		app_id: APP_ID,
		name: currentUserName, // Full name
		email: currentUserEmail, // Email address
		user_id: currentUserId, // Current user id
	};

	// Add Intercom chat
	(function () {
		const w = window;
		const ic = w.Intercom;
		if (typeof ic === 'function') {
			ic('reattach_activator');
			ic('update', w.intercomSettings);
		} else {
			const d = document;
			const i: any = function () {
				// eslint-disable-next-line prefer-rest-params
				i.c(arguments);
			};
			i.q = [];
			i.c = function (args: any) {
				i.q.push(args);
			};
			w.Intercom = i;
			const l = function () {
				const s = d.createElement('script');
				s.type = 'text/javascript';
				s.async = true;
				s.src = `https://widget.intercom.io/widget/${APP_ID}`;
				const x = d.getElementsByTagName('script')[0];
				if (x.parentNode) {
					x.parentNode.insertBefore(s, x);
				}
			};
			if (document.readyState === 'complete') {
				l();
			} else if (w.attachEvent) {
				w.attachEvent('onload', l);
			} else {
				w.addEventListener('load', l, false);
			}
		}
	})();
};

export default addChatToDom;
