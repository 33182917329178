/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class User {
    'id'?: string;
    'createdAt'?: any;
    'email'?: string;
    'temporaryEmail'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'occupation'?: string;
    'deleted'?: boolean;
    'referralCode'?: string;
    'theme'?: string;
    'role'?: string;
    'webOnboarded'?: boolean;
    'desktopOnboarded'?: boolean;
    'canvasTyles'?: any;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "temporaryEmail",
            "baseName": "temporaryEmail",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "occupation",
            "baseName": "occupation",
            "type": "string",
            "format": ""
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "referralCode",
            "baseName": "referralCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "theme",
            "baseName": "theme",
            "type": "string",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "string",
            "format": ""
        },
        {
            "name": "webOnboarded",
            "baseName": "webOnboarded",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "desktopOnboarded",
            "baseName": "desktopOnboarded",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "canvasTyles",
            "baseName": "canvasTyles",
            "type": "any",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return User.attributeTypeMap;
    }

    public constructor() {
    }
}

