import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import { TagState } from '../models/State';

const tagsState = proxy<TagState>({
	displayedTags: new Set<SelectableItem>(),
	selectedTagIds: new Set<string>(),
	searchResultsTags: [],
	selectedTag: null,
});

export const tagsStateActions = {
	resetTagsState(): void {
		tagsState.selectedTagIds = new Set<string>();
		tagsState.searchResultsTags = [];
		tagsState.selectedTag = null;
	},
};

devtools(tagsState, { name: 'Tags' });

export default tagsState;
