import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { pulse } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import backgroundUrl from 'assets/images/intro-circle_background.png';
import gradientStill from 'assets/images/blob-gradient_still.png';
import { renderBetaLogo, renderTylesIntroLogo } from 'components/icon/helpers';
import Icon from 'components/icon';
import Spacing from 'components/spacing';
import './style.scss';

const pulseAnimation = keyframes`${pulse}`;

const PulseSlowDiv = styled.div`
	animation: 3s ${pulseAnimation};
	animation-iteration-count: infinite;
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background: #1e1e1e;
`;

const RadialDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: radial-gradient(black 30%, #1e1e1e 100%);
	width: 100%;
	height: 100%;
`;

const Background = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: fill;
	background: url(${backgroundUrl});
	background-repeat: no-repeat;
	background-position: center;
	width: 100vw;
	height: 100vh;
`;

const Container = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const Divider = styled.div`
	border-top: 1px solid #343434;
	width: 80px;
`;

const ShineDiv = styled.div`
	display: flex;
	align-items: center;
	border-style: none;
`;
export interface Props {
	children: ReactElement;
}

const IntroScreenWrapper = ({ children }: Props) => {
	const [showNext, setShowNext] = useState(false);

	const onKeydown = (e: any) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setShowNext(true);
		}
	};
	useEffect(() => {
		document.addEventListener('keydown', onKeydown);
		return () => {
			document.removeEventListener('keydown', onKeydown);
		};
	}, [showNext]);

	return showNext ? (
		children
	) : (
		<Wrapper>
			<RadialDiv>
				<Background>
					<Container>
						<div className="wraps">
							<PulseSlowDiv className="intro-logo">
								<img src={gradientStill} alt="intro-logo" />
							</PulseSlowDiv>
							<div className="intro-logo">
								<div className="beta-logo">{renderBetaLogo()}</div>
								<Spacing size="extra-small" />
								<div>{renderTylesIntroLogo()}</div>
							</div>
						</div>
						<Spacing size="extra-small" />
						<div className="intro_header">
							Work your <strong>magic.</strong>
						</div>
						<Spacing size="extra-small" />
						<div className="intro_subheader">BY TYLES</div>
						<Spacing />
						<Divider />
						<Spacing />
						<ShineDiv>
							<div
								className="shine"
								role="button"
								tabIndex={0}
								onKeyPress={(e: SyntheticEvent) => onKeydown(e)}
								onClick={() => setShowNext(true)}
							>
								Press enter to begin
							</div>
							<Icon type="arrow-point-right" />
						</ShineDiv>
					</Container>
				</Background>
			</RadialDiv>
		</Wrapper>
	);
};

export default IntroScreenWrapper;
