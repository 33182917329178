import removeElement from './removeElement';
import { TyleToDeleteData } from '../../../interfaces/TyleInterface';
import useTyleHooks from '../../../hooks/useTyleHooks';

const deleteElementTyle = () => {
	const { deleteTyle } = useTyleHooks();

	const confirmDelete = async (tyleToDeleteData: TyleToDeleteData | undefined) => {
		if (!tyleToDeleteData?.element?.tyleId) {
			return;
		}
		removeElement(tyleToDeleteData);
		await deleteTyle({ tyleId: tyleToDeleteData.element?.tyleId });
	};

	return {
		confirmDelete,
	};
};

export default deleteElementTyle;
