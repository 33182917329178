import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { TerminalState } from '../models/State';
import menuItems, { emptyTyle } from './__fixtures__/initialTerminalState';
import { TerminalLabelsEnum, TerminalViewEnum } from '../interfaces/TerminalMenuInterface';

const terminalState = proxy<TerminalState>({
	showTerminal: false,
	operation: null,
	menuItems,
	selectedMenuItem: TerminalLabelsEnum.SAVE_TO_INBOX,
	navigation: TerminalViewEnum.DEFAULT,
	search: '',
	tyleToCreate: emptyTyle,
	contentTyleToCreate: emptyTyle.content,
	doubleClickedTyle: null,
});

devtools(terminalState, { name: 'Terminal' });

export default terminalState;
