import React, { ReactElement } from 'react';
import { useSnapshot } from 'valtio';
import Tooltip from '../../tooltip/Tooltip';
import Link from '../../link/Link';
import canvasState from '../../../state/CanvasState';
import Item from '../../item';
import StyledLogoIcon from './TylesLogoButton.styled';

const TylesLogoButton = (): ReactElement => {
	const { isPublicView } = useSnapshot(canvasState);

	return (
		<Item>
			<Tooltip tip="Go To Tyles" offset={{ top: 3 }}>
				<Link variant="no-decoration" href="https://tyles.io/" target="_blank">
					<StyledLogoIcon isPublic={isPublicView} className="tyles-logo-16" type="tyles-logo-16" />
				</Link>
			</Tooltip>
		</Item>
	);
};

export default TylesLogoButton;
