/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { PlatePluginComponent } from '@udecode/plate';
import styled from 'styled-components';
import classNames from 'classnames';
import { MoreHorizontalOutline as ThreeDotsIcon } from '@styled-icons/evaicons-outline/MoreHorizontalOutline';
import { subscribeKey } from 'valtio/utils';
import { useSnapshot } from 'valtio';
import getTylePreviewData from 'helpers/getTylePreviewData';
import { InlineTypes, Transformations } from 'components/editor/transforms/transformations';
import removeElement from 'components/editor/utils/removeElement';
import PopoverMenu from 'components/popover-menu/PopoverMenu';
import { TyleActionEnum, TyleInterface } from 'interfaces/TyleInterface';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import tylePreviewHelper from 'helpers/tylePreviewHelper';
import { PopoverContent } from 'components/popover-menu';
import transformToTyleBlock from 'components/editor/transforms/transformToTyleBlock';
import transformToTyleInline from 'components/editor/transforms/transformToTyleInline';
import transformToText from 'components/editor/transforms/transformToText';
import { CustomEditor, CustomElement } from 'types/slate';
import DeleteConfirmationModal from 'containers/DeleteConfirmationModal';
import truncateString from 'helpers/truncateString';
import useTyleHooks from 'hooks/useTyleHooks';
import tylesState from 'state/TylesState';
import { detectOperatingSystem } from 'helpers';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import sharedState from 'state/SharedState';
import canvasState from 'state/CanvasState';
import getTyleCardSource from 'helpers/getTyleCardSource';
import { nanoid } from 'nanoid';
import { getEmptyImage } from 'react-dnd-html5-backend';
import useTyleCardDragHooks from 'hooks/DragAndDropHooks/useTyleCardDragHooks';
import { DragItemTypeEnum } from 'interfaces/DragAndDrop/DragAndDropEnums';
import useCanvasHooks from 'hooks/useCanvasHooks';
import deleteElementTyle from '../../utils/deleteElement';

export const StyledSource = styled.span`
	padding-left: 5px;
	margin-left: 5px;
	border-left: 1px solid ${(props) => props.theme.colors?.stroke};
`;

export const StyledLink = styled.a`
	text-decoration: none;
	color: ${(props) => props.theme.colors?.paragraph};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	font-size: ${(props) => props.theme.fontSizes?.headingXSmall};
	background-color: none;
	font-size: 13px;
	line-height: 17.5px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover {
		color: ${(props) => props.theme.colors?.softParagraph};
	}
`;

export const StyledImg = styled.img`
	width: 12px;
	height: 12px;
	margin-right: 3px;
	border-radius: 2px;
`;

const StyledSpan = styled.span`
	cursor: pointer;
	color: ${({ theme }) => theme.colors?.paragraph};
	display: inline;
	border-radius: ${(props) => props.theme.radius.medium};
	border: 1px solid ${(props) => props.theme.colors?.stroke};
	line-height: 1.55em;
	user-select: none;
	background-color: ${(props) => props.theme.colors?.inlineBg};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	font-size: ${(props) => props.theme.fontSizes?.medium};
	padding: 0 6px 0 4px;

	.kum-icon {
		line-height: 0;
	}

	.three-dots_inline-tyle {
		display: none;
	}

	&:hover,
	&.show-popover {
		.three-dots_inline-tyle {
			display: inline-block;
		}
	}
`;

const StyledThreeDots = styled(ThreeDotsIcon)`
	margin-left: 3px;
`;

const TyleInline: PlatePluginComponent = ({
	attributes,
	children,
	element,
	nodeProps,
	editor,
}: any): ReactElement | null => {
	const { tyleId } = element;
	const { isPublicView } = useSnapshot(canvasState);
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const { getTyle } = useTyleHooks();
	const { confirmDelete } = deleteElementTyle();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isHotkeyPressed, setIsHotkeyPressed] = useState(false);
	const [tyleToDeleteData, setTyleToDeleteData] = useState<
		{ editor: CustomEditor; element: CustomElement } | undefined
	>();
	const [tyleToRender, setTyleToRender] = useState<TyleInterface | undefined>(tylesState.tylesObjects[tyleId]);
	const { addTyleToNavigation } = useCanvasHooks();
	const [{ isDragging }, drag, preview] = useTyleCardDragHooks({
		id: nanoid(),
		tyleId,
		type: DragItemTypeEnum.ELEMENT_TYLE_INLINE,
	});

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		if (tyleId && !tylesState.tylesObjects[tyleId]) {
			getTyle(tyleId).then((tyleFromBackend: TyleInterface) => {
				if (!tyleFromBackend || tyleFromBackend?.deleted) {
					removeElement({ editor, element });
					return;
				}
				setTyleToRender(tyleFromBackend);
			});
		}

		const unsubscribeFromTylesObjects = subscribeKey(tylesState.tylesObjects, tyleId, (value: TyleInterface) => {
			if (!value || value?.deleted) {
				removeElement({ editor, element });
				return;
			}
			setTyleToRender(value);
		});

		/**
		 *  When the user focus the app in the browser tab,
		 *  force re-rendering of the tyle window to update the content in the editor
		 */
		const unsubscribeFromAppFocused = subscribeKey(sharedState, 'appFocused', () => {
			getTyle(tyleId).then((tyleFromBackend: TyleInterface) => {
				if (!tyleFromBackend || tyleFromBackend?.deleted) {
					removeElement({ editor, element });
					return;
				}
				setTyleToRender(tyleFromBackend);
			});
		});

		return () => {
			unsubscribeFromTylesObjects();
			unsubscribeFromAppFocused();
		};
	}, [tyleId]);

	useEffect(() => {
		const onKeydown = (e: any) => {
			const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
			const cmdOrCtrl = isMacOS ? e.metaKey : e.ctrlKey;
			if (cmdOrCtrl) {
				setIsHotkeyPressed(true);
			}
		};

		const onKeyup = (e: any) => {
			setIsHotkeyPressed(false);
		};
		document.addEventListener('keydown', onKeydown);
		document.addEventListener('keyup', onKeyup);
		return () => {
			document.removeEventListener('keydown', onKeydown);
			document.removeEventListener('keyup', onKeyup);
		};
	}, [editor]);

	const handleAction = (item: PopoverMenuItemInterface) => {
		switch (item.action) {
			case Transformations.ELEMENT_TYLE_BLOCK:
				transformToTyleBlock({ editor, elementToTransform: element });
				break;

			case Transformations.ELEMENT_TYLE_INLINE_TITLE:
				transformToTyleInline({ editor, elementToTransform: element, view: InlineTypes.TITLE });
				break;

			case Transformations.ELEMENT_TYLE_INLINE_CONTENT:
				transformToTyleInline({ editor, elementToTransform: element, view: InlineTypes.CONTENT });
				break;

			case Transformations.ELEMENT_TEXT:
				transformToText({ editor, elementToTransform: element });
				break;

			case TyleActionEnum.REMOVE: {
				removeElement({ editor, element });
				break;
			}

			case TyleActionEnum.DELETE: {
				togglePopover();
				deleteElement({ editorToEdit: editor, elementToEdit: element });
				break;
			}

			default:
				console.log(item);
		}
	};

	const deleteElement = ({
		editorToEdit,
		elementToEdit,
	}: {
		editorToEdit: CustomEditor;
		elementToEdit: CustomElement;
	}) => {
		setShowDeleteModal(true);
		setTyleToDeleteData({ editor: editorToEdit, element: elementToEdit });
	};

	const onSingleClickHandler = (event: SyntheticEvent) => {
		if (event.target instanceof HTMLAnchorElement) {
			return;
		}
		if (!element || !editor.id || !tyleId) {
			return;
		}
		if (canvasState.isPublicView && sharedState.publicWindowEntity?.windowId) {
			tylesState.clickedTyle = { windowId: editor.id, tyleId };
			return;
		}
		addTyleToNavigation({
			windowId: editor.id,
			destinationTyleId: tyleId,
			isPublicTyle: !!(canvasState.isPublicView && sharedState.publicWindowEntity),
		});
	};

	const { getTylePreview } = tylePreviewHelper;

	const togglePopover = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	const onCmdOrCtrlClick = (event: any) => {
		if (event.target instanceof HTMLAnchorElement) {
			return;
		}
		if (!element || !tyleId) {
			return;
		}
		if (isHotkeyPressed) {
			event.stopPropagation();
			tylesState.cmdOrCtrlClickedTyle = tyleId;
		}
	};

	const tyleData = tyleToRender && getTylePreviewData(tyleToRender);

	return (
		<span {...attributes} contentEditable={false} style={{ display: 'inline', userSelect: 'none' }}>
			<span
				ref={drag}
				contentEditable={false}
				onClick={(e: SyntheticEvent) => {
					onCmdOrCtrlClick(e);
					if (isHotkeyPressed) {
						return;
					}
					onSingleClickHandler(e);
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						onSingleClickHandler(event as SyntheticEvent);
					}
				}}
				role="button"
				tabIndex={0}
				style={{ display: 'inline', userSelect: 'none' }}
			>
				{tyleData && tyleToRender && (
					<StyledSpan className={classNames({ 'show-popover': isPopoverOpen })}>
						{getTylePreview(tyleToRender)}
						{tyleToRender.source && tyleToRender.source.url && (
							<StyledSource>
								<StyledImg
									alt={tyleToRender.source.title}
									style={{ margin: '0 2px 2px 2px' }}
									src={getTyleCardSource(tyleToRender.source)?.favicon}
								/>
								<StyledLink href={getTyleCardSource(tyleToRender.source)?.url ?? ''} target="_blank">
									{getTyleCardSource(tyleToRender.source)?.hostName}
								</StyledLink>
							</StyledSource>
						)}
						{tyleToRender.source && !tyleToRender.source.url && (
							<StyledSource>
								<StyledLink>{tyleToRender.source.applicationName}</StyledLink>
							</StyledSource>
						)}
						{!isPublicView && (
							<PopoverContent
								isOpen={isPopoverOpen}
								positions={['bottom', 'left', 'right', 'top']}
								align="end"
								onClickOutside={() => setIsPopoverOpen(false)}
								content={
									<PopoverMenu
										items={[
											{
												label: 'Display Full-width',
												action: Transformations.ELEMENT_TYLE_BLOCK,
											},
											{
												divider: true,
											},
											{
												label: 'Turn into text',
												action: Transformations.ELEMENT_TEXT,
											},
											{
												label: 'Remove',
												action: TyleActionEnum.REMOVE,
											},
											{
												label: 'Delete',
												action: TyleActionEnum.DELETE,
											},
										]}
										onEvent={handleAction}
									/>
								}
							>
								<StyledThreeDots
									className="three-dots_inline-tyle"
									size={14}
									onClick={(event) => {
										event.stopPropagation();
										togglePopover();
									}}
									aria-hidden="true"
								/>
							</PopoverContent>
						)}
					</StyledSpan>
				)}
				{showDeleteModal && (
					<DeleteConfirmationModal
						show={!!tyleId}
						onClose={() => setShowDeleteModal(false)}
						onSubmit={() => {
							confirmDelete(tyleToDeleteData)
								.then(() => {
									setShowDeleteModal(false);
								})
								.catch((err) => {
									setShowDeleteModal(false);
									console.log('ERROR DELETING TYLE', err);
								});
						}}
						headline={`Delete Tyle "${truncateString(tyleToRender?.title, 34)}"`}
						content={`Are you sure you want to delete the Tyle "${truncateString(
							tyleToRender?.title,
							tyleToRender?.backlink && tyleToRender?.backlink.length > 0 ? 13 : 45,
						)}"? ${
							tyleToRender?.backlink && tyleToRender?.backlink?.length > 0
								? `This action will affect ${tyleToRender.backlink.length} Tyles and action can’t be undone`
								: 'This action can’t be undone.'
						}`}
					/>
				)}
			</span>
			{children}
		</span>
	);
};

export default TyleInline;
