import { EditorState } from 'models/State';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const editorState = proxy<EditorState>({
	imageUploadProgress: 'idle',
	cloudImage: undefined,
});

devtools(editorState, { name: 'Editor' });

export default editorState;
