import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSnapshot } from 'valtio';
import Button from 'components/button';
import classNames from 'classnames';
import { Footer as ModalFooter, Modal } from 'components/modal';
import { Menu, MenuItem } from 'components/menu';
import Content from 'components/content';
import Headline from 'components/headline';
import Input from 'components/input';
import SidebarLayout from 'components/layout';
import Sidebar from 'components/sidebar';
import ModalConfirmation from 'components/modal-confirmation';
import { ApiContext } from 'components/App';
import UserSettingsTabsEnum from '../interfaces/UserSettingsInterface';
import Footer from '../components/footer';
import AccountSettings from './AccountSettings';
import useLogout from '../hooks/useLogout';
import userState from '../state/UserState';
import { Role, UserInterface } from '../interfaces/UserInterface';
import { DeleteAccountModal } from '../components/NewModals';
import InviteCodeSettings from './InviteCodeSettings';

interface OnCloseModalFunc {
	(close: boolean): void;
}

interface UserSettingsProps {
	showModal: boolean;
	onClose: OnCloseModalFunc;
}

const UserSettingsModule = ({ showModal, onClose }: UserSettingsProps): ReactElement => {
	const api = useContext(ApiContext);
	const { user, isEditing, changeEmailError } = useSnapshot(userState);
	const [initialUser, setInitialUser] = useState<UserInterface>({});

	const { logout } = useLogout();

	const [selectedTab, setTabSelected] = useState(UserSettingsTabsEnum.MY_ACCOUNT);
	// Delete user account
	const [deleteModalOpened, setDeleteModalOpen] = useState<boolean>(false);
	const [userToDeleteEmail, setUserToDeleteEmail] = useState<string>('');
	const [errorDeleteUser, setErrorDeleteUser] = useState<string>('');

	// Changed email address
	const [isChangeEmailModalOpened, setIsChangeEmailModalOpen] = useState(false);

	useEffect(() => {
		// SET INITIAL USER WHEN EMPTY
		if (!Object.keys(initialUser).length && user) {
			setInitialUser({ ...user });
		}
	}, [user]);

	const handleClose = () => {
		onClose(true);
	};

	const handleCloseDeleteModal = () => {
		setErrorDeleteUser('');
		setDeleteModalOpen(false);
	};

	const openDeleteAccountModal = () => {
		setErrorDeleteUser('');
		setDeleteModalOpen(true);
	};

	const redirectAfterDeletion = () => {
		setErrorDeleteUser('');
		logout();
	};

	const deleteUserAccount = () => {
		if (!userToDeleteEmail || userToDeleteEmail !== userState.user?.email) {
			setErrorDeleteUser('Please enter the correct email');
			return;
		}
		api
			.deleteUserAccount(userToDeleteEmail)
			.then(() => {
				setErrorDeleteUser('');
				userState.user = {};
				userState.isEditing = false;
				userState.changeEmailError = '';
				redirectAfterDeletion();
			})
			.catch(() => {
				setErrorDeleteUser('Oops, something went wrong. Please, try again later.');
			});
	};

	const changeEmail = () => {
		if (!userState.user?.email) {
			userState.changeEmailError = 'Please, enter an email.';
			return;
		}
		api
			.updateUserAccount(userState.user)
			.then((userAccount) => {
				userState.changeEmailError = '';
				userState.isEditing = false;
				userState.user = { ...userAccount, role: userAccount.role as Role };
				setIsChangeEmailModalOpen(true);
				setDeleteModalOpen(false);
			})
			.catch((err) => {
				userState.changeEmailError = 'Ops, something went wrong. Please, try again later.';
			});
	};

	const renderDeleteSuccess = () => {
		if (Object.keys(userState.user).length) {
			return null;
		}

		return (
			<div style={{ padding: '10px' }} className="alert-success">
				User successfully deleted.
			</div>
		);
	};

	const renderChangeEmailError = () => {
		if (userState.changeEmailError) {
			return (
				<div
					style={{ padding: '10px' }}
					className={classNames({
						alert: true,
						'alert-danger': !!userState.changeEmailError,
					})}
				>
					{userState.changeEmailError}
				</div>
			);
		}
		return <></>;
	};

	const renderDeleteModal = () => {
		return (
			<DeleteAccountModal
				show={deleteModalOpened}
				stacked={2}
				submitButtonLabel="Delete Account"
				onClose={handleCloseDeleteModal}
				onConfirm={deleteUserAccount}
			>
				<Content>
					<Headline variant="h2">Delete Account</Headline>
					<p className="confirmation-message">
						Deleting your account cannot be undone, your data will be permanently erased. Please type in your email to
						confirm.
					</p>
					<Form.Group>
						<Input
							label="Email"
							focus
							placeholder="Enter your email address..."
							onChange={(event: any) => {
								setUserToDeleteEmail(event);
								setErrorDeleteUser(''); // Reset validation error
							}}
							validationMessage={errorDeleteUser}
						/>
					</Form.Group>
					{renderDeleteSuccess()}
				</Content>
			</DeleteAccountModal>
		);
	};

	const renderEmailChangedModal = () => {
		return (
			<ModalConfirmation show={isChangeEmailModalOpened} stacked={2}>
				<Content>
					<Headline variant="h1">Email update</Headline>
					<p className="confirmation-message">
						We&apos;ll send you an email with a verification link to your new address. Please click on it to complete
						the email change.
					</p>
				</Content>
				<ModalFooter>
					<Button
						label="Done"
						onEvent={() => {
							setIsChangeEmailModalOpen(false);
						}}
					/>
				</ModalFooter>
			</ModalConfirmation>
		);
	};

	const handleSubmit = () => {
		if (!userState.user) {
			return;
		}
		if (initialUser?.email !== userState.user.email) {
			changeEmail();
		} else {
			api
				.updateUserAccount({
					firstName: userState.user.firstName,
					lastName: userState.user.lastName,
					occupation: userState.user.occupation,
					email: userState.user.email,
				})
				.then((userAccount) => {
					userState.isEditing = false;
					userState.user = { ...userAccount, role: userAccount.role as Role };
				});
		}
	};

	return (
		<>
			<Modal show={showModal} style={{ minHeight: '658px' }} variant="form" stacked={3} handleClose={handleClose}>
				<SidebarLayout>
					<Sidebar>
						<Menu label="Profile">
							<MenuItem
								label="My Account"
								active={selectedTab === UserSettingsTabsEnum.MY_ACCOUNT}
								onEvent={() => setTabSelected(UserSettingsTabsEnum.MY_ACCOUNT)}
							/>
							{user.role === Role.ADMIN && (
								<MenuItem
									label="Invite Codes"
									active={selectedTab === UserSettingsTabsEnum.INVITE_CODES}
									onEvent={() => setTabSelected(UserSettingsTabsEnum.INVITE_CODES)}
								/>
							)}
						</Menu>
					</Sidebar>
					<Content>
						<div className="growing">
							{user && selectedTab === UserSettingsTabsEnum.MY_ACCOUNT && (
								<AccountSettings
									onDeleteAccountClick={() => {
										openDeleteAccountModal();
									}}
									onSubmit={handleSubmit}
								/>
							)}
							{user && selectedTab === UserSettingsTabsEnum.INVITE_CODES && <InviteCodeSettings />}
							{renderChangeEmailError()}
						</div>
						<Footer>
							{isEditing ? (
								<>
									<Button
										label="Cancel"
										onEvent={() => {
											userState.user = { ...initialUser };
											userState.isEditing = false;
										}}
										variant="basic"
									/>
									<Button label="Save" onEvent={handleSubmit} />
								</>
							) : (
								<Button label="Done" onEvent={handleClose} />
							)}
						</Footer>
					</Content>
				</SidebarLayout>
			</Modal>
			{deleteModalOpened ? renderDeleteModal() : null}
			{isChangeEmailModalOpened ? renderEmailChangedModal() : null}
		</>
	);
};

export default UserSettingsModule;
