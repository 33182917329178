import getCaretCoordinates from 'textarea-caret';

// TODO: Add support for multiple triggers ('@', ':' etc)
export const defaultTriggers = ['#'];

/**
 * Get the offset of the trigger character in an HTMLInputElement.
 *
 * @param inputElement Input element to get the offset from.
 * @param triggers  An optional array of trigger characters to check against.
 * @returns
 */
export const getTriggerOffset = (inputElement: HTMLInputElement, triggers = defaultTriggers): number => {
	const { value, selectionStart } = inputElement;
	if (!selectionStart) return -1;
	// TODO: Add support for multiple triggers
	return value.indexOf(triggers[0]) ?? -1;
};

/**
 * Determine if a trigger character was typed in an input, based on the current selectionStart.
 *
 * @param inputElement - Input element to check for a trigger character.
 * @param triggers - An optional array of trigger characters to check against.
 * @returns The trigger character, or null if no trigger character was typed.
 */

export const getTrigger = (inputElement: HTMLInputElement, triggers = defaultTriggers): string | null => {
	const { value, selectionStart } = inputElement;
	// Get position of the first character of a text selection or cursor position in the input. Returns null if no selection
	if (!selectionStart) return null;

	// Get character immediately preceding the current cursor position
	const previousChar = value[selectionStart - 1];
	if (!previousChar) return null;

	// Check if character is isolated at the start of the input (secondPrecedingChar is null) or is isolated by whitespace.
	// If not, return null (eg "hello#world", trigger is not isolated).
	const secondPreviousChar = value[selectionStart - 2];
	const isIsolated = !secondPreviousChar || /\s/.test(secondPreviousChar);
	if (!isIsolated) return null;

	// Determine if previous character is a trigger character
	if (triggers.includes(previousChar)) return previousChar;
	return null;
};

/**
 * Get search value typed after an isolated trigger character.
 *
 * @param inputElement - Input element to get the search value from.
 * @param triggers - An optional array of trigger characters to check against.
 * @returns The search value, or an empty string if no trigger character was typed.
 */
export const getSearchValue = (inputElement: HTMLInputElement, triggers = defaultTriggers): string => {
	const offset = getTriggerOffset(inputElement, triggers);
	if (offset === -1 || !inputElement.selectionStart) return '';
	return inputElement.value.slice(offset + 1, inputElement.selectionStart);
};

/**
 * Get position for the dropdown to be displayed.
 *
 * @param inputElement - Input element to get position from.
 * @param triggers - An optional array of trigger characters to check against.
 * @returns An object containing the x and y coordinates for the dropdown to be displayed.
 */
export const getAnchorRect = (
	inputElement: HTMLInputElement,
	triggers = defaultTriggers,
): { x: number; y: number; height?: number } => {
	const offset = getTriggerOffset(inputElement, triggers);
	if (!offset) return { x: 65, y: 56 }; // Initial position for the dropdown if trigger is typed at the start of the input

	// Get position of the text caret in pixels
	const { left, top, height } = getCaretCoordinates(inputElement, offset + 1);
	const { x, y } = inputElement.getBoundingClientRect();
	return {
		x: left + x - inputElement.scrollLeft,
		y: top + y - inputElement.scrollTop + height + 5, // The value 5 is adding some padding to the top of the dropdown
		height,
	};
};

/**
 * Replace a search value within a string with a display value.
 *
 * @param offset Character offset of the search value
 * @param searchValue Search value to be replaced
 * @param displayValue Value to replace the search value with
 * @param prevValue The previous value
 * @param isTag Whether the display value is a tag or not
 * @returns String with the search value replaced with the display value
 */
export const replaceSubstring = (
	offset: number,
	searchValue: string,
	displayValue: string,
	prevValue: string,
	isTag?: boolean,
) => {
	const nextValue = isTag
		? `${prevValue.slice(0, offset) + displayValue}`
		: `${prevValue.slice(0, offset) + displayValue} ${prevValue.slice(offset + searchValue.length + 1)}`;

	return nextValue;
};

/**
 * Returns a function to replace a search value within a string with a display value.
 * Useful for use with useState.
 *
 * @param offset Character offset of the search value
 * @param searchValue Search value to be replaced
 * @param displayValue Value to replace the search value with
 * @param isTag Whether the display value is a tag or not
 * @returns A function that takes the previous value from useState and returns the next value
 */
export const getReplaceSubstringFn = (
	offset: number,
	searchValue: string,
	displayValue: string,
	isTag?: boolean,
): ((prevValue: string) => string) => {
	return (prevValue: string) => replaceSubstring(offset, searchValue, displayValue, prevValue, isTag);
};
