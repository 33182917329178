/* eslint-disable react/jsx-props-no-spreading */
import React, { SyntheticEvent, useState } from 'react';
import {
	BlockToolbarButton,
	ELEMENT_IMAGE,
	ELEMENT_OL,
	ELEMENT_UL,
	getPluginType,
	RootClassName,
	ToolbarDropdown as Dropdown,
	usePlateEditorState,
} from '@udecode/plate';
import styled from 'styled-components';
import Icon from 'components/icon';
import translateFragmentType from 'components/editor/utils/translateFragmentType';
import transformToList from 'components/editor/transforms/transformToList';
import Tooltip from 'components/tooltip/Tooltip';
import { CustomElement } from 'types/slate';
import transformGeneric from 'components/editor/transforms/transformGeneric';
import { EDITOR_COMMANDS } from '../SlashCommandMenu/Items';
import LinkToolbarButton from './LinkToolbarButton';

const StyledHeader = styled.div`
	font-size: 11px;
	font-weight: 500;
	color: #666;
	padding: 5px 0 5px 8px;
`;

export interface Props {
	selectedBlock?: CustomElement;
}

const ToolbarDropdown = ({ selectedBlock }: Props) => {
	const editor = usePlateEditorState();

	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	if (!selectedBlock || !('type' in selectedBlock)) {
		return <></>;
	}

	const { type } = selectedBlock;
	return (
		<div className="balloon-dropdown">
			<Dropdown
				control={
					<BlockToolbarButton
						type=""
						icon={
							<div className="toolbar-dropdown-toggle">
								<Tooltip tip="Turn into">
									<span>{translateFragmentType((type as string) || null)}</span>
									<Icon type="dropdown-arrow" />
								</Tooltip>
							</div>
						}
					/>
				}
				open={dropdownOpen}
				onOpen={() => {
					setDropdownOpen(!dropdownOpen);
				}}
			>
				<div className="balloon-dropdown-elements">
					<StyledHeader>Turn into</StyledHeader>
					{EDITOR_COMMANDS.map((command) => {
						const elementProps: { type: string; icon: any; classNames?: any } = {
							type: command.data.type,
							classNames: 'block-button',
							icon: (
								<div className="toolbar-label">
									{command.data.icon}
									{command.text}
								</div>
							),
						};

						if (command.data.type === type) {
							elementProps.classNames = {
								root: 'balloon-toolbar',
							} as RootClassName;
						}
						if (command.key === 'link') {
							return <></>;
							/* return (
								<LinkToolbarButton
									key={command.key}
									icon={
										<div className="toolbar-label">
											{command.data.icon}
											{command.text}
										</div>
									}
								/>
							); */
						}
						return (
							<BlockToolbarButton
								key={command.key}
								{...elementProps}
								onMouseDown={(e: SyntheticEvent) => {
									e.stopPropagation();
									e.preventDefault();
									if (command.data.type === ELEMENT_OL || command.data.type === ELEMENT_UL) {
										transformToList(command.data.type, editor);
									} else {
										if (command.data.type === ELEMENT_IMAGE) {
											return;
										}
										transformGeneric(editor, getPluginType(editor, command.data.type));
									}
								}}
							/>
						);
					})}
				</div>
			</Dropdown>
		</div>
	);
};

export default ToolbarDropdown;
