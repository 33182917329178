import { NodeIdPlugin } from '@udecode/plate';
import { nanoid } from 'nanoid';
import { MyPlatePlugin } from '../types/plateTypes';

const nodeIdPlugin: Partial<MyPlatePlugin<NodeIdPlugin>> = {
	options: {
		idCreator: () => `${nanoid()}`,
	},
};

export default nodeIdPlugin;
