import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

export interface SidebarProps {
	children: ReactNode;
	style?: Record<string, any>;
	noBorder?: boolean;
}

const StyledSidebarDiv = styled.div.attrs((props: { noBorder: boolean }) => props)`
	border-right: ${(props) => (props.noBorder ? 0 : `1px solid ${props.theme.colors.gray300}`)};
	height: 100%;
`;

const Sidebar = ({
	children,
	style = { width: '200px', minHeight: '658px' },
	noBorder = false,
}: SidebarProps): ReactElement => {
	return (
		<StyledSidebarDiv style={style} noBorder={noBorder}>
			{children}
		</StyledSidebarDiv>
	);
};

export default Sidebar;
