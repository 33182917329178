/* eslint-disable react/destructuring-assignment */
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import Label from 'components/label';
import Button from 'components/button';
import Tooltip, { TooltipPosition } from 'components/tooltip/Tooltip';
import Link from 'components/link/Link';
import useTagsHooks from 'hooks/useTagsHooks';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import { tylesObjectActions } from 'state/TylesState';

const StyledWrapper = styled.div`
	display: flex;
	flex-flow: column;
	margin-right: 1rem;
`;

const style = {
	overflow: 'hidden',
	WebkitLineClamp: 2,
	WebkitBoxOrient: 'vertical',
	display: '-webkit-box',
	maxWidth: '180px',
	maxHeight: '34px',
	marginBottom: '5px',
	color: 'inherit',
};
const showMoreStyle = {
	maxWidth: '180px',
	maxHeight: '100px',
	overflow: 'scroll',
	marginBottom: '-10px',
	color: 'inherit',
};

export interface Metadata {
	title: string;
	content: string;
	link?: string;
}

export interface Props {
	tyleId: string;
	isCanvas?: boolean;
}

const CreateMetaData = ({ tyleId, isCanvas }: Props) => {
	const [showMore, setShowMore] = useState(false);
	const { getTagsByTyleId } = useTagsHooks();
	const tyle = tylesObjectActions.getTyleWithIdFromState(tyleId);
	const createdAt = tyle?.createdAt && new Date(tyle?.createdAt).toLocaleDateString();
	const updatedAt = tyle?.updatedAt && new Date(tyle?.updatedAt);
	const [tagLabels, setTagLabels] = useState<SelectableItem[]>([]);

	useEffect(() => {
		if (!tyle || !tyle.id) return;
		getTagsByTyleId(tyle.id).then((tags) => {
			setTagLabels(tags.map((tag) => ({ label: tag.label, value: tag.id })));
		});
	}, []);

	const lastEdited =
		updatedAt &&
		`${updatedAt.toLocaleDateString()} at ${updatedAt.toLocaleString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		})}`;
	const metaData: Metadata[] = [{ title: 'Date Created', content: createdAt ?? '' }];

	if (lastEdited) {
		metaData.unshift({ title: 'Last Edited', content: lastEdited ?? createdAt });
	}

	if (tyle?.source?.applicationName) {
		metaData.unshift({ title: 'App', content: tyle.source.applicationName });
	}
	if (tagLabels.length > 0) {
		metaData.unshift({ title: 'Tags', content: tagLabels.map((tag) => tag.label).join(', ') });
	}

	if (tyle?.source?.sourceText) {
		metaData.unshift({ title: 'Source text', content: tyle.source.sourceText });
	}

	if (tyle?.source?.url && tyle.source.title) {
		metaData.unshift({
			title: 'Source URL',
			content: tyle.source.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0],
			link: tyle.source.url,
		});
		metaData.unshift({ title: 'Source title', content: tyle.source.title });
	}

	const createSourceText = (title: string, content: string): ReactElement => {
		return (
			<div key={title} style={{ display: 'flex', width: isCanvas ? '100%' : '300px' }}>
				<Label
					key={title}
					label={title}
					variant="light"
					size="small"
					style={{ whiteSpace: 'nowrap', width: '100px', minWidth: '100px' }}
				/>
				<div style={{ display: 'flex', flexFlow: 'column' }}>
					{title === 'Tags' ? (
						<Tooltip tip={content} open={TooltipPosition.TOP} offset={{ top: 3 }} fontSize="9px">
							<Label
								key={content}
								label={content}
								size="small"
								style={
									showMore
										? { ...showMoreStyle, maxWidth: isCanvas ? '100%' : '180px' }
										: { ...style, maxWidth: isCanvas ? '100%' : '180px' }
								}
							/>
						</Tooltip>
					) : (
						<Label
							key={content}
							label={content}
							size="small"
							style={
								showMore
									? { ...showMoreStyle, maxWidth: isCanvas ? '100%' : '180px' }
									: { ...style, maxWidth: isCanvas ? '100%' : '180px' }
							}
						/>
					)}
					{content.length > 60 && title === 'Source text' && (
						<Button
							onEvent={() => {
								setShowMore(!showMore);
							}}
							style={{ fontSize: '11px', color: '#ababab', padding: '0 0 10px 0' }}
							variant="basic"
							label={showMore ? 'Show less' : 'Show more'}
						/>
					)}
				</div>
			</div>
		);
	};
	return (
		<div style={{ display: 'flex', flexFlow: 'row' }}>
			<StyledWrapper>
				{metaData.map((data) => {
					if (data.title === 'Source text' || data.title === 'Tags') {
						return createSourceText(data.title, data.content);
					}
					return (
						<div key={data.title} style={{ display: 'flex', width: isCanvas ? '100%' : '300px' }}>
							<Label
								key={data.title}
								label={data.title}
								variant="light"
								size="small"
								style={{ whiteSpace: 'nowrap', width: '100px', minWidth: '100px' }}
							/>
							{data.link ? (
								<Tooltip tip={data.link} open={TooltipPosition.TOP} offset={{ top: 3 }} maxWidth="240px" fontSize="9px">
									<Link href={data.link} target="_blank" style={style}>
										<Label key={data.content} label={data.content} size="small" style={style} />
									</Link>
									<svg
										style={{ verticalAlign: 'middle', marginTop: '3px' }}
										width="9"
										height="9"
										viewBox="0 0 9 9"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.00006 3.53025L2.77244 6.75788L2.24219 6.22763L5.46944 3H2.62506V2.25H6.75006V6.375H6.00006V3.53025V3.53025Z"
											fill="#666666"
										/>
									</svg>
								</Tooltip>
							) : (
								<Label
									key={data.content}
									label={data.content}
									size="small"
									style={{ ...style, maxWidth: isCanvas ? '100%' : '180px' }}
								/>
							)}
						</div>
					);
				})}
			</StyledWrapper>
		</div>
	);
};

export default CreateMetaData;
