/* eslint-disable react/no-unstable-nested-components */
import React, { ReactElement, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import './Tooltip.scss';
import { useSnapshot } from 'valtio';
import { TooltipPosition } from './Tooltip';
import sharedState from '../../state/SharedState';

export interface Offset {
	top?: number;
	right?: number;
}

export interface TooltipInitializationProps {
	position?: TooltipPosition;
	id: string;
	offset?: Offset;
	fontSize?: string;
	maxWidth?: string;
}

const TooltipInitialization = ({
	position,
	id,
	offset,
	fontSize,
	maxWidth,
}: TooltipInitializationProps): ReactElement => {
	const { isDropdownOpen } = useSnapshot(sharedState);
	const [show, setShow] = useState(false);
	const topCss = offset?.top ? `margin-top: ${offset.top}px !important;` : '';
	const rightCss = offset?.right ? `margin-right: ${offset.right}px !important;` : '';
	const fontSizeCss = fontSize ? `font-size: ${fontSize};` : '';
	const maxWidthCss = maxWidth
		? `max-width: ${maxWidth};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      `
		: '';
	const generatedCss = `.custom-${id} {
    ${topCss}
    ${rightCss}
    ${fontSizeCss}
    ${maxWidthCss}
    }`;

	useEffect(() => {
		setShow(isDropdownOpen);
	}, [isDropdownOpen]);
	return (
		<>
			<style>{generatedCss}</style>
			<ReactTooltip
				id={id}
				scrollHide
				resizeHide
				clickable={false}
				place={position}
				disable={show}
				globalEventOff="click"
				effect="solid"
				getContent={(dataTip) => <>{dataTip}</>}
				className={classNames({
					'kum-tooltip': true,
					top: !offset && position === TooltipPosition.TOP,
					bottom: !offset && position === TooltipPosition.BOTTOM,
					[`custom-${id}`]: offset?.top || offset?.right,
				})}
			/>
		</>
	);
};

export default TooltipInitialization;
