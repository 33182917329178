import { AutoformatPlugin } from '@udecode/plate';
import { MyEditor, MyPlatePlugin, MyValue } from '../../types/plateTypes';
import autoformatRules from './autoformatRules';

const autoformatPlugin: Partial<MyPlatePlugin<AutoformatPlugin<MyValue, MyEditor>>> = {
	options: {
		rules: autoformatRules as any,
		enableUndoOnDelete: true,
	},
};

export default autoformatPlugin;
