/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import Headline from 'components/headline';
import Paragraph from 'components/paragraph';
import Button from 'components/button';
import Spacing from 'components/spacing';
import checkmark from 'styles/animations/checkmark-anim.json';
import IPCChannel from '../../model/IPC/IPCChannel';
import useAnalytics, { TrackingEvent } from '../../hooks/useAnalytics';

export interface Props {
	index: number;
	showModal: boolean;
	handleClick: (percent: number, nextIndex: number) => void;
	buttonActive: boolean;
	openModal: () => void;
	openLearnWhyModal: (type: 'screen-recording' | 'accessibility') => void;
	openErrorModal: (type: 'accessibility' | 'screen-recording') => void;
	openSkipPermissionsModal: (continueClicked: boolean) => void;
	loadSupport: (load: boolean) => void;
}

const Content = ({
	index,
	showModal,
	handleClick,
	buttonActive,
	openModal,
	openLearnWhyModal,
	openErrorModal,
	openSkipPermissionsModal,
	loadSupport,
}: Props) => {
	const [header, setHeader] = useState<string>('');
	const [paragraph, setParagraph] = useState<string | JSX.Element>();
	const [buttonContent, setButtonContent] = useState<string>('');
	const [percent, setPercent] = useState<number>(0);
	const [show, setShow] = useState(showModal);
	const [openPermissions, setOpenPermissions] = useState<null | IPCChannel>(null);
	const [accessibilityClicked, setAccessibilityClicked] = useState<boolean>(false);
	const [screenRecordingClicked, setScreenRecordingClicked] = useState<boolean>(false);
	const [accessibilityStatus, setAccessibilityStatus] = useState<boolean>();
	const [screenRecordingStatus, setScreenRecordingStatus] = useState<boolean>();
	const [showSkip, setShowSkip] = useState(false);
	const [showLearnWhyScreenRecording, setShowLearnWhyScreenRecording] = useState(false);
	const [showLearnWhyAccessibility, setShowLearnWhyAccessibility] = useState(false);
	const { track } = useAnalytics();

	useEffect(() => {
		if (accessibilityStatus) {
			handleClick(percent, index + 1);
			setAccessibilityStatus(false);
			setOpenPermissions(null);
		}
		if (screenRecordingStatus) {
			handleClick(percent, index + 1);
			setScreenRecordingStatus(false);
			setOpenPermissions(null);
		}
	}, [accessibilityStatus, screenRecordingStatus]);

	const onClick = () => {
		// Track signup when permissions screen begins
		if (index === 0) {
			track(TrackingEvent.SIGNUP);
		}
		if (index === 1) {
			if (!accessibilityClicked) {
				setOpenPermissions(IPCChannel.OPEN_ACCESSIBILITY_PERMISSION);
				setAccessibilityClicked(true);
				setHeader('Please give access to continue');
				setParagraph(
					<div>
						<div style={{ color: '#EDEDED', padding: '10px 0' }}>
							(1/2) Enable Accessibility <br />
						</div>
						Tyles needs to access to your computer’s Accessibility features to work properly
					</div>,
				);
				setShowLearnWhyAccessibility(false);
				setPercent(67);
				setButtonContent('Continue');
			} else {
				if (!window.ipc) {
					return;
				}
				if (process.env.NODE_ENV === 'development') {
					handleClick(percent, index + 1);
					return;
				}

				window.ipc.invoke(IPCChannel.REPLY_ACCESSIBILITY_PERMISSION).then((result: string) => {
					if (result === 'authorized') {
						handleClick(percent, index + 1);
						return;
					}
					openErrorModal('accessibility');
				});
			}
			return;
		}
		if (index === 2) {
			if (!screenRecordingClicked) {
				setOpenPermissions(IPCChannel.OPEN_SCREEN_RECORDING_PERMISSION);
				setScreenRecordingClicked(true);
				setHeader('Please give access to continue');
				setParagraph(
					<div>
						<div style={{ color: '#EDEDED', padding: '10px 0' }}>
							(2/2) Screen Recording <br />
						</div>
						Tyles needs to access to your computer’s Screen Recording features to work properly.
					</div>,
				);
				setShowLearnWhyScreenRecording(false);
				setButtonContent('Continue');
			} else {
				if (!window.ipc) {
					return;
				}
				if (process.env.NODE_ENV === 'development') {
					handleClick(percent, index + 1);
					return;
				}

				window.ipc.invoke(IPCChannel.REPLY_SCREEN_RECORDING_PERMISSION).then((result: string) => {
					if (result === 'authorized') {
						handleClick(percent, index + 1);
						return;
					}
					openErrorModal('screen-recording');
				});
			}
			return;
		}
		handleClick(percent, index + 1);
	};

	useEffect(() => {
		if (openPermissions !== null && window.ipc) {
			window.ipc.send(openPermissions, 'open');
		}
	}, [openPermissions]);

	useEffect(() => {
		if (show === showModal) return;
		setShow(!show);
	}, [showModal]);

	useEffect(() => {
		setShowLearnWhyAccessibility(false);
		setShowLearnWhyScreenRecording(false);
		switch (index) {
			case 0:
				setHeader('Welcome to Tyles');
				setParagraph(
					<span style={{ color: '#AAAAAA' }}>
						Setting up Tyles takes <span style={{ color: 'white' }}>less than 2 minutes. </span>Firstly, tell us a bit
						about yourself.{' '}
					</span>,
				);
				setButtonContent('Continue');
				setPercent(35);
				break;
			case 1:
				setHeader("Let's get started");
				setParagraph(
					<div>
						<div style={{ color: '#EDEDED', padding: '10px 0' }}>
							(1/2) Enable Accessibility <br />
						</div>
						Tyles needs to access to your computer’s Accessibility features to work properly.
					</div>,
				);
				setButtonContent('Grant Access');
				setShowSkip(false);
				setShowLearnWhyAccessibility(true);
				setShowLearnWhyScreenRecording(false);
				setPercent(50);
				break;
			case 2:
				setHeader("Let's get started");
				setParagraph(
					<div>
						<div style={{ color: '#EDEDED', padding: '10px 0' }}>
							(2/2) Enable Screen Recording <br />
						</div>
						Tyles needs to access to your computer’s Screen Recording features to work properly.
					</div>,
				);
				setButtonContent('Grant Access');
				setShowSkip(false);
				setShowLearnWhyScreenRecording(true);
				setShowLearnWhyAccessibility(false);
				setPercent(67);
				break;
			case 3:
				if (process.env.NODE_ENV === 'development') {
					window.location.href = '/';
					return;
				}
				if (window.ipc) {
					window.ipc.send(IPCChannel.APP_RELOAD, 'reload');
				}
				loadSupport(true);
				break;

			default:
				break;
		}
	}, [index]);

	const renderButton = () => {
		return (
			<Button
				style={{ backgroundColor: '#343434', border: 'none', color: '#EDEDED' }}
				label={buttonContent}
				onEvent={() => onClick()}
				disabled={buttonActive}
			/>
		);
	};

	const renderSkipButton = () => {
		return (
			<Button
				variant="basic"
				label="Skip"
				style={{ border: 'none', color: '#EDEDED', fontSize: '14px' }}
				onEvent={() => openSkipPermissionsModal(false)}
			/>
		);
	};

	const renderLearnWhyButton = (type: 'screen-recording' | 'accessibility') => {
		return (
			<Button
				variant="basic"
				label="Learn why"
				style={{ border: 'none', color: '#EDEDED', fontSize: '14px' }}
				onEvent={() => openLearnWhyModal(type)}
			/>
		);
	};

	return (
		<>
			<Headline variant="h2" style={{ color: '#EDEDED' }}>
				{header}
			</Headline>
			<Spacing size="small" />
			<Paragraph style={{ color: '#AAAAAA' }}>{paragraph}</Paragraph>
			{header === 'Success' && (
				<div style={{ width: '45px', height: '35px', transform: 'scale(1)' }}>
					<Lottie animationData={checkmark} loop={false} />
				</div>
			)}
			<Spacing size="extra-small" />
			{renderButton()}
			{showSkip && renderSkipButton()}
			{showLearnWhyScreenRecording && renderLearnWhyButton('screen-recording')}
			{showLearnWhyAccessibility && renderLearnWhyButton('accessibility')}
		</>
	);
};

export default Content;
