import React, { ReactElement } from 'react';
import { useSnapshot } from 'valtio';
import userState from '../state/UserState';
import Input from '../components/input';
import Label from '../components/label';
import Headline from '../components/headline';
import Divider from '../components/divider';
import FormGroup from '../components/form-group';
import Paragraph from '../components/paragraph';
import Button from '../components/button';

interface AccountSettingsProps {
	onDeleteAccountClick: (account: AccountAttribute) => void;
	onSubmit: () => void;
}

export interface AccountAttribute {
	firstName?: string;
	lastName?: string;
	email?: string;
	temporaryEmail?: string;
}

const AccountSettings = ({ onDeleteAccountClick, onSubmit }: AccountSettingsProps): ReactElement => {
	const { user } = useSnapshot(userState);

	const handleChange = () => {
		userState.isEditing = true;
		userState.changeEmailError = '';
	};

	return (
		<>
			<Headline style={{ marginTop: 0 }}>My Account</Headline>
			<Divider />
			<FormGroup label="Personal Information">
				<Input
					label="First Name"
					value={user.firstName || ''}
					onChange={(newFirstName) => {
						userState.user.firstName = newFirstName;
						handleChange();
					}}
					onSubmit={onSubmit}
				/>
				<Input
					label="Last Name"
					value={user.lastName || ''}
					onChange={(newLastName) => {
						userState.user.lastName = newLastName;
						handleChange();
					}}
					onSubmit={onSubmit}
				/>
				<Input
					label="Email"
					value={user.email || ''}
					onChange={(newEmail) => {
						userState.user.email = newEmail;
						handleChange();
					}}
					onSubmit={onSubmit}
				/>
				{user.temporaryEmail && (
					<Label label={`Please verify your new email: ${user.temporaryEmail}`} variant="light" size="small" />
				)}
			</FormGroup>
			<Divider />
			<FormGroup label="Account">
				<Paragraph>Joined 2 months ago</Paragraph>
				<Button label="Delete account" onEvent={onDeleteAccountClick} variant="danger" />
			</FormGroup>
		</>
	);
};

export default AccountSettings;
