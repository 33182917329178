import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledTyleWindowContent = styled(motion.div).attrs((props: { columns: number }) => props)`
	position: relative;
	height: 100%;
	overflow-y: scroll;
	background-color: ${({ theme }) => theme?.colors?.background};
	border-bottom-left-radius: ${({ theme }) => theme?.radius?.big};
	border-bottom-right-radius: ${({ theme }) => theme?.radius?.big};
	padding: ${({ columns }) => (columns === 1 ? '45px 248px' : '45px 54px 45px 0px;')};

	@media (max-width: 1024px) {
		padding: 45px 54px 45px 0px;
	}
`;
export const StyledLinksBacklinksViewHeader = styled.div`
	display: flex;
	flex-flow: column;
	margin-bottom: 30px;
`;

export const StyledLinksBacklinksView = styled.div`
	height: 100%;
	overflow-y: scroll;
	padding-left: 45px;
`;

export default StyledTyleWindowContent;
