import { CSSProperties, ReactElement } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { Sparkles } from '@styled-icons/ionicons-sharp/Sparkles';
import { TyleInterface } from 'interfaces/TyleInterface';
import { tylesObjectActions } from 'state/TylesState';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import useElectronEvents from 'helpers/useElectronEvents';
import IPCChannel from 'model/IPC/IPCChannel';
import { emptyTyle } from 'state/__fixtures__/initialTerminalState';
import useFastCapture from 'hooks/useFastCapture';
import { NotificationMessage, NotificationTypeEnum } from 'helpers/useGetNotificationFromElectron';
import CapturedContent from 'components/FastCapture/components/CapturedContent';
import Section from 'components/FastCapture/components/Section';
import TagSection from 'components/FastCapture/components/TagSection';
import AutocompleteDropdownContainer from 'containers/AutocompleteDropdownContainer';
import BarLoader from 'react-spinners/BarLoader';
import light from 'themes/light';

const override: CSSProperties = {
	position: 'relative',
	margin: '12px 0',
	left: '25%',
	opacity: '0.2',
};

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: ${(props) => props.theme.radius?.big};
	background: ${(props) => props.theme.colors?.clipperBackground};
	backdrop-filter: blur(10px);
	gap: 6px;
	padding: 6px;
`;

export interface FastCaptureProps {
	tyle: TyleInterface;
	onCloseDesktop: (notificationMessage: NotificationMessage) => void;
}

const FastCaptureContainer = ({ tyle, onCloseDesktop }: FastCaptureProps): ReactElement => {
	const { sendMessage } = useElectronEvents();
	const {
		tyleToSave,
		tagItems,
		isTagLoading,
		placeholder,
		success,
		defaultSelectedItem,
		creationStatus,
		tagErrorMessage,
		addHighlight,
		addHighlightToNewTyle,
	} = useFastCapture(tyle);

	const onNewOption = async (title: string) => {
		sendMessage({ subject: IPCChannel.RESIZE_FASTCAPTURE, message: '-8' });
		if (tyleToSave) {
			await addHighlightToNewTyle({ ...emptyTyle, title });
			onCloseDesktop({ id: nanoid(), type: NotificationTypeEnum.CREATE_NEW, message: title || '' });
		}
	};

	const onSubmit = async (item: SelectableItem) => {
		sendMessage({ subject: IPCChannel.RESIZE_FASTCAPTURE, message: '-8' });
		if (tyleToSave) {
			const destinationTyle = tylesObjectActions.getTyleWithIdFromState(item.value);
			await addHighlight(destinationTyle);
			onCloseDesktop({ id: nanoid(), type: NotificationTypeEnum.ADD_TO, message: item.label });
		}
	};

	return (
		<StyledContainer>
			<>
				<Section header="Captured" content={<CapturedContent tyle={tyle} />} />
				<Section
					header="Tags"
					icon={<Sparkles size={14} />}
					content={
						<TagSection tyle={tyle} tagItems={tagItems} isTagLoading={isTagLoading} tagErrorMessage={tagErrorMessage} />
					}
				/>
				{tyleToSave ? (
					<Section
						content={
							<AutocompleteDropdownContainer
								key={tyle.id}
								tyleId={tyleToSave?.id}
								placeholder={placeholder}
								defaultItem={defaultSelectedItem}
								onSubmit={onSubmit}
								onSelect={() => {}}
								onNewOptionClick={onNewOption}
								onChange={() => {}}
								creationStatus={creationStatus}
							/>
						}
					/>
				) : (
					<Section
						content={
							<BarLoader
								color={light.colors.tagText}
								cssOverride={override}
								loading
								aria-label="Loading Spinner"
								data-testid="loader"
							/>
						}
					/>
				)}
			</>
		</StyledContainer>
	);
};

export default FastCaptureContainer;
