import addProtocolIfNeeded from 'helpers/addProtocolIfNeeded';
import { SourceInterface } from 'interfaces/SourceInterface';
import { SourceTyleCardInterface } from 'interfaces/SearchSidebarInterface';

/**
 * Get the source of a Tyle to render in the Tyle Card into the sidebar
 * @param source
 */
const getTyleCardSource = (source: SourceInterface): SourceTyleCardInterface | null => {
	if (!source.url || !source.applicationName) {
		return null;
	}

	// Ensure the URL has the correct protocol
	const fixedUrl = addProtocolIfNeeded(source.url);
	const domain = new URL(fixedUrl)?.hostname;
	const hostName = new URL(fixedUrl)?.hostname?.replace('www.', '');
	return {
		url: fixedUrl,
		hostName: hostName || source.applicationName,
		favicon: domain ? `https://www.google.com/s2/favicons?domain=${domain}` : '',
	};
};

export default getTyleCardSource;
