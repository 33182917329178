/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Source } from '../models/Source';
import { Tag } from '../models/Tag';
import { TyleConnection } from '../models/TyleConnection';
import { HttpFile } from '../http/http';

export class Tyle {
    'id'?: string;
    'createdAt'?: any;
    'updatedAt'?: any;
    'source'?: Source;
    'sourceId'?: string;
    'title'?: string;
    'content'?: Array<any>;
    'link'?: Array<TyleConnection>;
    'backlink'?: Array<TyleConnection>;
    'previousInstance'?: Tyle;
    'derivedInstances'?: Array<Tyle>;
    'inbox'?: boolean;
    'favorite'?: boolean;
    'deleted'?: boolean;
    'tags'?: Array<Tag>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "any",
            "format": ""
        },
        {
            "name": "source",
            "baseName": "source",
            "type": "Source",
            "format": ""
        },
        {
            "name": "sourceId",
            "baseName": "sourceId",
            "type": "string",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "Array<any>",
            "format": ""
        },
        {
            "name": "link",
            "baseName": "link",
            "type": "Array<TyleConnection>",
            "format": ""
        },
        {
            "name": "backlink",
            "baseName": "backlink",
            "type": "Array<TyleConnection>",
            "format": ""
        },
        {
            "name": "previousInstance",
            "baseName": "previousInstance",
            "type": "Tyle",
            "format": ""
        },
        {
            "name": "derivedInstances",
            "baseName": "derivedInstances",
            "type": "Array<Tyle>",
            "format": ""
        },
        {
            "name": "inbox",
            "baseName": "inbox",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "favorite",
            "baseName": "favorite",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "tags",
            "baseName": "tags",
            "type": "Array<Tag>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Tyle.attributeTypeMap;
    }

    public constructor() {
    }
}

