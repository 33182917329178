import { SelectableItem } from './DropdownItem/DropdownItem';

export const measureInputWidth = (text: string) => {
	const context = document.createElement('canvas').getContext('2d');
	if (context) {
		context.font = '14px system-ui';
		return context.measureText(text).width;
	}
	return 0;
};

export const getNewSize = (value: string, items: SelectableItem[]) => {
	if (!value) return 0;
	if (items.length > 1) return 90;
	if (items.length === 1) return 63;
	if (items.length === 0) return 30;
	return 0;
};
