import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { ELEMENT_BLOCKQUOTE, ELEMENT_PARAGRAPH } from '@udecode/plate';
import _ from 'lodash';
import useElectronDataExchange, { DataFromElectronInterface } from './useElectronDataExchange';
import { TyleInterface } from '../interfaces/TyleInterface';
import { Tyle } from '../models/Tyle';
import truncateStringWithEllipsis from './truncateStringWithEllipsis';
import addProtocolIfNeeded from './addProtocolIfNeeded';

interface TyleFromElectronInterface {
	tyle: TyleInterface | null;
}

const useGetTyleFromElectron = (): TyleFromElectronInterface => {
	const { dataFromElectron } = useElectronDataExchange();
	const [tyle, setTyle] = useState<TyleInterface | null>(null);
	const previousData = useRef<DataFromElectronInterface | null>(null);

	useEffect(() => {
		if (!dataFromElectron) {
			setTyle(null);
			return;
		}
		if (_.isEqual(dataFromElectron, previousData.current)) {
			return;
		}
		const createdAt = new Date();

		previousData.current = dataFromElectron;

		const { source: dataSource, content: text } = dataFromElectron;
		const source = dataSource
			? {
					title: dataSource.title,
					url: 'url' in dataSource && dataSource.url ? addProtocolIfNeeded(dataSource.url) : undefined,
					sourceText: text,
					applicationName: dataSource.owner.name,
					createdAt,
			  }
			: undefined;

		const tyleObject = new Tyle({
			id: nanoid(),
			title: (source?.title && source?.sourceText) || source?.title || '',
			content: [
				{
					type: ELEMENT_BLOCKQUOTE,
					children: [{ text: text || '' }],
				},
				{
					type: ELEMENT_PARAGRAPH,
					children: [{ text: '' }],
				},
			],
			source,
			createdAt,
			deleted: false,
		});

		setTyle(tyleObject);
	}, [dataFromElectron]);

	return { tyle };
};

export default useGetTyleFromElectron;
