import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
	display: flex;
	flex-flow: column;
	padding-left: 45px;
`;

export const StyledEditorView = styled.div`
	height: 100%;
	overflow-y: scroll;
`;

export const StyledEditorViewPreviewSection = styled.div`
	padding: 0.2rem 12px;
`;
