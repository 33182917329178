/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useSnapshot, subscribe } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { debounce } from 'lodash';
import tyleWindowHelper from 'containers/TyleWindow/helpers';
import TyleWindowHeader from 'containers/TyleWindow/TyleWindowHeader';
import TyleWindowContent from 'containers/TyleWindow/TyleWindowContent';
import tylesState from 'state/TylesState';
import { TyleActionEnum, TyleInterface } from 'interfaces/TyleInterface';
import { TyleWindowActionEnum, TyleWindowViews } from 'interfaces/TyleWindowInterface';
import useTyleHooks from 'hooks/useTyleHooks';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import newCanvasState, { newCanvasStateService } from 'state/NewCanvas';
import truncateString from 'helpers/truncateString';
import DeleteConfirmationModal from 'containers/DeleteConfirmationModal';
import canvasState from 'state/CanvasState';
import useCanvasHooks from 'hooks/useCanvasHooks';
import TyleCloseButton from 'components/TyleWindow/TyleCloseButton';
import sharedState from 'state/SharedState';
import useSharedHooks from 'hooks/useSharedHooks';
import { getSelectedWindowId } from 'state/helpers';
import { ColumnContainer, TyleWindowContainer } from './HorizontalCanvas.styled';
import { isElementInViewport } from './helpers';

export interface ColumnProps {
	windowId: string;
	index: number;
	columns?: number;
	createWindowForTyle: (tyle: TyleInterface) => void;
}

const Column = ({ windowId, index, columns = 2, createWindowForTyle }: ColumnProps) => {
	const [tyle, setTyle] = useState<TyleInterface>();
	const [currentView, setCurrentView] = useState<TyleWindowViews>(TyleWindowViews.EDITOR);
	const [showDeleteTyleModal, setShowDeleteTyleModal] = useState(false);
	const [showCloseButton, setShowCloseButton] = useState<boolean>(false);
	const { updateTyle, deleteTyle, getTyle } = useTyleHooks();
	const { navigateBack, navigateForward, updateScrollTopForWindow } = useCanvasHooks();
	const { removeWindowFromUserCanvasTyles } = useSharedHooks();
	const { changeView } = tyleWindowHelper();
	const { isPublicView } = useSnapshot(canvasState);
	const [selected, setSelected] = useState(newCanvasStateService.isWindowSelected(windowId));

	const debouncedTyleUpdate = useCallback(
		debounce((updatedTyle: TyleInterface) => {
			updateTyle(updatedTyle).then((updatedTyleFromBackend: TyleInterface) => {
				setTyle(updatedTyleFromBackend);
			});
		}, 1000),
		[],
	);

	useEffect(() => {
		if (!tyle?.id) {
			return () => {};
		}
		/**
		 *  When the user focus the app in the browser tab,
		 *  force re-rendering of the tyle window to update the content in the editor
		 */
		const unsubscribeFromAppFocus = subscribeKey(sharedState, 'appFocused', () => {
			if (!tyle?.id) {
				return;
			}
			getTyle(tyle.id).then((tyleFromBackend: TyleInterface) => {
				if (!tyleFromBackend || tyleFromBackend?.deleted) {
					removeWindowFromUserCanvasTyles(windowId);
					return;
				}
				setTyle(tyleFromBackend);
			});
		});

		const unsubscribeFromTylesState = subscribeKey(tylesState.tylesObjects, tyle?.id, (cachedTyle) => {
			if (!cachedTyle?.id) {
				return;
			}
			setTyle(cachedTyle);
		});

		return () => {
			unsubscribeFromAppFocus();
			unsubscribeFromTylesState();
		};
	}, [tyle?.id, windowId]);

	// Subscribe to canvas state to monitor for window updates
	useEffect(() => {
		const unsubscribeUserCanvasTyles = subscribe(newCanvasState.userCanvasTyles, () => {
			if (!newCanvasState.userCanvasTyles.windows[windowId]) {
				return;
			}
			const { tyleId } = newCanvasState.userCanvasTyles.windows[windowId];
			if (!tyleId) {
				return;
			}
			if (tyleId !== tyle?.id) {
				getTyle(tyleId).then((tyleFromBackend: TyleInterface) => {
					setTyle(tyleFromBackend);
				});
			}
			setSelected(getSelectedWindowId(newCanvasState.userCanvasTyles) === windowId);
		});

		return () => {
			unsubscribeUserCanvasTyles();
		};
	}, []);

	useEffect(() => {
		const { tyleId } = newCanvasState.userCanvasTyles.windows[windowId];
		// When the tyle is not yet in the state, get tyle from backend
		if (!tyleId) {
			return;
		}
		// Set tyle at initialization
		if (!tylesState.tylesObjects[tyleId]) {
			getTyle(tyleId)
				.then((tyleFromBackend: TyleInterface) => {
					setTyle(tyleFromBackend);
				})
				.catch(() => {
					// Remove tyle from local storage and canvas state if it does not exist in the backend
					removeWindowFromUserCanvasTyles(windowId);
				});
		} else {
			setTyle(tylesState.tylesObjects[tyleId]);
		}
	}, [windowId, setTyle]);

	const threeDotsMenuSelectOption = (option: PopoverMenuItemInterface) => {
		if (!tyle?.id) {
			return;
		}
		switch (option.action) {
			case TyleActionEnum.FAVORITE_ADD:
				if (!tyle) {
					return;
				}
				updateTyle({
					...tyle,
					favorite: true,
				}).then((tyleUpdated) => setTyle(tyleUpdated));
				break;

			case TyleActionEnum.FAVORITE_REMOVE:
				if (!tyle) {
					return;
				}
				updateTyle({ ...tyle, favorite: false }).then((tyleUpdated) => setTyle(tyleUpdated));
				break;
			case TyleActionEnum.DELETE:
				setShowDeleteTyleModal(true);
				break;
			default:
				throw new Error(`Invalid Tyle action type: ${option.action}`);
		}
	};

	const onTitleChangeHandler = useCallback(
		(titleChanged: string) => {
			if (!tyle?.id) {
				return;
			}
			debouncedTyleUpdate({ ...tyle, title: titleChanged });
		},
		[tyle, debouncedTyleUpdate],
	);

	const handleClick = useCallback(
		(event: any) => {
			if (!isElementInViewport(event.target)) {
				event.target.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
			if (newCanvasState.selectedWindowId !== windowId) {
				newCanvasStateService.selectWindow(windowId);
			}
		},
		[windowId, newCanvasStateService, isElementInViewport],
	);

	const handleDeleteTyle = useCallback(() => {
		if (!tyle?.id) {
			return;
		}
		deleteTyle({ tyleId: tyle.id, windowId }).then(() => {
			setShowDeleteTyleModal(false);
		});
	}, [tyle, deleteTyle, windowId]);

	const handleBackNavigation = useCallback(() => {
		navigateBack({ windowId });
		setCurrentView(TyleWindowViews.EDITOR);
	}, [windowId, navigateBack, setCurrentView]);

	const handleForwardNavigation = useCallback(() => {
		navigateForward({ windowId });
		setCurrentView(TyleWindowViews.EDITOR);
	}, [windowId, navigateForward, setCurrentView]);

	const handleChangeView = useCallback(
		(action: TyleWindowActionEnum) => {
			setCurrentView(changeView(action, currentView));
		},
		[setCurrentView, currentView, changeView],
	);

	const handleScrollUpdate = useCallback(
		(newScrollTop: number) => {
			updateScrollTopForWindow({ windowId, scrollTop: newScrollTop });
		},
		[windowId, updateScrollTopForWindow],
	);

	if (!tyle?.id) {
		return null;
	}

	return (
		<>
			{showDeleteTyleModal && tyle?.id && (
				<DeleteConfirmationModal
					show={!!tyle?.id}
					onClose={() => setShowDeleteTyleModal(false)}
					onSubmit={handleDeleteTyle}
					headline={`Delete Tyle "${truncateString(tyle?.title, 34)}"`}
					content={`Are you sure you want to delete the Tyle "${truncateString(
						tyle?.title,
						tyle?.backlink?.length && tyle?.backlink?.length > 0 ? 13 : 45,
					)}"? ${
						tyle?.backlink?.length && tyle?.backlink?.length > 0
							? `This action will affect ${tyle.backlink.length} Tyles and action can’t be undone`
							: 'This action can’t be undone.'
					}`}
				/>
			)}

			<div style={{ position: 'relative' }}>
				<Draggable draggableId={windowId} index={index}>
					{(provided, snapshot) => (
						<ColumnContainer
							id={`${windowId}`}
							ref={provided.innerRef}
							{...provided.draggableProps}
							onClick={handleClick}
						>
							{tyle && (
								<TyleWindowContainer
									onMouseEnter={() => setShowCloseButton(true)}
									onMouseLeave={() => setShowCloseButton(false)}
									columns={columns}
								>
									<TyleCloseButton
										show={!isPublicView && !snapshot.isDragging && showCloseButton}
										handleClose={() => removeWindowFromUserCanvasTyles(windowId)}
									/>

									<TyleWindowHeader
										selected={selected}
										windowId={windowId}
										showNavigation={newCanvasState.userCanvasTyles.windows[windowId].queue?.length > 1}
										isDragging={snapshot.isDragging}
										dragHandleProps={provided.dragHandleProps}
										tyle={tyle}
										view={currentView}
										onThreeDotsMenuSelect={threeDotsMenuSelectOption}
										onChangeView={handleChangeView}
										navigateBack={handleBackNavigation}
										navigateForward={handleForwardNavigation}
									/>

									<TyleWindowContent
										columns={columns}
										tyle={tyle}
										view={currentView}
										windowId={windowId}
										scrollTop={newCanvasState.userCanvasTyles.windows[windowId].scrollTop}
										onTitleChange={onTitleChangeHandler}
										createWindowForTyle={createWindowForTyle}
										updateScrollTop={handleScrollUpdate}
										changeView={handleChangeView}
									/>
								</TyleWindowContainer>
							)}
						</ColumnContainer>
					)}
				</Draggable>
			</div>
		</>
	);
};

export default Column;
