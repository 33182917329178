import { useContext } from 'react';
import { ApiContext } from 'components/App';
import { UserInterface } from 'interfaces/UserInterface';
import userState from 'state/UserState';
import useAnalytics, { TrackingEvent } from './useAnalytics';

const useUserHooks = () => {
	const api = useContext(ApiContext);
	const { track } = useAnalytics();

	const getUserAccount = (): Promise<UserInterface> => {
		return api.getUserAccount().then((userAccount) => {
			userState.user = userAccount;
			return userAccount;
		});
	};

	const updateUserAccount = (user: UserInterface): Promise<UserInterface> => {
		track(TrackingEvent.UPDATE_USER_ACCOUNT);

		return api
			.updateUserAccount({
				...user,
			})
			.then((userAccount) => {
				userState.user = userAccount;
				return userAccount;
			});
	};

	return {
		getUserAccount,
		updateUserAccount,
	};
};

export default useUserHooks;
