import { useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import { nanoid } from 'nanoid';
import PaginationDots from 'components/PaginationDots';

const fadeInAnimation = keyframes`${fadeIn}`;

const FadeInDiv = styled.div`
	animation: 2s ${fadeInAnimation};
`;

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
`;

export interface Props {
	slides: JSX.Element[];
	loop?: boolean;
	duration?: number;
	style?: Record<string, any>;
	updateActiveIndex?: (index: number) => void;
	currentIndex?: number;
}

const CustomCarousel = ({ slides, loop, duration, style, updateActiveIndex, currentIndex }: Props) => {
	const [indicators, setIndicators] = useState<number[]>([]);
	const [activeIndex, setActiveIndex] = useState(currentIndex || 0);

	const handleUpdateIndex = useCallback(
		(index: number) => {
			setActiveIndex(index);
			if (updateActiveIndex) updateActiveIndex(index);
		},
		[activeIndex],
	);

	useEffect(() => {
		setActiveIndex(currentIndex || 0);
	}, [currentIndex]);

	useEffect(() => {
		setIndicators(Array.from(Array(slides.length).keys()));
	}, [activeIndex, slides]);
	return (
		<Wrapper>
			<Carousel
				style={style}
				activeIndex={activeIndex}
				indicators
				nextLabel=""
				prevLabel=""
				slide={false}
				controls={false}
			>
				{slides.map((content) => {
					return (
						<Carousel.Item key={nanoid()}>
							<FadeInDiv>{content}</FadeInDiv>
						</Carousel.Item>
					);
				})}
			</Carousel>
			<PaginationDots
				items={indicators}
				loop={loop}
				duration={duration}
				setActiveIndex={handleUpdateIndex}
				currentIndex={activeIndex}
			/>
		</Wrapper>
	);
};

export default CustomCarousel;
