import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import './FormGroup.scss';

export interface FormGroupProps {
	children: any;
	label: string;
}

const FormGroup = ({ children, label }: FormGroupProps): ReactElement => {
	return (
		<div className="kum-form-group">
			<div className="label">{label}</div>
			<Form.Group>{children}</Form.Group>
		</div>
	);
};

export default FormGroup;
