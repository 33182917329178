import { Space as ApiSpace } from '../api/generated/client';
import adaptSpace from './adaptSpace';
import { SpaceInterface } from '../interfaces/SpaceInterface';

const adaptSpaces = (spaces: Array<ApiSpace>): Array<SpaceInterface> => {
	return spaces.map((space) => {
		return adaptSpace(space);
	});
};

export default adaptSpaces;
