import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Plate, TEditableProps } from '@udecode/plate';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSnapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { debounce } from 'lodash';
import { TyleBlockInterface } from 'interfaces/TyleInterface';
import GetNode from 'components/editor/components/GetNode';
import { getNodesWithId } from 'components/editor/dnd';
import useTyleHooks from 'hooks/useTyleHooks';
import newCanvasState from 'state/NewCanvas';
import canvasState from 'state/CanvasState';
import tylesState from 'state/TylesState';
import sharedState from 'state/SharedState';
import ResetEditorOnValueChange from 'components/editor/utils/resetEditorElement';
import { usePreventBodyScroll } from 'hooks/ScrollHooks';
import { editableProps } from './components/Elements';
import { MyRootBlock, MyValue } from './types/plateTypes';
import SlashCommandMenu from './components/SlashCommandMenu';
import plugins from './plugins';
import BalloonToolbar from './components/BalloonToolbar/BalloonToolbar';
import './Editor.scss';

export interface Props {
	tyleId: string;
	windowId: string;
	className?: string;
	updateTyle?: (tyleId: string, content: Array<any>) => void;
	tyleUrl?: string;
}

const Editor = ({ tyleId, windowId, className, updateTyle, tyleUrl }: Props) => {
	const { updateTyleBlock, getTyle } = useTyleHooks();
	const [initialValue, setInitialValue] = useState<MyValue>();
	const { isPublicView } = useSnapshot(canvasState);
	const [editablePropsEditor, setEditablePropsEditor] = useState<TEditableProps<MyValue>>(editableProps);
	const { enableScroll, disableScroll } = usePreventBodyScroll();
	const containerRef = useRef<HTMLDivElement>(null);
	const isFirstEditorRender = useRef<boolean>(true);

	const value = useMemo(() => {
		return initialValue;
	}, [initialValue]);

	useEffect(() => {
		if (tylesState.tylesObjects && tylesState.tylesObjects[tyleId]?.content) {
			const contentWithNodes = [
				...getNodesWithId({
					nodes: tylesState.tylesObjects[tyleId].content as unknown as MyRootBlock[],
				}),
			];
			setInitialValue(contentWithNodes);
		} else {
			getTyle(canvasState.isPublicView && tyleUrl ? tyleUrl : tyleId).then((tyleFromBackend) => {
				const contentWithNodes = [...getNodesWithId({ nodes: tyleFromBackend.content as unknown as MyRootBlock[] })];
				setInitialValue(contentWithNodes);
			});
		}
		const unsubscribeFromTyle = subscribeKey(tylesState.tylesObjects, tyleId, (tyle) => {
			const contentWithNodes = [...getNodesWithId({ nodes: tyle.content as unknown as MyRootBlock[] })];
			setInitialValue(contentWithNodes);
		});
		return () => {
			unsubscribeFromTyle();
		};
	}, [tyleId, tyleUrl]);

	// If it's public view, disable editor editing
	useEffect(() => {
		setEditablePropsEditor({ ...editablePropsEditor, readOnly: isPublicView });
	}, [isPublicView]);

	const debouncedUpdateBlockHandler = useCallback(
		debounce((block: TyleBlockInterface) => {
			return updateTyleBlock({ tyleId, block });
		}, 2000),
		[tyleId],
	);

	const handleOnChange = useCallback(
		debounce((newValue: MyValue) => {
			newCanvasState.clearSaveTimer = true;
			if (updateTyle) {
				updateTyle(tyleId, newValue);
			}
		}, 2000),
		[updateTyle, value],
	);

	const handleMouseOver = useCallback(() => {
		// This is needed to prevent the body from scrolling when hovering over the editor
		enableScroll();
		sharedState.isEditorHovered = true;
	}, []);
	const handleMouseLeave = useCallback(() => {
		disableScroll();
		sharedState.isEditorHovered = false;
	}, []);
	const handleOnFocus = useCallback(() => {}, []);

	const generatedClassName = className ? `tyle-editor ${className}` : 'tyle-editor';

	return (
		<div
			className={generatedClassName}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			onFocus={handleOnFocus}
			ref={containerRef}
		>
			<DndProvider backend={HTML5Backend}>
				{initialValue && (
					<Plate<MyValue>
						id={windowId}
						key={`${tyleId}`}
						editableProps={editablePropsEditor}
						plugins={plugins}
						initialValue={value}
						onChange={handleOnChange}
					>
						<ResetEditorOnValueChange isFirstRender={isFirstEditorRender} value={value} />
						<GetNode
							initialValue={value}
							windowId={windowId}
							tyleId={tyleId}
							updateBlock={debouncedUpdateBlockHandler}
						/>
						<BalloonToolbar />
						<SlashCommandMenu pluginKey="/" />
					</Plate>
				)}
			</DndProvider>
		</div>
	);
};

export default Editor;
