export const reorder = (list: string[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

type ReorderMapProps = {
	map: Record<string, string[]>;
	source: { droppableId: string; index: number };
	destination: { droppableId: string; index: number };
};

export const reorderMap = ({ map, source, destination }: ReorderMapProps) => {
	const current = [...map[source.droppableId]];
	const next = [...map[destination.droppableId]];
	const target = current[source.index];

	// moving to same list
	if (source.droppableId === destination.droppableId) {
		const reordered = reorder(current, source.index, destination.index);
		const result = {
			...map,
			[source.droppableId]: reordered,
		};
		return {
			map: result,
		};
	}

	// remove from original
	current.splice(source.index, 1);
	// insert into next
	next.splice(destination.index, 0, target);

	const result = {
		...map,
		[source.droppableId]: current,
		[destination.droppableId]: next,
	};

	return {
		map: result,
	};
};

export function isElementInViewport(el: any) {
	const rect = el.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
	);
}
