import { SourceInterface } from './SourceInterface';
import { CustomEditor, CustomElement } from '../types/slate';

export interface TyleInterface {
	id?: string;
	createdAt?: Date;
	updatedAt?: Date;
	source?: SourceInterface;
	title?: string;
	content?: CustomElement[];
	link?: TyleConnectionInterface[];
	backlink?: TyleConnectionInterface[];
	deleted?: boolean;
	favorite?: boolean;
	previousInstance?: TyleInterface;
	previousInstanceId?: string;
	derivedInstances?: Array<TyleInterface>;
	inbox?: boolean;
}

export interface TyleConnectionInterface {
	createdAt: Date;
	linkId: string;
	backlinkId: string;
}

export interface TyleRelationInterface {
	childTyleId: string;
	parentTyleId: string;
}

export interface GeneratedTyleUrlInterface {
	url: string;
	disabled: boolean;
	tyleId: string;
}

export enum TyleActionEnum {
	VIEW = 'view',
	REMOVE = 'remove',
	DELETE = 'delete',
	CLOSE = 'close',
	INBOX_REMOVE = 'inboxRemove',
	FAVORITE_ADD = 'favoriteAdd',
	FAVORITE_REMOVE = 'favoriteRemove',
	NEW_INSTANCE = 'newInstance',
	SHOW_PREVIOUS = 'showPrevious',
	SHOW_ORIGIN = 'showOrigin',
	TURN_INTO = 'turnInto',
	TURN_INTO_TEXT = 'turnIntoText',
	TURN_INTO_TITLE = 'turnIntoTitle',
	TURN_INTO_TYLE_TEXT = 'turnIntoTyleText',
	TURN_INTO_BLOCK = 'turnIntoBlock',
	ADD_LINK = 'addLink',
	CREATE = 'create',
}

export interface AddLinkToTyleProps {
	tyleId: string;
	linkId: string;
}

export interface RemoveLinkFromTyleProps {
	tyleId: string;
	linkId: string;
}

export interface AddToFavoriteProps {
	tyleId: string;
}

export interface RemoveFromFavoriteProps {
	tyleId: string;
}

export interface UpdateTyleBlockInterface {
	tyleId: string;
	block: TyleBlockInterface;
}

export interface RemoveTyleBlockInterface {
	tyleId: string;
	blockIds: Array<string>;
}

export interface TyleBlockInterface {
	id: string;
	type: any;
	position?: number;
	children: Array<any>;
	checked?: boolean;
	tyleId?: string;
	url?: string;
}

export interface TyleBlockToRemoveInterface {
	blockIds: Array<string>;
}

export interface AddToTyleProps {
	destinationTyle: TyleInterface;
	tyleToSave: TyleInterface;
	isQuote?: boolean;
	highlight?: string;
}

export interface TyleToDeleteData {
	editor: CustomEditor;
	element: any;
}
