import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import { TyleActionEnum } from 'interfaces/TyleInterface';
import { TyleMenuEnum } from '../../interfaces/TerminalMenuInterface';

const threeDotsMenuOptions = (id: string, isFavorite: boolean): PopoverMenuItemInterface[] => {
	return [
		!isFavorite
			? {
					label: TyleMenuEnum.ADD_TO_FAVORITE,
					action: TyleActionEnum.FAVORITE_ADD,
					data: id,
			  }
			: {
					label: 'Remove from favorites',
					action: TyleActionEnum.FAVORITE_REMOVE,
					data: id,
			  },
		{
			label: 'Delete',
			action: TyleActionEnum.DELETE,
			data: id,
		},
	];
};

export default threeDotsMenuOptions;
