import type { CSSProperties, ReactElement } from 'react';
import type { XYCoord } from 'react-dnd';
import { useDragLayer } from 'react-dnd';
import { useEffect } from 'react';
import { TyleCardPreview } from '../TyleCard/TyleCardDragPreview';
import { DragItemTypeEnum } from '../../interfaces/DragAndDrop/DragAndDropEnums';

const layerStyles: CSSProperties = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null) => {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}

	const { x, y } = currentOffset;

	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const CustomDragLayer = (): ReactElement | null => {
	const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	if (!isDragging) {
		return null;
	}
	return (
		<div style={layerStyles}>
			<div style={getItemStyles(initialOffset, currentOffset)}>
				<TyleCardPreview tyleId={item.tyleId} />
			</div>
		</div>
	);
};

export default CustomDragLayer;
