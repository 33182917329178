import { ReactElement } from 'react';
import './Toast.scss';
import Icon from '../icon';

const style = {
	display: 'flex',
	flexFlow: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
};

export interface ToastProps {
	children: any;
	width?: number | string;
	height?: string;
	fontSize?: string;
	onCloseClick: () => void;
}

const Toast = ({
	children,
	width = 384,
	height = 'auto',
	fontSize = '13px',
	onCloseClick,
}: ToastProps): ReactElement => {
	setTimeout(() => {
		onCloseClick();
	}, 3000);
	return (
		<div className="kum-toast" style={{ ...style, width, height, fontSize }}>
			{children}
			<button className="close" type="button" onClick={onCloseClick}>
				<Icon type="close-gray" />
			</button>
		</div>
	);
};

export default Toast;
