import React from 'react';
import Headline from 'components/headline';
import Paragraph from 'components/paragraph';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import Icon from 'components/icon';

const StyledModal = styled.div`
	width: 315px;
	height: 389px;
	background: rgba(28 21 27 / 80%);
	backdrop-filter: blur(15px);
	border: 1px solid rgba(88, 88, 88, 0.5);
	border-radius: 10px;
`;

const StyledHeader = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(88, 88, 88, 0.5);
	width: 100%;
	height: 55px;
	text-align: center;
	padding: 0 0 0 12px;
`;

const ContentWrapper = styled.div`
	padding: 20px;
`;

const PermissionCard = styled.div`
	display: flex;
	align-items: center;
	background: rgba(245, 245, 245, 0.1);
	backdrop-filter: blur(5px);
	border-radius: 5px;
	padding: 12px 15px;
	margin-bottom: 15px;
`;

export interface Props {
	showModal: boolean;
	handleClose: () => void;
}

const PermissionsModal = ({ showModal, handleClose }: Props) => {
	const types = ['accessibility', 'screen-recording'];

	const renderContent = (headline: string, paragraph: string, src: string) => {
		return (
			<PermissionCard>
				<img src={src} alt="icon" width={30} height={30} style={{ marginRight: '20px' }} />
				<div>
					<Headline style={{ color: '#EEEEEE', textAlign: 'left', fontSize: '12px', margin: 0 }} variant="h2">
						{headline}
					</Headline>
					<Paragraph style={{ color: '#AAAAAA', textAlign: 'left', fontSize: '11px' }}>{paragraph}</Paragraph>
				</div>
			</PermissionCard>
		);
	};
	const renderCard = (type: string) => {
		switch (type) {
			case 'accessibility':
				return renderContent(
					'Accessibility',
					'This allows us to access a mouse click emphasis and more.',
					'assets/images/accessibility_icon.png',
				);
			case 'screen-recording':
				return renderContent(
					'Screen Recording',
					'This allows us to return focus to the correct application when you close the Terminal. Tyles does not record your screen otherwise.',
					'assets/images/screen-recording.png',
				);
			default:
				return <></>;
		}
	};
	return (
		<Modal
			centered
			className="permissions_modal"
			show={showModal}
			animation={false}
			onHide={handleClose}
			style={{ margin: 0 }}
		>
			<Modal.Body style={{ width: '315px' }}>
				<StyledModal>
					<StyledHeader>
						<Icon type="chevron-left" onClick={handleClose} />
						<Headline style={{ color: '#eeeeee', margin: 0, paddingLeft: '50px', fontSize: '14px' }} variant="h2">
							Permission Request
						</Headline>
					</StyledHeader>
					<ContentWrapper>
						<Paragraph style={{ fontSize: '12px', marginBottom: '15px', color: '#aaaaaa' }}>
							To use the Terminal please enable Accessibility and Screen Recording access.
						</Paragraph>
						{types.map((type) => (
							<div key={type}>{renderCard(type)}</div>
						))}
					</ContentWrapper>
				</StyledModal>
			</Modal.Body>
		</Modal>
	);
};

export default PermissionsModal;
