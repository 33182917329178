import { Space as ApiSpace } from '../api/generated/client';
import InvalidDataException from '../exceptions/InvalidDataException';
import { Space } from '../models/Space';
import { SpaceInterface } from '../interfaces/SpaceInterface';

const adaptSpace = (space: ApiSpace): SpaceInterface => {
	if (!space.id) {
		throw new InvalidDataException('broken');
	}
	return new Space({
		id: space.id,
		createdAt: space.createdAt ?? null,
		updatedAt: space.updatedAt ?? null,
		label: space.label ? space.label : '',
		userId: space.userId,
		tylesData: space.tylesData,
		closed: space.closed,
		deleted: space.deleted,
		selected: space.selected,
	});
};

export default adaptSpace;
