import { ReactElement, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { subscribeKey } from 'valtio/utils';
import { SearchSidebarItemInterface, SearchSidebarItemTypeEnum } from 'interfaces/SearchSidebarInterface';
import SearchSidebarContentSection from 'components/SearchSidebarContent/SearchSidebarContentSection';
import { TyleInterface } from 'interfaces/TyleInterface';
import useSearchQueryHooks from 'hooks/useSearchQueryHooks';
import tylesState from 'state/TylesState';
import useTyleHooks from 'hooks/useTyleHooks';
import sharedState from '../../state/SharedState';
import tagsState from '../../state/TagsState';
import initialSections from './helpers';
import { SearchQuery } from '../../models/SearchQuery';
import { SearchQueryInterface } from '../../interfaces/SearchQueryInterface';

const StyledSearchSidebarList = styled.div`
	max-height: calc(80% - 60px);
	flex-direction: column;
	overflow-y: auto;
	padding: 0 0 30px 0;
`;

export interface Props {
	changeInputText: (text: string) => void;
	createWindowInCanvas: (tyle: TyleInterface) => void;
}

const SearchSidebarContent = ({ changeInputText, createWindowInCanvas }: Props): ReactElement => {
	const { getFavoriteTyles } = useTyleHooks();
	const { getRecentSearchQueries } = useSearchQueryHooks();
	const [sections, setSections] = useState<SearchSidebarItemInterface[]>(initialSections);

	// If not in state, call api to get favorites
	useEffect(() => {
		// Listen to favorite tyles in state and update the items
		const unsubscribeFavoriteTyles = subscribeKey(tylesState, 'favoriteTyles', (tyles: TyleInterface[]) => {
			const index = sections.findIndex((section) => section.type === SearchSidebarItemTypeEnum.FAVORITES);
			if (tyles && index !== -1) {
				const items = [...sections];
				items[index].options = tyles;
				setSections(items);
			}
		});

		const unsubscribeSearchTyleResults = subscribeKey(tylesState, 'searchResultsTyles', (tyles: TyleInterface[]) => {
			// Avoid showing search results if there are no tags selected and no search input text
			if (!tagsState.selectedTagIds.size && !sharedState.searchSidebarInputText) {
				return;
			}
			const index = sections.findIndex((section) => section.type === SearchSidebarItemTypeEnum.SEARCH_RESULTS);
			if (tyles && index !== -1) {
				const items = [
					...sections.map((section) => ({
						...section,
						hidden: section.type !== SearchSidebarItemTypeEnum.SEARCH_RESULTS,
					})),
				];
				items[index].options = tyles;
				setSections(items);
			}
		});

		// Listen to search input change, if the text is empty, restore favorite and recent searches sections
		const unsubscribeSearchInputText = subscribeKey(sharedState, 'searchSidebarInputText', (value: string) => {
			if (!value) {
				const items = [
					...sections.map((section) => ({
						...section,
						hidden: section.type === SearchSidebarItemTypeEnum.SEARCH_RESULTS,
					})),
				];
				setSections(items);
			}
		});

		// Listen to tags input change, if the search input is empty and selected tags are empty, restore favorite and recent searches sections
		const unsubscribeTagsSize = subscribeKey(tagsState, 'selectedTagIds', (value: Set<string>) => {
			if (!value.size && !sharedState.searchSidebarInputText) {
				setSections(initialSections);
			}
		});

		const unsubscribeSearchQueries = subscribeKey(
			sharedState,
			'recentSearchQueries',
			(value: SearchQueryInterface[]) => {
				const index = sections.findIndex((section) => section.type === SearchSidebarItemTypeEnum.RECENTLY_SEARCHED);
				if (value && index !== -1) {
					const items = [...sections];
					items[index].options = value;
				}
			},
		);

		return () => {
			unsubscribeFavoriteTyles();
			unsubscribeSearchTyleResults();
			unsubscribeSearchInputText();
			unsubscribeTagsSize();
			unsubscribeSearchQueries();
		};
	}, []);

	useEffect(() => {
		if (tylesState.status !== 'loading') {
			getFavoriteTyles().then();
		}

		getRecentSearchQueries({ limit: 3 }).then();
	}, []);

	return (
		<StyledSearchSidebarList data-testid="sidebar-tabs">
			{sections?.map(
				(item: SearchSidebarItemInterface, index: number) =>
					!item.hidden && (
						<SearchSidebarContentSection
							key={nanoid()}
							item={item}
							index={index}
							changeInputText={changeInputText}
							createWindowInCanvas={createWindowInCanvas}
						/>
					),
			)}
		</StyledSearchSidebarList>
	);
};

export default SearchSidebarContent;
