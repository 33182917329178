import {
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_H4,
	ELEMENT_H5,
	ELEMENT_H6,
	ELEMENT_OL,
	ELEMENT_PARAGRAPH,
	ELEMENT_UL,
	IndentListPlugin,
} from '@udecode/plate';
import { MyPlatePlugin } from '../types/plateTypes';

const indentListPlugin: Partial<MyPlatePlugin<IndentListPlugin>> = {
	inject: {
		props: {
			validTypes: [
				ELEMENT_PARAGRAPH,
				ELEMENT_H1,
				ELEMENT_H2,
				ELEMENT_H3,
				ELEMENT_H4,
				ELEMENT_H5,
				ELEMENT_H6,
				ELEMENT_BLOCKQUOTE,
				ELEMENT_CODE_BLOCK,
				ELEMENT_OL,
				ELEMENT_UL,
			],
		},
	},
};

export default indentListPlugin;
