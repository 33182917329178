import InvalidDataException from 'exceptions/InvalidDataException';
import { User as ApiUser } from 'api/generated/client';
import User from 'models/User';
import { UserInterface, Role } from 'interfaces/UserInterface';

const adaptUser = (user: ApiUser): UserInterface => {
	const {
		id,
		email,
		firstName,
		lastName,
		occupation,
		role,
		temporaryEmail,
		deleted,
		referralCode,
		theme,
		canvasTyles,
		webOnboarded,
		desktopOnboarded,
	} = user;
	if (!id) {
		throw new InvalidDataException('Could not find user');
	}

	return new User({
		id,
		email: email || '',
		temporaryEmail: temporaryEmail || '',
		firstName,
		lastName,
		occupation,
		deleted,
		referralCode,
		role: role as Role,
		theme,
		canvasTyles,
		webOnboarded,
		desktopOnboarded,
	});
};

export default adaptUser;
