/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

export interface ParagraphProps {
	children: ReactNode | string;
	variant?: 'default' | 'soft' | 'small' | 'heading';
	margin?: 'default' | 'none';
	classN?: string;
	style?: Record<string, any>;
}

const StyledParagraph = styled.p`
	font-size: ${(props) => props.theme.fontSizes?.paragraph};
	line-height: 1.55;
	font-weight: ${(props) => props.theme.fontWeights?.base};
	letter-spacing: 0.01em;
	margin: 0 0 6px 0;
	color: ${(props) => props.theme.colors?.gray100};

	&.soft {
		color: ${(props) => props.theme.colors?.gray100};
	}

	&.heading {
		color: ${(props) => props.theme.colors?.black100};
	}

	&.small {
		font-size: ${(props) => props.theme.fontSizes?.paragraphSmall};
		color: ${(props) => props.theme.colors?.gray100};
	}

	&.no-margin {
		margin: 0;
	}
`;

const Paragraph = ({ children, variant, margin, placeholder, style, classN }: ParagraphProps | any): ReactElement => {
	return (
		<StyledParagraph
			data-placeholder={placeholder}
			className={classNames({
				soft: variant === 'soft',
				small: variant === 'small',
				heading: variant === 'heading',
				'no-margin': margin === 'none',
				[classN]: true,
			})}
			style={style}
		>
			{children}
		</StyledParagraph>
	);
};

export default Paragraph;
