import { useContext } from 'react';
import { ApiContext } from 'components/App';
import { SearchQueryParams } from 'interfaces/SearchQueryInterface';
import tylesState from 'state/TylesState';
import sharedState from 'state/SharedState';

const useSearchQueryHooks = () => {
	const api = useContext(ApiContext);

	const getRecentSearchQueries = async ({ searchPhrase, limit, filters }: SearchQueryParams) => {
		tylesState.status = 'loading';
		const searchQueries = await api.getRecentSearchQueries(searchPhrase, limit, filters);
		sharedState.recentSearchQueries = searchQueries;
		tylesState.status = 'success';
		return searchQueries;
	};

	return { getRecentSearchQueries };
};

export default useSearchQueryHooks;
