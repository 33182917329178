import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { ApiContext } from 'components/App';
import ResponseStatus from '../interfaces/ResponseStatus';
import Headline from '../components/headline';
import Spacing from '../components/spacing';
import Page from '../components/page';
import Paragraph from '../components/paragraph';
import Panel from '../components/panel';
import Button from '../components/button';
import useAuthentication from '../helpers/useAuthentication';
import IPCChannel from '../model/IPC/IPCChannel';
import userState from '../state/UserState';

export default function NewEmailValidationPage(): ReactElement {
	const { token } = useParams<{ token: string }>();
	const [status, setStatus] = useState<ResponseStatus>(ResponseStatus.INITIAL);
	const history = useHistory();
	const [newEmailChanged, setNewEmailChanged] = useState('');
	const { isLoggedIn, updateToken } = useAuthentication();

	const api = useContext(ApiContext);

	const forceRedirect = () => {
		if (process.env.NODE_ENV === 'development') {
			window.location.href = '/';
			return;
		}
		window.location.href = '/';
		if (window.ipc) {
			window.ipc.send(IPCChannel.APP_RELOAD, 'reload');
		}
	};

	const goToTylesDesktop = () => {
		window.location.href = `tyles:///login`;
	};

	useEffect(() => {
		(async () => {
			if (status !== ResponseStatus.INITIAL) {
				return;
			}
			updateToken(token);
			api.setAuthentication(token);
			setStatus(ResponseStatus.LOADING);
			try {
				await api.getAuthentication();

				if (!isLoggedIn()) {
					return;
				}

				// Get user
				api.getUserAccount().then((userAccount) => {
					userState.user = userAccount;

					if (!userAccount.email) {
						return;
					}
					setNewEmailChanged(userAccount.email);
				});
			} catch (error) {
				setStatus(ResponseStatus.ERROR);
				history.push('/login', {
					from: 'token',
				});
			}
		})();
	});

	const renderEmailChange = () => {
		return (
			<Page orientation="center" verticalAlign="center">
				<Headline variant="title">Tyles</Headline>
				<Spacing />
				<Panel>
					<Headline variant="h2">Email change successful</Headline>
					<Paragraph>
						We&apos;re confirming that your email was updated to{' '}
						<a href={`mailto:${newEmailChanged}`}>{newEmailChanged}</a>.
					</Paragraph>

					<div className="buttons-container">
						<Button label="Continue with Tyles desktop" variant="primary" onEvent={goToTylesDesktop} />
						<Button label="Continue with Tyles web" variant="plain" onEvent={forceRedirect} />
					</div>
				</Panel>
			</Page>
		);
	};

	return renderEmailChange();
}
