import { PopoverMenu } from 'components/popover-menu';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import React, { ReactElement } from 'react';
import { Transforms } from 'slate';
import sharedState from 'state/SharedState';

interface LinkPopoverContentProps {
	url: string;
	id: string;
	onDelete: () => void;
	onCancel: (close: boolean) => void;
}

const LinkPopoverContent = ({ id, url, onCancel, onDelete }: LinkPopoverContentProps): ReactElement => {
	const onEvent = ({ action }: PopoverMenuItemInterface) => {
		switch (action) {
			case 'open': {
				let urlToOpen = url;
				if (!url.match(/^http(s)+:\/\//i)) {
					urlToOpen = `//${url}`;
				}

				window.open(urlToOpen, '_blank');
				break;
			}

			case 'copy':
				// todo: some feedback after copy success
				navigator.clipboard.writeText(url).then();
				sharedState.showCustomToast = true;
				sharedState.customToastContent = 'Link copied!';
				break;

			case 'delete':
				onDelete();
				break;
			case 'edit':
				sharedState.editHyperlink = id;
				break;

			default:
				break;
		}
	};

	const items: Array<PopoverMenuItemInterface> = [
		{
			sectionTitle: 'Linked to',
		},
		{
			label: url,
			action: 'open',
			icon: 'external-link-icon',
		},
		{
			divider: true,
		},
		{
			label: 'Copy link',
			action: 'copy',
			icon: 'copy-link',
		},
		{
			label: 'Delete link',
			action: 'delete',
			icon: 'delete-link',
		},
		{
			label: 'Edit text',
			action: 'edit',
			icon: 'edit-link',
		},
	];
	return <PopoverMenu align="center" items={items} onCancel={onCancel} onEvent={onEvent} />;
};

export default LinkPopoverContent;
