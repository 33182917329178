import { adaptTyleConnection } from 'adapters';
import { Tyle as ApiTyle } from '../api/generated/client';
import InvalidDataException from '../exceptions/InvalidDataException';
import { Tyle } from '../models/Tyle';

const adaptTyle = (tyle: ApiTyle): Tyle => {
	if (!tyle.id) {
		throw new InvalidDataException('broken');
	}

	return new Tyle({
		id: tyle.id,
		createdAt: new Date(`${tyle.createdAt}`),
		updatedAt: tyle.updatedAt ? new Date(tyle.updatedAt) : undefined,
		source: tyle.source,
		title: tyle.title,
		content: tyle?.content,
		link: tyle.link?.map((link) => adaptTyleConnection(link)),
		backlink: tyle.backlink?.map((backlink) => adaptTyleConnection(backlink)),
		previousInstance: tyle.previousInstance ? adaptTyle(tyle.previousInstance) : undefined,
		derivedInstances: tyle.derivedInstances?.map((derivedInstance) => adaptTyle(derivedInstance)),
		deleted: tyle.deleted ?? false,
		favorite: tyle.favorite,
		inbox: tyle.inbox,
	});
};

export default adaptTyle;
