import { validationHelper } from './index';

export interface UseAuthenticationValidationInterface {
	emailValidationMessage: (email: string) => string | undefined;
	inviteCodeValidationMessage: (inviteCode: string) => string | undefined;
}

const useAuthenticationValidation = (): UseAuthenticationValidationInterface => {
	const emailValidationMessage = (email: string): string | undefined => {
		const validation = validationHelper().Email(email);
		if (!!validation.error && validation.error.message) {
			return validation.error.message;
		}
		return undefined;
	};

	const inviteCodeValidationMessage = (inviteCode: string): string | undefined => {
		const validation = validationHelper().InviteCode(inviteCode);
		if (!!validation.error && validation.error.message) {
			return validation.error.message;
		}
		return undefined;
	};

	return { emailValidationMessage, inviteCodeValidationMessage };
};

export default useAuthenticationValidation;
