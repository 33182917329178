import { TElement } from '@udecode/plate';
import useTyleHooks from 'hooks/useTyleHooks';
import { TyleBlockInterface } from 'interfaces/TyleInterface';
import tylesState from 'state/TylesState';
import { ELEMENT_TYLE_BLOCK } from '../types/tyleTypes';

const useRemoveTyleBlock = () => {
	const { removeLinkFromTyle } = useTyleHooks();

	const validateTyleBlock = (tyleBlock: TyleBlockInterface | undefined) => {
		const { type, tyleId } = tyleBlock || {};
		return type === ELEMENT_TYLE_BLOCK && tyleId ? tyleId : null;
	};

	const removeFirstTyleBlock = (
		tyleId: string,
		initialValue: TyleBlockInterface[] | undefined,
		newValue: TElement[],
	) => {
		const isTyleBlockRemoved = newValue[0] && newValue[0].type !== ELEMENT_TYLE_BLOCK && !newValue[0].id;
		const linkId = validateTyleBlock(initialValue?.[0]);

		if (linkId && isTyleBlockRemoved) {
			// If first block removed is tyle block, set isDeleteBlock to true
			tylesState.isDeleteBlock = true;
			removeLinkFromTyle({ tyleId, linkId });
		}
	};

	return {
		removeFirstTyleBlock,
		validateTyleBlock,
	};
};

export default useRemoveTyleBlock;
