import styled, { css } from 'styled-components';
import Icon from '../../icon';

const StyledLogoIcon = styled(Icon).attrs((props: { isPublic: boolean }) => props)`
	${(props) =>
		props.isPublic &&
		css`
			font-size: initial;

			&.kum-icon {
				display: flex;
				align-items: center;

				> svg {
					vertical-align: unset;
				}
			}
		`}
`;

export default StyledLogoIcon;
