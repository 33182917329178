import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { api } from 'components/App';
import { SharedState } from '../models/State';
import { tylesObjectActions } from './TylesState';

const sharedState = proxy<SharedState>({
	appFocused: false,
	isDropdownOpen: false,
	showToast: false,
	selectedTyle: undefined,
	showInboxPanel: false,
	showCustomToast: false,
	customToastContent: '',
	editHyperlink: '',
	isEditorFocused: false,
	isEditorHovered: false,
	searchSidebarInputText: '',
	recentSearchQueries: [],
	searchTrigger: null,
	publicWindowEntity: undefined,
});

export const sharedStateActions = {
	triggerAppFocused: async () => {
		sharedState.appFocused = !sharedState.appFocused;
		const tyle = await api.getLastUpdatedTyle().catch((error) => {
			console.error(error);
		});
		if (!tyle || !tyle.id) {
			return;
		}
		tylesObjectActions.updateTyleObject({ id: tyle.id, newTyleProperties: tyle });
	},
};

devtools(sharedState, { name: 'Shared' });

export default sharedState;
