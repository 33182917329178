import { ReactElement } from 'react';
import Divider from '../divider';
import './Footer.scss';

export interface FooterProps {
	children: ReactElement;
}

const Footer = ({ children }: FooterProps): ReactElement => (
	<div className="kum-footer">
		<div style={{ flexGrow: 1 }} />
		<Divider />
		<div style={{ width: '100%', textAlign: 'right' }}>{children}</div>
	</div>
);

export default Footer;
