import { Token as ApiToken } from '../api/generated/client';
import InvalidDataException from '../exceptions/InvalidDataException';

const adaptToken = (token: ApiToken): string => {
	if (!token.token) {
		throw new InvalidDataException();
	}

	return token.token;
};

export default adaptToken;
