import { Transforms } from 'slate';
import { CustomEditor, CustomElement } from 'types/slate';
import selectByElement from './selectByElement';

const removeElement = ({ editor, element }: { editor: CustomEditor; element: CustomElement }): void => {
	selectByElement({ editor, element });
	Transforms.delete(editor, { at: editor.selection?.focus, unit: 'block' });
};

export default removeElement;
