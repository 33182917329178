import { Editor, Transforms } from 'slate';
import { ELEMENT_H1 } from '@udecode/plate';
import { ReactEditor } from 'slate-react';
import { CustomEditor, CustomElement } from '../../../types/slate';
import { ELEMENT_TYLE_BLOCK, ELEMENT_TYLE_INLINE } from '../types/tyleTypes';
import selectByElement from '../utils/selectByElement';
import { tylesObjectActions } from '../../../state/TylesState';

interface TransformationData {
	editor: CustomEditor;
	elementToTransform: CustomElement;
}

const transformToText = ({ editor, elementToTransform }: TransformationData): void => {
	const { type } = elementToTransform;
	if (!('tyleId' in elementToTransform) || !elementToTransform.tyleId) {
		return;
	}
	const tyle = tylesObjectActions.getTyleWithIdFromState(elementToTransform.tyleId);

	switch (type) {
		case ELEMENT_TYLE_BLOCK: {
			if (!tyle) {
				return;
			}

			const path = ReactEditor.findPath(editor, elementToTransform);
			selectByElement({ editor, element: elementToTransform });
			Transforms.removeNodes(editor);

			if (!tyle?.content) {
				return;
			}

			const fragmentToInsert = [...tyle.content];

			if (tyle.title) {
				fragmentToInsert.unshift({
					type: ELEMENT_H1,
					children: [
						{
							text: tyle.title,
						},
					],
				});
			}
			Transforms.insertNodes(editor, fragmentToInsert, { at: path });

			break;
		}

		case ELEMENT_TYLE_INLINE: {
			if (!tyle) {
				return;
			}

			selectByElement({ editor, element: elementToTransform });
			Transforms.delete(editor);

			if (!tyle.content) {
				return;
			}

			const fragmentToInsert = [...tyle.content];

			if (tyle.title) {
				fragmentToInsert.unshift({
					type: ELEMENT_H1,
					children: [
						{
							text: tyle.title,
						},
					],
				});
			}

			Editor.insertFragment(editor, fragmentToInsert);

			break;
		}

		default:
			break;
	}
};

export default transformToText;
