import {
	ELEMENT_PARAGRAPH,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_UL,
	ELEMENT_LI,
	ELEMENT_OL,
	ELEMENT_TODO_LI,
	ELEMENT_CODE_BLOCK,
	ELEMENT_BLOCKQUOTE,
} from '@udecode/plate';

const translateFragmentType = (type: string | null): string => {
	switch (type) {
		case ELEMENT_PARAGRAPH:
			return 'Paragraph';
		case ELEMENT_H1:
			return 'Headline 1';
		case ELEMENT_H2:
			return 'Headline 2';
		case ELEMENT_H3:
			return 'Headline 3';
		case ELEMENT_UL:
			return 'Bulleted List';
		case ELEMENT_LI:
			return 'List Element';
		case ELEMENT_OL:
			return 'Numbered List';
		case ELEMENT_TODO_LI:
			return 'To Do Item';
		case ELEMENT_CODE_BLOCK:
			return 'Code Block';
		case ELEMENT_BLOCKQUOTE:
			return 'Quote';

		default:
			return '';
	}
};

export default translateFragmentType;
