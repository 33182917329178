import { useEffect, useState } from 'react';
import 'react-step-progress-bar/styles.css';
import User from 'models/User';
import userState from 'state/UserState';
import SignupScreen from 'components/SignupScreen';
import Spacing from 'components/spacing';
import { renderTylesBetaHeadlineLogo } from 'components/icon/helpers';
import detectEnvironment, { EnvironmentEnum } from 'helpers/detectEnvironment';
import IPCChannel from 'model/IPC/IPCChannel';
import Headline from 'components/headline';
import Paragraph from 'components/paragraph';
import useUserHooks from 'hooks/useUserHooks';
import Button from 'components/button';
import Setup from 'containers/PermissionsPage/Setup';
import {
	Background,
	Container,
	ContainerLeft,
	ContainerRight,
	ContentContainter,
	Logo,
	Navbar,
	Wrapper,
} from 'containers/PermissionsPage/styles';
import useAnalytics, { TrackingEvent } from 'hooks/useAnalytics';

type UserInfo = {
	firstName?: string;
	lastName?: string;
	occupation?: string;
};

export interface Props {
	user: User;
	loadSupport: (load: boolean) => void;
}

const RegistrationPage = ({ user, loadSupport }: Props) => {
	const [buttonActive, setButtonActive] = useState(true);
	const isDesktop = detectEnvironment() === EnvironmentEnum.DESKTOP;
	const { updateUserAccount } = useUserHooks();
	const { track } = useAnalytics();

	const [{ firstName, lastName, occupation }, setUpdatedUserInfo] = useState<UserInfo>({});

	useEffect(() => {
		if (firstName && lastName && occupation) {
			setButtonActive(false);
			return;
		}

		setButtonActive(true);
	}, [firstName, lastName, occupation]);

	const handleSubmit = () => {
		updateUserAccount({
			...user,
			firstName,
			lastName,
			occupation,
		}).then(() => {
			userState.showOnBoarding = true;
			track(TrackingEvent.SIGNUP);
			if (isDesktop && window.ipc) {
				window.ipc.send(IPCChannel.APP_RELOAD, 'reload');
				return;
			}
			window.location.href = '/';
			loadSupport(true);
		});
	};

	const updateUserInfo = (userInfo: Record<string, string>) => {
		setUpdatedUserInfo(userInfo);
	};

	const content = () => {
		return (
			<ContentContainter>
				<ContainerLeft>
					<Headline variant="h2" style={{ margin: '0 0 20px 0', color: '#ededed' }}>
						Welcome to Tyles
					</Headline>
					<Paragraph style={{ color: '#A0A0A0' }}>
						Setting up Tyles takes <span style={{ color: '#ededed' }}>less than two minutes.</span>
						<br /> Firstly, tell us a bit about yourself.
					</Paragraph>
					<Spacing size="small" />
					<Button
						style={{ backgroundColor: '#343434', border: 'none', color: '#EDEDED' }}
						label="Continue"
						disabled={buttonActive}
						onEvent={handleSubmit}
					/>
				</ContainerLeft>
				<ContainerRight style={{ paddingTop: '3rem' }}>
					<Setup index={0} user={user} setUserInfo={updateUserInfo} />
				</ContainerRight>
			</ContentContainter>
		);
	};
	return (
		<Wrapper>
			<Navbar />
			<Background>
				<Container>
					<Logo>{renderTylesBetaHeadlineLogo()}</Logo>
					<SignupScreen content={content()} />
				</Container>
			</Background>
		</Wrapper>
	);
};

export default RegistrationPage;
