/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import styled from 'styled-components';
import { UserMenuActionDto, UserMenuInterface } from '../../interfaces/UserMenuInterface';
import UserAvatar from './UserAvatar';
import { NavigationBarClickActionEnum } from '../../interfaces/NavigationBarInterface';
import Tooltip, { TooltipPosition } from '../tooltip/Tooltip';
import createOptions from './helpers';
import Dropdown from '../Dropdownn';

export interface UserMenuProps extends UserMenuInterface {
	onEvent: (event: UserMenuActionDto) => void;
	onOpenChange?: (open: boolean) => void;
}

const StyledUserMenu = styled.div`
	display: flex;
	align-items: center;
`;

const UserMenu = ({ id, user, onEvent, onOpenChange }: UserMenuProps): ReactElement => {
	const handleClick = (click: PopoverMenuItemInterface) => {
		const navBarAction: UserMenuActionDto = {
			id,
			action: click.action as NavigationBarClickActionEnum,
		};
		onEvent(navBarAction);
	};

	const displayName = user.firstName || user.lastName || '';

	return (
		user && (
			<Tooltip tip="User settings" open={TooltipPosition.BOTTOM}>
				<Dropdown
					trigger={
						<StyledUserMenu>
							{displayName && <UserAvatar size="20" firstName={user.firstName ?? ''} lastName={user.lastName ?? ''} />}
						</StyledUserMenu>
					}
					onOpenChange={onOpenChange}
					top={12}
					items={createOptions(user)}
					onEvent={(item) => {
						handleClick(item);
					}}
				/>
			</Tooltip>
		)
	);
};

export default UserMenu;
