import * as Switch from '@radix-ui/react-switch';
import './SwitchToggle.scss';

export interface SwitchToggleProps {
	checked: boolean;
	onCheckedChange: (checked: boolean) => void;
}

const SwitchToggle = ({ checked, onCheckedChange }: SwitchToggleProps) => {
	return (
		<div className="switch-toggle-container">
			<Switch.Root className="SwitchRoot" id="toggle-mode" checked={checked} onCheckedChange={onCheckedChange}>
				<Switch.Thumb className="SwitchThumb" />
			</Switch.Root>
		</div>
	);
};

export default SwitchToggle;
