import styled from 'styled-components';
import { SourceInterface } from 'interfaces/SourceInterface';
import getTyleCardSource from 'helpers/getTyleCardSource';
import { MouseEventHandler, useCallback } from 'react';

export const StyledSource = styled.a.attrs((props: { selected: boolean }) => props)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	color: ${(props) => props.theme.colors?.paragraph};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	font-size: ${(props) => props.theme.fontSizes?.headingXSmall};
	background-color: none;

	&:hover {
		color: ${(props) => props.theme.colors?.softParagraph};
	}
`;

export const StyledSourceLink = styled.span`
	font-size: 13px;
	line-height: 17.5px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${(props) => props.theme.colors?.softParagraph};
`;

export const StyledSourceIcon = styled.img`
	width: 12px;
	height: 12px;
	margin-right: 3px;
	border-radius: 2px;
`;

export interface FaviconProps {
	source: SourceInterface;
	favicon?: boolean;
	link?: boolean;
}

const Favicon = ({ source, favicon = true, link = false }: FaviconProps) => {
	const handleSourceClick: MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
		event.stopPropagation();
	}, []);
	return source.url ? (
		<StyledSource href={getTyleCardSource(source)?.url ?? ''} target="_blank" onClick={handleSourceClick}>
			{favicon && source.url && <StyledSourceIcon src={getTyleCardSource(source)?.favicon} />}
			{link && <StyledSourceLink>{getTyleCardSource(source)?.hostName ?? ''}</StyledSourceLink>}
		</StyledSource>
	) : (
		<StyledSourceLink>{source.applicationName}</StyledSourceLink>
	);
};

export default Favicon;
