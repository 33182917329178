import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTyleWindowHeader = styled.div.attrs(
	(props: { isDragging: boolean; columns: number; selected: boolean }) => props,
)`
	padding: 0 20px 0 8px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors?.stroke};
	background-color: ${({ isDragging, selected, theme }) =>
		isDragging || selected ? theme.colors?.selected : theme.colors?.backgroundActive};

	&:hover {
		background-color: ${({ theme }) => theme.colors?.selected};
	}
`;

export const StyledTyleWindowHeaderLeft = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
`;

export const StyledTyleWindowHeaderTitle = styled.p.attrs((props: { isDragging: boolean }) => props)`
	padding-left: 16px;
	margin: 0;
	font-size: ${(props) => props.theme.fontSizes?.h3};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	text-overflow: ellipsis; /* enables ellipsis */
	white-space: nowrap; /* keeps the text in a single line */
	overflow: hidden; /* keeps the element from overflowing its parent */
`;

export const StyledTyleWindowHeaderActions = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	> * {
		margin-left: 10px;
	}
`;

export const StyledTyleWindowHeaderFavoriteIconWrapper = styled(motion.div).attrs((props: { hide: boolean }) => props)`
	display: ${(props) => (props.hide ? 'none' : 'block')};
	margin-left: 10px;
	color: #666666;

	> * {
		cursor: default;
	}
`;

export const StyledSeparator = styled.div`
	border-right: 1px solid ${(props) => props.theme.colors?.stroke};
	margin-left: 10px;
	height: 12px;
`;
