import React, { ReactElement, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import Toast from '../components/toast/Toast';
import Text from '../components/text/Text';
import Link from '../components/link/Link';
import Icon from '../components/icon';
import getReferralDashboardUrl from '../helpers/getReferralDashboardUrl';
import openExternalUrl from '../helpers/openExternalUrl';
import userState from '../state/UserState';

const SHOW_REFERRAL = 'showReferral';

const ReferralToast = (): ReactElement => {
	const showReferral = JSON.parse(localStorage.getItem(SHOW_REFERRAL) || 'false');
	const [isShowToast, setIsShowToast] = useState(showReferral);
	const { user } = useSnapshot(userState);

	/**
	 * Check number days between actual date and user registration date
	 * If days are >= 14 and showReferral on localStorage is not set to false,
	 * show notification and set showReferral to true.
	 * The showReferral on localStorage will be set to false only when the user will click on close icon
	 */
	useEffect(() => {
		if (!userState.user?.createdAt || (localStorage.getItem(SHOW_REFERRAL) !== null && !showReferral)) {
			return;
		}
		const registrationDate: any = new Date(userState.user.createdAt);
		const today: any = new Date();
		const diffTime = Math.abs(today - registrationDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		if (diffDays >= 14) {
			localStorage.setItem(SHOW_REFERRAL, JSON.stringify(true));
			// setIsShowToast(true);
		}
	}, [user]);

	const handleLinkClick = () => {
		if (!userState.user?.referralCode) {
			return;
		}
		openExternalUrl(getReferralDashboardUrl(userState.user.referralCode));
	};

	if (isShowToast && user && user.referralCode) {
		return (
			<Toast
				width={191}
				onCloseClick={() => {
					localStorage.setItem(SHOW_REFERRAL, JSON.stringify(false));
					setIsShowToast(false);
				}}
			>
				<Text weight="semibold">Enjoying Tyles?</Text>
				<br />
				<Text>Refer a friend and get both of you 3 months of Tyles Pro for free.</Text>
				<br />
				<Link
					variant="no-decoration"
					href={getReferralDashboardUrl(user.referralCode)}
					target="_blank"
					onLinkClick={handleLinkClick}
				>
					<span>Referral link </span>
					<Icon type="arrow-right-slim" style={{ lineHeight: '19.5px', fontSize: '13px' }} />
				</Link>
			</Toast>
		);
	}

	return <></>;
};

export default ReferralToast;
