/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect, useState } from 'react';
import { Link as InternalLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import Lottie from 'lottie-react';
import TermsAndService from 'components/TermsAndService';
import Headline from 'components/headline';
import useElectronEvents from 'helpers/useElectronEvents';
import Input from 'components/input';
import Paragraph from 'components/paragraph';
import checkmark from 'styles/animations/checkmark-anim.json';
import { ApiContext } from 'components/App';
import useAuthenticationValidation from 'helpers/useAuthenticationValidation';
import { detectOperatingSystem, useEnvironmentDetection } from 'helpers';
import useSignup from 'hooks/SignupHooks/useSignup';
import Button from 'components/button';
import Spacing from 'components/spacing';
import { renderTylesBetaHeadlineLogo } from 'components/icon/helpers';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import Carousel from 'components/Carousel';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Because Typescript can't find the image file.
import dark from 'themes/dark';
import backgroundUrl from '../assets/images/background_gradient_signup.png';
import LoadingSpinner from '../components/loading-spinner';

const fadeInAnimation = keyframes`${fadeIn}`;

const FadeInDiv = styled.div`
	animation: 3s ${fadeInAnimation};
	position: fixed;
	top: 150px;
`;

const Wrapper = styled.div`
	background-color: #000000;
	width: 100%;
	height: 100vh;
`;

const Navbar = styled.div`
	position: absolute;
	width: 100%;
	height: 30px;
	-webkit-app-region: drag;
`;

const PageWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-flow: row;
`;

const Background = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: url(${backgroundUrl});
	height: 100vh;
`;

const StyledInternalLink = styled(InternalLink)`
	text-decoration: none;
	color: #aaaaaa;

	&:hover {
		color: #aaaaaa;
		text-decoration: underline;
	}
`;

const PageStyle = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	width: 50vw;
`;

const RightPageStyle = styled(PageStyle)`
	background-image: radial-gradient(30% 100% at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 1));
`;

const SwitchLoginMode = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-flow: row;
	align-items: center;
`;

export interface Props {
	mode?: 'signup' | 'login';
}

export const LoadingSpinnerTestId = 'loading-spinner';

const SignInPage = ({ mode }: Props) => {
	const [email, setEmail] = useState<string>('');
	const [inviteCode, setInviteCode] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [show, setShow] = useState('form');
	const api = useContext(ApiContext);
	const [emailErrorMsg, setEmailErrorMsg] = useState<string | undefined>();
	const [inviteCodeErrorMsg, setInviteCodeErrorMsg] = useState<string | undefined>();
	const { emailValidationMessage, inviteCodeValidationMessage } = useAuthenticationValidation();
	const environment = useEnvironmentDetection();
	const { sendMessage } = useElectronEvents();
	const { signup } = useSignup();
	const win32 = detectOperatingSystem() === OperatingSystemPlatformEnum.WINDOWS;
	const isDev = process.env.NODE_ENV === 'development';

	useEffect(() => {
		setEmailErrorMsg(undefined);
		setInviteCodeErrorMsg(undefined);
	}, [mode]);

	const submitLogin = async () => {
		setLoading(true);
		const validationMessageEmail = emailValidationMessage(email);
		setEmailErrorMsg(validationMessageEmail);
		if (validationMessageEmail) {
			return;
		}

		await api
			.sendLoginRequest(email, environment)
			.then(() => {
				setLoading(false);
				setShow('note');
				sendMessage({ subject: 'user', message: 'login' });
			})

			.catch((err) => {
				setLoading(false);
				try {
					const errMessage = JSON.parse(err.message)?.message;
					setEmailErrorMsg(errMessage);
				} catch {
					setEmailErrorMsg(err.message);
				}
			});
	};

	const submitSignUp = async () => {
		setLoading(true);
		const validationMessageInviteCode = inviteCodeValidationMessage(inviteCode);
		setInviteCodeErrorMsg(validationMessageInviteCode);
		if (validationMessageInviteCode) {
			return;
		}

		const validationMessageEmail = emailValidationMessage(email);
		setEmailErrorMsg(validationMessageEmail);
		if (validationMessageEmail) {
			return;
		}

		await signup({ email, environment, inviteCode, referral: window.location.href.indexOf('referral') !== -1 })
			.then(() => {
				setLoading(false);
				setShow('note');
			})
			.catch((err) => {
				setLoading(false);
				try {
					const errMessage = JSON.parse(err.message)?.message;
					if (errMessage?.includes('Invite code')) {
						setInviteCodeErrorMsg(errMessage);
					} else {
						setEmailErrorMsg(errMessage);
					}
				} catch {
					setEmailErrorMsg(err.message);
				}
			});
	};

	const updateEmail = (emailValue: string) => {
		setEmailErrorMsg(undefined);
		setEmail(emailValue);
	};

	const updateInviteCode = (inviteCodeValue: string) => {
		setInviteCodeErrorMsg(undefined);
		setInviteCode(inviteCodeValue);
	};

	const submit = () => {
		if (mode === 'login') {
			submitLogin().then();
			return;
		}
		submitSignUp().then();
	};

	const renderContent = (headline: string, paragraph: string, textAlign: 'center' | 'left') => {
		return (
			<div>
				<Headline style={{ color: '#EEEEEE', textAlign }} variant="h2">
					{headline}
				</Headline>
				<Paragraph style={{ color: '#AAAAAA', textAlign, padding: '0.5rem 0 0.5rem 0' }}>{paragraph}</Paragraph>
			</div>
		);
	};

	const renderLoginForm = () => {
		const loginOrSignupContent =
			mode === 'signup'
				? renderContent('Signup', "Enter your email and we'll send you a link to create a new profile.", 'left')
				: renderContent('Login', "Enter your email and we'll send you a link to login to your account.", 'left');

		return (
			<>
				<div style={{ maxWidth: '385px' }} className="signIn-page_input">
					{loginOrSignupContent}
					<Input
						style={{
							marginBottom: `${mode === 'signup' ? '25px' : '15px'}`,
							backgroundColor: '#1E1E1E',
							borderColor: '#343434',
							color: '#EDEDED',
							fontSize: '13px',
							width: '100%',
						}}
						onChange={updateEmail}
						onSubmit={submit}
						required
						focus
						label="Email"
						placeholder="Enter your email address..."
						validationMessage={emailErrorMsg}
					/>
					{mode === 'signup' && (
						<Input
							style={{
								marginBottom: '15px',
								backgroundColor: '#1E1E1E',
								borderColor: '#343434',
								color: '#EDEDED',
								fontSize: '13px',
								width: '100%',
							}}
							onChange={updateInviteCode}
							onSubmit={submit}
							label="Invite code"
							required
							placeholder="Enter your invite code..."
							validationMessage={inviteCodeErrorMsg}
						/>
					)}
					<SwitchLoginMode>
						{mode === 'login' && (
							<Paragraph style={{ textAlign: 'left' }} variant="small">
								<StyledInternalLink to="/signup">New here? Register an account</StyledInternalLink>
							</Paragraph>
						)}
						{mode === 'signup' && (
							<Paragraph variant="small">
								<StyledInternalLink to="/login">Already have an account? Sign in</StyledInternalLink>
							</Paragraph>
						)}
						<div style={{ margin: 0 }}>
							{!loading ? (
								<Button
									style={{
										backgroundColor: dark.colors.stroke,
										border: 'none',
										color: dark.colors.heading,
									}}
									label="Continue"
									onEvent={submit}
									disabled={!email}
								/>
							) : (
								<div data-testid={LoadingSpinnerTestId}>
									<LoadingSpinner size="sm" />
								</div>
							)}
						</div>
					</SwitchLoginMode>
				</div>
				<Spacing />
				{mode === 'signup' && <TermsAndService />}
			</>
		);
	};

	const redirectWindow = (deepLink: string) => {
		window.location.href = deepLink;
	};

	const renderNote = () => {
		return (
			<div style={{ width: '385px', height: '250px' }}>
				<Headline style={{ marginBottom: '15px', color: '#eeeeee' }} variant="h2">
					Check your email
				</Headline>
				<Paragraph style={{ color: '#aaaaaa' }}>
					Please open temporary login link sent to <br />
					<span style={{ color: '#eeeeee' }}>{email}</span>.
				</Paragraph>
				<Lottie
					animationData={checkmark}
					loop={false}
					style={{ width: '35px', height: '17px', transform: 'scale(2.5)' }}
				/>
				{win32 && isDev && (
					<>
						<Spacing size="extra-small" />
						<Paragraph style={{ color: 'orange' }}>
							Please copy and paste the login link with the token from the page that opens when you press the "Login to
							Tyles" button from the email.
						</Paragraph>
						<Input
							style={{
								marginBottom: '15px',
								backgroundColor: '#1E1E1E',
								borderColor: '#343434',
								color: '#EDEDED',
								fontSize: '13px',
								width: '100%',
							}}
							onSubmit={(value) => redirectWindow(value)}
							label="Token link"
							placeholder="Enter token link..."
						/>
					</>
				)}
				<Spacing size="extra-small" />
				<div onClick={() => setShow('form')}>
					<Paragraph style={{ color: '#777777', textDecoration: 'underline', cursor: 'pointer' }}>
						Wrong email? Click here
					</Paragraph>
				</div>
			</div>
		);
	};

	const formOrNote = show === 'form' ? renderLoginForm() : renderNote();

	const carouselSlides = () => {
		const slides = [
			{
				headline: 'Welcome',
				paragraph: 'Tyles is like a note app. Unlike any note app. Build up knowledge as easy as playing with Lego.',
			},
			{
				headline: 'A text clipper on steroids',
				paragraph: 'Tyles allows you to save information from web, PDFs or any app in seconds including its source.',
			},
			{
				headline: 'Connect thoughts easily',
				paragraph: 'Tyles enables you to connect information with simple drag and drop – all on a big canvas.',
			},
		];
		return slides.map((content) => renderContent(content.headline, content.paragraph, 'center'));
	};
	return (
		<Wrapper>
			<Navbar />
			<Background>
				<PageWrapper>
					<PageStyle>
						<FadeInDiv>{renderTylesBetaHeadlineLogo()}</FadeInDiv>
						{formOrNote}
					</PageStyle>
					<RightPageStyle>
						<img src="assets/videos/stayInFocusgif.gif" alt="stay_in_focus" />
						<Carousel slides={carouselSlides()} loop duration={5000} style={{ width: '65%' }} />
					</RightPageStyle>
				</PageWrapper>
			</Background>
		</Wrapper>
	);
};

export default SignInPage;
