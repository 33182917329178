import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { UserState } from '../models/State';

const userState = proxy<UserState>({
	isEditing: false,
	changeEmailError: '',
	showOnBoarding: false,
	user: {},
});

devtools(userState, { name: 'User' });

export default userState;
