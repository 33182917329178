import { ReactElement, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import {
	SearchSidebarItemInterface,
	SearchSidebarItemTypeEnum,
	SearchSidebarShortcutsEnum,
} from 'interfaces/SearchSidebarInterface';
import { TyleInterface } from 'interfaces/TyleInterface';
import SearchSidebarContentSectionOption from './SearchSidebarContentSectionOption';

const StyledSearchSidebarItem = styled(motion.div)`
	padding-top: 30px;
	min-height: 120px;
`;

const StyledSearchSidebarItemHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledSearchSidebarTitle = styled.p`
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 15px;
	color: ${(props) => props.theme.colors?.paragraph};
`;

const StyledSearchSidebarLength = styled(StyledSearchSidebarTitle)`
	font-weight: 400;
`;

const StyledSearchSidebarItemList = styled.ul.attrs((props: { type: SearchSidebarItemTypeEnum }) => props)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	list-style: none;
	padding: 0;
	margin: 0;
	max-height: ${(props) => (props.type === SearchSidebarItemTypeEnum.RECENTLY_SEARCHED ? '160px' : '100%')};
	overflow-y: auto;

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: ${(props) => props.theme.radius?.radius};
		background-color: ${(props) => props.theme.colors?.gray300};
		-webkit-box-shadow: 0 0 1px ${(props) => props.theme.shadows?.light};
		box-shadow: 0 0 1px ${(props) => props.theme.shadows?.light};
	}
`;

interface SearchSidebarItemProps {
	item: SearchSidebarItemInterface;
	index: number;
	changeInputText: (value: string) => void;
	createWindowInCanvas: (tyle: TyleInterface) => void;
}

const SearchSidebarContentSection = ({
	item,
	index,
	changeInputText,
	createWindowInCanvas,
}: SearchSidebarItemProps): ReactElement => {
	const { type, title, optionComponent: OptionComponent, options } = item;
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [selectedTab, setSelectedTab] = useState<SearchSidebarItemTypeEnum>();

	// Use shortcuts to focus items
	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			// Select item on enter
			if (event.key === SearchSidebarShortcutsEnum.ENTER && selectedIndex) {
				if (selectedTab === SearchSidebarItemTypeEnum.RECENTLY_SEARCHED) {
					changeInputText(options[selectedIndex - 1].text);
					return;
				}
				createWindowInCanvas(options[selectedIndex - 1]);
			}
			// Navigate trough items of each section when a section is selected with shortcuts
			if (event.key === SearchSidebarShortcutsEnum.ARROW_DOWN && selectedTab && selectedIndex < options.length) {
				// todo change 6 with length of options - 1
				const newIndex = selectedIndex + 1;
				setSelectedIndex(newIndex);
			}
			if (event.key === SearchSidebarShortcutsEnum.ARROW_UP && selectedTab && selectedIndex > 1) {
				const newIndex = selectedIndex - 1;
				setSelectedIndex(newIndex);
			}
			// Select recently searched when pressing option + 1
			if (event.altKey && event.code === SearchSidebarShortcutsEnum.KEY_1) {
				setSelectedTab(SearchSidebarItemTypeEnum.RECENTLY_SEARCHED);
				setSelectedIndex(1);
			}
			// Select favorites when pressing option + 2
			if (event.altKey && event.code === SearchSidebarShortcutsEnum.KEY_2) {
				setSelectedTab(SearchSidebarItemTypeEnum.FAVORITES);
				setSelectedIndex(1);
			}
			// Select search results when pressing option + 3
			if (event.altKey && event.code === SearchSidebarShortcutsEnum.KEY_3) {
				setSelectedTab(SearchSidebarItemTypeEnum.SEARCH_RESULTS);
				setSelectedIndex(1);
			}
		};
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [selectedTab, selectedIndex, options.length]);

	return (
		<StyledSearchSidebarItem custom={index}>
			<StyledSearchSidebarItemHeader>
				<StyledSearchSidebarTitle>{title}</StyledSearchSidebarTitle>
				{item.type !== SearchSidebarItemTypeEnum.RECENTLY_SEARCHED && (
					<StyledSearchSidebarLength>{options.length} results</StyledSearchSidebarLength>
				)}
			</StyledSearchSidebarItemHeader>

			<StyledSearchSidebarItemList type={type}>
				{options.map((tyle, i) => (
					<SearchSidebarContentSectionOption
						key={tyle.id}
						optionComponent={<OptionComponent tyle={tyle} selected={selectedIndex === i + 1 && type === selectedTab} />}
						value={tyle.id}
						selected={selectedIndex === i + 1 && type === selectedTab}
						onSelect={() => {
							if (type === SearchSidebarItemTypeEnum.RECENTLY_SEARCHED) {
								changeInputText(options[i].text);
								return;
							}
							createWindowInCanvas(tyle);
						}}
					/>
				))}
			</StyledSearchSidebarItemList>
		</StyledSearchSidebarItem>
	);
};

export default SearchSidebarContentSection;
