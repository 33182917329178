import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import sharedState from 'state/SharedState';

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export const ScrollSourceEnum = {
	TOUCHPAD: 'touchpad',
	MOUSEWHEEL: 'mousewheel',
};

export const detectTouchpad = (e: React.WheelEvent) => {
	const isThouchpad = Math.abs(e.deltaX) !== 0 || Math.abs(e.deltaY) < 15;
	return isThouchpad ? ScrollSourceEnum.TOUCHPAD : ScrollSourceEnum.MOUSEWHEEL;
};

/**
 * Enables horizontal mouse wheel scrolling on a div element. Does not apply to touchpads.
 */
export const onMouseWheel = (e: React.WheelEvent) => {
	const isThouchpad = detectTouchpad(e) === ScrollSourceEnum.TOUCHPAD;
	if (isThouchpad) {
		e.stopPropagation();
		return;
	}
	if (sharedState.isEditorHovered) return;
	const strength = Math.abs(e.deltaY);
	if (e.deltaY === 0) return;

	const el = e.currentTarget;

	el.scrollTo({
		left: el.scrollLeft + e.deltaY,
		// large scrolls with smooth animation behavior will lag, so switch to auto
		behavior: strength > 70 ? 'auto' : 'smooth',
	});
};

/**
 *  Enables horizontal mouse wheel scrolling on menu for react-horizontal-scrolling-menu.
 */
export const onWheel = (apiObj: ScrollVisibilityApiType, e: React.WheelEvent): void => {
	const isThouchpad = detectTouchpad(e) === ScrollSourceEnum.TOUCHPAD;
	if (isThouchpad) {
		e.stopPropagation();
		return;
	}

	if (e.deltaY < 0) {
		apiObj.scrollNext();
	} else if (e.deltaY > 0) {
		apiObj.scrollPrev();
	}
};
