import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './Text.scss';

export interface TextProps {
	children: any;
	style?: any;
	weight?: 'base' | 'light' | 'semibold' | 'bold';
}
const Text = ({ children, style, weight = 'base' }: TextProps): ReactElement => {
	return (
		<span
			className={classNames({
				'kum-text': true,
				'weight-base': weight === 'base',
				'weight-light': weight === 'light',
				'weight-semibold': weight === 'semibold',
				'weight-bold': weight === 'bold',
			})}
			style={style}
		>
			{children}
		</span>
	);
};

export default Text;
