import React from 'react';
import Button from 'components/button';
import Headline from 'components/headline';
import Paragraph from 'components/paragraph';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const StyledModal = styled.div`
	width: 478px;
	height: 197px;
	background: rgba(28, 21, 27, 0.5);
	backdrop-filter: blur(30px);
	border: 1px solid rgba(88, 88, 88, 0.5);
	border-radius: 10px;
	padding: 16px 32px;
`;

export interface Props {
	showModal: boolean;
	continueClicked?: boolean;
	handleClose: () => void;
	onSkipPermission: () => void;
}

const SkipPermissionsModal = ({ showModal, continueClicked, handleClose, onSkipPermission }: Props) => {
	return (
		<Modal centered className="skip-permissions_modal" show={showModal} onHide={handleClose} style={{ margin: 0 }}>
			<Modal.Body style={{ width: '478px' }}>
				<StyledModal>
					<Headline style={{ color: '#EDEDED' }} variant="h2">
						Are you sure?
					</Headline>
					<Paragraph style={{ fontSize: '14px', color: '#A0A0A0', paddingTop: '5px' }}>
						{continueClicked
							? `You haven't given us the required permission. You won't be able to capture information in seconds.`
							: `If you skip this step, you won’t be able to capture information in seconds. You can give permissions at any point later.`}
					</Paragraph>
					<div
						style={{
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'flex-end',
							paddingTop: '20px',
						}}
					>
						<Button
							style={{ border: 'none', color: '#EDEDED', fontSize: '14px' }}
							label="Go back"
							variant="basic"
							onEvent={handleClose}
						/>
						<Button
							style={{ backgroundColor: '#343434', border: 'none', color: '#EDEDED' }}
							label="Skip"
							onEvent={onSkipPermission}
						/>
					</div>
				</StyledModal>
			</Modal.Body>
		</Modal>
	);
};

export default SkipPermissionsModal;
