import { useState, useEffect } from 'react';

type DeviceType = 'phone' | 'tablet' | 'desktop';

interface DeviceInfo {
	deviceTypeByWidth: DeviceType;
	deviceType: DeviceType;
	isMobile: boolean; // True only if deviceType !== 'desktop', does not go by width
	userAgent: string;
}

const TABLET_REGEX =
	/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i;
const PHONE_REGEX =
	/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/i;

/**
 * Detects device type based on the user agent string
 * @param {string} userAgent - User agent string
 * @returns Detected device type ('phone', 'tablet', or 'desktop')
 */
const detectDeviceTypeByUserAgent = (userAgent: string): DeviceType => {
	if (TABLET_REGEX.test(userAgent)) {
		return 'tablet';
	}
	if (PHONE_REGEX.test(userAgent)) {
		return 'phone';
	}
	return 'desktop';
};

/**
 * Detects device type based on the window width
 * @returns Detected device type ('phone', 'tablet', or 'desktop')
 */
const getDeviceTypeByWidth = (): DeviceType => {
	const width = window.innerWidth;

	if (width < 768) {
		return 'phone';
	}
	if (width >= 768 && width < 1024) {
		return 'tablet';
	}
	return 'desktop';
};

/**
 * Custom hook to detect device type
 * @returns Information about the detected device type, mobile status, and user agent
 */
const useDeviceType = (): DeviceInfo => {
	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
		deviceTypeByWidth: 'desktop',
		deviceType: 'desktop',
		isMobile: false,
		userAgent: '',
	});

	useEffect(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		const deviceTypeByUserAgent = detectDeviceTypeByUserAgent(userAgent);

		const updateDeviceInfo = () => {
			const deviceTypeByWidth = getDeviceTypeByWidth();

			setDeviceInfo({
				deviceTypeByWidth,
				deviceType: deviceTypeByUserAgent,
				isMobile: deviceTypeByUserAgent !== 'desktop',
				userAgent,
			});
		};

		// Updates device information on window resize
		const onResize = () => {
			updateDeviceInfo();
		};

		window.addEventListener('resize', onResize);
		updateDeviceInfo();

		// Cleanup
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return deviceInfo;
};

export default useDeviceType;
