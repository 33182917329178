import useAnalytics, { TrackingEvent } from './useAnalytics';

const useErrorHooks = () => {
	const { track } = useAnalytics();

	const trackErrorAndRefreshApp = () => {
		window.location.reload();
		track(TrackingEvent.APP_ERROR_AND_REFRESH);
	};

	return { trackErrorAndRefreshApp };
};

export default useErrorHooks;
