import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import './Modals.scss';

export interface ModalErrorProps {
	children: ReactNode;
	show: boolean;
	onClose: () => void;
	stacked?: number;
	closeTimer?: number; // in milliseconds
}

const ErrorModal = ({ children, show, stacked = 1, closeTimer = 5000, onClose }: ModalErrorProps): ReactElement => {
	const [showState, setShowState] = useState<boolean>(show);

	// Close Error modal after 5 seconds or closeTimer input value
	useEffect(() => {
		if (!showState) {
			return;
		}
		setTimeout(() => {
			setShowState(false);
			onClose();
		}, closeTimer);
	}, [showState]);

	// Toggle modal
	useEffect(() => {
		if (showState === show) return;
		setShowState(!showState);
	}, [show]);

	return (
		<Modal
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			animation
			data-backdrop="static"
			onHide={() => {
				onClose();
				setShowState(false);
			}}
			backdropClassName={`${classNames({
				'error-modal-backdrop': true,
			})} stacked-${stacked}`}
			className={`${classNames({
				'error-modal': true,
				prompt: true,
			})} stacked-${stacked}`}
		>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
};

export default ErrorModal;
