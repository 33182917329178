import { ReactElement } from 'react';
import './Pages.scss';
import { renderTylesHeadlineLogo } from '../components/icon/helpers';
import Headline from '../components/headline';
import Paragraph from '../components/paragraph';

const DocumentRemovedPage = (): ReactElement => {
	return (
		<div className="document-removed">
			{renderTylesHeadlineLogo()}
			<Headline variant="h1">Document has been removed</Headline>
			<Paragraph>We couldn&#39;t load the document you were trying to open</Paragraph>
		</div>
	);
};

export default DocumentRemovedPage;
