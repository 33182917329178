import gradient from 'assets/images/mobile-blobs.png';
import useDeviceType from 'hooks/useDetectDeviceType';
import Headline from 'components/headline';
import dark from 'themes/dark';
import Paragraph from 'components/paragraph';
import { renderTylesBetaHeadlineLogo } from 'components/icon/helpers';
import Spacing from 'components/spacing';
import { MobileGradient, MobileViewContainer, MobileViewContent, TabletGradient } from './MobileView.styled';

const MobileView = () => {
	const { deviceTypeByWidth } = useDeviceType();
	return (
		<MobileViewContainer>
			<MobileViewContent>
				{renderTylesBetaHeadlineLogo()}
				<Spacing size="small" />
				<Headline variant="h1" style={{ color: `${dark.colors.heading}` }}>
					Apologies, Tyles only works on desktop for now
				</Headline>
				<Spacing size="extra-small" />
				<Paragraph>
					It seems like you’re coming from a mobile device. Please try to log in again from a desktop device.
				</Paragraph>
			</MobileViewContent>
			{deviceTypeByWidth === 'phone' ? (
				<MobileGradient alt="mobile-blob" src={gradient} />
			) : (
				<TabletGradient alt="tablet-blob" src={gradient} />
			)}
		</MobileViewContainer>
	);
};

export default MobileView;
