import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './Divider.scss';

export interface DividerProps {
	direction?: 'horizontal' | 'vertical';
}

const Divider = ({ direction }: DividerProps): ReactElement => (
	<div
		className={classNames({
			'kum-divider': true,
			vertical: direction === 'vertical',
		})}
	/>
);

export default Divider;
