import { NavigationBarClickActionEnum } from 'interfaces/NavigationBarInterface';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import { UserInterface } from '../../interfaces/UserInterface';
import { detectOperatingSystem } from '../../helpers';
import { OperatingSystemPlatformEnum } from '../../helpers/detectOperatingSystem';

export const TERMINAL_NAME = 'Clipper';

const createOptions = (user: UserInterface): Array<PopoverMenuItemInterface> => {
	const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
	const isWindows = detectOperatingSystem() === OperatingSystemPlatformEnum.WINDOWS;

	const options: Array<PopoverMenuItemInterface> = [
		{
			header: true,
			icon: 'tyles-logo',
			strongLabel: 'Tyles',
			label: ' | Beta',
			disabled: true,
		},
		/*
		{
			label: 'Change theme',
			action: NavigationBarClickActionEnum.CHANGE_THEME,
		},
		 */
		{
			label: 'Settings',
			action: NavigationBarClickActionEnum.SETTINGS,
		},
		{
			divider: true,
			data: { key: 'divider1' },
		},
	];

	if (isMacOS) {
		options.push({
			label: `Download ${TERMINAL_NAME}`,
			action: '',
			submenuItems: [
				{
					label: 'Download for Apple Silicon',
					action: NavigationBarClickActionEnum.GET_APPLE_SILICON_APP,
				},
				{
					label: 'Download for Apple Intel',
					action: NavigationBarClickActionEnum.GET_APPLE_INTEL_APP,
				},
			],
		});
	}

	if (isWindows) {
		options.push({
			label: `Download ${TERMINAL_NAME} for Windows`,
			action: NavigationBarClickActionEnum.GET_WINDOWS_APP,
		});
	}

	if (user.referralCode) {
		options.push({
			label: 'Refer a friend',
			action: NavigationBarClickActionEnum.REFERRAL,
		});
	}
	return [
		...options,
		{
			divider: true,
			data: { key: 'divider2' },
		},
		{
			label: 'Logout',
			action: NavigationBarClickActionEnum.LOGOUT,
		},
	];
};

export default createOptions;
