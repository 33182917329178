import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import React from 'react';
import Headline from '../headline';
import Paragraph from '../paragraph';
import Button from '../button';

const StyledModal = styled.div`
	width: 478px;
	height: 197px;
	background: rgba(28, 21, 27, 0.5);
	backdrop-filter: blur(30px);
	border: 1px solid rgba(88, 88, 88, 0.5);
	border-radius: 10px;
	padding: 16px 32px;
`;

export interface Props {
	showModal: boolean;
	type: 'accessibility' | 'screen-recording';
	okayClicked: () => void;
	handleClose: () => void;
}

const ErrorPermissionsModal = ({ showModal, type, okayClicked, handleClose }: Props) => {
	return (
		<Modal centered className="error-permissions-modal" show={showModal} onHide={handleClose} style={{ margin: 0 }}>
			<Modal.Body style={{ width: '478px' }}>
				<StyledModal>
					<Headline style={{ color: '#EDEDED' }} variant="h2">
						Please give permission
					</Headline>
					<Paragraph style={{ fontSize: '14px', color: '#A0A0A0', paddingTop: '5px' }}>
						{type === 'accessibility'
							? 'In order to continue, you need to grant access to your computer’s Accessibility features.'
							: 'In order to continue, you need to grant access to your computer’s Screen Recording features.'}
					</Paragraph>
					<div
						style={{
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'flex-end',
							paddingTop: '20px',
						}}
					>
						<Button
							style={{ backgroundColor: '#343434', border: 'none', color: '#EDEDED' }}
							label="Okay"
							onEvent={okayClicked}
						/>
					</div>
				</StyledModal>
			</Modal.Body>
		</Modal>
	);
};

export default ErrorPermissionsModal;
