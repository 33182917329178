import { TokenPair as ApiTokenPair } from '../api/generated/client';
import { TokenPair } from '../models/TokenPair';
import { TokenPairInterface } from '../interfaces/TokenPairInterface';
import InvalidDataException from '../exceptions/InvalidDataException';

const adaptTokenPair = (tokenPair: ApiTokenPair): TokenPairInterface => {
	if (!tokenPair.token || !tokenPair.refreshToken) {
		throw new InvalidDataException();
	}

	return new TokenPair({
		token: tokenPair.token,
		refreshToken: tokenPair.refreshToken,
	});
};

export default adaptTokenPair;
