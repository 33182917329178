/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { PlatePluginComponent } from '@udecode/plate';
import { subscribeKey } from 'valtio/utils';
import { useSnapshot } from 'valtio';
import tylesState from 'state/TylesState';
import TyleWrapper from 'components/tyle/TyleWrapper';
import {
	defaultWindowHeight,
	defaultWindowWidth,
	defaultWindowZIndex,
} from 'interfaces/DragAndDrop/DragAndDropInterfaces';
import useTyleHooks from 'hooks/useTyleHooks';
import getTylePreviewData from 'helpers/getTylePreviewData';
import truncateString from 'helpers/truncateString';
import { TyleActionEnum, TyleInterface } from 'interfaces/TyleInterface';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import { TyleMenuEnum } from 'interfaces/TerminalMenuInterface';
import DeleteConfirmationModal from 'containers/DeleteConfirmationModal';
import { CustomEditor, CustomElement } from 'types/slate';
import { DragCard } from 'components/DragAndDrop/DragElements/DragCards';
import { DragAndDropAreaEnum, DragItemTypeEnum } from 'interfaces/DragAndDrop/DragAndDropEnums';
import { InlineTypes, Transformations } from 'components/editor/transforms/transformations';
import { detectOperatingSystem } from 'helpers';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import transformToTyleBlock from 'components/editor/transforms/transformToTyleBlock';
import transformToTyleInline from 'components/editor/transforms/transformToTyleInline';
import transformToText from 'components/editor/transforms/transformToText';
import removeElement from 'components/editor/utils/removeElement';
import './TyleBlock.scss';
import { getEmptyImage } from 'react-dnd-html5-backend';
import sharedState from 'state/SharedState';
import canvasState from 'state/CanvasState';
import useTyleCardDragHooks from 'hooks/DragAndDropHooks/useTyleCardDragHooks';
import deleteElementTyle from '../../utils/deleteElement';
import useCanvasHooks from '../../../../hooks/useCanvasHooks';

const TyleBlock: PlatePluginComponent = ({ attributes, children, element, nodeProps, editor }: any) => {
	const { tyleId } = element;
	const { isPublicView } = useSnapshot(canvasState);
	const { updateTyle, getTyle } = useTyleHooks();
	const { addTyleToNavigation } = useCanvasHooks();
	const { confirmDelete } = deleteElementTyle();
	const [isHotkeyPressed, setIsHotkeyPressed] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [tyleToDeleteData, setTyleToDeleteData] = useState<{ editor: CustomEditor; element: any } | undefined>();
	const [tyleToRender, setTyleToRender] = useState<TyleInterface | undefined>(tylesState.tylesObjects[tyleId]);

	const [{ isDragging }, drag, preview] = useTyleCardDragHooks({
		id: nanoid(),
		tyleId,
		type: DragItemTypeEnum.ELEMENT_TYLE_BLOCK,
	});

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		if (tyleId && !tylesState.tylesObjects[tyleId]) {
			getTyle(tyleId).then((tyleFromBackend: TyleInterface) => {
				if (!tyleFromBackend || tyleFromBackend?.deleted) {
					removeElement({ editor, element });
					return;
				}
				setTyleToRender(tyleFromBackend);
			});
		}

		const unsubscribe = subscribeKey(tylesState.tylesObjects, tyleId, (value: TyleInterface) => {
			if (!value || value?.deleted) {
				removeElement({ editor, element });
			}
			setTyleToRender(value);
		});

		/**
		 *  When the user focus the app in the browser tab,
		 *  force re-rendering of the tyle window to update the content in the editor
		 */
		const unsubscribe2 = subscribeKey(sharedState, 'appFocused', () => {
			getTyle(tyleId).then((tyleFromBackend: TyleInterface) => {
				if (!tyleFromBackend || tyleFromBackend?.deleted) {
					removeElement({ editor, element });
					return;
				}
				setTyleToRender(undefined);
				setTimeout(() => {
					setTyleToRender(tyleFromBackend);
				}, 1);
			});
		});

		return () => {
			unsubscribe();
			unsubscribe2();
		};
	}, [tyleId]);

	const deleteElement = ({
		editorToEdit,
		elementToEdit,
	}: {
		editorToEdit: CustomEditor;
		elementToEdit: CustomElement;
	}) => {
		setShowDeleteModal(true);
		setTyleToDeleteData({ editor: editorToEdit, element: elementToEdit });
	};

	const handleAction = (item: PopoverMenuItemInterface) => {
		switch (item.action) {
			case Transformations.ELEMENT_TYLE_BLOCK:
				transformToTyleBlock({ editor, elementToTransform: element });
				break;

			case Transformations.ELEMENT_TYLE_INLINE_TITLE:
				transformToTyleInline({ editor, elementToTransform: element, view: InlineTypes.TITLE });
				break;

			case Transformations.ELEMENT_TYLE_INLINE_CONTENT:
				transformToTyleInline({ editor, elementToTransform: element, view: InlineTypes.CONTENT });
				break;

			case Transformations.ELEMENT_TEXT:
				transformToText({ editor, elementToTransform: element });
				break;

			case TyleActionEnum.REMOVE:
				removeElement({ editor, element });
				break;
			case TyleActionEnum.FAVORITE_ADD:
				if (!tyleToRender) {
					return;
				}
				updateTyle({ ...tyleToRender, favorite: true }, 'FROM TYLE BLOCK 86').then();
				break;

			case TyleActionEnum.FAVORITE_REMOVE:
				if (!tyleToRender) {
					return;
				}
				updateTyle({ ...tyleToRender, favorite: false }, 'FROM TYLE BLOCK 93').then();
				break;

			case TyleActionEnum.DELETE:
				deleteElement({ editorToEdit: editor, elementToEdit: element });
				break;

			default:
				console.log(item);
		}
	};

	const onSingleClickHandler = () => {
		if (!element || !editor.id || !tyleId) {
			return;
		}
		if (canvasState.isPublicView && sharedState.publicWindowEntity?.windowId) {
			tylesState.clickedTyle = { windowId: editor.id, tyleId };
			return;
		}
		addTyleToNavigation({
			windowId: editor.id,
			destinationTyleId: tyleId,
			isPublicTyle: !!(canvasState.isPublicView && sharedState.publicWindowEntity),
		});
	};

	const onCmdOrCtrlClick = (event: any) => {
		if (!element) {
			return;
		}
		const { tyle: tyleToOpen } = element;
		if (isHotkeyPressed) {
			event.stopPropagation();
			tylesState.cmdOrCtrlClickedTyle = tyleToOpen.id;
		}
	};

	useEffect(() => {
		const onKeydown = (e: any) => {
			const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
			const cmdOrCtrl = isMacOS ? e.metaKey : e.ctrlKey;
			if (cmdOrCtrl) {
				setIsHotkeyPressed(true);
			}
		};

		const onKeyup = (e: any) => {
			setIsHotkeyPressed(false);
		};
		document.addEventListener('keydown', onKeydown);
		document.addEventListener('keyup', onKeyup);
		return () => {
			document.removeEventListener('keydown', onKeydown);
			document.removeEventListener('keyup', onKeyup);
		};
	}, [editor]);

	const tyleData = tyleToRender && getTylePreviewData(tyleToRender);

	return (
		<div className="editor-tyle-block" {...attributes} contentEditable={false}>
			<div
				className="editor-tyle-element"
				style={{ userSelect: 'none' }}
				contentEditable={false}
				onClick={(e) => {
					// prevent bubbling up because of Slate DOM error
					// e.preventDefault();
					// e.stopPropagation();
					onCmdOrCtrlClick(e);
					if (isHotkeyPressed) {
						return;
					}
					onSingleClickHandler();
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						onSingleClickHandler();
					}
				}}
				role="button"
				tabIndex={0}
			>
				{tyleData && tyleToRender && (
					<DragCard
						id={nanoid()}
						tyleId={tyleId ?? 'anonymous'}
						type={DragItemTypeEnum.ELEMENT_TYLE_BLOCK}
						from={DragAndDropAreaEnum.EDITOR}
						to={DragAndDropAreaEnum.EDITOR}
						width={defaultWindowWidth}
						height={defaultWindowHeight}
						zIndex={defaultWindowZIndex}
					>
						<TyleWrapper
							id={tyleId}
							showLinkedTyles={false}
							showDerivedInstances={false}
							hideInstances
							hideOptions={isPublicView}
							hasDerivedInstances={false}
							onEvent={handleAction}
							onSelect={onSingleClickHandler}
							hideLinks
							tyleData={tyleData}
							link={tyleToRender.link}
							backlink={tyleToRender.backlink}
							favorite={tyleToRender.favorite}
							deleted={tyleToRender.deleted}
							createdAt={tyleToRender.createdAt}
							dropdownMenu={[
								{
									label: 'Display Inline',
									action: Transformations.ELEMENT_TYLE_INLINE_TITLE,
								},
								{
									divider: true,
								},
								{
									label: 'Turn into text',
									action: Transformations.ELEMENT_TEXT,
								},
								{
									label: 'Remove',
									action: TyleActionEnum.REMOVE,
								},
								{
									label: 'Delete',
									action: TyleActionEnum.DELETE,
								},
								!tyleToRender.favorite
									? {
											label: TyleMenuEnum.ADD_TO_FAVORITE,
											action: TyleActionEnum.FAVORITE_ADD,
									  }
									: {
											label: TyleMenuEnum.REMOVE_FROM_FAVORITES,
											action: TyleActionEnum.FAVORITE_REMOVE,
									  },
							]}
						/>
					</DragCard>
				)}
				{showDeleteModal && (
					<DeleteConfirmationModal
						show={!!tyleToDeleteData?.element?.tyleId}
						onClose={() => setShowDeleteModal(false)}
						onSubmit={() => {
							confirmDelete(tyleToDeleteData)
								.then(() => {
									setShowDeleteModal(false);
								})
								.catch((err) => {
									setShowDeleteModal(false);
									console.log('ERROR DELETING TYLE', err);
								});
						}}
						headline={`Delete Tyle "${truncateString(
							tyleToDeleteData?.element?.tyle?.title ? tyleToDeleteData?.element?.tyle?.title : 'Untitled',
							34,
						)}"`}
						content={`Are you sure you want to delete the Tyle "${truncateString(
							tyleToDeleteData?.element?.tyle?.title ? tyleToDeleteData?.element?.tyle?.title : 'Untitled',
							tyleToDeleteData?.element?.tyle?.backlink?.length > 0 ? 13 : 45,
						)}"? ${
							tyleToDeleteData?.element?.tyle?.backlink?.length > 0
								? `This action will affect ${tyleToDeleteData?.element?.tyle?.backlink?.length} Tyles and action can’t be undone`
								: 'This action can’t be undone.'
						}`}
					/>
				)}
			</div>
			{children}
		</div>
	);
};

export default TyleBlock;
