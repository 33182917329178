import { useContext } from 'react';
import tagsState from 'state/TagsState';
import { ApiContext } from '../components/App';
import useAnalytics, { TrackingEvent } from './useAnalytics';

const useTagsHooks = () => {
	const api = useContext(ApiContext);
	const { track } = useAnalytics();

	const getTopTags = (limit = 10) => {
		return api.getTopTags(limit).then((tags) => {
			return tags;
		});
	};

	/**
	 * Generates tags based on tyle content.
	 */
	const generateTags = (tyleId: string) => {
		track(TrackingEvent.GENERATE_TAGS_FOR_TYLE);
		return api.generateTagsForTyle(tyleId);
	};

	/**
	 * Get tags from search
	 */
	const getTagsFromSearch = async (searchPhrase: string, limit?: number, filters?: Record<string, string[]>) => {
		track(TrackingEvent.SEARCH_TAGS);
		const tags = await api.searchTags(searchPhrase, limit, filters);
		tagsState.searchResultsTags = tags;
		return tags;
	};
	/**
	 * Get tags from tyle id
	 */
	const getTagsByTyleId = async (tyleId: string) => {
		const tags = await api.getTagsByTyleId(tyleId);
		return tags;
	};

	return {
		getTopTags,
		generateTags,
		getTagsFromSearch,
		getTagsByTyleId,
	};
};

export default useTagsHooks;
