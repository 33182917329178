import { ToggleGeneratedUrl } from '../api/generated/client/index';
import InvalidDataException from '../exceptions/InvalidDataException';
import { GeneratedTyleUrlInterface } from '../interfaces/TyleInterface';
import TyleGeneratedUrl from '../models/TyleGeneratedUrl';

const adaptGeneratedUrl = (generatedUrlResponse: ToggleGeneratedUrl): GeneratedTyleUrlInterface => {
	if (!generatedUrlResponse.url) {
		throw new InvalidDataException('Missing url in payload');
	}

	return new TyleGeneratedUrl({
		url: generatedUrlResponse.url,
		disabled: generatedUrlResponse?.disabled || false,
		tyleId: generatedUrlResponse.tyleId || '',
	});
};

export default adaptGeneratedUrl;
