import React, { ReactElement } from 'react';
import Content from 'components/content';
import Headline from 'components/headline';
import ModalConfirmation from 'components/modal-confirmation';
import { Footer } from '../components/modal';
import Button from '../components/button';

export interface Props {
	show: boolean;
	headline: string;
	content: string;
	onClose: () => void;
	onSubmit: () => void;
}

const DeleteModalConfirmation = ({ show, headline, content, onClose, onSubmit }: Props): ReactElement => {
	return (
		<ModalConfirmation show={show} stacked={1}>
			<Content>
				<Headline variant="h2">{headline}</Headline>
				<p className="confirmation-message">{content}</p>
			</Content>
			<Footer>
				<Button
					label="Cancel"
					variant="basic"
					onEvent={(event: any) => {
						event.stopPropagation();
						onClose();
					}}
				/>
				<Button
					label="Delete"
					onEvent={(event: any) => {
						event.stopPropagation();
						onSubmit();
					}}
				/>
			</Footer>
		</ModalConfirmation>
	);
};

export default DeleteModalConfirmation;
