import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import useTyleHooks from '../useTyleHooks';
import useCanvasHooks from '../useCanvasHooks';
import sharedState from '../../state/SharedState';

const useShortcutHooks = () => {
	const { createEmptyTyle } = useTyleHooks();
	const { addTyleToUserCanvasTyles } = useCanvasHooks();
	const newNote = async () => {
		// create new note
		const newTyle = await createEmptyTyle();
		if (!newTyle.id) {
			return;
		}
		await addTyleToUserCanvasTyles({ windowId: nanoid(), tyleId: newTyle.id });
	};

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (sharedState.isEditorFocused) {
				return;
			}
			// check shortcuts
			if (event.altKey && event.code === 'KeyC') {
				newNote().then();
			}
		};
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	return { newNote };
};

export default useShortcutHooks;
