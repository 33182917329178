import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import Button from '../button';
import userState from '../../state/UserState';

export interface ModalDeleteAccountProps {
	children: ReactNode;
	show: boolean;
	onClose: () => void;
	onConfirm: () => void;
	stacked?: number;
	submitButtonLabel: string;
}

const DeleteAccountModal = ({
	children,
	show,
	stacked = 1,
	submitButtonLabel,
	onClose,
	onConfirm,
}: ModalDeleteAccountProps): ReactElement => {
	const [showState, setShowState] = useState<boolean>(show);

	// Toggle modal
	useEffect(() => {
		if (showState === show) return;
		setShowState(!showState);
	}, [show]);

	return (
		<Modal
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			animation
			data-backdrop="static"
			onHide={() => {
				onClose();
				setShowState(false);
			}}
			backdropClassName={`${classNames({
				'modal-backdrop': true,
			})} stacked-${stacked}`}
			className={`${classNames({
				'delete-account-modal': true,
				prompt: true,
			})} stacked-${stacked}`}
		>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				<div className="kum-modal-footer">
					<Button
						label="Cancel"
						variant="basic"
						onEvent={() => {
							onClose();
							setShowState(false);
						}}
					/>
					{Object.keys(userState.user).length && (
						<Button
							label={submitButtonLabel}
							variant="danger"
							onEvent={() => {
								onConfirm();
								setShowState(false);
							}}
						/>
					)}
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteAccountModal;
