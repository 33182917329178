import React, { ReactElement } from 'react';
import './Panel.scss';
import classNames from 'classnames';

export interface PanelProps {
	children: any;
	orientation?: 'left' | 'center' | 'right';
	paddingCondensed?: boolean;
	paddingCondensedSecondary?: boolean;
	strongShadow?: boolean;
	header?: any;
	size?: 'small' | 'large' | 'full-width';
	style?: Record<string, any>;
}

const Panel = ({
	children,
	orientation,
	paddingCondensed,
	paddingCondensedSecondary,
	header,
	strongShadow,
	size,
	style,
}: PanelProps): ReactElement => {
	return (
		<div
			style={style}
			className={classNames({
				'kum-panel': true,
				[`orientation-${orientation || 'left'}`]: true,
				'with-header': !!header,
				'padding-condensed': !!paddingCondensed,
				'padding-condensed-secondary': !!paddingCondensedSecondary,
				'shadow-strong': !!strongShadow,
				[size as string]: !!size,
			})}
		>
			{header ? <div className="panel-header">{header}</div> : <></>}
			{header ? <div className="panel-body">{children}</div> : children}
		</div>
	);
};

export default Panel;
