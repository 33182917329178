import { TyleInterface } from 'interfaces/TyleInterface';
import { CustomElement } from '../types/slate';

export type IconType = 'bookmark-icon' | 'quote-icon' | 'document-icon';

export interface TylePreviewDataInterface {
	title?: string;
	date: string;
	createdAt: Date;
	updatedAt: Date | undefined;
	content?: Array<CustomElement> | null;
	icon: IconType;
	sourceUrl: string;
	sourceTitle: string;
	applicationName?: string;
}

export default function getTylePreviewData(tyle: TyleInterface): TylePreviewDataInterface {
	const { title, content, source, createdAt, updatedAt } = tyle;
	const date = `${new Date(createdAt).toLocaleString('default', { month: 'short' })} ${new Date(createdAt).getDate()}`;
	const sourceTitle = (source && source.title) ?? '';
	const sourceUrl =
		(source && source.url && source.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]) ?? '';
	// Simplify this check
	const hasNoContent =
		(content && content.length === 0) ||
		(content &&
			content[0].children &&
			content.length === 1 &&
			content[0].children.length === 1 &&
			!content[0].children[0].text);

	const getIconName = (): IconType => {
		if (sourceUrl && sourceTitle && hasNoContent) return 'bookmark-icon';
		if (!updatedAt) return 'quote-icon';
		if (updatedAt) return 'document-icon';

		return 'document-icon';
	};

	return {
		title,
		date,
		createdAt,
		updatedAt,
		content: hasNoContent ? null : content,
		icon: getIconName(),
		applicationName: source?.applicationName,
		sourceUrl,
		sourceTitle,
	};
}
