import { ReactElement } from 'react';
import { useSnapshot } from 'valtio';
import Item from 'components/item';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/button';
import { TyleWindowActionEnum } from 'interfaces/TyleWindowInterface';
import canvasState from 'state/CanvasState';

export interface TyleLinksActionProps {
	linkLength: number;
	selected: boolean;
	onEvent: (action: TyleWindowActionEnum) => void;
}

export const LinksButtonTestId = 'links-button-test-id';
const TyleLinksAction = ({ linkLength, selected, onEvent }: TyleLinksActionProps): ReactElement | null => {
	if (linkLength === 0) return null;
	const { isPublicView } = useSnapshot(canvasState);

	return (
		<Item>
			<Tooltip tip="Links" offset={{ top: 3 }}>
				<Button
					testId={LinksButtonTestId}
					variant="link"
					label={`${linkLength} `}
					selected={selected}
					onEvent={() => {
						onEvent(TyleWindowActionEnum.VIEW_LINKS);
					}}
					iconPosition="left"
				/>
				{isPublicView && <span className="separator-links" />}
			</Tooltip>
		</Item>
	);
};

export default TyleLinksAction;
