import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { subscribeKey } from 'valtio/utils';
import { ApiService } from 'services/ApiService';
import { AuthenticationService } from 'services/AuthenticationService';
import { Api } from 'api/Api';
import { EnvironmentEnum } from 'helpers/detectEnvironment';
import { UserInterface } from 'interfaces/UserInterface';
import useIPCModal from 'hooks/ElectronHooks/useIPCModal';
import StorageService from 'services/StorageService';
import useAnalytics from 'hooks/useAnalytics';
import useAuthentication from 'helpers/useAuthentication';
import StyledTheme from 'themes';
import TokenValidationPage from 'pages/TokenValidationPage';
import NewEmailValidationPage from 'pages/NewEmailValidationPage';
import { useEnvironmentDetection } from 'helpers';
import PublicTylePage from 'pages/PublicTylePage';
import userState from 'state/UserState';
import InviteCodeValidationPage from 'pages/InviteCodeValidationPage';
import DesktopOnBoarding from 'containers/DesktopOnBoarding';
import LoggedOutApp from './LoggedOutApp';
import LoggedInApp from './LoggedInApp';
import './App.scss';

/**
 * TODO Please try to integrate the send function into the App component or use the useElectronEvents helper
 */
// const { send } = window.ipc;

declare module 'valtio' {
	function useSnapshot<T extends object>(p: T): T;
}

export const authenticationService = new AuthenticationService(StorageService());

export const api = new ApiService(new Api(authenticationService), authenticationService);

export const ApiContext = React.createContext(api);

export const queryClient = new QueryClient();

const App = (): ReactElement => {
	const { pageview, identify } = useAnalytics();
	const { isLoggedIn } = useAuthentication();
	const detectedEnvironment = useEnvironmentDetection();

	useEffect(() => {
		/**
		 * Identify user on Segment
		 */
		const unsubscribe = subscribeKey(userState, 'user', (value: UserInterface) => {
			if (value) {
				identify(value);
			}
		});

		pageview();

		return () => {
			unsubscribe();
		};
	}, []);

	// Add variable name for future implementation for modals
	const [, setShowMessage] = useState(false);
	const [, setModalChoice] = useState<boolean | null>(null);

	const toggleMessage = useCallback(() => setShowMessage((prevState) => !prevState), []);

	useIPCModal(toggleMessage, setModalChoice);

	const renderDownloadRoute = () => {
		return (
			<Route path="/download">
				<Switch>
					<Route
						path="/download/apple_silicon/mac"
						component={() => {
							window.location.href = 'https://tyles-public.s3.amazonaws.com/Tyles-0.9.3-arm64.dmg';
							setTimeout(() => {
								window.location.href = 'https://tyles.io/installation';
							}, 1000);
							return null;
						}}
					/>
					<Route
						path="/download/apple_intel/mac"
						component={() => {
							window.location.href = 'https://tyles-public.s3.amazonaws.com/Tyles-0.9.3.dmg';
							setTimeout(() => {
								window.location.href = 'https://tyles.io/installation';
							}, 1000);
							return null;
						}}
					/>
					<Route
						path="/download/windows_64/win"
						component={() => {
							// todo
							window.location.href = 'https://tyles-public.s3.amazonaws.com/Tyles+Setup+0.9.3.exe';
							setTimeout(() => {
								window.location.href = 'https://tyles.io/installation-windows';
							}, 1000);
							return null;
						}}
					/>
				</Switch>
			</Route>
		);
	};

	return process.env.NODE_ENV &&
		process.env.NODE_ENV === 'production' &&
		detectedEnvironment === EnvironmentEnum.DESKTOP ? (
		<QueryClientProvider client={queryClient}>
			<ApiContext.Provider value={api}>
				<StyledTheme>
					<HashRouter>
						<Switch>
							{renderDownloadRoute()}
							<Route path="/newEmail">
								<NewEmailValidationPage />
							</Route>
							<Route path="/desktopApp">
								<TokenValidationPage />
							</Route>
							<Route path="/desktopOnBoarding">
								<DesktopOnBoarding />
							</Route>
							<Route path="/">
								<div className="App">{!isLoggedIn() ? <LoggedOutApp /> : <LoggedInApp />}</div>
							</Route>
						</Switch>
					</HashRouter>
				</StyledTheme>
			</ApiContext.Provider>
		</QueryClientProvider>
	) : (
		<QueryClientProvider client={queryClient}>
			<ApiContext.Provider value={api}>
				<StyledTheme>
					<BrowserRouter>
						<Switch>
							{renderDownloadRoute()}
							<Route path="/desktopOnBoarding">
								<DesktopOnBoarding />
							</Route>
							<Route path="/newEmail/:token">
								<NewEmailValidationPage />
							</Route>
							{!isLoggedIn() && (
								<Route path="/inviteCode/:code">
									<InviteCodeValidationPage />
								</Route>
							)}
							<Route path="/:type/token/:token">
								<TokenValidationPage />
							</Route>
							<Route path="/tyle-url/:url">
								<PublicTylePage />
							</Route>
							<Route path="/">
								<div className="App">{!isLoggedIn() ? <LoggedOutApp /> : <LoggedInApp />}</div>
							</Route>
						</Switch>
					</BrowserRouter>
				</StyledTheme>
			</ApiContext.Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

export default App;
