const dark = {
	colors: {
		black100: '#111111',
		lightHeading: '#111111',
		gray100: '#111111',
		gray200: '#111111',
		gray300: '#111111',
		gray400: '#111111',
		gray500: 'rgba(246, 246, 246, 0.6)',
		white100: '#111111',
		background: '#161616',
		paragraph: '#A0A0A0',
		softParagraph: '#777777',
		selected: '#343434',
		stroke: '#343434',
		scrollThumb: '#c1c1c1',
		inlineBg: '#262626',
		heading: '#ededed',
		overlay: 'rgba(0, 0, 0, 0.65)',
		tagBg: '#1E1E1E',
		tagOutline: '#A48BC5',
		tagText: '#A48BC5',
		clipperBackground: 'rgba(28, 21, 27, 0.1)',
		clipperHeader: '#16ADDD',
	},
	newNote: {
		stroke: '#1D0F31',
		fill: '#7B61FF',
		textFill: '#FFFFFF',
		shortcutFill: '#FFFFF',
		shortcutTextFill: '#7B61FF',
	},
	radius: {
		base: '4px',
		big: '10px',
		normal: '8px',
		medium: '6px',
		ms: '5px',
		small: '2px',
		modal: '30px',
	},
	fontSizes: {
		paragraph: '0.9rem',
		medium: '0.8rem',
		headingSmall: '12px',
		paragraphSmall: '0.688rem',
		headingXSmall: '0.588rem',
		modalHeading: '1.2rem',
		headingBig: '20px',
	},
	lineHeights: {
		default: '1.55',
	},
	fontWeights: {
		base: '400',
		medium: '500',
		semiBold: '600',
		bold: '700',
	},
	letterSpacings: {
		minimum: '0.01em',
	},
	margins: {
		paragraph: '0 0 6px 0',
		list: '0 0 6px 16px',
	},
	paddings: {
		input: '15px 14px 14px 14px',
		tag: '2px 5px 2px 8px',
	},
	shadows: {
		light: '0px 1px 9px rgba(0, 0, 0, 0.07)',
		'drag-and-drop': '0px 1px 59px rgba(17, 17, 17, 0.11)',
		strong: '2px 5px 12px rgba(17, 17, 17, 0.26)',
		soft: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
	},
};

export default dark;
