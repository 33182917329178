import React, { ReactElement } from 'react';
import './Content.scss';

export interface ContentProps {
	children: any;
	padding?: boolean;
}

const Content = ({ children, padding }: ContentProps): ReactElement => {
	return <div className={`content ${padding && 'content-padding'}`}>{children}</div>;
};

export default Content;
