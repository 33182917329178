import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import '../modal/Modal.scss';
import { Modal } from 'components/modal';

export interface ModalConfirmationProps {
	children: ReactNode;
	show: boolean;
	stacked?: number;
}

const ModalConfirmation = ({ children, show, stacked }: ModalConfirmationProps): ReactElement => {
	const [showState, setShowState] = useState(show);

	useEffect(() => {
		if (showState === show) return;
		setShowState(!showState);
	}, [show]);

	return (
		<Modal stacked={stacked} variant="prompt" show={showState} handleClose={() => setShowState(false)}>
			{children}
		</Modal>
	);
};

export default ModalConfirmation;
