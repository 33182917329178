import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import { subscribeKey } from 'valtio/utils';
import { api } from 'components/App';
import { TyleInterface } from 'interfaces/TyleInterface';
import { DesktopAppTypeEnum } from 'interfaces/UserSettingsInterface';
import { NavigationBarActionDto, NavigationBarClickActionEnum } from 'interfaces/NavigationBarInterface';
import userState from 'state/UserState';
import useTyleHooks from 'hooks/useTyleHooks';
import UserSettingsModule from 'containers/UserSettings';
import tagsState from 'state/TagsState';
import getDownloadLink from 'helpers/getDownloadLink';
import getReferralDashboardUrl from 'helpers/getReferralDashboardUrl';
import openExternalUrl from 'helpers/openExternalUrl';
import useLogout from 'hooks/useLogout';
import useCanvasHooks from 'hooks/useCanvasHooks';
import { newCanvasStateService } from 'state/NewCanvas';
import tylesState from 'state/TylesState';
import useAnalytics, { TrackingEvent } from 'hooks/useAnalytics';
import SearchSidebar from './SearchSidebar';
import useSearchQueryHooks from '../../hooks/useSearchQueryHooks';

export const SearchSidebarContainerTestId = 'search-sidebar-container';

const SearchSidebarContainer = (): ReactElement => {
	const { user } = useSnapshot(userState);
	const { logout } = useLogout();
	const { getSearchResultsTyles } = useTyleHooks();
	const { getRecentSearchQueries } = useSearchQueryHooks();
	const { addTyleToUserCanvasTyles } = useCanvasHooks();
	const [showUserSettingsModal, setShowUserSettingsModal] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const { track } = useAnalytics();

	const debouncedTylesSearch = useCallback(
		debounce((input: string) => {
			const filters = tagsState.selectedTagIds.size ? { tags: Array.from(tagsState.selectedTagIds) } : undefined;
			if (!input && !filters) {
				return;
			}
			getSearchResultsTyles(input, filters, 10).then(() => {
				getRecentSearchQueries({ limit: 3 }).then();
			});
			setSearchValue(input);
		}, 500),
		[],
	);

	const closeUserSettingsModal = (closed: boolean) => {
		setShowUserSettingsModal(!closed);
	};

	// Update search results based on selected tags and search phrase
	useEffect(() => {
		const unsubscribeToTagState = subscribeKey(tagsState, 'selectedTagIds', (selectedTagIds) => {
			const filters = selectedTagIds.size ? { tags: Array.from(selectedTagIds) } : undefined;
			if (selectedTagIds.size && !searchValue) {
				getSearchResultsTyles('', filters, 10).then();
				return;
			}
			if (selectedTagIds.size && searchValue) {
				getSearchResultsTyles(searchValue, filters, 10).then();
				return;
			}
			if (!filters && !searchValue) {
				tylesState.searchResultsTyles = [];
				return;
			}
			if (!tagsState.selectedTagIds.size && searchValue) {
				getSearchResultsTyles(searchValue).then();
			}
		});
		return () => {
			unsubscribeToTagState();
		};
	}, [searchValue]);

	const addTyleToCanvas = async (tyle: TyleInterface) => {
		if (!tyle.id) return;
		const windowId = newCanvasStateService.getWindowForTyle(tyle.id);
		if (!windowId) {
			const newWindowId = nanoid();
			await addTyleToUserCanvasTyles({ windowId: newWindowId, tyleId: tyle.id });
			const element = document.getElementById(newWindowId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
			}
		}
		if (windowId) {
			const element = document.getElementById(windowId);
			if (element) {
				newCanvasStateService.selectWindow(windowId);
				element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
			}
		}
	};

	const handleUserMenuEvent = ({ action }: NavigationBarActionDto) => {
		switch (action) {
			case NavigationBarClickActionEnum.GUIDE: {
				window.open('https://tyles.io/guide', '_blank');
				break;
			}
			case NavigationBarClickActionEnum.SETTINGS: {
				setShowUserSettingsModal(true);
				break;
			}
			case NavigationBarClickActionEnum.REFERRAL: {
				if (!userState.user || !userState.user.referralCode) {
					break;
				}
				openExternalUrl(getReferralDashboardUrl(userState.user.referralCode));
				break;
			}
			case NavigationBarClickActionEnum.GET_APPLE_SILICON_APP:
				track(TrackingEvent.TERMINAL_DOWNLOADED);
				openExternalUrl(`${window.location.host}/${getDownloadLink(DesktopAppTypeEnum.APPLE_SILICON)}`);
				break;

			case NavigationBarClickActionEnum.GET_APPLE_INTEL_APP:
				track(TrackingEvent.TERMINAL_DOWNLOADED);
				openExternalUrl(`${window.location.host}/${getDownloadLink(DesktopAppTypeEnum.APPLE_INTEL)}`);
				break;

			case NavigationBarClickActionEnum.GET_WINDOWS_APP:
				track(TrackingEvent.TERMINAL_DOWNLOADED);
				openExternalUrl(`${window.location.host}/${getDownloadLink(DesktopAppTypeEnum.WINDOWS_64)}`);
				break;

			case NavigationBarClickActionEnum.LOGOUT:
				logout();
				break;

			case NavigationBarClickActionEnum.CHANGE_THEME:
				api
					.updateUserAccount({ ...userState.user, theme: userState.user.theme === 'LIGHT' ? 'DARK' : 'LIGHT' })
					.then((userAccount) => {
						userState.changeEmailError = '';
						userState.isEditing = false;
						userState.user = { ...userAccount };
					});
				break;

			default: {
				// todo
				break;
			}
		}
	};

	return (
		<div data-testid={SearchSidebarContainerTestId}>
			<UserSettingsModule showModal={showUserSettingsModal} onClose={closeUserSettingsModal} />
			<SearchSidebar
				user={user}
				onSearch={debouncedTylesSearch}
				onUserMenuEvent={handleUserMenuEvent}
				createWindowInCanvas={addTyleToCanvas}
			/>
		</div>
	);
};

export default SearchSidebarContainer;
