import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div.attrs(
	(props: { size?: 'default' | 'small' | 'extra-small' | 'large' | 'extra-large'; customSize: string }) => props,
)`
	height: ${(props) => {
		if (props.customSize) {
			return props.customSize;
		}
		switch (props.size) {
			case 'default':
				return '42px';
			case 'small':
				return '34px';
			case 'extra-small':
				return '22px';
			case 'large':
				return '60px';
			case 'extra-large':
				return '100px';
			default:
				return '34px';
		}
	}};
`;

export interface SpacingProps {
	size?: 'default' | 'small' | 'extra-small' | 'large' | 'extra-large';
	customSize?: string;
}

const Spacing = ({ size = 'default', customSize }: SpacingProps): ReactElement => {
	return <StyledDiv size={size} customSize={customSize} />;
};

export default Spacing;
