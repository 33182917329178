/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReactElement, useState } from 'react';
import { Transforms } from 'slate';
import { ELEMENT_PARAGRAPH, usePlateEditorState } from '@udecode/plate';
import Icon from 'components/icon';
import { CustomEditor, CustomElement } from 'types/slate';
import selectByElement from 'components/editor/utils/selectByElement';
import { isEmptyContent } from 'components/tyle/helpers';

interface PlusIconProps {
	element: CustomElement;
}

const PlusIcon = ({ element }: PlusIconProps): ReactElement => {
	const editor = usePlateEditorState() as CustomEditor;
	const [isOpen, setIsOpen] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);

	if (!editor) {
		return <></>;
	}

	const handleOnClick = () => {
		if (isOpen) {
			if (isEmpty) {
				if (isEmptyContent([element])) {
					selectByElement({ editor, element });
					editor.insertText('/');
					return;
				}
				editor.deleteBackward('character');
				editor.deleteBackward('character');
				setIsOpen(false);
				return;
			}

			selectByElement({ editor, element });
			Transforms.move(editor, { distance: 1, unit: 'line', edge: 'focus' });
			Transforms.move(editor, { distance: 1, unit: 'line', edge: 'anchor' });
			editor.deleteForward('character');
			editor.deleteForward('character');
			editor.insertNode({
				type: ELEMENT_PARAGRAPH,
				children: [
					{
						text: '',
					},
				],
			});
			setIsOpen(false);
		}

		if (!isOpen) {
			if (isEmptyContent([element])) {
				selectByElement({ editor, element });
				editor.insertText('/');
				setIsOpen(true);
				setIsEmpty(true);
				return;
			}
			selectByElement({ editor, element });
			Transforms.move(editor, { distance: 1, unit: 'line', edge: 'focus' });
			Transforms.move(editor, { distance: 1, unit: 'line', edge: 'anchor' });
			editor.insertNode({
				type: ELEMENT_PARAGRAPH,
				children: [
					{
						text: '',
					},
				],
			});
			editor.insertText('/');
			setIsOpen(true);
		}
	};

	return <Icon onClick={handleOnClick} type="plus" style={{ paddingBottom: element.type === 'h1' ? '' : '2px' }} />;
};

export default PlusIcon;
