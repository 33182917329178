import { useEffect, useMemo, useRef } from 'react';
import { Combobox } from '@udecode/plate-ui-combobox';
import { comboboxStore, usePlateEditorRef, useEventEditorSelectors, PlateEditor } from '@udecode/plate';
import { ELEMENT_MENTION } from '@udecode/plate-mention';
import EDITOR_COMMANDS from '../Elements/slashCommands';
import handleSelectCommand from './onSelectItem';
import getComboboxStyles from './Combobox.styled';

const CommandMenu = ({ component, pluginKey = ELEMENT_MENTION, id = pluginKey }: any) => {
	// Editor and get trigger based on Editor
	const editor = usePlateEditorRef();
	// const { trigger } = getPluginOptions(editor, pluginKey);

	// focused editorId and id of open combobox
	const focusedEditorId = useEventEditorSelectors.focus();
	const activeId = comboboxStore.use.activeId();

	// store value identifying if combobox is open
	const open = comboboxStore.use.isOpen();
	const text = comboboxStore.use.text();
	const search = useRef<string | null>(null);

	const isOpen = useMemo(() => {
		if (!open || focusedEditorId !== editor.id || activeId !== id) {
			return undefined;
		}
		return true;
	}, [open, editor.id, focusedEditorId, activeId, id]);

	useEffect(() => {
		if (text === null) {
			search.current = text;
			return;
		}

		if (isOpen && text !== search.current) {
			search.current = text;
		}
	}, [isOpen, text, search]);

	return (
		<Combobox
			id={id}
			styles={getComboboxStyles()}
			controlled
			trigger={pluginKey}
			items={EDITOR_COMMANDS}
			onRenderItem={(data) => {
				if (data.item.data.element) {
					return data.item.data.element;
				}
				return data.item.text;
			}}
			component={component}
			onSelectItem={(plateEditor, item) => handleSelectCommand(plateEditor as PlateEditor, item)}
		/>
	);
};

export default CommandMenu;
