/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import './Label.scss';
import Link from '../link/Link';

export interface Props {
	label: string;
	variant?: 'light' | 'medium' | 'dark';
	size?: 'small';
	style?: Record<string, any>;
}

const Label = ({ label, variant, size, style }: Props): ReactElement => {
	return (
		<div
			style={style}
			className={classNames({
				'text-label': true,
				light: variant === 'light',
				medium: variant === 'medium',
				dark: variant === 'dark',
				small: size === 'small',
			})}
		>
			{label}
		</div>
	);
};

export default Label;
