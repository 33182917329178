import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MotionContainer = styled(motion.div)`
	position: absolute;
	bottom: 20px;
	right: 20px;
`;
export const Container = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	width: 140px;
	position: absolute;
	bottom: 20px;
	right: 20px;
	height: 30px;
	padding: 5px 10px;
	border-radius: ${(props) => props.theme.radius.normal};
	background-color: ${(props) => props.theme.newNote.fill};
	box-shadow: ${(props) => props.theme.shadows.light};
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	border: 1px solid ${(props) => props.theme.newNote.stroke};
	z-index: 2;
`;

export const StyledLabel = styled.div`
	color: ${(props) => props.theme.newNote.textFill};
	font-size: ${(props) => props.theme.fontSizes?.medium};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
`;

export const ShortcutContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
`;

export const StyledShortcut = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.newNote.shortcutTextFill};
	height: 20px;
	width: 20px;
	font-size: ${(props) => props.theme.fontSizes?.paragraphSmall};
	font-weight: ${(props) => props.theme.fontWeights?.medium};
	background-color: ${(props) => props.theme.newNote.shortcutFill};
	border-radius: ${(props) => props.theme.radius?.base};
	border: 1px solid ${(props) => props.theme.newNote.stroke};
`;
