import React, { SyntheticEvent, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { nanoid } from 'nanoid';
import {
	ELEMENT_OL,
	ELEMENT_UL,
	getPluginType,
	TComboboxItemWithData,
	TElement,
	usePlateEditorRef,
} from '@udecode/plate';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { ReOrderDotsVertical as DragIndicator } from '@styled-icons/fluentui-system-regular/ReOrderDotsVertical';
import { CustomEditor } from 'types/slate';
import selectByElement from 'components/editor/utils/selectByElement';
import tylesState from 'state/TylesState';
import transformToList from 'components/editor/transforms/transformToList';
import transformGeneric from 'components/editor/transforms/transformGeneric';
import { CommandItemProps, EDITOR_COMMANDS } from '../SlashCommandMenu/Items';
import './style.scss';

const styles = {
	dragButton: {
		width: 18,
		height: 18,
		color: 'rgba(55, 53, 47, 0.3)',
	},
};

export interface Props {
	element: TElement;
}

const Draghandle = ({ element }: Props) => {
	const editor = usePlateEditorRef();
	const slateEditor = useSlateStatic();
	const path = ReactEditor.findPath(slateEditor, element);
	const [open, setOpen] = useState(false);
	const handleDelete = () => {
		if (editor) {
			selectByElement({ editor: editor as CustomEditor, element });
			tylesState.isDeleteBlock = true;
		}
	};
	const handleDuplicate = () => {
		if (editor) {
			selectByElement({ editor: editor as CustomEditor, element });
			tylesState.isDuplicateBlock = true;
		}
	};

	const translateBlockElement = (command: TComboboxItemWithData<CommandItemProps>) => {
		Transforms.select(editor as CustomEditor, path);
		if (command.data.type === ELEMENT_OL || command.data.type === ELEMENT_UL) {
			transformToList(command.data.type, editor);
		} else {
			transformGeneric(editor, getPluginType(editor, command.data.type));
		}
	};
	return (
		<div>
			{!open ? (
				<div className="drag-button noselect">
					<DragIndicator
						style={styles.dragButton}
						onMouseDown={(e) => e.preventDefault()}
						onClick={() => setOpen(true)}
					/>
				</div>
			) : (
				<DropdownMenu.Root open={open} onOpenChange={() => setOpen(false)}>
					<DropdownMenu.Trigger asChild>
						<div className="drag-button">
							<DragIndicator style={styles.dragButton} />
						</div>
					</DropdownMenu.Trigger>

					<DropdownMenu.Portal>
						<DropdownMenu.Content className="DropdownMenuContent" style={{ minWidth: '169px' }} sideOffset={0}>
							<DropdownMenu.Item className="DropdownMenuItem" onClick={handleDelete}>
								Delete <div className="RightSlot" />
							</DropdownMenu.Item>
							{/* <DropdownMenu.Item className="DropdownMenuItem" onClick={handleDuplicate}>
								Duplicate <div className="RightSlot" />
							</DropdownMenu.Item> */}
							<DropdownMenu.Sub>
								<DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
									Turn into
									<div className="RightSlot">
										<ChevronRightIcon />
									</div>
								</DropdownMenu.SubTrigger>
								<DropdownMenu.Portal>
									<DropdownMenu.SubContent className="DropdownMenuSubContent" sideOffset={7} alignOffset={-5}>
										<DropdownMenu.Label className="DropdownMenuLabel">Turn into</DropdownMenu.Label>
										{EDITOR_COMMANDS.map((command) => {
											if (command.key === 'link') {
												return <></>;
											}
											return (
												<DropdownMenu.Item
													className="DropdownMenuItem"
													role="button"
													key={nanoid()}
													onClick={(e: SyntheticEvent) => {
														e.preventDefault();
														translateBlockElement(command);
													}}
												>
													{command.data.icon}
													<div style={{ paddingLeft: '5px' }}>{command.text}</div>
												</DropdownMenu.Item>
											);
										})}
									</DropdownMenu.SubContent>
								</DropdownMenu.Portal>
							</DropdownMenu.Sub>
						</DropdownMenu.Content>
					</DropdownMenu.Portal>
				</DropdownMenu.Root>
			)}
		</div>
	);
};

export default Draghandle;
