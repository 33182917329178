import { TokenPairInterface } from '../interfaces/TokenPairInterface';
import { StorageKeys, StorageServiceInterface } from './StorageService';

export class AuthenticationService implements AuthenticationServiceInterface {
	private authentication?: TokenPairInterface;

	private storage: StorageServiceInterface;

	constructor(storage: StorageServiceInterface) {
		this.storage = storage;
		this.init();
	}

	private init() {
		const authentication = this.storage.get(StorageKeys.AUTHENTICATION);
		if (authentication) {
			this.authentication = authentication as unknown as TokenPairInterface;
		}
	}

	logout(): void {
		this.storage.remove(StorageKeys.AUTHENTICATION);
	}

	isLoggedIn(): boolean {
		return !!this.authentication;
	}

	getAuthentication(): TokenPairInterface | null {
		return this.authentication || null;
	}

	updateToken(token: string): void {
		if (!this.authentication) {
			return;
		}

		this.authentication.token = token;

		this.storeAuthentication();
	}

	private storeAuthentication() {
		this.storage.store({
			[StorageKeys.AUTHENTICATION]: this.authentication,
		});
	}

	authenticate(tokenPair: TokenPairInterface): void {
		this.authentication = tokenPair;

		this.storeAuthentication();
	}
}

export interface AuthenticationServiceInterface {
	logout(): void;
	updateToken(token: string): void;
	getAuthentication(): TokenPairInterface | null;
	authenticate(tokenPair: TokenPairInterface): void;
	isLoggedIn(): boolean;
}
