import { TyleConnection as ApiTyleConnection } from '../api/generated/client';
import { TyleConnectionInterface } from '../interfaces/TyleInterface';

const adaptTyleConnection = (apiTyleConnection: ApiTyleConnection): TyleConnectionInterface => {
	if (!apiTyleConnection.createdAt || !apiTyleConnection.linkId || !apiTyleConnection.backlinkId) {
		throw new Error('corrupt');
	}
	return {
		createdAt: new Date(apiTyleConnection.createdAt),
		linkId: apiTyleConnection.linkId,
		backlinkId: apiTyleConnection.backlinkId,
	};
};

export const reAdaptTyleConnection = (tyleConnection: TyleConnectionInterface): ApiTyleConnection => {
	if (!tyleConnection.createdAt || !tyleConnection.linkId || !tyleConnection.backlinkId) {
		throw new Error('corrupt');
	}
	return {
		createdAt: tyleConnection.createdAt.toISOString(),
		linkId: tyleConnection.linkId,
		backlinkId: tyleConnection.backlinkId,
	};
};

export default adaptTyleConnection;
