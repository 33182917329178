export enum EnvironmentEnum {
	DESKTOP = 'desktopApp',
	WEB = 'webApp',
}

const detectEnvironment = (): EnvironmentEnum => {
	// Detect the user agent when the `nodeIntegration` option is set to true
	return typeof navigator === 'object' && navigator.userAgent.indexOf('Electron') >= 0
		? EnvironmentEnum.DESKTOP
		: EnvironmentEnum.WEB;
};

export default detectEnvironment;
