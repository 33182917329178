import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import Tags from 'components/Tags';
import { TyleInterface } from 'interfaces/TyleInterface';

interface Props {
	tyle: TyleInterface;
	tagItems: SelectableItem[];
	isTagLoading: boolean;
	tagErrorMessage: string;
}

const TagSection = ({ tyle, tagItems, isTagLoading, tagErrorMessage }: Props) => {
	return (
		<Tags
			key={tyle.id}
			items={tagItems}
			style={{ maxHeight: '64px', height: '113px' }}
			onItemClick={() => {}}
			onCloseTag={() => {}}
			loading={isTagLoading}
			showCloseIcon={false}
			errorMessage={tagErrorMessage}
		/>
	);
};

export default TagSection;
