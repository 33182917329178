import { ReactElement, useEffect, useReducer, useRef } from 'react';
import useKeyPress from 'hooks/useKeyPress';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import { renderItem, setChange, StyledAutocomplete, StyledLiElement } from './helpers';

export interface Props {
	items: SelectableItem[];
	onSelect: (item: SelectableItem) => void;
	position?: Record<string, number>;
}

const AutocompleteComboboxContainer = ({ items, onSelect, position = { x: 0, y: 0 } }: Props): ReactElement => {
	const selectRef = useRef<HTMLUListElement>(null);
	const arrowUpPressed = useKeyPress('ArrowUp');
	const arrowDownPressed = useKeyPress('ArrowDown');

	const reducer = ({ selectedIndex }: { selectedIndex: number }, action: { type: string; payload: number }) => {
		switch (action.type) {
			case 'arrowUp':
				return {
					selectedIndex: selectedIndex !== 0 ? selectedIndex - 1 : items.length - 1,
				};
			case 'arrowDown':
				return {
					selectedIndex: selectedIndex !== items.length - 1 ? selectedIndex + 1 : 0,
				};
			case 'select':
				return { selectedIndex: action.payload };
			default:
				throw new Error();
		}
	};
	const [state, dispatch] = useReducer(reducer, { selectedIndex: 0 });

	useEffect(() => {
		if (arrowUpPressed) {
			dispatch({ type: 'arrowUp', payload: state.selectedIndex });
		}
	}, [arrowUpPressed]);

	useEffect(() => {
		if (arrowDownPressed) {
			dispatch({ type: 'arrowDown', payload: state.selectedIndex });
		}
	}, [arrowDownPressed]);

	useEffect(() => {
		dispatch({ type: 'select', payload: 0 });
	}, [items]);

	const onClickHandler = (item: SelectableItem, index = 0) => {
		dispatch({ type: 'select', payload: index });
		onSelect(item);
	};

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				const activeItem = selectRef.current?.querySelector('.active');
				if (activeItem && activeItem.textContent) {
					onClickHandler({ value: activeItem.id, label: activeItem.textContent?.slice(1) });
				}
			}
		};
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	if (items.length === 0) {
		return (
			<StyledAutocomplete style={{ left: position.x, top: position.y }}>
				<StyledLiElement>No Results</StyledLiElement>
			</StyledAutocomplete>
		);
	}

	return (
		<StyledAutocomplete id="autocomplete-dropdown" ref={selectRef} style={{ left: position.x, top: position.y }}>
			{items.map((item, i) => {
				const className = i === state.selectedIndex ? 'active' : '';
				setChange(selectRef);
				return (
					<StyledLiElement
						key={item.value}
						id={item.value}
						role="button"
						className={className}
						selected={i === state.selectedIndex}
						aria-pressed={i === state.selectedIndex}
						tabIndex={0}
						onMouseOver={() => {
							dispatch({ type: 'select', payload: i });
						}}
						onClick={() => {
							onClickHandler(item, i);
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								onClickHandler(item, i);
								e.currentTarget.blur();
							}
						}}
					>
						{renderItem(item)}
					</StyledLiElement>
				);
			})}
		</StyledAutocomplete>
	);
};

export default AutocompleteComboboxContainer;
