import {
	ELEMENT_BLOCKQUOTE,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_PARAGRAPH,
	ELEMENT_TODO_LI,
	isBlockAboveEmpty,
	isSelectionAtBlockStart,
	ResetNodePlugin,
} from '@udecode/plate';
import { MyPlatePlugin } from '../types/plateTypes';

const resetBlockTypesCommonRule = {
	types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
	defaultType: ELEMENT_PARAGRAPH,
};

const resetBlockTypePlugin: Partial<MyPlatePlugin<ResetNodePlugin>> = {
	options: {
		rules: [
			{
				...resetBlockTypesCommonRule,
				hotkey: 'Enter',
				predicate: isBlockAboveEmpty,
			},
			{
				...resetBlockTypesCommonRule,
				hotkey: 'Backspace',
				predicate: isSelectionAtBlockStart,
			},
		],
	},
};

export default resetBlockTypePlugin;
