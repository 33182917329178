import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledBorder = styled.div`
	border: 1px solid RGBA(88, 88, 88, 0.5);
	background: RGBA(82, 82, 82, 0.1);
	padding: 5rem 4rem 5rem 5rem;
	width: 982px;
	height: 530px;
	border-radius: 20px;
`;

export interface Props {
	content: ReactElement;
}

const SignupScreen = ({ content }: Props) => {
	return <StyledBorder>{content}</StyledBorder>;
};

export default SignupScreen;
