export * from '../models/CreateSpace';
export * from '../models/EditorBlock';
export * from '../models/EditorBlocksToRemove';
export * from '../models/GenerateTagsForTyle';
export * from '../models/GenericError';
export * from '../models/GenericSuccess';
export * from '../models/GetTylesFavoritesRequest';
export * from '../models/GetUserTopTagsParams';
export * from '../models/InviteCode';
export * from '../models/InviteCodeCreateManyParams';
export * from '../models/PostLoginRequest';
export * from '../models/PostSignUpRequest';
export * from '../models/PostTyleRequest';
export * from '../models/SearchQuery';
export * from '../models/SearchQueryParams';
export * from '../models/SearchQueryParamsFilters';
export * from '../models/SearchTylesParams';
export * from '../models/SearchTylesParamsFilters';
export * from '../models/Source';
export * from '../models/Space';
export * from '../models/Tag';
export * from '../models/TagCreateManyParams';
export * from '../models/TagCreateOneParams';
export * from '../models/ToggleGeneratedUrl';
export * from '../models/ToggleGeneratedUrlParams';
export * from '../models/Token';
export * from '../models/TokenPair';
export * from '../models/TrackObject';
export * from '../models/TrackObjectTrack';
export * from '../models/Tyle';
export * from '../models/TyleConnection';
export * from '../models/TyleLink';
export * from '../models/TyleRelation';
export * from '../models/TylesCount';
export * from '../models/TylesUpdatedCount';
export * from '../models/UpdateSpace';
export * from '../models/UpdateSpaceRequest';
export * from '../models/UpdateTyleRequest';
export * from '../models/User';
export * from '../models/UserDelete';

import { CreateSpace } from '../models/CreateSpace';
import { EditorBlock } from '../models/EditorBlock';
import { EditorBlocksToRemove } from '../models/EditorBlocksToRemove';
import { GenerateTagsForTyle } from '../models/GenerateTagsForTyle';
import { GenericError } from '../models/GenericError';
import { GenericSuccess } from '../models/GenericSuccess';
import { GetTylesFavoritesRequest } from '../models/GetTylesFavoritesRequest';
import { GetUserTopTagsParams } from '../models/GetUserTopTagsParams';
import { InviteCode } from '../models/InviteCode';
import { InviteCodeCreateManyParams } from '../models/InviteCodeCreateManyParams';
import { PostLoginRequest } from '../models/PostLoginRequest';
import { PostSignUpRequest } from '../models/PostSignUpRequest';
import { PostTyleRequest } from '../models/PostTyleRequest';
import { SearchQuery } from '../models/SearchQuery';
import { SearchQueryParams } from '../models/SearchQueryParams';
import { SearchQueryParamsFilters } from '../models/SearchQueryParamsFilters';
import { SearchTylesParams } from '../models/SearchTylesParams';
import { SearchTylesParamsFilters } from '../models/SearchTylesParamsFilters';
import { Source } from '../models/Source';
import { Space } from '../models/Space';
import { Tag } from '../models/Tag';
import { TagCreateManyParams } from '../models/TagCreateManyParams';
import { TagCreateOneParams } from '../models/TagCreateOneParams';
import { ToggleGeneratedUrl } from '../models/ToggleGeneratedUrl';
import { ToggleGeneratedUrlParams } from '../models/ToggleGeneratedUrlParams';
import { Token } from '../models/Token';
import { TokenPair } from '../models/TokenPair';
import { TrackObject } from '../models/TrackObject';
import { TrackObjectTrack } from '../models/TrackObjectTrack';
import { Tyle } from '../models/Tyle';
import { TyleConnection } from '../models/TyleConnection';
import { TyleLink } from '../models/TyleLink';
import { TyleRelation } from '../models/TyleRelation';
import { TylesCount } from '../models/TylesCount';
import { TylesUpdatedCount } from '../models/TylesUpdatedCount';
import { UpdateSpace } from '../models/UpdateSpace';
import { UpdateSpaceRequest } from '../models/UpdateSpaceRequest';
import { UpdateTyleRequest } from '../models/UpdateTyleRequest';
import { User } from '../models/User';
import { UserDelete } from '../models/UserDelete';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0,
  "application/x-www-form-urlencoded": 0
}


let enumsMap: Set<string> = new Set<string>([
]);

let typeMap: {[index: string]: any} = {
    "CreateSpace": CreateSpace,
    "EditorBlock": EditorBlock,
    "EditorBlocksToRemove": EditorBlocksToRemove,
    "GenerateTagsForTyle": GenerateTagsForTyle,
    "GenericError": GenericError,
    "GenericSuccess": GenericSuccess,
    "GetTylesFavoritesRequest": GetTylesFavoritesRequest,
    "GetUserTopTagsParams": GetUserTopTagsParams,
    "InviteCode": InviteCode,
    "InviteCodeCreateManyParams": InviteCodeCreateManyParams,
    "PostLoginRequest": PostLoginRequest,
    "PostSignUpRequest": PostSignUpRequest,
    "PostTyleRequest": PostTyleRequest,
    "SearchQuery": SearchQuery,
    "SearchQueryParams": SearchQueryParams,
    "SearchQueryParamsFilters": SearchQueryParamsFilters,
    "SearchTylesParams": SearchTylesParams,
    "SearchTylesParamsFilters": SearchTylesParamsFilters,
    "Source": Source,
    "Space": Space,
    "Tag": Tag,
    "TagCreateManyParams": TagCreateManyParams,
    "TagCreateOneParams": TagCreateOneParams,
    "ToggleGeneratedUrl": ToggleGeneratedUrl,
    "ToggleGeneratedUrlParams": ToggleGeneratedUrlParams,
    "Token": Token,
    "TokenPair": TokenPair,
    "TrackObject": TrackObject,
    "TrackObjectTrack": TrackObjectTrack,
    "Tyle": Tyle,
    "TyleConnection": TyleConnection,
    "TyleLink": TyleLink,
    "TyleRelation": TyleRelation,
    "TylesCount": TylesCount,
    "TylesUpdatedCount": TylesUpdatedCount,
    "UpdateSpace": UpdateSpace,
    "UpdateSpaceRequest": UpdateSpaceRequest,
    "UpdateTyleRequest": UpdateTyleRequest,
    "User": User,
    "UserDelete": UserDelete,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }

            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
                if (value !== undefined) {
                    instance[attributeType.name] = value;
                }
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "text/plain") {
            return String(data);
        }

        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "text/plain") {
            return rawData;
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        if (mediaType === "text/html") {
            return rawData;
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
