import { ReactElement, useEffect, useRef, useState } from 'react';
import useGetNotificationFromElectron, { NotificationMessage } from 'helpers/useGetNotificationFromElectron';
import FastCaptureSuccessNotification from 'components/FastCaptureSuccessNotification';

const ClipperNotificationPage = (): ReactElement => {
	const { notification } = useGetNotificationFromElectron();
	const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>({ id: '', message: '' });

	useEffect(() => {
		if (!notification) return;
		setNotificationMessage(notification);
	}, [notification, setNotificationMessage]);

	return <FastCaptureSuccessNotification key={notificationMessage.id} content={notificationMessage} />;
};

export default ClipperNotificationPage;
