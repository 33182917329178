/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class UpdateTyleRequest {
    'title'?: any;
    'content'?: any;
    'deleted'?: any;
    'inbox'?: any;
    'favorite'?: any;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "title",
            "baseName": "title",
            "type": "any",
            "format": ""
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "any",
            "format": ""
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "any",
            "format": ""
        },
        {
            "name": "inbox",
            "baseName": "inbox",
            "type": "any",
            "format": ""
        },
        {
            "name": "favorite",
            "baseName": "favorite",
            "type": "any",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return UpdateTyleRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

