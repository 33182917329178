import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './Page.scss';

export interface PageProps {
	children: any;
	orientation: 'left' | 'center' | 'right';
	verticalAlign?: 'center' | 'top';
	style?: Record<string, any>;
}

const Page = ({ children, orientation, verticalAlign, style }: PageProps): ReactElement => {
	return (
		<div
			style={style}
			className={classNames({
				'kum-page': true,
				[`orientation-${orientation}`]: !!orientation,
				[`vertical-align-${verticalAlign}`]: !!verticalAlign,
			})}
		>
			{children}
		</div>
	);
};

export default Page;
