import {
	ELEMENT_BLOCKQUOTE,
	ELEMENT_CODE_BLOCK,
	ELEMENT_DEFAULT,
	ELEMENT_H1,
	ELEMENT_H2,
	ELEMENT_H3,
	ELEMENT_H4,
	ELEMENT_H5,
	ELEMENT_H6,
	ELEMENT_HR,
	getPluginType,
	insertEmptyCodeBlock,
	insertNodes,
	setNodes,
} from '@udecode/plate';
import { MyAutoformatRule } from '../../types/plateTypes';
import { preFormat } from './autoformatUtils';

const autoformatBlocks: MyAutoformatRule[] = [
	{
		mode: 'block',
		type: ELEMENT_H1,
		match: '# ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_H2,
		match: '## ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_H3,
		match: '### ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_H4,
		match: '#### ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_H5,
		match: '##### ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_H6,
		match: '###### ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_BLOCKQUOTE,
		match: '> ',
		preFormat,
	},
	{
		mode: 'block',
		type: ELEMENT_CODE_BLOCK,
		match: '```',
		triggerAtBlockStart: false,
		preFormat,
		format: (editor) => {
			insertEmptyCodeBlock(editor, {
				defaultType: getPluginType(editor, ELEMENT_DEFAULT),
				insertNodesOptions: { select: true },
			});
		},
	},
	{
		mode: 'block',
		type: ELEMENT_HR,
		match: ['---', '—-', '___ '],
		format: (editor) => {
			setNodes(editor, { type: ELEMENT_HR });
			insertNodes(editor, {
				type: ELEMENT_DEFAULT,
				children: [{ text: '' }],
			});
		},
	},
];

export default autoformatBlocks;
