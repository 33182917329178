import { useDrag } from 'react-dnd';
import { DragTyleInterface } from 'interfaces/DragAndDrop/DragAndDropInterfaces';
import tylesState from 'state/TylesState';

const useCustomDragCard = ({ id, tyleId, type, from, to, width, height, zIndex }: DragTyleInterface) => {
	return useDrag(
		() => ({
			type,
			item(): DragTyleInterface {
				return {
					id,
					tyleId,
					type,
					from,
					to,
					width,
					height,
					zIndex,
				};
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
				clientOffset: monitor.getClientOffset(),
			}),

			end(_, monitor) {
				tylesState.dropResult = monitor.getDropResult();
			},
		}),
		[id, tyleId, type, from, to, width, height, zIndex],
	);
};

export default useCustomDragCard;
