/* eslint-disable no-param-reassign */
import { useDrag } from 'react-dnd';
import { TEditor } from '@udecode/plate-core';
import { DragAndDropAreaEnum } from 'interfaces/DragAndDrop/DragAndDropEnums';

const useCustomDragBlock = (editor: TEditor, id: string, type: string) => {
	return useDrag(
		() => ({
			type,
			item() {
				editor.isDragging = true;
				document.body.classList.add('dragging');
				return { id, type, to: DragAndDropAreaEnum.EDITOR };
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
				didDrop: monitor.didDrop(),
			}),
			end: () => {
				editor.isDragging = false;
				document.body.classList.remove('dragging');
			},
		}),
		[],
	);
};

export default useCustomDragBlock;
