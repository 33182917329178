import React, { useState, useRef, useEffect, useCallback } from 'react';
import { nanoid } from 'nanoid';
import DropdownItem, { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import useElectronEvents from 'helpers/useElectronEvents';
import { detectOperatingSystem } from 'helpers';
import IPCChannel from 'model/IPC/IPCChannel';
import fastCaptureState from 'state/FastCaptureState';
import { NotificationTypeEnum } from 'helpers/useGetNotificationFromElectron';
import { getNewSize, measureInputWidth } from './helpers';
import {
	StyledContainer,
	StyledInputContainer,
	StyledPrefix,
	StyledPlaceholder,
	StyledInput,
	StyledList,
	StyledNewNote,
} from './AutocompleteDropdown.styled';

export interface AutocompleteDropdownProps {
	prefix?: string;
	items: SelectableItem[];
	placeholder?: string;
	defaultItem?: SelectableItem;
	isLoading?: boolean;
	onSelect: (item: SelectableItem) => void;
	onSubmit: (item: SelectableItem) => void;
	onChange: (value: string) => void;
	onNewOptionClick?: (value: string) => void;
}

const AutocompleteDropdown = ({
	prefix,
	placeholder,
	items,
	defaultItem,
	isLoading,
	onSelect,
	onSubmit,
	onChange,
	onNewOptionClick,
}: AutocompleteDropdownProps) => {
	const [value, setValue] = useState('');
	const [suggestedText, setSuggestedText] = useState('');
	const [inputWidth, setInputWidth] = useState(0);
	const [activeIndex, setActiveIndex] = useState(-1);
	const [showDropdown, setShowDropdown] = useState(false);
	const [cancelAutoSave, setCancelAutoSave] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;
	const ref = useRef<HTMLDivElement>(null);
	const { sendMessage, sendMessageResize } = useElectronEvents();

	useEffect(() => {
		inputRef.current?.focus();
		if (defaultItem) {
			onSelect(defaultItem);
		}
	}, [defaultItem]);

	const handleClose = () => {
		if (!cancelAutoSave) {
			sendMessage({ subject: IPCChannel.UNFOCUS_FASTCAPTURE, message: 'unfocus' });
			sendMessage({
				subject: IPCChannel.OPEN_CLIPPER_NOTIFICATION,
				message: { id: nanoid(), message: '', type: NotificationTypeEnum.CAPTURE },
			});
			fastCaptureState.showFastCapture = false;
			fastCaptureState.cancelAutoSave = false;
		}
	};

	const onCancelAutoSave = () => {
		if (!cancelAutoSave) {
			setCancelAutoSave(true);
		}
	};

	/* 	useEffect(() => {
		let timeout: any = null;
		if (!cancelAutoSave) {
			timeout = setTimeout(() => {
				handleClose();
			}, 5000);
		} else {
			clearTimeout(timeout);
		}

		return () => {
			// Needs cleanup function
			clearTimeout(timeout);
		};
	}, [cancelAutoSave]); */

	const updateSuggestedText = (label = '') => {
		const valueLower = value.toLowerCase();
		const findMatchingItem = (item: any) => item.label.toLowerCase().startsWith(valueLower);

		const matchingItem = label ? items.find((item) => item.label === label) : items.find(findMatchingItem);
		if (matchingItem) {
			setSuggestedText(matchingItem.label.slice(value.length));
			if (!label) setActiveIndex(0);
		} else {
			setSuggestedText('');
			setActiveIndex(-1);
		}
	};

	useEffect(() => {
		setInputWidth(measureInputWidth(value));
		updateSuggestedText();
		// Resize electron window
		const newSize = getNewSize(value, items);
		sendMessageResize(newSize);
	}, [value, items, isLoading]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		onChange(e.target.value);
		if (!e.target.value) {
			setActiveIndex(-1);
			setShowDropdown(false);
			return;
		}
		setShowDropdown(true);
	};

	const handleItemClick = (index: number) => {
		if (index === items.length) {
			onSelect({ value, label: value });
			onNewOptionClick?.(value);
		} else {
			onSubmit(items[index]);
		}
		setShowDropdown(false);
		setValue('');
		setActiveIndex(-1);
	};

	const handleBlur = () => {
		setTimeout(() => {
			setActiveIndex(-1);
		}, 200);
	};

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			// Cancel auto-save when a key is pressed
			onCancelAutoSave();

			// Check if Cmd (for MacOS) or Ctrl (for other OS) key is pressed
			const cmdOrCtrl = isMacOS ? e.metaKey : e.ctrlKey;

			// Update the active index with a new index value, and handle related actions
			const updateActiveIndex = (newIndex: number) => {
				setActiveIndex(() => {
					// Ensure the new index is within bounds
					const index = Math.max(Math.min(newIndex, items.length), -1);

					// Update suggested text and call onSelect with the corresponding item
					if (items[index]) {
						updateSuggestedText(items[index].label);
						onSelect(items[index]);
					}

					// If the index points to the last item (new note) and value exists, call onSelect with the value
					if (index === items.length && value) {
						onSelect({ value, label: value });
					}

					return index;
				});
			};

			// Handle specific key press events
			switch (e.key) {
				case 'ArrowDown': {
					e.preventDefault();
					// Move active index down when the down arrow key is pressed
					updateActiveIndex(activeIndex + 1);
					break;
				}
				case 'ArrowUp': {
					e.preventDefault();
					// Move active index up when the up arrow key is pressed
					updateActiveIndex(activeIndex - 1);
					break;
				}
				case 'Enter': {
					e.preventDefault();
					setCancelAutoSave(true);

					// Handle Cmd/Ctrl + Enter to create a new note
					if (cmdOrCtrl) {
						onSelect({ value, label: value });
						onNewOptionClick?.(value);
						return;
					}
					// Handle Enter key press based on active index value
					// If active index is -1 (meaning input is focused), call onSubmit with the default item
					if (activeIndex === -1 && defaultItem) {
						if (value && onNewOptionClick) {
							onNewOptionClick(value);
							return;
						}
						onSubmit(defaultItem);
						// If active index has items.length new option is selected
					} else if (activeIndex === items.length) {
						onNewOptionClick?.(value);
					} else {
						// Otherwise, call onSubmit with the corresponding item
						onSubmit(items[activeIndex]);
					}
					// Hide dropdown and reset active index after handling Enter key press
					setShowDropdown(false);
					setActiveIndex(-1);
					break;
				}
				default:
					break;
			}
		},
		[activeIndex, value, defaultItem],
	);

	return (
		<StyledContainer className="autocomplete" ref={ref} role="searchbox" onClick={onCancelAutoSave}>
			<StyledInputContainer>
				<StyledPrefix>{prefix} </StyledPrefix>
				{value && <StyledPlaceholder inputWidth={inputWidth}>{suggestedText}</StyledPlaceholder>}
				<StyledInput
					ref={inputRef}
					type="text"
					role="combobox"
					autoFocus
					placeholder={placeholder}
					onMouseDown={onCancelAutoSave}
					value={value}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					onBlur={handleBlur}
				/>
			</StyledInputContainer>
			{showDropdown && (
				<>
					<StyledList className="autocomplete-dropdown">
						{items.map((item, index) => (
							<DropdownItem
								key={item.value}
								value={item.value}
								label={item.label}
								className={`dropdown-item ${index === activeIndex ? 'active' : ''}`}
								selected={index === activeIndex}
								onClick={() => handleItemClick(index)}
							/>
						))}
					</StyledList>
					<StyledNewNote>
						<DropdownItem
							key={nanoid()}
							className={`dropdown-item ${items.length === activeIndex ? 'active' : ''}`}
							label="New note: "
							value={value}
							title={value}
							selected={activeIndex === items.length}
							onClick={() => handleItemClick(items.length)}
						/>
					</StyledNewNote>
				</>
			)}
		</StyledContainer>
	);
};

export default AutocompleteDropdown;
