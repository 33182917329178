/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, ReactNode } from 'react';
import { ELEMENT_PARAGRAPH } from '@udecode/plate';
import Button from 'components/button';
import Paragraph from 'components/paragraph';
import Icon from 'components/icon';
import { TyleActionEnum } from 'interfaces/TyleInterface';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import { TylePreviewDataInterface } from '../../helpers/getTylePreviewData';
import { CustomElement } from '../../types/slate';

export const createTextContent = (content: any, onHover: boolean, index: number, isTitle?: boolean) => {
	if (!content) {
		return '';
	}
	let text = '';
	// Checks if first line is longer than two lines
	for (let i = 0; i < content.length; i++) {
		if (isTitle) {
			text = content[i].text;
			break;
		}
		if (content[i].type === 'tyle-block' || content[i].type === 'tyle-inline') {
			text = content[i].tyle.title ?? 'Untitled';
			break;
		}

		text = content[i].text;
	}
	return text;
};

export const isEmptyContent = (content: Array<CustomElement> | null | undefined): boolean => {
	if (!content) {
		return true;
	}

	return !!(
		content.length === 1 &&
		content[0].type === ELEMENT_PARAGRAPH &&
		content[0].children &&
		content[0].children.length === 1 &&
		content[0].children[0].text === ''
	);
};

export const getTyleContent = (tyleData: any, isHover: boolean, isTitle?: boolean) => {
	return tyleData?.content?.map((tyleContent: any, i: number) => {
		if (tyleContent.type === 'tyle-block' || tyleContent.type === 'tyle-inline') {
			return tyleContent.tyle && tyleContent.tyle.title ? tyleContent.tyle.title : 'Untitled';
		}
		return createTextContent(tyleContent.children, isHover, i, isTitle);
	});
};

export const setHidden = (tyleData: any, content: Array<CustomElement> | null | undefined | string) => {
	return (
		(!tyleData.content && !tyleData.title) ||
		(!tyleData.content && !tyleData.title && !tyleData.sourceTitle) ||
		(!tyleData.content && tyleData.title && tyleData.title.length > 50) ||
		(!tyleData.content && tyleData.title && !content) ||
		(!tyleData.title && tyleData.content)
	);
};

export const renderLinkedTyles = (showChildren: boolean, children?: ReactNode) => {
	if (!showChildren) {
		return <></>;
	}

	return <div className="tyle-collection">{children}</div>;
};

export const tyleCollection = (collection: ReactNode, showChildren: boolean, children?: ReactNode): ReactElement => {
	return (
		<div className="tyle-collection">
			{collection}
			{renderLinkedTyles(showChildren, children)}
		</div>
	);
};

export const hideIcon = ({ content, title, sourceUrl, sourceTitle, icon }: TylePreviewDataInterface): boolean => {
	if (!icon || (!content && title && sourceUrl && sourceTitle)) return true;

	return !!(!title && content);
};

export const hasLinksOrBacklinks = (links: any) => {
	return links && links.length > 0;
};

export const renderLinkOrBacklink = (links: any, type: 'link' | 'backlink') => {
	return <Button variant={type} label={`${links.length}`} />;
};

export const renderConnections = ({ link, backlink }: any) => {
	if (!hasLinksOrBacklinks(link) && !hasLinksOrBacklinks(backlink)) {
		return null;
	}

	if (hasLinksOrBacklinks(link) && hasLinksOrBacklinks(backlink)) {
		return (
			<span className="buttons-horizontal">
				{renderLinkOrBacklink(link, 'link')}
				{renderLinkOrBacklink(backlink, 'backlink')}
			</span>
		);
	}
	return <></>;
};

// Option menu
export const renderRightHover = (isPopoverOpen: boolean, setIsPopoverOpen: (arg: boolean) => void) => {
	return (
		<div
			style={{ display: 'inline-block' }}
			onClick={(event) => {
				event.stopPropagation();
				setIsPopoverOpen(!isPopoverOpen);
			}}
		>
			<Paragraph variant="small" margin="none">
				<Button
					label="Options"
					variant="plain"
					icon={
						<Icon
							style={{
								lineHeight: '16px',
								verticalAlign: 'middle',
								marginLeft: 3,
								fontSize: '1.2em',
							}}
							type="chevron-down"
						/>
					}
					iconPosition="right"
					style={{ backgroundColor: '#F2F2F2', padding: '0.2em 0.5em 1.7em 0.8em', marginBottom: '0.1em' }}
				/>
			</Paragraph>
		</div>
	);
};

export const withNestedTyles = (children: any, setShow: (arg: boolean) => void, show: boolean) => {
	return (
		<div className="tyle-wrapper">
			<div
				className="icon"
				onClick={() => {
					setShow(!show);
				}}
			>
				{show ? <Icon type="arrow-down" /> : <Icon type="arrow-right" />}
			</div>
			{children}
		</div>
	);
};

export const getDropdownMenuOptions = (id: string, isFavorite?: boolean): PopoverMenuItemInterface[] => {
	return [
		!isFavorite
			? {
					label: 'Mark as favorite',
					action: TyleActionEnum.FAVORITE_ADD,
					data: id,
			  }
			: {
					label: 'Remove from favorites',
					action: TyleActionEnum.FAVORITE_REMOVE,
					data: id,
			  },
		{
			label: 'Delete',
			action: TyleActionEnum.DELETE,
			data: id,
		},
	];
};
