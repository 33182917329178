import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const easeIn = (t: number, alpha: number) => t ** alpha;

const getMask = (opacity: number) => `linear-gradient(180deg, black, rgba(255, 255, 255, ${opacity})) center bottom/100%
    56px no-repeat,
linear-gradient(180deg, black, black) center top/100% calc(100% - 56px)
    no-repeat`;

interface ScrollFadeProps {
	initialScrollTop: number;
	updateScrollTop: (scrollTop: number) => void;
}

const ScrollFade = ({ initialScrollTop, updateScrollTop }: ScrollFadeProps) => {
	const rootRef = useRef<HTMLDivElement>(null);

	const debouncedScrollTop = useCallback(
		debounce((scroll: number) => {
			updateScrollTop(scroll);
		}, 1000),
		[],
	);

	const onScroll = useCallback(() => {
		const scrollElement = rootRef.current?.parentElement;
		if (scrollElement) {
			const { offsetHeight: elementHeight, scrollHeight: elementWidth, scrollTop } = scrollElement;
			debouncedScrollTop(scrollTop);
			const opacity = easeIn(scrollTop / (elementHeight - elementWidth), 10);
			const mask = getMask(opacity);
			scrollElement.style.mask = mask;
			scrollElement.style.webkitMask = mask;
		}
	}, []);

	useEffect(() => {
		const scrollElement = rootRef.current?.parentElement;

		if (scrollElement) {
			const { offsetHeight, scrollHeight } = scrollElement;
			if (offsetHeight !== scrollHeight) {
				const mask = getMask(0);
				scrollElement.style.mask = mask;
				scrollElement.style.webkitMask = mask;
			}
			scrollElement.scrollTop = initialScrollTop;

			scrollElement.addEventListener('scroll', onScroll);
			return () => scrollElement.removeEventListener('scroll', onScroll);
		}

		return () => {};
	}, []);

	return <div className="scroll-fade" ref={rootRef} />;
};

export default ScrollFade;
