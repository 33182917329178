enum Transformations {
	ELEMENT_TYLE_BLOCK = 'elementTyleBlock',
	ELEMENT_TYLE_INLINE_CONTENT = 'elementTyleInlineContent',
	ELEMENT_TYLE_INLINE_TITLE = 'elementTyleInlineTitle',
	ELEMENT_TEXT = 'elementText',
}

enum InlineTypes {
	CONTENT = 'content',
	TITLE = 'title,',
}

export { Transformations, InlineTypes };
