import React, { ReactElement, SyntheticEvent } from 'react';
import { Button as BsButton } from 'react-bootstrap';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import './Button.scss';

export interface ButtonProps {
	label: string;
	variant?:
		| 'primary'
		| 'basic'
		| 'basic-small'
		| 'danger'
		| 'small'
		| 'plain'
		| 'link'
		| 'backlink'
		| 'instances'
		| 'space'
		| 'share'
		| 'expandView'
		| 'back-icon';
	fullWidth?: boolean;
	disabled?: boolean;
	onEvent?: any;
	icon?: any;
	dropdownRef?: any;
	selected?: boolean;
	iconPosition?: 'left' | 'right';
	style?: Record<string, any>;
	testId?: string;
}

const Button = ({
	label,
	variant,
	fullWidth,
	disabled,
	onEvent,
	icon,
	dropdownRef,
	selected,
	iconPosition,
	style,
	testId,
}: ButtonProps): ReactElement => {
	const handleClick = (event: SyntheticEvent) => {
		if (!onEvent) {
			return;
		}
		onEvent(event);
	};

	const getBsVariant = () => {
		if (!variant) {
			return 'primary';
		}

		if (['small', 'link', 'backlink', 'instances', 'share'].includes(variant)) {
			return 'small';
		}

		if (['back-icon'].includes(variant)) {
			return 'basic-small';
		}

		return variant;
	};

	const renderIcon = () => {
		if (icon) {
			return icon;
		}

		switch (variant) {
			case 'link':
				return <Icon type="link" />;
			case 'backlink':
				return <Icon type="backlink" />;
			case 'instances':
				return <Icon type="instances" />;
			case 'space':
				return <Icon type="space" />;
			case 'back-icon':
				return <Icon type="chevron-left" />;
			default:
				return null;
		}
	};

	return (
		<span
			ref={dropdownRef}
			className={classNames({
				'kum-button': true,
				small: variant && ['small', 'link', 'backlink', 'instances', 'space', 'share'].includes(variant),
				primary: variant === 'primary',
				plain: variant === 'plain',
				basic: variant === 'basic',
				basicSmall: variant === 'basic-small',
				danger: variant === 'danger',
				'expand-view': variant === 'expandView',
				'full-width': !!fullWidth,
				selected: !!selected,
			})}
		>
			<BsButton data-testid={testId} variant={getBsVariant()} onClick={handleClick} disabled={disabled} style={style}>
				{iconPosition && iconPosition === 'left' ? (
					<>
						{renderIcon()}
						{label}
					</>
				) : (
					<>
						{label}
						{renderIcon()}
					</>
				)}
			</BsButton>
		</span>
	);
};

export default Button;
