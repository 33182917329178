import { TokenPairInterface } from '../interfaces/TokenPairInterface';

// eslint-disable-next-line import/prefer-default-export
export class TokenPair implements TokenPairInterface {
	public token;

	public refreshToken;

	constructor(tokenPair: TokenPairInterface) {
		this.token = tokenPair.token;
		this.refreshToken = tokenPair.refreshToken;
	}
}
