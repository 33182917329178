import { ReactNode, SyntheticEvent } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { nanoid } from 'nanoid';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import Icon from 'components/icon';
import './style.scss';

export interface DropdownItem {
	header?: boolean;
	strongLabel?: string;
	label?: string;
	action?: any;
	icon?: string;
	id?: string;
	shortcut?: string;
	shortcuts?: string[];
	data?: any;
	disabled?: boolean;
	hidden?: boolean;
	divider?: boolean;
	sectionTitle?: string;
	submenu?: boolean;
	submenuItems?: DropdownItem[];
	submenuContent?: string[];
	alignSubItems?: string;
	custom?: ReactNode;
	event?: MouseEvent;
}

export interface Props {
	trigger: any;
	items: DropdownItem[];
	className?: string;
	onEvent: (item: any) => void;
	onOpenChange?: (open: boolean) => void;
	top?: number;
}

const Dropdown = ({ trigger, items, className, onEvent, onOpenChange, top = -15 }: Props) => {
	const handleClick = (item: DropdownItem) => {
		onEvent(item);
	};
	return (
		<DropdownMenu.Root onOpenChange={onOpenChange}>
			<DropdownMenu.Trigger className="DropdownMenuTrigger">{trigger}</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<DropdownMenu.Content
					className={`DropdownMenuContent ${className}`}
					sideOffset={top}
					align="start"
					alignOffset={0}
				>
					{items.map((item) => {
						if (item.hidden) return null;
						if (item.divider) {
							return <DropdownMenu.Separator className="DropdownMenuSeparator" key={nanoid()} />;
						}
						if (item.submenuItems) {
							return (
								<DropdownMenu.Sub key={nanoid()}>
									<DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
										{item.label}
										<div className="RightSlot">
											<ChevronRightIcon />
										</div>
									</DropdownMenu.SubTrigger>
									<DropdownMenu.Portal>
										<DropdownMenu.SubContent className="DropdownMenuSubContent" sideOffset={9} alignOffset={-5}>
											<DropdownMenu.Label className="DropdownMenuLabel">{item.sectionTitle}</DropdownMenu.Label>
											{item.submenuItems.map((subItem) => {
												return (
													<DropdownMenu.Item
														disabled={item.disabled}
														className="DropdownMenuItem"
														key={nanoid()}
														onClick={(e: SyntheticEvent) => {
															e.preventDefault();
															handleClick(subItem);
														}}
													>
														{subItem.label}
													</DropdownMenu.Item>
												);
											})}
										</DropdownMenu.SubContent>
									</DropdownMenu.Portal>
								</DropdownMenu.Sub>
							);
						}
						if (item.header) {
							return (
								<div key={nanoid()}>
									<DropdownMenu.Item className="dropdown-header" disabled onClick={(event) => event.stopPropagation()}>
										{item.icon && <Icon type={item.icon} />}
										<div className="label">
											{item.strongLabel && <strong>{item.strongLabel}</strong>} {item.label}
										</div>
									</DropdownMenu.Item>
									<DropdownMenu.Separator className="DropdownMenuSeparator" />
								</div>
							);
						}
						return (
							<DropdownMenu.Item
								key={nanoid()}
								disabled={item.disabled}
								className="DropdownMenuItem"
								onClick={() => handleClick(item)}
							>
								{item.label}{' '}
								<div className="RightSlot">
									{item.shortcuts?.map((shortcut) => {
										return shortcut;
									})}
								</div>
							</DropdownMenu.Item>
						);
					})}
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export default Dropdown;
