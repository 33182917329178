import { ReactElement, useContext, useEffect, useState } from 'react';
import { PopoverMenuItemInterface } from 'interfaces/PopoverMenuItemInterface';
import { TyleInterface } from 'interfaces/TyleInterface';
import Dropdown from 'components/Dropdownn';
import Icon from '../../icon';
import threeDotsMenuOptions from '../helpers';
import { ApiContext } from '../../App';
import StyledThreeDotsMenu from './ThreeDotsMenu.styled';

interface ThreeDotsMenuProps {
	tyle: TyleInterface;
	onSelect: (item: PopoverMenuItemInterface) => void;
}

const ThreeDotsMenu = ({ tyle, onSelect }: ThreeDotsMenuProps): ReactElement | null => {
	const [items, setItems] = useState<PopoverMenuItemInterface[]>([]);
	const [isFavorite, setIsFavorite] = useState<boolean>(tyle?.favorite ?? false);

	// Update three dots menu options according to origin tyle, previous instance and favorite status
	useEffect(() => {
		if (!tyle?.id) {
			return;
		}
		setItems(threeDotsMenuOptions(tyle.id, isFavorite));
	}, [isFavorite]);

	const handleDropdownOpened = (opened: boolean) => {
		if (opened) {
			setIsFavorite(tyle?.favorite ?? false);
		}
	};

	return items ? (
		<StyledThreeDotsMenu>
			<Dropdown
				trigger={<Icon className="popover-toggle" type="more" />}
				onOpenChange={handleDropdownOpened}
				items={items}
				onEvent={onSelect}
			/>
		</StyledThreeDotsMenu>
	) : null;
};

export default ThreeDotsMenu;
