import { ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';
import tagsState from 'state/TagsState';
import useTagsHooks from 'hooks/useTagsHooks';
import sharedState from 'state/SharedState';
import Tags from 'components/Tags';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import SearchCombobox from 'components/SearchCombobox';
import userState from 'state/UserState';
import emojiList from 'models/fixtures/EmojiFixture';
import { Tag } from 'models/Tag';

export const StyledSearchSidebarHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-bottom: 1px solid ${(props) => props.theme.colors?.gray300};
	max-height: 135px;
	padding-bottom: 5px;
`;

export interface SearchSidebarHeaderProps {
	inputText: string;
	handleInputChange: (value: string) => void;
}

const SearchSidebarHeader = ({ inputText, handleInputChange }: SearchSidebarHeaderProps): ReactElement => {
	const { getTopTags, getTagsFromSearch } = useTagsHooks();
	const { displayedTags } = useSnapshot(tagsState);
	const [dropdownItems, setDropdownItems] = useState<SelectableItem[]>([]);
	const [selectableTagItems, setSelectableTagItems] = useState<SelectableItem[]>([]);
	const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set());

	useEffect(() => {
		getTopTags().then((topTags) => {
			const set = new Set<SelectableItem>();
			const formattedTags = topTags.map((tag) => {
				const selectableItem = { label: tag.label, value: tag.id };
				set.add(selectableItem);
				return selectableItem;
			});
			tagsState.displayedTags = set;
			setSelectableTagItems(formattedTags);
			setDropdownItems(formattedTags);
		});
	}, []);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			const input = document.getElementById('search-input') as HTMLTextAreaElement;
			// Remove input focus when "option" or "command" key is pressed to activate a shortcut
			if (
				(event.altKey || event.metaKey) &&
				(event.code === 'Digit1' || event.code === 'Digit2' || event.code === 'Digit3')
			) {
				input.blur();
			}
			// Focus input when "shift" + "option" or "command" key is pressed
			if (event.shiftKey && (event.altKey || event.metaKey)) {
				input.focus();
			}
		};
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	const handleChange = useCallback(
		(value: string) => {
			sharedState.searchSidebarInputText = value; // Update input text in shared state to manage results
			handleInputChange(value);
		},
		[handleInputChange],
	);

	function filterTags(tagsToFilter: Tag[], selectedTags: Set<string>): SelectableItem[] {
		return tagsToFilter
			.filter((tag) => !selectedTags.has(tag.id))
			.map((tag) => ({
				label: tag.label,
				value: tag.id,
			}));
	}

	const handleTagInputChange = useCallback((value: string) => {
		if (!value) {
			getTopTags().then((topTags) => {
				setDropdownItems(filterTags(topTags, tagsState.selectedTagIds));
			});
			return;
		}
		getTagsFromSearch(value).then((tagSearchResults) => {
			setDropdownItems(filterTags(tagSearchResults, tagsState.selectedTagIds));
		});
	}, []);

	const handleEmojiSearch = useCallback((value: string) => {
		if (!value) {
			setDropdownItems(emojiList);
			return;
		}
		setDropdownItems(emojiList.filter((emoji) => emoji.label.includes(value)));
	}, []);

	const handleTriggerInputChange = useCallback((value: string) => {
		switch (sharedState.searchTrigger) {
			case '@': // Get users
				if (userState.user && userState.user.id) {
					setDropdownItems([
						{
							label: `${userState.user.firstName} ${userState.user.lastName}`,
							value: userState.user.id,
						},
					]);
				}
				break;
			case '#': // Get tags
				handleTagInputChange(value);
				break;
			case ':': // Get emojis
				handleEmojiSearch(value);
				break;
			default:
				break;
		}
	}, []);

	const handleClick = useCallback(
		(item: SelectableItem) => {
			const hasItem = Array.from(tagsState.displayedTags).find((tag) => tag.value === item.value);
			if (!hasItem) {
				const newTagItems = Array.from(tagsState.displayedTags);
				newTagItems.unshift(item);
				const newSet = new Set<SelectableItem>();
				newSet.add(item);
				newTagItems.forEach((tag) => newSet.add(tag));
				tagsState.displayedTags = newSet;
				setSelectableTagItems(newTagItems);
			}
			if (hasItem && tagsState.selectedTagIds?.has(item.value)) {
				selectedSet?.delete(item.value);
				setSelectedSet(new Set(selectedSet));
				tagsState.selectedTagIds.delete(item.value);
				tagsState.selectedTagIds = new Set(tagsState.selectedTagIds);
				tagsState.selectedTag = item;
				return;
			}
			tagsState.selectedTag = item;
			tagsState.selectedTagIds?.add(item.value);
			tagsState.selectedTagIds = new Set(tagsState.selectedTagIds);
			setSelectedSet(new Set(tagsState.selectedTagIds));
		},
		[selectedSet, selectableTagItems, displayedTags, setSelectedSet],
	);

	const handleCloseTag = useCallback(
		(item: SelectableItem) => {
			tagsState.displayedTags.delete(item);
			selectedSet.delete(item.value);
			setSelectedSet(new Set(selectedSet));
			tagsState.selectedTagIds = new Set(selectedSet);
			const index = selectableTagItems.findIndex((tag) => tag.value === item.value);
			const newTagItems = selectableTagItems;
			newTagItems.splice(index, 1);
			setSelectableTagItems(newTagItems);
		},
		[selectableTagItems],
	);
	return (
		<StyledSearchSidebarHeader>
			<SearchCombobox
				items={dropdownItems}
				onChange={handleChange}
				onTriggerChange={handleTriggerInputChange}
				onSelect={handleClick}
				defaultValue={inputText}
			/>
			<Tags
				items={selectableTagItems}
				onItemClick={handleClick}
				onCloseTag={handleCloseTag}
				selectedItems={selectedSet}
			/>
		</StyledSearchSidebarHeader>
	);
};

export default SearchSidebarHeader;
