export interface StorageObjectInterface {
	[key: string]: any;
}

export interface StorageServiceInterface {
	remove(key: string): void;

	store(object: StorageObjectInterface): void;

	get(key: StorageKeys): Record<string, unknown> | null;
}

export enum StorageKeys {
	AUTHENTICATION = 'authentication',
	USER_CANVAS_TYLES = 'userCanvasTyles',
}

const StorageService = (): StorageServiceInterface => {
	const store = (object: StorageObjectInterface): void => {
		const key = Object.keys(object).pop();

		if (!key) {
			return;
		}

		localStorage.setItem(key, JSON.stringify(object[key]));
	};

	const get = (key: string): Record<string, unknown> | null => {
		const value = localStorage.getItem(key);
		if (!value || value === 'undefined') {
			return null;
		}

		return JSON.parse(value);
	};

	const remove = (key: string): void => {
		localStorage.removeItem(key);
	};

	return {
		store,
		get,
		remove,
	};
};

export default StorageService;
