const emojiList = [
	{ value: '😄', label: '😄 smile' },
	{ value: '😆', label: '😆 laughing' },
	{ value: '😊', label: '😊 blush' },
	{ value: '😃', label: '😃 smiley' },
	{ value: '😏', label: '😏 smirk' },
	{ value: '😍', label: '😍 heart_eyes' },
	{ value: '😘', label: '😘 kissing_heart' },
	{ value: '😚', label: '😚 kissing_closed_eyes' },
	{ value: '😳', label: '😳 flushed' },
	{ value: '😌', label: '😌 relieved' },
	{ value: '😁', label: '😁 grin' },
	{ value: '😉', label: '😉 wink' },
	{ value: '😀', label: '😀 grinning' },
	{ value: '😗', label: '😗 kissing' },
	{ value: '😙', label: '😙 kissing_smiling_eyes' },
	{ value: '😛', label: '😛 stuck_out_tongue' },
	{ value: '😴', label: '😴 sleeping' },
	{ value: '😟', label: '😟 worried' },
	{ value: '😦', label: '😦 frowning' },
	{ value: '😧', label: '😧 anguished' },
	{ value: '😮', label: '😮 open_mouth' },
	{ value: '😬', label: '😬 grimacing' },
	{ value: '😕', label: '😕 confused' },
	{ value: '😯', label: '😯 hushed' },
	{ value: '😑', label: '😑 expressionless' },
	{ value: '😒', label: '😒 unamused' },
	{ value: '😅', label: '😅 sweat_smile' },
	{ value: '😓', label: '😓 sweat' },
	{ value: '😥', label: '😥 disappointed_relieved' },
	{ value: '😩', label: '😩 weary' },
	{ value: '😔', label: '😔 pensive' },
	{ value: '😞', label: '😞 disappointed' },
	{ value: '😖', label: '😖 confounded' },
	{ value: '😨', label: '😨 fearful' },
	{ value: '😰', label: '😰 cold_sweat' },
	{ value: '😣', label: '😣 persevere' },
	{ value: '😢', label: '😢 cry' },
	{ value: '😭', label: '😭 sob' },
	{ value: '😂', label: '😂 joy' },
	{ value: '😲', label: '😲 astonished' },
	{ value: '😱', label: '😱 scream' },
	{ value: '😫', label: '😫 tired_face' },
	{ value: '😠', label: '😠 angry' },
	{ value: '😡', label: '😡 rage' },
	{ value: '😤', label: '😤 triumph' },
	{ value: '😪', label: '😪 sleepy' },
	{ value: '😋', label: '😋 yum' },
	{ value: '😷', label: '😷 mask' },
	{ value: '😎', label: '😎 sunglasses' },
	{ value: '😵', label: '😵 dizzy_face' },
	{ value: '👿', label: '👿 imp' },
	{ value: '😈', label: '😈 smiling_imp' },
	{ value: '😐', label: '😐 neutral_face' },
	{ value: '😶', label: '😶 no_mouth' },
	{ value: '😇', label: '😇 innocent' },
	{ value: '👽', label: '👽 alien' },
	{ value: '💛', label: '💛 yellow_heart' },
	{ value: '💙', label: '💙 blue_heart' },
	{ value: '💜', label: '💜 purple_heart' },
	{ value: '❤️', label: '❤️ heart' },
	{ value: '💚', label: '💚 green_heart' },
	{ value: '💔', label: '💔 broken_heart' },
	{ value: '💓', label: '💓 heartbeat' },
	{ value: '💗', label: '💗 heartpulse' },
	{ value: '💕', label: '💕 two_hearts' },
	{ value: '💞', label: '💞 revolving_hearts' },
	{ value: '💘', label: '💘 cupid' },
	{ value: '💖', label: '💖 sparkling_heart' },
	{ value: '✨', label: '✨ sparkles' },
	{ value: '⭐', label: '⭐ star' },
	{ value: '🌟', label: '🌟 star2' },
	{ value: '💫', label: '💫 dizzy' },
	{ value: '💥', label: '💥 boom' },
	{ value: '💢', label: '💢 anger' },
	{ value: '❗', label: '❗ exclamation' },
	{ value: '❓', label: '❓ question' },
	{ value: '💤', label: '💤 zzz' },
	{ value: '💨', label: '💨 dash' },
	{ value: '💦', label: '💦 sweat_drops' },
	{ value: '🎶', label: '🎶 notes' },
	{ value: '🎵', label: '🎵 musical_note' },
	{ value: '🔥', label: '🔥 fire' },
	{ value: '💩', label: '💩 shit' },
	{ value: '👍', label: '👍 thumbsup' },
	{ value: '👎', label: '👎 thumbsdown' },
	{ value: '👌', label: '👌 ok_hand' },
	{ value: '👊', label: '👊 facepunch' },
	{ value: '✊', label: '✊ fist' },
	{ value: '✌️', label: '✌️ v' },
	{ value: '👋', label: '👋 wave' },
	{ value: '✋', label: '✋ raised_hand' },
	{ value: '👐', label: '👐 open_hands' },
	{ value: '☝️', label: '☝️ point_up' },
	{ value: '👇', label: '👇 point_down' },
	{ value: '👈', label: '👈 point_left' },
	{ value: '👉', label: '👉 point_right' },
	{ value: '🙌', label: '🙌 raised_hands' },
	{ value: '🙏', label: '🙏 pray' },
	{ value: '👆', label: '👆 point_up_2' },
	{ value: '👏', label: '👏 clap' },
	{ value: '💪', label: '💪 muscle' },
	{ value: '🤘', label: '🤘 metal' },
	{ value: '🖕', label: '🖕 fu' },
	{ value: '🚶', label: '🚶 walking' },
	{ value: '🏃', label: '🏃 running' },
	{ value: '👫', label: '👫 couple' },
	{ value: '👪', label: '👪 family' },
	{ value: '👬', label: '👬 two_men_holding_hands' },
	{ value: '👭', label: '👭 two_women_holding_hands' },
	{ value: '💃', label: '💃 dancer' },
	{ value: '👯', label: '👯 dancers' },
	{ value: '🙆‍♀️', label: '🙆‍♀️ ok_woman' },
	{ value: '🙅', label: '🙅 no_good' },
	{ value: '💁', label: '💁 information_desk_person' },
	{ value: '🙋', label: '🙋 raising_hand' },
	{ value: '👰‍♀️', label: '👰‍♀️ bride_with_veil' },
	{ value: '🙇', label: '🙇 bow' },
	{ value: '💏', label: '💏 couplekiss' },
	{ value: '💑', label: '💑 couple_with_heart' },
	{ value: '💆', label: '💆 massage' },
	{ value: '💇', label: '💇 haircut' },
	{ value: '💅', label: '💅 nail_care' },
	{ value: '👦', label: '👦 boy' },
	{ value: '👧', label: '👧 girl' },
	{ value: '👩', label: '👩 woman' },
	{ value: '👨', label: '👨 man' },
	{ value: '👶', label: '👶 baby' },
	{ value: '👵', label: '👵 older_woman' },
	{ value: '👴', label: '👴 older_man' },
	{ value: '👲', label: '👲 man_with_gua_pi_mao' },
	{ value: '👳‍♂️', label: '👳‍♂️ man_with_turban' },
	{ value: '👷', label: '👷 construction_worker' },
	{ value: '👮', label: '👮 cop' },
	{ value: '👼', label: '👼 angel' },
	{ value: '👸', label: '👸 princess' },
	{ value: '😺', label: '😺 smiley_cat' },
	{ value: '😸', label: '😸 smile_cat' },
	{ value: '😻', label: '😻 heart_eyes_cat' },
	{ value: '😽', label: '😽 kissing_cat' },
	{ value: '😼', label: '😼 smirk_cat' },
	{ value: '🙀', label: '🙀 scream_cat' },
	{ value: '😿', label: '😿 crying_cat_face' },
	{ value: '😹', label: '😹 joy_cat' },
	{ value: '😾', label: '😾 pouting_cat' },
	{ value: '👹', label: '👹 japanese_ogre' },
	{ value: '👺', label: '👺 japanese_goblin' },
	{ value: '🙈', label: '🙈 see_no_evil' },
	{ value: '🙉', label: '🙉 hear_no_evil' },
	{ value: '🙊', label: '🙊 speak_no_evil' },
	{ value: '💂‍♂️', label: '💂‍♂️ guardsman' },
	{ value: '💀', label: '💀 skull' },
	{ value: '🐾', label: '🐾 feet' },
	{ value: '👄', label: '👄 lips' },
	{ value: '💋', label: '💋 kiss' },
	{ value: '💧', label: '💧 droplet' },
	{ value: '👂', label: '👂 ear' },
	{ value: '👀', label: '👀 eyes' },
	{ value: '👃', label: '👃 nose' },
	{ value: '👅', label: '👅 tongue' },
	{ value: '🔔', label: '🔔 bell' },
	{ value: '🔕', label: '🔕 no_bell' },
	{ value: '🎋', label: '🎋 tanabata_tree' },
	{ value: '🎉', label: '🎉 tada' },
	{ value: '🎊', label: '🎊 confetti_ball' },
	{ value: '🔮', label: '🔮 crystal_ball' },
	{ value: '♻️', label: '♻️ recycle' },
	{ value: '🔚', label: '🔚 end' },
	{ value: '🔛', label: '🔛 on' },
	{ value: '🔜', label: '🔜 soon' },
];

export default emojiList;
