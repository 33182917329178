import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { TyleInterface } from 'interfaces/TyleInterface';
import TyleWindowHeader from 'containers/TyleWindow/TyleWindowHeader';
import TyleWindowContent from 'containers/TyleWindow/TyleWindowContent';
import { TyleWindowContainer } from 'containers/HorizontalCanvas/HorizontalCanvas.styled';
import tyleWindowHelper from 'containers/TyleWindow/helpers';
import { useSnapshot } from 'valtio';
import { TyleWindowActionEnum, TyleWindowViews } from '../../interfaces/TyleWindowInterface';
import { ApiContext } from '../App';
import StyledPublicTyleWindow from './PublicTyleWindow.styled';
import tylesState from '../../state/TylesState';
import sharedState from '../../state/SharedState';
import useCanvasHooks from '../../hooks/useCanvasHooks';
import useTyleHooks from '../../hooks/useTyleHooks';
import canvasState from '../../state/CanvasState';

export interface PublicTyleWindowProps {
	tyleFromUrl: TyleInterface | undefined;
	url: string;
}

const PublicTyleWindow = ({ tyleFromUrl, url }: PublicTyleWindowProps): ReactElement | null => {
	const api = useContext(ApiContext);
	const { clickedTyle } = useSnapshot(tylesState);
	const [tyle, setTyle] = useState<TyleInterface | undefined>(tyleFromUrl);
	const [windowId, setWindowId] = useState<string>(nanoid());
	const [currentView, setCurrentView] = useState<TyleWindowViews>(TyleWindowViews.EDITOR);
	const { changeView } = tyleWindowHelper();
	const { addTyleToNavigation, navigateBack, navigateForward, makeAllLinksPublicForPublicTyle } = useCanvasHooks();
	const { getTyle } = useTyleHooks();

	// Get tyle from url and set navigation object
	useEffect(() => {
		if (!tyleFromUrl?.id) {
			return;
		}

		setTyle(tyleFromUrl);

		sharedState.publicWindowEntity = {
			tyleId: tyleFromUrl.id,
			selected: true,
			scrollTop: 0,
			queue: [tyleFromUrl.id],
			navigationIndex: 0,
			windowId,
		};

		// Get linked tyles for this tyle
		if (!tyleFromUrl.link?.length || !tyleFromUrl.id) {
			return;
		}
		makeAllLinksPublicForPublicTyle({ tyleLinks: tyleFromUrl.link }).then(() => {
			// Rerender tyle after state update for links
			setTyle(tyleFromUrl);
		});
	}, [tyleFromUrl, url, windowId]);

	// Subscribe to clicked tyle (block or inline) in editor to navigate to that tyle
	useEffect(() => {
		if (!tylesState.clickedTyle || !tyle?.id) {
			return;
		}
		setCurrentView(TyleWindowViews.EDITOR);
		const parentTyleId = tyle?.id;
		const childTyleId = tylesState.clickedTyle.tyleId;
		if (!sharedState.publicWindowEntity) {
			return;
		}
		const { queue, navigationIndex } = sharedState.publicWindowEntity;
		if (parentTyleId === queue[navigationIndex]) {
			api.postGenerateTyleUrlForChild({ childTyleId, parentTyleId }).then((newTyle) => {
				setTyle(newTyle);
				addTyleToNavigation({
					windowId,
					destinationTyleId: childTyleId,
					isPublicTyle: !!(canvasState.isPublicView && sharedState.publicWindowEntity),
				});
			});
		}
	}, [clickedTyle]);

	const handleNavigateBack = async () => {
		if (!sharedState.publicWindowEntity) {
			return;
		}
		const tyleToRenderId = sharedState.publicWindowEntity.queue[sharedState.publicWindowEntity.navigationIndex - 1];
		setTyle(tylesState.tylesObjects[tyleToRenderId] ?? tylesState.tylesObjects[url]);
		navigateBack({ windowId, isPublicTyle: true });
	};

	const handleNavigateForward = () => {
		if (!sharedState.publicWindowEntity) {
			return;
		}
		const tyleToRenderId = sharedState.publicWindowEntity.queue[sharedState.publicWindowEntity.navigationIndex + 1];
		setTyle(tylesState.tylesObjects[tyleToRenderId] ?? tylesState.tylesObjects[url]);
		navigateForward({ windowId, isPublicTyle: true });
	};

	return tyleFromUrl ? (
		<StyledPublicTyleWindow>
			<TyleWindowContainer isPublic>
				<TyleWindowHeader
					windowId={windowId}
					isDragging={false}
					dragHandleProps={undefined}
					tyle={tyle}
					view={currentView}
					selected={false}
					onThreeDotsMenuSelect={() => null}
					onChangeView={(action: TyleWindowActionEnum) => {
						setCurrentView(changeView(action, currentView));
					}}
					showNavigation={!!(sharedState.publicWindowEntity && sharedState.publicWindowEntity?.queue?.length > 1)}
					navigateBack={handleNavigateBack}
					navigateForward={handleNavigateForward}
				/>

				<TyleWindowContent
					columns={1}
					tyleUrl={url}
					tyle={tyle}
					view={currentView}
					windowId={windowId}
					changeView={() => null}
					onTitleChange={() => null}
					createWindowForTyle={() => null}
					scrollTop={0}
					updateScrollTop={() => null}
				/>
			</TyleWindowContainer>
		</StyledPublicTyleWindow>
	) : null;
};

export default PublicTyleWindow;
