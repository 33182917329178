import { useContext } from 'react';
import {
	AddLinkToTyleProps,
	AddToTyleProps,
	RemoveLinkFromTyleProps,
	RemoveTyleBlockInterface,
	TyleInterface,
	UpdateTyleBlockInterface,
} from '../interfaces/TyleInterface';
import useAnalytics, { TrackingEvent } from './useAnalytics';
import getTyleLinkDistinction from '../helpers/getTyleLinkDistinction';
import { ApiContext } from '../components/App';
import { GenericError, GenericSuccess, Tyle } from '../api/generated/client';
import tylesState, { tylesObjectActions } from '../state/TylesState';
import { ELEMENT_TYLE_INLINE } from '../components/editor/types/tyleTypes';
import useSharedHooks from './useSharedHooks';
import sharedState from '../state/SharedState';
import newCanvasState from '../state/NewCanvas';
import canvasState from '../state/CanvasState';

const useTyleHooks = () => {
	const api = useContext(ApiContext);
	const { track } = useAnalytics();
	const { removeWindowFromUserCanvasTyles, removeTyleIdAndNextElementsFromEveryQueue } = useSharedHooks();

	const addLinkToTyle = async ({ tyleId, linkId }: AddLinkToTyleProps): Promise<TyleInterface> => {
		track(TrackingEvent.TYLE_CONNECTION_CREATE);
		const tyleUpdated = await api.postLinkTyle(tyleId, { id: linkId });

		tylesObjectActions.updateTyleObject({ id: tyleId, newTyleProperties: tyleUpdated });

		if (tylesState.selectedTyle?.id === tyleId) {
			tylesState.selectedTyle = {
				...tylesState.selectedTyle,
				...tyleUpdated,
			};
		}
		return tyleUpdated;
	};

	const removeLinkFromTyle = async ({ tyleId, linkId }: RemoveLinkFromTyleProps): Promise<TyleInterface> => {
		track(TrackingEvent.TYLE_CONNECTION_DELETE);
		const tyleUpdated = await api.deleteLinkTyle(tyleId, { id: linkId });

		tylesObjectActions.updateTyleObject({ id: tyleId, newTyleProperties: tyleUpdated });

		if (tylesState.selectedTyle?.id === tyleId) {
			tylesState.selectedTyle = {
				...tylesState.selectedTyle,
				...tyleUpdated,
			};
		}
		tylesState.isDeleteBlock = false;
		return tyleUpdated;
	};

	const updateTyle = async (tyle: TyleInterface, from?: string, callApi = true): Promise<TyleInterface> => {
		if (process.env.NODE_ENV === 'development') {
			console.log('FROM', from);
		}
		let tyleToUpdate = tyle;
		if (!tyleToUpdate.id) {
			return new Promise<TyleInterface>((resolve) => {
				resolve(tyleToUpdate);
			});
		}

		if (callApi) {
			tyleToUpdate = await api.updateTyle(tyleToUpdate.id, tyleToUpdate);
		}

		if (tyleToUpdate?.id) {
			tylesObjectActions.updateTyleObject({ id: tyleToUpdate.id, newTyleProperties: tyleToUpdate });
		}

		// Update favorite tyles list when tyle is added to favorites or removed
		if (!tyleToUpdate?.favorite && tylesState.favoriteTyles.findIndex((t) => t.id === tyleToUpdate.id) > -1) {
			tylesState.favoriteTyles = tylesState.favoriteTyles.filter((t) => t.id !== tyleToUpdate.id);
		}
		if (tyleToUpdate.favorite && tylesState.favoriteTyles.findIndex((t) => t.id === tyleToUpdate.id) === -1) {
			tylesState.favoriteTyles = [...tylesState.favoriteTyles, tyleToUpdate];
		}

		if (tylesState.selectedTyle?.id === tyle?.id) {
			tylesState.selectedTyle = {
				...tylesState.selectedTyle,
				...tyle,
			};
		}
		return tyleToUpdate;
	};

	const handleLinks = (tyleToUpdate: TyleInterface): void => {
		const { linkIdsToAdd, linkIdsToRemove } = getTyleLinkDistinction(tyleToUpdate);
		if (linkIdsToRemove.length > 0) {
			tylesState.isDeleteBlock = true;
			linkIdsToRemove.forEach(async (linkId) => {
				if (!tyleToUpdate.id || !linkId) {
					return;
				}
				await removeLinkFromTyle({ tyleId: tyleToUpdate.id, linkId }).then(() => {
					updateTyle(tyleToUpdate).then();
				});
			});
		}

		if (linkIdsToAdd.length > 0) {
			linkIdsToAdd.forEach(async (linkId) => {
				if (!tyleToUpdate.id || !linkId) {
					return;
				}
				await addLinkToTyle({ tyleId: tyleToUpdate.id, linkId }).then(() => {
					updateTyle(tyleToUpdate).then(() => {
						if (tylesState.dropResult) {
							tylesState.dropResult = null;
						}
					});
				});
			});
		}
	};

	const updateTyleContent = async (tyle: TyleInterface, from = 'updateTyleContent'): Promise<TyleInterface> => {
		if (process.env.NODE_ENV === 'development') {
			console.log('FROM', from);
		}
		const tyleToUpdate = tyle;
		if (!tyleToUpdate.id) {
			return new Promise<TyleInterface>((resolve) => {
				resolve(tyleToUpdate);
			});
		}

		handleLinks(tyleToUpdate);

		return updateTyle(tyleToUpdate, 'FROM TYLE HOOKS 106');
	};

	const insertTyleBlock = ({ tyleId, block }: UpdateTyleBlockInterface): Promise<GenericSuccess | GenericError> => {
		return api.insertTyleBlock(tyleId, block);
	};

	const updateTyleBlock = async ({
		tyleId,
		block,
	}: UpdateTyleBlockInterface): Promise<GenericSuccess | GenericError> => {
		handleLinks(tylesState.tylesObjects[tyleId]);
		const updateTyleBlockCall = api.updateTyleBlock(tyleId, block);

		const updatedTyle = tylesState.tylesObjects[tyleId];
		if (updatedTyle?.content) {
			const newContent = updatedTyle.content.map((b) => {
				if (b.id === block.id) {
					return block;
				}
				return b;
			});
			await updateTyle({ ...updatedTyle, content: newContent }, 'FROM TYLE HOOKS 127', false);
		}
		return updateTyleBlockCall;
	};

	const removeTyleBlocks = ({ tyleId, blockIds }: RemoveTyleBlockInterface): Promise<GenericSuccess | GenericError> => {
		return api.removeTyleBlocks(tyleId, { blockIds });
	};

	const createEmptyTyle = async (): Promise<TyleInterface> => {
		const tyleCreated = await api.createEmptyTyle();
		if (tyleCreated.id) {
			tylesObjectActions.addTyleObject({ id: tyleCreated.id, tyle: tyleCreated });
		}
		return tyleCreated;
	};

	const createTyle = async (tyle: Tyle): Promise<TyleInterface> => {
		const tyleCreated = await api.createTyle(tyle);
		if (tyleCreated.id) {
			tylesObjectActions.addTyleObject({ id: tyleCreated.id, tyle: tyleCreated });
		}
		return tyleCreated;
	};

	const createInstanceFromTyle = async (id: string) => {
		const newInstance = await api.createNewInstanceFromTyle(id);
		if (newInstance.id) {
			tylesObjectActions.addTyleObject({ id: newInstance.id, tyle: newInstance });
		}
		return newInstance;
	};

	const getTyle = async (id: string): Promise<TyleInterface> => {
		if (canvasState.isPublicView) {
			const tyle = await api.getPublicTyle(id);
			tylesState.tylesObjects[id] = tyle;
			return tyle;
		}
		const tyle = await api.getTyle(id);
		tylesState.tylesObjects[id] = tyle;
		return tyle;
	};

	const deleteTyle = async ({ tyleId, windowId }: { tyleId: string; windowId?: string }): Promise<{ id: string }> => {
		track(TrackingEvent.TYLE_DELETE);
		const tylesUpdated = await api.softDeleteTyle(tyleId);
		if (windowId) {
			removeWindowFromUserCanvasTyles(windowId);
		}
		removeTyleIdAndNextElementsFromEveryQueue(tyleId);
		tylesObjectActions.deleteTyleObject({ id: tyleId });
		return tylesUpdated;
	};

	/**
	 * Get list of inbox tyles IDs
	 */
	const getInboxTyles = async (): Promise<Array<string>> => {
		const inboxTyles = await api.getInboxTyles();
		tylesState.inboxTyles = inboxTyles.map((tyleId: string) => {
			if (!tylesState.tylesObjects[tyleId]) {
				getTyle(tyleId).then((tyle) => {
					return tyle;
				});
			}
			return tylesState.tylesObjects[tyleId];
		});
		return inboxTyles;
	};

	/**
	 * Get list of linked tyles
	 */
	const getLinkedTylesById = (id: string): Promise<TyleInterface[]> => {
		return api.getLinkedTyles(id);
	};

	/**
	 * Get list of backlinked tyles
	 */
	const getBacklinkedTylesById = (id: string): Promise<TyleInterface[]> => {
		return api.getBacklinkedTyles(id);
	};

	const updateTylesObjectState = (tyles: TyleInterface[]) => {
		tyles.forEach((tyle) => {
			if (tyle.id && !tylesState.tylesObjects[tyle.id]) {
				tylesObjectActions.addTyleObject({ id: tyle.id, tyle });
			}
		});
	};
	/**
	 * Get list of recent tyles
	 */
	const getRecentlyCreatedTyles = async (): Promise<Array<TyleInterface>> => {
		const recentTyles = await api.getRecentlyCreatedTyles();
		tylesState.recentTyles = recentTyles;
		updateTylesObjectState(tylesState.recentTyles);
		return recentTyles;
	};

	/**
	 * Get list of favorite tyles
	 */
	const getFavoriteTyles = async (): Promise<Array<TyleInterface>> => {
		tylesState.status = 'loading';
		const favoriteTyles = await api.getFavoriteTyles();
		if (!favoriteTyles) {
			tylesState.status = 'error';
		}
		tylesState.favoriteTyles = favoriteTyles;
		updateTylesObjectState(tylesState.favoriteTyles);
		tylesState.status = 'success';
		return favoriteTyles;
	};

	/**
	 * Get search results tyles
	 */
	const getSearchResultsTyles = async (
		search: string,
		filters?: Record<string, string[]>,
		limit = 20,
	): Promise<Array<TyleInterface>> => {
		track(TrackingEvent.SEARCH);
		if (filters) {
			// Track how much a user has used the AI generated filters
			track(TrackingEvent.AI_FILTER_USED);
		}
		tylesState.status = 'loading';
		const searchResultTyles = await api.searchTyles(search, limit, filters).catch((err: Error) => {
			tylesState.status = 'error';
			throw err;
		});
		tylesState.searchResultsTyles = searchResultTyles;
		updateTylesObjectState(tylesState.searchResultsTyles);
		tylesState.status = 'success';
		return searchResultTyles;
	};

	/**
	 * Get search results by title
	 */
	const getSearchResultsByTitle = async (
		search: string,
		filters?: Record<string, string[]>,
		limit = 20,
	): Promise<Array<TyleInterface>> => {
		track(TrackingEvent.SEARCH);
		if (filters) {
			// Track how much a user has used the AI generated filters
			track(TrackingEvent.AI_FILTER_USED);
		}
		const searchResultTyles = await api.searchTylesByTitle(search, limit, filters);
		tylesState.searchResultsTyles = searchResultTyles;
		updateTylesObjectState(tylesState.searchResultsTyles);
		return searchResultTyles;
	};

	const getLastUpdatedTyle = async (): Promise<TyleInterface> => {
		const tyle = await api.getLastUpdatedTyle();
		updateTylesObjectState([tyle]);
		return tyle;
	};

	const clearInboxTyles = async (): Promise<boolean> => {
		const cleared = await api.clearInboxTyles();
		if (!cleared) {
			return false;
		}
		tylesObjectActions.clearInboxTyles();
		return true;
	};

	/**
	 * Save the highlight as a quote or paragraph block in a newly created tyle and append this to the destination tyle passed as parameter
	 * @param destinationTyle The destination tyle
	 * @param tyleToSave The new tyle metadata
	 */
	const addHighlightToTyle = async ({ destinationTyle, tyleToSave }: AddToTyleProps): Promise<TyleInterface | null> => {
		if (!destinationTyle?.id) {
			return null;
		}

		if (!tyleToSave?.id) {
			return null;
		}

		if (!destinationTyle.content) {
			return updateTyleContent(
				{
					...destinationTyle,
					content: [
						{
							type: 'p',
							children: [
								{ text: '' },
								{
									type: ELEMENT_TYLE_INLINE,
									tyleId: tyleToSave.id,
									children: [{ text: '' }],
								},
								{ text: '' },
							],
						},
					],
				},
				'ADD HIGHLIGHT',
			);
		}
		return updateTyleContent(
			{
				...destinationTyle,
				content: [
					...destinationTyle.content,
					{
						type: 'p',
						children: [
							{ text: '' },
							{
								type: ELEMENT_TYLE_INLINE,
								tyleId: tyleToSave.id,
								children: [{ text: '' }],
							},
							{ text: '' },
						],
					},
				],
			},
			'ADD HIGHLIGHT',
		).then((tyle) => {
			if (!tyle?.id || !tyleToSave.id) return tyle;
			track(TrackingEvent.HIGHLIGHT_CREATED);
			addLinkToTyle({ tyleId: tyle.id, linkId: tyleToSave.id });
			return tyle;
		});
	};

	return {
		updateTyle,
		updateTyleBlock,
		updateTyleContent,
		insertTyleBlock,
		removeTyleBlocks,
		addLinkToTyle,
		removeLinkFromTyle,
		getLinkedTylesById,
		getBacklinkedTylesById,
		createTyle,
		createEmptyTyle,
		createInstanceFromTyle,
		deleteTyle,
		getTyle,
		getInboxTyles,
		getRecentlyCreatedTyles,
		getFavoriteTyles,
		getSearchResultsTyles,
		getSearchResultsByTitle,
		clearInboxTyles,
		addHighlightToTyle,
		getLastUpdatedTyle,
	};
};

export default useTyleHooks;
