import Icon from 'components/icon';
import Paragraph from 'components/paragraph';
import styled from 'styled-components';

export const StyledFavoriteIconSpan = styled.span`
	position: absolute;
	line-height: 1;
	margin: -54px 0 0 -8px;
	padding: 3px;
	color: #aaaaaa;
`;

export const StyledTyleWrapper = styled.div.attrs((props: { selected: boolean }) => props)`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	height: 58px;
	border: 1px solid ${(props) => (props.selected ? '#9d9d9d' : '#e8e8e8')};
	border-radius: 8px;
	width: 100%;
	min-width: 275px;
	&:hover {
		background-color: ${(props) => (props.selected ? '#F2F2F2' : 'rgba(242, 242, 242, 0.3)')};
		cursor: pointer;
	}
	background-color: ${(props) => (props.selected ? 'rgba(242, 242, 242, 0.5)' : null)};
`;

export const StyledTyleContentContainer = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;
	padding: 0 12px;
	height: 100%;
	align-items: center;
`;

export const StyledIcon = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 12px 0 0;
`;

export const StyledContentWrapper = styled.div.attrs((props: { showQuoteIcon: boolean }) => props)`
	display: flex;
	flex-flow: column;
	width: 100%;
	min-width: 200px;
	height: 39px;
	border-left: ${(props) => (props.showQuoteIcon ? '1px solid #AAAAAA' : '0')};
	padding-left: ${(props) => (props.showQuoteIcon ? '12px' : '0')};
`;

export const StyledHeaderWrapper = styled.div.attrs((props: { hasSource: boolean }) => props)`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	height: ${(props) => !props.hasSource && '40px'};
	width: 100%;
`;

export const StyledHeader = styled.div.attrs(
	(props: { isHover: boolean; isOverflow: boolean; isBookmark: boolean; hasContent: boolean; hasSource: boolean }) =>
		props,
)`
	display: flex;
	align-items: center;
	max-width: ${(props) => (props.isHover && props.isOverflow ? '270px' : '280px')};
	height: ${(props) => !props.hasContent && !props.hasSource && '40px'};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: ${(props) => {
		if (!props.isBookmark && props.hasContent) return 'nowrap';
		if (props.isBookmark && props.isOverflow) return '';
		return 'break-spaces';
	}};
`;

export const StyledParagraph = styled.div.attrs((props: { date: boolean; isHover: boolean; hidden: boolean }) => props)`
	display: flex;
	flex-flow: row;
	color: hsl(0, 0%, 40%);
	font-size: 0.688rem;
	max-width: ${(props) => (props.isHover ? '260px' : '280px')};
	overflow: hidden;
	white-space: nowrap;
	text-align: ${(props) => props.date && 'right'};
`;

export const StyledMetaData = styled(StyledParagraph)`
	flex-flow: column !important;
	justify-content: space-between;
	height: 100%;
	max-width: 100px;
	min-width: 73px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledSource = styled.div`
	overflow: hidden;
	padding-left: 12px;
	text-overflow: ellipsis;
`;

export const StyledConnectionSource = styled.div.attrs((props: { isHover: boolean; hidden: boolean }) => props)`
	display: flex;
	flex-flow: row;
	max-width: 278px;
	overflow: auto;
	height: 16px;
	visibility: ${(props) => props.hidden && 'hidden'};
`;

export const StyledTitle = styled(Paragraph).attrs((props: { isBookmark: boolean }) => props)`
	border-left: ${(props) => props.isBookmark && '2px solid #aaa'};
`;

export const StyledContentContainer = styled.div.attrs((props: { hidden: boolean }) => props)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	visibility: ${(props) => props.hidden && 'hidden'};
	color: hsl(0, 0%, 40%);
	font-size: 0.688rem;
`;

export const StyledContentText = styled.div.attrs(
	(props: { showFade: boolean; isHover: boolean; isOverflow: boolean }) => props,
)`
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 17px;
	mask-image: ${(props) =>
		props.showFade && props.isHover && props.isOverflow && 'linear-gradient(to left, transparent 8%, black 15%)'};
`;

export const StyledMetaDataText = styled.div.attrs(
	(props: { showFade: boolean; isHover: boolean; hasSource: boolean }) => props,
)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	float: right;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	min-width: ${(props) => getMinWidth(props.showFade, props.hasSource, props.isHover)};
`;

export const getMinWidth = (showFade: boolean, hasSource: boolean, isHover: boolean) => {
	if (!hasSource && showFade) return '';
	if (hasSource && !isHover) return '25%';

	if (isHover) return '25%';
	return '';
};
