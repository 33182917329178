import { ReactElement } from 'react';
import { NotificationMessage } from 'helpers/useGetNotificationFromElectron';
import {
	StyledContentWrapper,
	StyledContentLabelWrapper,
	StyledHeader,
	StyledParagraph,
	StyledCheckCircle,
} from './FastCaptureSuccessNotification.styled';

export interface FastCaptureSuccessNotificationProps {
	content: NotificationMessage;
}

const FastCaptureSuccessNotification = ({ content }: FastCaptureSuccessNotificationProps): ReactElement => {
	return (
		<StyledContentWrapper>
			<StyledContentLabelWrapper>
				<StyledHeader>
					<StyledParagraph>{content.message}</StyledParagraph>
					<StyledCheckCircle size={14} />
				</StyledHeader>
			</StyledContentLabelWrapper>
		</StyledContentWrapper>
	);
};

export default FastCaptureSuccessNotification;
