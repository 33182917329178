import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './Link.scss';

export interface LinkProps {
	href: string;
	target?: string;
	children: any;
	variant?: 'inline' | 'no-decoration';
	style?: Record<string, any>;
	onLinkClick?: () => void;
}
const Link = ({
	href,
	children,
	style,
	variant = 'inline',
	target = '_self',
	onLinkClick,
}: LinkProps): ReactElement => {
	const handleClick = () => {
		if (!onLinkClick) {
			return;
		}

		onLinkClick();
	};

	return (
		<a
			className={classNames({
				'kum-link': true,
				inline: variant === 'inline',
				'no-decoration': variant !== 'inline',
			})}
			href={href}
			target={target}
			style={style}
			onClick={handleClick}
		>
			{children}
		</a>
	);
};

export default Link;
