import React, { ReactElement, ReactNode } from 'react';

export interface BlockquoteProps {
	children?: ReactNode;
}

const Blockquote = ({ children }: BlockquoteProps): ReactElement => {
	return <blockquote className="slate-blockquote">{children}</blockquote>;
};

export default Blockquote;
