/**
 * Allows you to create hotkeys which insert a line break, without exiting the current block.
 */
import { ELEMENT_BLOCKQUOTE, ELEMENT_CODE_BLOCK, ELEMENT_TD, SoftBreakPlugin } from '@udecode/plate';
import { MyPlatePlugin } from '../types/plateTypes';

const softBreakPlugin: Partial<MyPlatePlugin<SoftBreakPlugin>> = {
	options: {
		rules: [
			{ hotkey: 'shift+enter' },
			/* {
				hotkey: 'enter',
				query: {
					allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
				},
			}, */
		],
	},
};

export default softBreakPlugin;
