import { ELEMENT_LINK, getPluginType, unwrapNodes, usePlateEditorState } from '@udecode/plate';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomEditor } from 'types/slate';

interface UseDeleteLinkProps {
	editorId: string;
}
const useDeleteLink = ({ editorId }: UseDeleteLinkProps) => {
	const editor = usePlateEditorState(editorId);

	const deleteLink = (element: any) => {
		if (!editor || !ReactEditor.findPath(editor as ReactEditor, element)) {
			return;
		}

		Transforms.select(editor as CustomEditor, ReactEditor.findPath(editor as ReactEditor, element));
		ReactEditor.focus(editor as CustomEditor);
		if (!editor.selection) {
			return;
		}
		unwrapNodes(editor, { at: editor.selection, match: { type: getPluginType(editor, ELEMENT_LINK) } });
	};

	return { deleteLink };
};

export default useDeleteLink;
