import { nanoid } from 'nanoid';
import FloatingButton from 'components/FloatingButton';
import { detectOperatingSystem } from 'helpers';
import { ShortcutLabelEnum, ShortcutMap } from 'hooks/ShortcutHooks';
import useCanvasHooks from 'hooks/useCanvasHooks';
import useTyleHooks from 'hooks/useTyleHooks';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';

const FloatingButtonContainer = () => {
	const { createEmptyTyle } = useTyleHooks();
	const { addTyleToUserCanvasTyles } = useCanvasHooks();
	const operatingSystem =
		detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS
			? OperatingSystemPlatformEnum.MAC_OS
			: OperatingSystemPlatformEnum.WINDOWS;

	const onCreateTyle = async () => {
		const newTyle = await createEmptyTyle();
		if (!newTyle.id) {
			return;
		}
		await addTyleToUserCanvasTyles({ windowId: nanoid(), tyleId: newTyle.id });
	};

	return (
		<FloatingButton
			label={ShortcutLabelEnum.NEW_NOTE}
			shortcuts={ShortcutMap.get(ShortcutLabelEnum.NEW_NOTE)?.[operatingSystem]}
			onClick={onCreateTyle}
		/>
	);
};

export default FloatingButtonContainer;
