export enum ApiStatusEnum {
	INITIALIZING = 'initializing',
	IS_LOADING = 'isLoading',
	IS_FETCHING = 'isFetching',
	IS_SUCCESS = 'isSuccess',
	RESPONDED = 'responded',
	ERROR = 'error',
}

export interface ApiInterface {
	status: ApiStatusEnum;
}
