import Dropdown from 'components/Dropdownn';
import styled from 'styled-components';

const StyledThreeDotsMenu = styled.div`
	margin-left: 10px;

	.DropdownMenuTrigger {
		padding: 0;

		.kum-icon {
			font-size: inherit;
		}
	}
`;

export default StyledThreeDotsMenu;
