import { getListItemEntry, PlateEditor, unwrapList } from '@udecode/plate';
import { Transforms } from 'slate';
import { CustomEditor } from '../types/slate';

const transformGeneric = (editor: PlateEditor, type: string): void => {
	if (!editor) {
		return;
	}

	const res = getListItemEntry(editor, { at: editor.selection?.anchor.path });
	if (res) {
		// is List, so first, convert the block to the default block (paragraph)
		unwrapList(editor);
	}
	Transforms.setNodes(editor as CustomEditor, {
		type,
	});
	Transforms.collapse(editor as CustomEditor);
};

export default transformGeneric;
