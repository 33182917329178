import React, { ReactElement, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { subscribeKey } from 'valtio/utils';
import light from './light';
import dark from './dark';
import userState from '../state/UserState';
import { ThemeEnum } from '../interfaces/UserInterface';

interface ThemeProps {
	children: any;
}

const StyledTheme = ({ children }: ThemeProps): ReactElement => {
	const [theme, setTheme] = useState<object>(light);

	// Subscribe to valtio state change for user object
	subscribeKey(userState, 'user', (user) => {
		switch (user.theme) {
			case ThemeEnum.LIGHT:
				setTheme(light);
				break;
			case ThemeEnum.DARK:
				setTheme(dark);
				break;
			default:
				setTheme(light);
		}
	});

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledTheme;
