import { adaptToken } from 'adapters';
import {
	AccountApi,
	AuthenticationApi,
	DefaultApi,
	PublicApi,
	ServerConfiguration,
	SpacesApi,
	TylesApi,
	UsersApi,
	SearchApi,
	TagsApi,
	InviteCodesApi,
} from './generated/client';
import { ConfigurationParameters, createConfiguration } from './generated/client/configuration';
import ApiMiddleware from './ApiMiddleware';
import { AuthenticationServiceInterface } from '../services/AuthenticationService';
import {
	PromiseAccountApi,
	PromiseAuthenticationApi,
	PromiseDefaultApi,
	PromisePublicApi,
	PromiseSpacesApi,
	PromiseTylesApi,
	PromiseUsersApi,
	PromiseSearchApi,
	PromiseTagsApi,
	PromiseInviteCodesApi,
} from './generated/client/types/PromiseAPI';

// todo: Refactor to functional React hook
export class Api implements ApiInterface {
	private configuration: ConfigurationParameters = {
		baseServer: new ServerConfiguration<Record<string, string>>(process.env.REACT_APP_API_URL ?? '', {}),
		authMethods: {},
		promiseMiddleware: [new ApiMiddleware(this, this.authenticationService)],
	};

	private defaultApi = this.createDefaultApi();

	private accountApi = this.createAccountApi();

	private authenticationApi = this.createAuthenticationApi();

	private spacesApi = this.createSpacesApi();

	private tylesApi = this.createTylesApi();

	private usersApi = this.createUsersApi();

	private publicApi = this.createPublicApi();

	private searchApi = this.createSearchApi();

	private tagsApi = this.createTagsApi();

	private inviteCodesApi = this.createInviteCodesApi();

	constructor(private authenticationService: AuthenticationServiceInterface) {
		this.createDefaultApi();
		this.createAccountApi();
		this.createAuthenticationApi();
		this.createSpacesApi();
		this.createTylesApi();
		this.createUsersApi();
		this.createPublicApi();
		this.createSearchApi();
		this.createTagsApi();
	}

	public setAuthentication(token: string): void {
		this.configuration.authMethods = {
			bearerAuth: `Bearer ${token}`,
		};

		this.defaultApi = this.createDefaultApi();
		this.accountApi = this.createAccountApi();
		this.authenticationApi = this.createAuthenticationApi();
		this.spacesApi = this.createSpacesApi();
		this.tylesApi = this.createTylesApi();
		this.usersApi = this.createUsersApi();
		this.searchApi = this.createSearchApi();
		this.tagsApi = this.createTagsApi();
		this.inviteCodesApi = this.createInviteCodesApi();
	}

	private createDefaultApi() {
		return new DefaultApi(createConfiguration(this.configuration));
	}

	private createAccountApi() {
		return new AccountApi(createConfiguration(this.configuration));
	}

	private createAuthenticationApi() {
		return new AuthenticationApi(createConfiguration(this.configuration));
	}

	private createSpacesApi() {
		return new SpacesApi(createConfiguration(this.configuration));
	}

	private createTylesApi() {
		return new TylesApi(createConfiguration(this.configuration));
	}

	private createUsersApi() {
		return new UsersApi(createConfiguration(this.configuration));
	}

	private createPublicApi() {
		return new PublicApi(createConfiguration(this.configuration));
	}

	private createSearchApi() {
		return new SearchApi(createConfiguration(this.configuration));
	}

	private createTagsApi() {
		return new TagsApi(createConfiguration(this.configuration));
	}

	private createInviteCodesApi() {
		return new InviteCodesApi(createConfiguration(this.configuration));
	}

	public getDefaultApi(): DefaultApi {
		return this.defaultApi;
	}

	public getAccountApi(): AccountApi {
		return this.accountApi;
	}

	public getAuthenticationApi(): AuthenticationApi {
		return this.authenticationApi;
	}

	public getSpacesApi(): SpacesApi {
		return this.spacesApi;
	}

	public getTylesApi(): TylesApi {
		return this.tylesApi;
	}

	public getPublicApi(): PublicApi {
		return this.publicApi;
	}

	public getUsersApi(): UsersApi {
		return this.usersApi;
	}

	public getSearchApi(): SearchApi {
		return this.searchApi;
	}

	public getTagsApi(): TagsApi {
		return this.tagsApi;
	}

	public getInviteCodesApi(): PromiseInviteCodesApi {
		return this.inviteCodesApi;
	}

	public refreshToken(): Promise<string | void> {
		const authentication = this.authenticationService.getAuthentication();
		if (!authentication) {
			throw new Error('No refresh token found.');
		}
		this.setAuthentication(authentication.refreshToken);
		return this.authenticationApi
			.postRefreshToken()
			.then((apiToken) => {
				const token = adaptToken(apiToken);
				this.authenticationService.updateToken(token);
				this.setAuthentication(token);
				return token;
			})
			.catch((error) => {
				this.authenticationService.logout();
				throw error;
			});
	}
}

export interface ApiInterface {
	refreshToken(): Promise<string | void>;
	setAuthentication(token: string): void;
	getDefaultApi(): PromiseDefaultApi;
	getAccountApi(): PromiseAccountApi;
	getAuthenticationApi(): PromiseAuthenticationApi;
	getSpacesApi(): PromiseSpacesApi;
	getTylesApi(): PromiseTylesApi;
	getPublicApi(): PromisePublicApi;
	getUsersApi(): PromiseUsersApi;
	getSearchApi(): PromiseSearchApi;
	getTagsApi(): PromiseTagsApi;
	getInviteCodesApi(): PromiseInviteCodesApi;
}
