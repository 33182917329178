/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import {
	ELEMENT_PARAGRAPH,
	getListItemEntry,
	getPluginType,
	someNode,
	ToolbarButton,
	ToolbarButtonProps,
	unwrapList,
	usePlateEditorState,
} from '@udecode/plate';
import { ELEMENT_TYLE_BLOCK } from 'components/editor/types/tyleTypes';
import { Editor, Transforms, Location } from 'slate';
import { CustomEditor } from 'types/slate';
import useTyleHooks from 'hooks/useTyleHooks';
import { newCanvasStateService } from 'state/NewCanvas';
import tylesState from '../../../../state/TylesState';

const TransformToBlockTyleButton = ({ active, ...props }: ToolbarButtonProps): ReactElement => {
	const editor = usePlateEditorState();
	const { createTyle, addLinkToTyle, updateTyle } = useTyleHooks();

	const type = getPluginType(editor, ELEMENT_TYLE_BLOCK);

	const buttonClick = () => {
		if (!editor || !editor.getFragment()) {
			return;
		}
		// expand selection to full lines
		/* Transforms.move(editor as CustomEditor, { distance: 0, unit: 'line', edge: 'start', reverse: true });
		Transforms.move(editor as CustomEditor, { distance: 0, unit: 'line', edge: 'end' }); */

		createTyle({ title: '', content: editor.getFragment(), updatedAt: true }).then((tyle) => {
			if (!editor.selection) {
				return;
			}

			Transforms.delete(editor as CustomEditor);

			// check if the last deleted item was a list, if so, unwrap it
			const isList = getListItemEntry(editor);
			if (isList) {
				unwrapList(editor);
			}

			const selectedTyleId = newCanvasStateService.getSelectedWindowTyleId();
			if (!tyle.id || !selectedTyleId) {
				return;
			}
			// no need to update anything after this, so just executing api request
			addLinkToTyle({ tyleId: selectedTyleId, linkId: tyle.id }).then(() => {
				Editor.insertFragment(editor as CustomEditor, [
					{
						type: ELEMENT_TYLE_BLOCK,
						tyleId: tyle.id,
						children: [{ text: '' }],
					},
					{
						type: ELEMENT_PARAGRAPH,
						children: [{ text: '' }],
					},
				]);
			});
		});
	};

	useEffect(() => {
		if (editor.children.length === 1) {
			Transforms.insertNodes(editor as CustomEditor, {
				type: ELEMENT_PARAGRAPH,
				children: [{ text: '' }],
			});
		}
	}, [editor.children]);

	return (
		<ToolbarButton
			active={active ?? (!!editor?.selection && someNode(editor, { match: { type } }))}
			onMouseDown={buttonClick}
			{...props}
		/>
	);
};

export default TransformToBlockTyleButton;
