const openExternalUrl = (url: string) => {
	let urlToOpen = url;

	if (!urlToOpen.match(/^http(s)+:\/\//i)) {
		urlToOpen = `//${urlToOpen}`;
	}

	window.open(urlToOpen, '_blank');
};

export default openExternalUrl;
