import { ApiInterface } from './ApiInterface';
import { UserCanvasData } from './CanvasInterface';

export enum Role {
	USER = 'USER',
	ADMIN = 'ADMIN',
}

export interface UserInterface {
	id?: string;
	email?: string;
	temporaryEmail?: string;
	createdAt?: Date;
	password?: string;
	firstName?: string;
	lastName?: string;
	occupation?: string;
	deleted?: boolean;
	referralCode?: string;
	theme?: any;
	role?: string;
	canvasTyles?: UserCanvasData;
	webOnboarded?: boolean;
	desktopOnboarded?: boolean;
}

export enum ThemeEnum {
	LIGHT = 'LIGHT',
	DARK = 'DARK',
}

export interface UserListResponseInterface extends ApiInterface {
	users: Array<UserInterface>;
}
