import { ReactElement, useEffect, useState } from 'react';
import { TyleConnectionInterface, TyleInterface } from 'interfaces/TyleInterface';
import TyleCard from 'components/TyleCard/TyleCard';
import Headline from 'components/headline';
import useCanvasHooks from 'hooks/useCanvasHooks';
import { TyleWindowActionEnum } from 'interfaces/TyleWindowInterface';
import useTyleHooks from 'hooks/useTyleHooks';
import tylesState, { tylesObjectActions } from 'state/TylesState';
import { StyledLinksBacklinksView, StyledLinksBacklinksViewHeader } from '../../TyleWindowContent.styled';
import canvasState from '../../../../../state/CanvasState';
import sharedState from '../../../../../state/SharedState';

interface LinksViewProps {
	windowId: string;
	tyleId?: string;
	publicTyle?: TyleInterface;
	linksConnections?: Array<TyleConnectionInterface>;
	createWindowForTyle: (tyle: TyleInterface) => void;
	changeView: (action: TyleWindowActionEnum) => void;
}

export const LinksViewTestId = 'links-view-test-id';
const LinksView = ({
	windowId,
	tyleId,
	publicTyle,
	linksConnections,
	createWindowForTyle,
	changeView,
}: LinksViewProps): ReactElement | null => {
	const { addTyleToNavigation } = useCanvasHooks();
	const [links, setLinks] = useState<TyleInterface[]>([]);
	const { getLinkedTylesById } = useTyleHooks();
	const { makeAllLinksPublicForPublicTyle } = useCanvasHooks();

	// Refresh links when tyle is updated
	useEffect(() => {
		if (!tyleId || !linksConnections) {
			setLinks([]);
			return;
		}

		// In public view, take the whole tyle object from parent and load links from state
		if (canvasState.isPublicView && linksConnections && publicTyle) {
			makeAllLinksPublicForPublicTyle({ tyleLinks: linksConnections }).then(() => {
				const linksObjects = linksConnections.map((link) => tylesState.tylesObjects[link.backlinkId]);
				setLinks(linksObjects);
			});
			return;
		}
		// In logged in view, load links from backend
		getLinkedTylesById(tyleId)
			.then((tyles) => {
				setLinks(tyles);
			})
			.catch((error) => {
				console.error(error);
				setLinks([]);
			});
	}, [tyleId, linksConnections, publicTyle]);

	const renderItem = (tyleLink: TyleInterface) => {
		if (!tyleLink.id) {
			return null;
		}
		return (
			<TyleCard
				key={tyleLink.id}
				tyle={tyleLink}
				selected={false}
				onSingleClick={() => {
					if (!tyleLink?.id) {
						return;
					}
					tylesState.clickedTyle = { windowId, tyleId: tyleLink.id };
					changeView(TyleWindowActionEnum.VIEW_TYLE);
					addTyleToNavigation({
						windowId,
						destinationTyleId: tyleLink.id,
						isPublicTyle: !!(canvasState.isPublicView && sharedState.publicWindowEntity),
					});
				}}
				onDoubleClick={() => {
					setTimeout(() => {
						if (createWindowForTyle) {
							createWindowForTyle({ ...tyleLink });
						}
					}, 100);
				}}
			/>
		);
	};

	return (
		<StyledLinksBacklinksView data-testid={LinksViewTestId}>
			<StyledLinksBacklinksViewHeader>
				<Headline>Links</Headline>
			</StyledLinksBacklinksViewHeader>
			{links.map((tyleLink) => {
				return renderItem(tyleLink);
			})}
		</StyledLinksBacklinksView>
	);
};

export default LinksView;
