import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ResponseStatus from 'interfaces/ResponseStatus';
import useAuthentication from 'helpers/useAuthentication';
import { ApiContext } from 'components/App';
import userState from 'state/UserState';
import useLogin from 'hooks/useLogin';
import { Role } from 'interfaces/UserInterface';
import useAnalytics, { TrackingEvent } from 'hooks/useAnalytics';

const InviteCodeValidationPage = (): ReactElement => {
	const [status, setStatus] = useState<ResponseStatus>(ResponseStatus.INITIAL);
	const history = useHistory();
	const { authenticate, logout, updateToken, isLoggedIn } = useAuthentication();
	const { loggedIn } = useLogin();
	const { track } = useAnalytics();
	const { code } = useParams<{ code: string }>();

	const api = useContext(ApiContext);

	const authenticateUser = useCallback(
		async (response: { token: string; refreshToken: string }) => {
			authenticate(response);
			await api.setAuthentication(response.token);
			// Get user
			return api
				.getUserAccount()
				.then((userAccount) => {
					userState.user = {
						...userAccount,
						role: userAccount.role as Role,
					};
					if (!userAccount.email) {
						const errorMessage = 'No email found for user.';
						throw Error(errorMessage);
					}
					updateToken(response.token);
					return userAccount;
				})
				.catch((error) => {
					throw error;
				});
		},
		[userState],
	);

	useEffect(() => {
		if (status === ResponseStatus.SUCCESS) {
			history.push('');
			return;
		}
		if (status !== ResponseStatus.INITIAL) {
			return;
		}
		setStatus(ResponseStatus.LOADING);
		api
			.exchangeInviteCodeForToken(code)
			.then(async (response) => {
				if (!response.token || !response.refreshToken) {
					throw Error('Invalid invite code.');
					return;
				}
				await authenticateUser({ token: response.token, refreshToken: response.refreshToken })
					.catch((error) => {
						logout();
						console.error(error);
						setStatus(ResponseStatus.ERROR);
						history.push('/login');
					})
					.then((userData) => {
						if (userData) {
							track(TrackingEvent.SIGNUP, {
								email: userData.email,
								name: `${userData.firstName} ${userData.lastName}`,
								userId: userData.id,
							});
						}
						loggedIn();
						setStatus(ResponseStatus.SUCCESS);
					});
			})
			.catch((error) => {
				logout();
				setStatus(ResponseStatus.ERROR);
				console.error(error);
				history.push('/login');
			});
	}, [status, code, authenticateUser]);

	return (
		<>
			{status === ResponseStatus.LOADING && <div>Loading...</div>}
			{status === ResponseStatus.ERROR && <div>Error using invite code</div>}
			{status === ResponseStatus.SUCCESS && <div>Success using invite code</div>}
		</>
	);
};

export default InviteCodeValidationPage;
