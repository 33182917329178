import React from 'react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { createRoot } from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import * as Sentry from '@sentry/react';
import App from './components/App';
import ElectronIPCContext from './components/ElectronIPCContext';
import './index.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// React always set process.env.NODE_ENV to 'production' when you build
if (process.env.REACT_APP_COMMIT_SHA && process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		release: process.env.REACT_APP_COMMIT_SHA,
		environment: process.env.REACT_APP_NODE_ENV,
		// Performance Monitoring
		tracesSampleRate: +(process.env.REACT_APP_TRACE_SAMPLE_RATE || 0.1), // Capture 10% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

root.render(
	<DndProvider backend={TouchBackend} options={{ enableMouseEvents: true, enableTouchEvents: true, touchSlop: 10 }}>
		<ElectronIPCContext>
			<App />
		</ElectronIPCContext>
	</DndProvider>,
);
