/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled from 'styled-components';
import {
	slideDownAndFade,
	slideLeftAndFade,
	slideUpAndFade,
	slideRightAndFade,
} from 'styles/animations/slideAnimations';
import './Popover.scss';

const StyledContent = styled(PopoverPrimitive.Content).attrs((props: { width: number }) => props)`
	width: ${(props) => props.width}px !important;
	min-width: ${(props) => props.width}px !important;
	font-size: 13px;
	border-radius: 10px;
	padding: 15px;
	border: 1px solid #e8e8e8;
	background-color: white;
	z-index: 999 !important;
	box-shadow: 1px 5px 15px -10px rgb(17 17 17);
	@media (prefers-reduced-motion: no-preference) {
		animation-duration: 400ms;
		animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
		will-change: transform opacity;
		&[data-state='open'] {
			&[data-side='top'] {
				animation-name: ${slideDownAndFade};
			}
			&[data-side='right'] {
				animation-name: ${slideLeftAndFade};
			}
			&[data-side='bottom'] {
				animation-name: ${slideUpAndFade};
			}
			&[data-side='left'] {
				animation-name: ${slideRightAndFade};
			}
		}
	}
	&:active {
		border: none;
	}
`;

const Content = ({ children, ...props }: any) => {
	return (
		<PopoverPrimitive.Portal>
			<StyledContent {...props}>{children}</StyledContent>
		</PopoverPrimitive.Portal>
	);
};

export const RadixPopover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverContent = Content;

export interface Props {
	trigger: ReactNode;
	content: ReactNode;
	sideOffset?: number;
	width?: number;
	onOpenChange?: (open: boolean) => void;
}

const Popover = ({ trigger, content, sideOffset = -10, width = 218, onOpenChange }: Props) => {
	return (
		<RadixPopover onOpenChange={onOpenChange}>
			<PopoverTrigger asChild>
				<button type="button" className="popover-trigger-button" aria-label="Open">
					{trigger}
				</button>
			</PopoverTrigger>
			<PopoverContent sideOffset={sideOffset} width={width}>
				{content}
			</PopoverContent>
		</RadixPopover>
	);
};

export default Popover;
