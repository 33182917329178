import styled from 'styled-components';

export const StyledIndeterminateProgressBar = styled.div`
	/* Color */
	background: transparent;
	/* Rounded border */
	border-radius: 9999px;
	/* Size */
	height: 1px;
	position: absolute;
	overflow: hidden;
	width: 100%;
	top: 0;
	left: 0;
`;

export const StyledIndeterminateProgressBarFill = styled.div`
	/* Color */
	background-color: ${({ theme }) => theme.colors?.gray100};
	/* Rounded border */
	border-radius: 9999px;
	/* Absolute position */
	position: absolute;
	bottom: 0;
	top: 0;
	width: 50%;
	/* Move the bar infinitely */
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: indeterminate-progress-bar;

	@keyframes indeterminate-progress-bar {
		from {
			left: -50%;
		}
		to {
			left: 100%;
		}
	}
`;
