import React from 'react';
import Button from 'components/button';
import Headline from 'components/headline';
import Paragraph from 'components/paragraph';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const StyledModal = styled.div`
	width: 380px;
	height: auto;
	background: rgba(28, 21, 27, 0.5);
	backdrop-filter: blur(30px);
	border: 1px solid rgba(88, 88, 88, 0.5);
	border-radius: 10px;
	padding: 0;
`;

const StyledBox = styled.div`
	width: 100%;
	padding: 12px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(245, 245, 245, 0.09);
`;

export interface Props {
	showModal: boolean;
	type: 'screen-recording' | 'accessibility';
	handleClose: () => void;
	backPress: () => void;
}

const LearnWhyModal = ({ showModal, type, handleClose, backPress }: Props) => {
	return (
		<Modal centered className="learn-why-modal" show={showModal} onHide={handleClose} style={{ margin: 0 }}>
			<StyledModal>
				<Modal.Header style={{ alignItems: 'center', borderBottom: '1px solid rgba(88, 88, 88, 0.5)' }}>
					<Button variant="back-icon" style={{ color: '#EDEDED', padding: 0 }} label="" onEvent={backPress} />
					<Headline style={{ color: '#EDEDED', margin: 0 }} variant="h2">
						Permission Request
					</Headline>
					<span style={{ width: '28px' }} />
				</Modal.Header>
				<Modal.Body style={{ width: '100%', padding: '26px' }}>
					<Paragraph style={{ fontSize: '12px', color: '#A0A0A0', margin: '0 0 16px 0' }}>
						{type === 'accessibility' ? `To use the Terminal please enable Accessibility access.` : null}
						{type === 'screen-recording' ? `To use the Terminal please enable Screen Recording access.` : null}
					</Paragraph>
					<StyledBox>
						<div>
							{type === 'accessibility' ? (
								<img
									src="assets/images/accessibility_icon.png"
									alt="icon"
									width={30}
									height={30}
									style={{ marginRight: '20px' }}
								/>
							) : null}
							{type === 'screen-recording' ? (
								<img
									src="assets/images/screen-recording.png"
									alt="icon"
									width={30}
									height={30}
									style={{ marginRight: '20px' }}
								/>
							) : null}
						</div>
						<div>
							<Headline style={{ color: '#EDEDED', margin: 0, fontWeight: 'bold' }} variant="h3">
								{type === 'accessibility' && 'Accessibility'}
								{type === 'screen-recording' && 'Screen Recording'}
							</Headline>
							<Paragraph style={{ fontSize: '12px', color: '#A0A0A0', margin: 0 }}>
								{type === 'accessibility' ? `This allows us to access a mouse click emphasis and more.` : null}
								{type === 'screen-recording' ? (
									<>
										<span>This allows us to return focus to the correct application when you close the Terminal.</span>{' '}
										<span style={{ color: '#EDEDED' }}>Tyles does not record your screen otherwise.</span>
									</>
								) : null}
							</Paragraph>
						</div>
					</StyledBox>
				</Modal.Body>
			</StyledModal>
		</Modal>
	);
};

export default LearnWhyModal;
