/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, ReactElement } from 'react';
import Paragraph from 'components/paragraph';
import Icon from 'components/icon/Icon';
import { PopoverContent, PopoverMenu } from 'components/popover-menu';
import { TyleConnectionInterface } from 'interfaces/TyleInterface';
import { PopoverMenuItemInterface } from '../../interfaces/PopoverMenuItemInterface';
import { getTyleContent, hideIcon, renderRightHover, setHidden } from './helpers';
import {
	StyledSource,
	StyledContentWrapper,
	StyledHeader,
	StyledIcon,
	StyledParagraph,
	StyledTyleContentContainer,
	StyledTyleWrapper,
	StyledConnectionSource,
	StyledMetaData,
	StyledHeaderWrapper,
	StyledContentContainer,
	StyledContentText,
	StyledMetaDataText,
	StyledFavoriteIconSpan,
} from './styles';
import { IconType, TylePreviewDataInterface } from '../../helpers/getTylePreviewData';
import { CustomElement } from '../../types/slate';
import getTyleVisualization from '../../helpers/getTyleVisualization';

export interface TyleProps {
	id: string;
	favorite?: boolean;
	link?: TyleConnectionInterface[];
	backlink?: TyleConnectionInterface[];
	selected?: boolean;
	tyleData: TylePreviewDataInterface;
	onEvent: any;
	onSelect?: (arg: string) => void;
	onDoubleClick?: (arg: string) => void;
	hideOptions?: boolean;
	dropdownMenu: Array<PopoverMenuItemInterface>;
}

const Tyle = ({
	id,
	onSelect,
	onDoubleClick,
	onEvent,
	hideOptions,
	tyleData,
	favorite,
	dropdownMenu,
	link,
	selected,
	backlink,
}: TyleProps): ReactElement => {
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const [isHover, setIsHover] = useState<boolean>(false);
	const handleAction = (item: PopoverMenuItemInterface) => onEvent(item);

	const {
		icon,
		isShowLargeIconOnLeft,
		isShowLargeTextOnLeft,
		largeTextOnLeft,
		isShowSmallTextOnTopRight,
		smallTextOnTopRight,
		isShowSmallTextOnBottomLeft,
		smallTextOnBottomLeft,
		isShowSmallTextOnBottomRight,
		smallTextOnBottomRight,
		isShowSmallIconOnBottomLeft,
	} = getTyleVisualization(tyleData);

	const isOnlyLargeTextOnLeft = isShowLargeTextOnLeft && !isShowSmallTextOnBottomLeft;

	const getSource = () => {
		return isHover && !hideOptions ? (
			<PopoverContent
				isOpen={isPopoverOpen}
				positions={['bottom', 'left', 'right', 'top']}
				align="end"
				onClickOutside={() => setIsPopoverOpen(false)}
				content={<PopoverMenu items={dropdownMenu} onCancel={setIsPopoverOpen} onEvent={handleAction} />}
			>
				{renderRightHover(isPopoverOpen, setIsPopoverOpen)}
			</PopoverContent>
		) : (
			<>
				{isShowSmallTextOnBottomRight && (
					<StyledConnectionSource isHover={isHover}>
						<StyledSource>{smallTextOnBottomRight}</StyledSource>
					</StyledConnectionSource>
				)}
			</>
		);
	};

	const getMetaData = () => {
		if (isOnlyLargeTextOnLeft) {
			return (
				<StyledMetaData date>
					{isShowSmallTextOnTopRight && smallTextOnTopRight}
					<div
						style={{ display: 'flex', justifyContent: 'flex-end', marginTop: `${isHover ? '1px' : '7px'}` }}
						onClick={(event) => {
							event.stopPropagation();
						}} // avoid click for select
						onKeyDown={(event) => (event?.key === 'Enter' ? event.stopPropagation() : null)} // avoid select tyle after press enter
					>
						{getSource()}
					</div>
				</StyledMetaData>
			);
		}
		return <>{isShowSmallTextOnTopRight && <StyledParagraph date>{smallTextOnTopRight}</StyledParagraph>}</>;
	};

	return (
		<StyledTyleWrapper
			onMouseLeave={() => {
				setIsPopoverOpen(false);
				setIsHover(false);
			}}
			onMouseOver={() => setIsHover(true)}
			onClick={() => {
				onSelect?.(id);
			}}
			onDoubleClick={() => onDoubleClick?.(id)}
			selected={selected}
		>
			{favorite && (
				<StyledFavoriteIconSpan>
					<Icon type="ri-star-fill" style={{ fontSize: '0.7em' }} />
				</StyledFavoriteIconSpan>
			)}
			<StyledTyleContentContainer>
				{isShowLargeIconOnLeft && icon !== 'quote-icon' && (
					<StyledIcon>
						<Icon type={icon} />
					</StyledIcon>
				)}

				<StyledContentWrapper>
					<StyledHeaderWrapper>
						{isShowLargeTextOnLeft && (
							<StyledHeader
								isHover={isHover}
								hasContent={isShowLargeTextOnLeft}
								isBookmark={icon === 'bookmark-icon'}
								hasSource={isShowSmallTextOnBottomRight}
							>
								<Paragraph
									style={{
										maxWidth: '270px',
										maxHeight: '43px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										borderLeft: `${isShowLargeIconOnLeft && icon === 'quote-icon' && '1px solid #aaa'}`,
										paddingLeft: `${isShowLargeIconOnLeft && icon === 'quote-icon' && '12px'}`,
									}}
									variant="heading"
									margin="none"
								>
									{largeTextOnLeft}
								</Paragraph>
							</StyledHeader>
						)}
						{getMetaData()}
					</StyledHeaderWrapper>
					<StyledContentContainer>
						{isShowSmallTextOnBottomLeft && (
							<StyledContentText showFade={!hideOptions} isHover={isHover} isOverflow={isHover}>
								{isShowSmallIconOnBottomLeft && (
									<Icon type={`${icon}-inline`} style={{ paddingRight: '6px', fontSize: '10px' }} />
								)}
								{smallTextOnBottomLeft}
							</StyledContentText>
						)}
						<StyledMetaDataText showFade={hideOptions} isHover={isHover} hasSource={isShowSmallTextOnBottomRight}>
							{!isOnlyLargeTextOnLeft && getSource()}
						</StyledMetaDataText>
					</StyledContentContainer>
				</StyledContentWrapper>
			</StyledTyleContentContainer>
		</StyledTyleWrapper>
	);
};

export default Tyle;
