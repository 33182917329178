export enum DragItemTypeEnum {
	// According to plate definitions
	ELEMENT_BLOCKQUOTE = 'blockquote',
	ELEMENT_CODE_BLOCK = 'code_block',
	ELEMENT_CODE_LINE = 'code_line',
	ELEMENT_H1 = 'h1',
	ELEMENT_H2 = 'h2',
	ELEMENT_H3 = 'h3',
	ELEMENT_LI = 'li',
	ELEMENT_PARAGRAPH = 'p',
	ELEMENT_TODO_LI = 'action_item',
	ELEMENT_UL = 'ul',
	ELEMENT_TYLE_BLOCK = 'tyle-block',
	ELEMENT_TYLE_INLINE = 'tyle-inline',
	// Custom drag and drop definitions
	TYLE_WINDOW = 'tyle-window',
	TYLE_CARD = 'tyle-card',
}

export enum DragAndDropAreaEnum {
	TERMINAL = 'terminal',
	INBOX = 'inbox',
	CANVAS = 'canvas',
	EDITOR = 'editor',
	CONNECTION_VIEW = 'connection-view',
	SEARCH_SIDEBAR = 'search-sidebar',
}

export enum ParentEditorComponentEnum {
	TYLE_WINDOW = 'tyle-window',
	TERMINAL = 'terminal',
}

export enum DragAndSnapAreasEnum {
	CORNER_TOP_LEFT = 'cornerTopLeft',
	CORNER_TOP_RIGHT = 'cornerTopRight',
	CORNER_BOTTOM_LEFT = 'cornerBottomLeft',
	CORNER_BOTTOM_RIGHT = 'cornerBottomRight',
	BOTTOM_LEFT_COLUMN = 'bottomLeftColumn',
	BOTTOM_CENTER_COLUMN = 'bottomCenterColumn',
	BOTTOM_RIGHT_COLUMN = 'bottomRightColumn',
	LEFT = 'left',
	RIGHT = 'right',
	TOP = 'top',
}

export enum DragCardTypes {
	TYLE_INBOX = 'tyle_inbox',
	TYLE_TERMINAL = 'tyle_terminal',
	TYLE_INSTANCES_BACKLINKS_LINKS = 'tyle_instances_backlinks_links',
	TYLE_SEARCH_SIDEBAR = 'tyle_search_sidebar',
}
