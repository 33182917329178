import { SearchQuery } from 'models/SearchQuery';
import { SearchQuery as ApiSearchQuery } from 'api/generated/client';
import adaptSearchQuery from './adaptSearchQuery';

const adaptSearchQueries = (searchQueries: Array<ApiSearchQuery>): Array<SearchQuery> => {
	return searchQueries.map((searchQuery) => {
		return adaptSearchQuery(searchQuery);
	});
};

export default adaptSearchQueries;
