import { MouseEventHandler, ReactElement, useCallback, useEffect, useRef } from 'react';
import { detectOperatingSystem } from 'helpers';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import styled from 'styled-components';

export interface SelectableItem {
	label: string;
	value: string;
}

const StyledShortcutButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	width: 20px;
	height: 20px;
	background-color: ${(props) => props.theme.colors?.gray400};
	color: ${(props) => props.theme.colors?.gray100};
	border-radius: 5px;
`;

const StyledListItem = styled.li`
	width: 100%;
	font-size: 14px;
	color: ${(props) => props.theme.colors?.gray100};
	padding: 5px;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.colors?.gray400};
		border-radius: 4px;
	}
`;

const StyledListItemContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledListItemLabel = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;
const StyledItemTitle = styled.div`
	padding-left: 5px;
	color: ${(props) => props.theme.colors?.heading};
	font-weight: ${(props) => props.theme.fontWeights?.semiBold};
`;

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 50px;
`;

const StyledLabel = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;

export interface Props {
	label: string;
	value: string;
	title?: string;
	selected: boolean;
	onClick: (item: SelectableItem) => void;
	onSelected?: (item: SelectableItem) => void;
	className?: string;
}

const DropdownItem = ({ value, selected, label, title, className, onClick, onSelected }: Props): ReactElement => {
	const ref = useRef<HTMLLIElement>(null);
	const isMacOS = detectOperatingSystem() === OperatingSystemPlatformEnum.MAC_OS;

	useEffect(() => {
		if (selected) {
			ref.current?.focus();
			ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			onSelected?.({ value, label: title || label });
		}
	}, [selected]);

	const handleOnClick: MouseEventHandler<HTMLLIElement> = useCallback(
		(event) => {
			event.preventDefault();
			onClick({ value, label: title || label });
		},
		[value, label, onClick],
	);

	return (
		<StyledListItem ref={ref} onClick={handleOnClick} className={className}>
			<StyledListItemContent id={value}>
				<StyledListItemLabel>
					<StyledLabel>{label}</StyledLabel>
					<StyledItemTitle>{title}</StyledItemTitle>
				</StyledListItemLabel>
				{selected && !title && <StyledShortcutButton>↵</StyledShortcutButton>}
				{title && (
					<StyledButtonContainer>
						<StyledShortcutButton>{isMacOS ? '⌘' : 'ctrl'}</StyledShortcutButton>
						<StyledShortcutButton>↵</StyledShortcutButton>
					</StyledButtonContainer>
				)}
			</StyledListItemContent>
		</StyledListItem>
	);
};

export default DropdownItem;
