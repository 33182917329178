import { useEffect, useState } from 'react';
import StorageService, { StorageKeys } from '../services/StorageService';
import { TokenPairInterface } from '../interfaces/TokenPairInterface';
import userState from '../state/UserState';

interface AuthenticationInterface {
	isLoggedIn: () => boolean;
	isLoggedInWithName: () => boolean;
	logout: () => void;
	getAuthentication: () => TokenPairInterface | null;
	updateToken: (token: string) => void;
	authenticate: (tokenPair: TokenPairInterface) => void;
}

const useAuthentication = (): AuthenticationInterface => {
	const { store, get, remove } = StorageService();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore - this is a hack to get around the fact that authDetails type does no match with store return type.
	const authDetails: TokenPairInterface = get(StorageKeys.AUTHENTICATION) || null;

	const logout = (): void => {
		remove(StorageKeys.AUTHENTICATION);
	};

	const isLoggedIn = (): boolean => {
		if (authDetails === null) {
			return false;
		}
		return true;
	};

	const isLoggedInWithName = (): boolean => {
		if (!authDetails) {
			return false;
		}
		if (!userState.user?.firstName && !userState.user?.lastName) {
			return false;
		}
		return true;
	};

	const getAuthentication = (): TokenPairInterface | null => {
		return authDetails;
	};

	const updateToken = (token: string): void => {
		if (!authDetails) {
			return;
		}

		store({
			[StorageKeys.AUTHENTICATION]: {
				...authDetails,
				token,
			},
		});
	};

	const authenticate = (tokenPair: TokenPairInterface): void => {
		store({
			[StorageKeys.AUTHENTICATION]: tokenPair,
		});
	};

	return { logout, isLoggedIn, isLoggedInWithName, getAuthentication, updateToken, authenticate };
};

export default useAuthentication;
