import Favicon from 'components/Favicon';
import TextArea from 'components/TextArea';
import Spacing from 'components/spacing';
import { TyleInterface } from 'interfaces/TyleInterface';

interface Props {
	tyle: TyleInterface;
}

const CapturedContent = ({ tyle }: Props) => {
	return (
		<div>
			<TextArea
				key={tyle.id}
				value={tyle.content?.[0]?.children?.[0]?.text || ''}
				type={tyle.content?.[0]?.children?.[0]?.text ? 'quote' : 'capture'}
				focused={tyle.content?.[0]?.children?.[0]?.text !== ''}
				onChange={() => {}}
			/>
			<Spacing customSize="8px" />
			{tyle?.source && <Favicon source={tyle.source} link favicon />}
		</div>
	);
};

export default CapturedContent;
