import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface ListProps {
	variant?: 'ol' | 'ul';
	children: ReactNode;
}

const StyledList = css`
	font-size: ${(props) => props.theme.fontSizes.paragraph};
	line-height: ${(props) => props.theme.lineHeights.default};
	font-weight: ${(props) => props.theme.fontWeights.base};
	letter-spacing: ${(props) => props.theme.letterSpacings.minimum};
	margin: ${(props) => props.theme.margins.list};
	color: ${(props) => props.theme.colors.gray100};
	padding: 0;
`;

const StyledUl = styled.ul`
	${StyledList}
	list-style-type: disc;
`;
const StyledOl = styled.ol`
	${StyledList}
	list-style-type: decimal;
`;

const List = ({ variant, children }: ListProps): ReactElement => {
	switch (variant) {
		case 'ul':
			return <StyledUl>{children}</StyledUl>;

		case 'ol':
			return <StyledOl>{children}</StyledOl>;
		default:
			return <></>;
	}
};

export default List;
