/**
 * Tyles API
 * Tyles API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { User } from '../models/User';
import { HttpFile } from '../http/http';

export class Space {
    'id'?: string;
    'createdAt'?: string;
    'updatedAt'?: string;
    'user'?: User;
    'userId'?: string;
    'label'?: string;
    'tylesData'?: Array<any>;
    'deleted'?: boolean;
    'closed'?: boolean;
    'selected'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string",
            "format": ""
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string",
            "format": ""
        },
        {
            "name": "user",
            "baseName": "user",
            "type": "User",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        },
        {
            "name": "label",
            "baseName": "label",
            "type": "string",
            "format": ""
        },
        {
            "name": "tylesData",
            "baseName": "tylesData",
            "type": "Array<any>",
            "format": ""
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "closed",
            "baseName": "closed",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "selected",
            "baseName": "selected",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Space.attributeTypeMap;
    }

    public constructor() {
    }
}

