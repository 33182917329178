import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div.attrs((props: { isDragging: boolean; columns: number }) => props)`
	background-color: ${({ theme, isDragging }) => (isDragging ? theme.colors?.selected : theme.colors?.background)};
	max-height: 100vh; /* like display:flex but will allow bleeding over the window width */
	width: 100%;
	display: inline-flex;
	overflow-x: scroll;
	padding: 0 10px;

	::-webkit-scrollbar {
		height: 0.7rem;
		background-color: ${({ theme }) => theme.colors?.selected};
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset ${({ theme }) => theme.shadows?.light};
		box-shadow: inset ${({ theme }) => theme.shadows?.light};
		border: 1px solid ${({ theme }) => theme.colors?.stroke};
		border-radius: ${({ theme }) => theme.radius?.big};
		background-color: ${({ theme }) => theme.colors?.background};
	}

	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: ${({ theme }) => theme.colors?.scrollThumb};
		transition: background-color ease 0.2s;

		&:hover {
			background-color: ${({ theme }) => theme.colors?.softParagraph};
		}

		-webkit-box-shadow: ${({ theme }) => theme.shadows?.strong};
		box-shadow: ${({ theme }) => theme.shadows?.strong};
	}
`;

export const ContainerPlaceholder = styled.div`
	position: absolute;
	margin: 0;
	top: 50%;
	text-align: center;
	width: calc(100vw - 355px);
`;

export const ColumnContainer = styled.div.attrs((props: { columns: number }) => props)`
	position: relative;
	min-width: ${({ columns }) => (columns === 1 ? 'calc(98.5vw - 355px)' : 'calc(49vw - (355px/2))')};
	height: calc(100% - 20px);

	&:first-child {
		margin-left: 0px;
	}

	&:last-child {
		margin-right: 0px;
	}

	@media (max-width: 1024px) {
		min-width: calc(98.5vw - 355px);
	}
`;

export const TyleWindowContainer = styled.div.attrs((props: { columns: number; isPublic: boolean }) => props)`
	min-width: ${({ columns, isPublic }) =>
		// eslint-disable-next-line no-nested-ternary
		!isPublic ? (columns === 1 ? 'calc(98.5vw - 355px)' : 'calc(49vw - (355px/2))') : '100%'};
	max-width: ${(
		{ columns, isPublic }, // eslint-disable-next-line no-nested-ternary
	) => (!isPublic ? (columns === 1 ? 'calc(98.5vw - 355px)' : 'calc(49vw - (355px/2))') : '100%')};
	height: 100%;
	margin: ${({ isPublic }) => (isPublic ? '0' : '10px 5px')};
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.colors?.stroke};
	border-radius: ${({ theme }) => theme.radius?.big};

	@media (max-width: 1024px) {
		min-width: calc(98.5vw - 355px);
	}
`;
