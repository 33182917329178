import { ReactElement, useEffect, useState, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { debounce } from 'lodash';
import { PlateProvider } from '@udecode/plate';
import TyleTitle from 'components/TyleTitle';
import CustomAccordion from 'components/accordion';
import MetaData from 'components/MetaData';
import Divider from 'components/divider';
import Editor from 'components/editor';
import canvasState from 'state/CanvasState';
import useTyleHooks from 'hooks/useTyleHooks';
import ScrollFade from 'components/ScrollFade';
import ErrorModal from 'components/NewModals/ErrorModal';
import Content from 'components/content';
import Headline from 'components/headline';
import { Footer } from 'components/modal';
import Button from 'components/button';
import useErrorHooks from 'hooks/useErrorHooks';
import { newCanvasStateService } from 'state/NewCanvas';
import tylesState from 'state/TylesState';
import { StyledEditorView, StyledEditorViewPreviewSection, StyledHeaderContainer } from './EditorView.styled';

interface EditorViewProps {
	tyleId?: string;
	tyleUrl?: string;
	tyleTitle?: string;
	tyleSourceName?: string;
	windowId: string;
	scrollTop: number;
	onTitleChange: (title: string) => void;
	updateScrollTop: (scrollTop: number) => void;
}

const EditorView = ({
	tyleId,
	tyleTitle,
	tyleUrl,
	tyleSourceName,
	windowId,
	scrollTop,
	onTitleChange,
	updateScrollTop,
}: EditorViewProps): ReactElement | null => {
	const { isPublicView } = useSnapshot(canvasState);
	const { updateTyleContent } = useTyleHooks();
	const { trackErrorAndRefreshApp } = useErrorHooks();
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [isInputFocused, setIsInputFocused] = useState(false);

	if (!tyleId) {
		return null;
	}

	useEffect(() => {
		if (newCanvasStateService.isWindowSelected(windowId)) {
			setIsInputFocused(true);
		}
	}, []);

	const debouncedTyleUpdate = useCallback(
		debounce((id: string, updatedTyleContent: any[]) => {
			updateTyleContent({
				id,
				content: updatedTyleContent,
			}).catch((error) => {
				console.error(error);
				setShowErrorModal(true);
			});
		}, 2000),
		[],
	);

	return (
		<>
			<StyledEditorView>
				<StyledHeaderContainer>
					<TyleTitle
						rows={tyleSourceName ? 3 : undefined}
						focused={isInputFocused}
						value={tyleTitle}
						onChange={onTitleChange}
						onSubmit={() => {
							tylesState.isTitleSubmit = true;
						}}
					/>
					{!isPublicView && (
						<CustomAccordion
							title="Properties"
							content={
								<StyledEditorViewPreviewSection>
									<MetaData tyleId={tyleId} />
								</StyledEditorViewPreviewSection>
							}
						/>
					)}
					<Divider />
				</StyledHeaderContainer>
				<ScrollFade initialScrollTop={scrollTop} updateScrollTop={updateScrollTop} />
				<Editor tyleUrl={tyleUrl} tyleId={tyleId} windowId={windowId} updateTyle={debouncedTyleUpdate} />
			</StyledEditorView>

			<ErrorModal show={showErrorModal} onClose={() => trackErrorAndRefreshApp()}>
				<Content>
					<Headline variant="h3">Something went wrong</Headline>
					<p className="error-message">
						Action has failed. Please refresh the page and try again. <br />
						The page will automatically refresh in 5 seconds.
					</p>
				</Content>
				<Footer>
					<Button label="Refresh" onEvent={() => trackErrorAndRefreshApp()} />
				</Footer>
			</ErrorModal>
		</>
	);
};

export default EditorView;
