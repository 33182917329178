import { nanoid } from 'nanoid';
import { MyRootBlock } from '../types/plateTypes';

export interface EditorNodes {
	nodes?: MyRootBlock[];
}

const getNodesWithId = ({ nodes }: EditorNodes): any => {
	return (
		nodes &&
		nodes.map((node: any) => {
			// If there's not an id or the id is generated by the library (number), set a custom id

			const newNode = { ...node };
			const id = newNode?.id;
			if ('tyle' in newNode && !!newNode.tyle) {
				// TODO Remove tyle from node ?
				return {
					...newNode,
					id: id ?? nanoid(),
					tyleId: newNode?.tyle?.id,
				};
			}
			if ('children' in newNode && !!newNode.children?.length) {
				return {
					...newNode,
					id: id ?? nanoid(),
					children: getNodesWithId({ nodes: newNode.children }),
				};
			}
			return {
				...newNode,
				id: id ?? nanoid(),
			};
		})
	);
};

export default getNodesWithId;
