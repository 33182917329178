import IPCChannel from 'model/IPC/IPCChannel';
import { useEnvironmentDetection } from './index';
import { EnvironmentEnum } from './detectEnvironment';

export interface SendMessage {
	message: any;
	subject: string;
}

interface ElectronEventSender {
	sendMessage: ({ message, subject }: SendMessage) => void;
	sendMessageResize: (message: number) => void;
}

const useElectronEvents = (): ElectronEventSender => {
	const environment = useEnvironmentDetection();

	if (environment !== EnvironmentEnum.DESKTOP) {
		return {
			sendMessage: () => {
				// nothing
			},
			sendMessageResize: () => {
				// nothing
			},
		};
	}
	const { ipcRenderer } = window.require
		? window.require('electron')
		: {
				ipcRenderer: {
					send: (message: string) => {
						// void
					},
					on: (message: string) => {
						// void
					},
					removeListener: (message: string) => {
						// void
					},
				},
		  };

	const sendMessage = ({ message, subject }: SendMessage) => {
		ipcRenderer.send(subject, message);
	};

	const sendMessageResize = (message: number) => {
		sendMessage({ subject: IPCChannel.RESIZE_FASTCAPTURE, message });
	};

	return { sendMessage, sendMessageResize };
};

export default useElectronEvents;
