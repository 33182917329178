import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { SelectableItem } from 'components/AutocompleteDropdown/DropdownItem/DropdownItem';
import { TyleInterface } from 'interfaces/TyleInterface';
import useTagsHooks from 'hooks/useTagsHooks';
import useTyleHooks from 'hooks/useTyleHooks';
import { ApiContext } from 'components/App';
import useAnalytics from 'hooks/useAnalytics';
import userState from 'state/UserState';

const useFastCapture = (tyle: TyleInterface) => {
	const [placeholder, setPlaceholder] = useState<string>('');
	const [tyleToSave, setTyleToSave] = useState<TyleInterface>();
	const [defaultSelectedItem, setDefaultSelectedItem] = useState<SelectableItem>();
	const [success, setSuccess] = useState<boolean>(false);
	const [isTagLoading, setIsTagLoading] = useState<boolean>(true);
	const [creationStatus, setCreationStatus] = useState<'loading' | 'idle'>('idle');
	const [tagItems, setTagItems] = useState<SelectableItem[]>([]);
	const [tagErrorMessage, setTagErrorMessage] = useState<string>('');
	const isMounted = useRef(true);

	const api = useContext(ApiContext);

	const { addHighlightToTyle, createTyle, getLastUpdatedTyle } = useTyleHooks();
	const { getTagsByTyleId } = useTagsHooks();
	const { identify } = useAnalytics();

	// Fetch the last updated Tyle
	const fetchLastUpdatedTyle = useCallback(async () => {
		try {
			const tyleFromBackend = await getLastUpdatedTyle();
			setSuccess(false);
			if (!tyleFromBackend || !tyleFromBackend.id) return;
			setPlaceholder(tyleFromBackend.title ?? 'Untitled');
			setDefaultSelectedItem({ value: tyleFromBackend.id, label: tyleFromBackend.title ?? 'Untitled' });
		} catch (error) {
			console.error(error);
		}
	}, [getLastUpdatedTyle]);

	// Create a new Tyle from highlight
	const createNewTyle = useCallback(
		async (tyleFromElectron: TyleInterface) => {
			try {
				identify(userState.user); // Identify in electron
				const tyleFromBackend = await api.createTyle(tyleFromElectron);
				setTyleToSave(tyleFromBackend);
			} catch (error) {
				console.error(error);
			}
		},
		[createTyle, tyle],
	);

	// Fetch tags by newly created tyle id
	const fetchTagsByTyleId = useCallback(
		async (tyleId: string) => {
			try {
				setIsTagLoading(true);
				const tags = await getTagsByTyleId(tyleId);
				setTagItems(tags.map((tag) => ({ label: tag.label, value: tag.id })));
				setIsTagLoading(false);
				if (!tags.length) {
					setTagItems([]);
					setTagErrorMessage('No tags generated. Please use longer content to generate tags.');
				} else {
					setTagErrorMessage('');
				}
			} catch (error) {
				console.error(error);
				setIsTagLoading(false);
				setTagItems([]);
				setTagErrorMessage('');
			}
		},
		[getTagsByTyleId],
	);

	// Add a highlight to a Tyle
	const addHighlight = useCallback(
		async (destinationTyle: TyleInterface) => {
			try {
				if (!tyleToSave) return;
				setCreationStatus('loading');
				await addHighlightToTyle({ destinationTyle, tyleToSave });
				setCreationStatus('idle');
				setSuccess(true);
			} catch (error) {
				console.error(error);
			}
		},
		[addHighlightToTyle, tyleToSave],
	);
	// Add a highlight to a new Tyle
	const addHighlightToNewTyle = useCallback(
		async (newTyle: TyleInterface) => {
			try {
				if (!newTyle.title) return;
				const tyleFromBackend = await createTyle(newTyle);
				addHighlight(tyleFromBackend);
				setTyleToSave(tyleFromBackend);
				setSuccess(true);
			} catch (error) {
				console.error(error);
			}
		},
		[addHighlightToTyle, tyleToSave],
	);

	// useEffect hooks to handle side effects and data fetching

	// This effect is triggered when the tyle prop changes. It fetches the last updated Tyle and creates a new Tyle.
	useEffect(() => {
		if (!tyle) return () => {};

		const fetchLastUpdatedTyleAndCreateNewTyle = async () => {
			await fetchLastUpdatedTyle();
			await createNewTyle(tyle);
		};

		if (isMounted.current) {
			fetchLastUpdatedTyleAndCreateNewTyle();
		}

		return () => {
			isMounted.current = false;
		};
	}, [tyle, fetchLastUpdatedTyle, createNewTyle]);

	// This effect is triggered when tyleToSave state changes. It fetches the tags by the newly created tyle id.
	useEffect(() => {
		if (!tyleToSave?.id) return;

		fetchTagsByTyleId(tyleToSave.id);
	}, [tyleToSave]);

	return {
		tyleToSave,
		tagItems,
		isTagLoading,
		placeholder,
		success,
		defaultSelectedItem,
		creationStatus,
		tagErrorMessage,
		addHighlight,
		createNewTyle,
		addHighlightToNewTyle,
	};
};

export default useFastCapture;
