import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Headline.scss';

export interface HeadlineProps {
	children: ReactNode;
	variant?: 'title' | 'h1' | 'h2' | 'h3';
	align?: 'left' | 'center' | 'right';
	style?: CSSProperties;
}

const Headline = ({ children, variant, align, style }: HeadlineProps): ReactElement => {
	switch (variant) {
		case 'title':
			return (
				<div
					style={style}
					className={classNames({
						'kum-title': true,
						[`align-${align}`]: !!align,
					})}
				>
					{children}
				</div>
			);
		case 'h1':
			return (
				<h1
					style={style}
					className={classNames({
						'kum-headline': true,
						[`align-${align}`]: !!align,
					})}
				>
					{children}
				</h1>
			);
		case 'h2':
			return (
				<h2
					style={style}
					className={classNames({
						'kum-headline': true,
						[`align-${align}`]: !!align,
					})}
				>
					{children}
				</h2>
			);
		case 'h3':
			return (
				<h3
					style={style}
					className={classNames({
						'kum-headline': true,
						[`align-${align}`]: !!align,
					})}
				>
					{children}
				</h3>
			);
		default:
			return (
				<h1
					style={style}
					className={classNames({
						'kum-headline': true,
						[`align-${align}`]: !!align,
					})}
				>
					{children}
				</h1>
			);
	}
};

export default Headline;
