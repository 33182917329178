/* eslint-disable import/prefer-default-export */
import { SearchQueryInterface } from 'interfaces/SearchQueryInterface';

export class SearchQuery implements SearchQueryInterface {
	id: string;

	text: string;

	filters?: Record<string, any>;

	createdAt: Date;

	constructor(tag: SearchQueryInterface) {
		this.id = tag.id;
		this.text = tag.text;
		this.filters = tag.filters;
		this.createdAt = tag.createdAt;
	}
}
