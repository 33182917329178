import {
	createBasicElementsPlugin,
	createNodeIdPlugin,
	createLinkPlugin,
	createImagePlugin,
	createMediaEmbedPlugin,
	createSelectOnBackspacePlugin,
	ELEMENT_IMAGE,
	ELEMENT_MEDIA_EMBED,
	createIndentPlugin,
	createIndentListPlugin,
	createResetNodePlugin,
	createExitBreakPlugin,
	createSoftBreakPlugin,
	createListPlugin,
	createTodoListPlugin,
	createBasicMarksPlugin,
	createHighlightPlugin,
	createKbdPlugin,
	createAutoformatPlugin,
	AutoformatPlugin,
	createMentionPlugin,
	MentionElement,
	createComboboxPlugin,
	AnyObject,
} from '@udecode/plate';
import { createCloudAttachmentPlugin, createCloudPlugin } from '@udecode/plate-cloud';
import { createDndPlugin } from '@udecode/plate-dnd';
import { ELEMENT_TYLE_BLOCK, ELEMENT_TYLE_INLINE } from 'components/editor/types/tyleTypes';
import { createMyPlugins, MyEditor, MyPlatePlugin, MyValue } from '../types/plateTypes';
import { withStyledDraggables } from '../dnd';
import { plateUI } from '../components/Elements';
import indentListPlugin from './indentListPlugin';
import indentPlugin from './indentPlugin';
import resetBlockTypePlugin from './resetBlockTypePlugin';
import softBreakPlugin from './softBreakPlugin';
import exitBreakPlugin from './exitBreakPlugin';
import autoformatPlugin from './autoformat/autoFormatPlugin';
import createTyleInlinePlugin from './tyleinline';
import nodeIdPlugin from './nodeIdPlugin';
import createTyleBlockPlugin from './tyleblock';
import { createCloudImagePlugin } from './createCloudImagePlugin';

// set drag handle next to each block
export const components = withStyledDraggables(plateUI);

export const plugins = createMyPlugins(
	[
		createCloudPlugin({
			options: {
				/**
				 * You can use either a Portive API Key `apiKey` or an Auth Token
				 * `authToken` generated from the API Key.
				 * https://www.portive.com/docs/auth/intro
				 */
				// apiKey: 'PRTV_xxxx_xxxx'
				authToken:
					'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im5maFB2dEhONEpLa0NuWDMifQ.eyJpYXQiOjE2ODA1NTExODAsImV4cCI6MTcxMjEwODc4MH0.mEVL7yQIWdnrTLIpny6Y9ZGqngBGc8iCu4U_qSzORIE',
				// uploadStoreInitialValue, // don't need to specify this in actual app
			},
		}) as MyPlatePlugin<AnyObject>,
		createCloudAttachmentPlugin() as MyPlatePlugin<AnyObject>,
		createCloudImagePlugin(),
		createBasicElementsPlugin(),
		/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
		// @ts-ignore Because createDndPlugin has different type than expected.
		createDndPlugin(),
		createListPlugin(),
		createTodoListPlugin(),
		createBasicMarksPlugin(),
		createHighlightPlugin(),
		createKbdPlugin(),
		createLinkPlugin(),
		createIndentPlugin(indentPlugin),
		createIndentListPlugin(indentListPlugin),
		createResetNodePlugin(resetBlockTypePlugin),
		createSoftBreakPlugin(softBreakPlugin),
		createExitBreakPlugin(exitBreakPlugin),
		createNodeIdPlugin(nodeIdPlugin),
		createImagePlugin(),
		createMediaEmbedPlugin(),
		createSelectOnBackspacePlugin({
			options: {
				query: {
					allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED, ELEMENT_TYLE_BLOCK, ELEMENT_TYLE_INLINE],
				},
			},
		}),
		createAutoformatPlugin<AutoformatPlugin<MyValue, MyEditor>, MyValue>(autoformatPlugin),
		createTyleBlockPlugin(),
		createTyleInlinePlugin(),
		createComboboxPlugin(),
		createMentionPlugin({
			key: '/',
			component: MentionElement,
			options: { trigger: '/' },
		}),
	],
	{
		components,
	},
);
