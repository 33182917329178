import Joi, { ValidationResult } from 'joi';

const validationHelper = () => {
	const Email = (validEmail: string): ValidationResult => {
		const schema = Joi.object({
			Email: Joi.string().required().max(255).email({ tlds: false }).message('Invalid email address'),
		});

		return schema.validate({ Email: validEmail });
	};

	const InviteCode = (validCode: string): ValidationResult => {
		const schema = Joi.object({
			InviteCode: Joi.string().required().max(255),
		});

		return schema.validate({ InviteCode: validCode });
	};

	return { Email, InviteCode };
};

export default validationHelper;
