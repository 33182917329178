import React, { ReactElement } from 'react';
import { ImageToolbarButton, MediaEmbedToolbarButton, TComboboxItem } from '@udecode/plate';
import { Image } from '@styled-icons/material/Image';
import { OndemandVideo } from '@styled-icons/material/OndemandVideo';
import styled from 'styled-components';
import { TextT } from '@styled-icons/fluentui-system-regular/TextT';
import { TextBulletListLtr as BulletList } from '@styled-icons/fluentui-system-regular/TextBulletListLtr';
import { ListCheck } from '@styled-icons/bootstrap/ListCheck';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { FormatListNumbered } from '@styled-icons/material-sharp/FormatListNumbered';
import { TypeH1 } from '@styled-icons/bootstrap/TypeH1';
import { TypeH2 } from '@styled-icons/bootstrap/TypeH2';
import { TypeH3 } from '@styled-icons/bootstrap/TypeH3';
import { ChatQuote } from '@styled-icons/remix-line/ChatQuote';

const StyledSpan = styled.span`
	display: flex;
	flex-flow: row;
	width: 100%;
	gap: 15px;
`;
const style = {
	color: '#aaaaaa',
};

export interface TData {
	element?: ReactElement;
}

const EDITOR_COMMANDS: TComboboxItem<TData>[] = [
	{
		key: 'p',
		text: 'Paragraph',
		data: {
			element: (
				<StyledSpan>
					<TextT size="13px" style={style} />
					Paragraph
				</StyledSpan>
			),
		},
	},
	{
		key: 'h1',
		text: 'Headline 1',
		data: {
			element: (
				<StyledSpan>
					<TypeH1 size="13px" style={style} />
					Headline 1
				</StyledSpan>
			),
		},
	},
	{
		key: 'h2',
		text: 'Headline 2',
		data: {
			element: (
				<StyledSpan>
					<TypeH2 size="13px" style={style} />
					Headline 2
				</StyledSpan>
			),
		},
	},
	{
		key: 'h3',
		text: 'Headline 3',
		data: {
			element: (
				<StyledSpan>
					<TypeH3 size="13px" style={style} />
					Headline 3
				</StyledSpan>
			),
		},
	},
	{
		key: 'ul',
		text: 'Bulleted List',
		data: {
			element: (
				<StyledSpan>
					<BulletList size="13px" style={style} />
					Bulleted List
				</StyledSpan>
			),
		},
	},
	{
		key: 'ol',
		text: 'Numbered List',
		data: {
			element: (
				<StyledSpan>
					<FormatListNumbered size="13px" style={style} />
					Numbered List
				</StyledSpan>
			),
		},
	},
	{
		key: 'todo',
		text: 'Todo Item',
		data: {
			element: (
				<StyledSpan>
					<ListCheck size="13px" style={style} />
					Todo List
				</StyledSpan>
			),
		},
	},
	{
		key: 'code-block',
		text: 'Code Block',
		data: {
			element: (
				<StyledSpan>
					<CodeAlt size="13px" style={style} />
					Code Block
				</StyledSpan>
			),
		},
	},
	{
		key: 'quote',
		text: 'Quote',
		data: {
			element: (
				<StyledSpan>
					<ChatQuote size="13px" style={style} />
					Quote
				</StyledSpan>
			),
		},
	},
	{
		key: 'image',
		text: 'Image',
		data: {
			element: (
				<ImageToolbarButton
					styles={{ root: { width: '100%' } }}
					icon={
						<StyledSpan>
							<Image size="16px" color="#aaaaaa" />
							Image
						</StyledSpan>
					}
				/>
			),
		},
	},
	{
		key: 'video',
		text: 'video',
		data: {
			element: (
				<MediaEmbedToolbarButton
					styles={{ root: { width: '100%' } }}
					icon={
						<StyledSpan>
							<OndemandVideo size="16px" color="#aaaaaa" />
							Media
						</StyledSpan>
					}
				/>
			),
		},
	},
];

export default EDITOR_COMMANDS;
