import {
	ELEMENT_DEFAULT,
	ELEMENT_OL,
	ELEMENT_UL,
	getListItemEntry,
	getListTypes,
	getNode,
	isCollapsed,
	PlateEditor,
	setNodes,
	TElement,
	toggleList,
} from '@udecode/plate';
import { Path, Range, Transforms } from 'slate';
import { CustomEditor } from '../types/slate';

// transform a block that is not a list into a list
const transformBlockToList = (type: string, editor: PlateEditor): void => {
	if (!editor || !editor.selection) {
		return;
	}

	const at = editor.selection;
	// get the element type
	let updatedAt: Path;

	if (Range.isRange(at) && !isCollapsed(at)) {
		updatedAt = at.focus.path;
	} else if (Range.isRange(at)) {
		updatedAt = at.anchor.path;
	} else {
		updatedAt = at as Path;
	}

	if (!updatedAt) {
		return;
	}

	const node = getNode(editor, updatedAt) as TElement;

	if (!node) {
		return;
	}

	// first, convert the block to the default block (paragraph)
	setNodes(editor, {
		type: ELEMENT_DEFAULT,
	});

	// then, toggle the list.
	toggleList(editor, { type });
	Transforms.collapse(editor as CustomEditor);
};

// convert one list into another list
const convertList = (type: string, editor: PlateEditor): void => {
	if (!editor || !editor.selection) {
		return;
	}

	const res = getListItemEntry(editor);

	if (!res) {
		return;
	}

	const { list } = res;

	if (list[0].type === type) {
		// already the demanded type, so do nothing
		return;
	}

	// it is a list, but not with the same type, so set the other list type.
	setNodes(
		editor,
		{ type },
		{
			at: editor.selection,
			match: (node: any) => getListTypes(editor).includes(node.type),
			mode: 'lowest',
		},
	);
	Transforms.collapse(editor as CustomEditor);
};

// transform any block into a list
const transformToList = (type: string, editor: PlateEditor): void => {
	if (!editor || !editor.selection) {
		return;
	}

	if (type !== ELEMENT_OL && type !== ELEMENT_UL) {
		// not a list type, do nothing.
		return;
	}

	const res = getListItemEntry(editor);

	if (!res) {
		// no list found, so create a list out of block's contents
		transformBlockToList(type, editor);
		return;
	}

	// block is already a list, so transform from list to something else.
	convertList(type, editor);
};

export default transformToList;
