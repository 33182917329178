import { Transforms } from 'slate';
import { getPluginType, PlateEditor, TEditor, unwrapNodes } from '@udecode/plate';
import { ReactEditor } from 'slate-react';
import { CustomEditor, CustomElement } from '../types/slate';
import { ELEMENT_TYLE_BLOCK } from '../types/tyleTypes';
import selectByElement from '../utils/selectByElement';

interface TransformationData {
	editor: CustomEditor;
	elementToTransform: CustomElement;
}

const transformToTyleBlock = ({ editor, elementToTransform }: TransformationData): void => {
	if (!('tyleId' in elementToTransform) || !elementToTransform.tyleId) {
		return;
	}
	const path = ReactEditor.findPath(editor, elementToTransform);

	selectByElement({ editor, element: elementToTransform });

	unwrapNodes(editor as TEditor, { at: path });

	Transforms.delete(editor);
	Transforms.insertNodes(editor, {
		type: getPluginType(editor as PlateEditor, ELEMENT_TYLE_BLOCK) as 'tyle-block',
		tyleId: elementToTransform.tyleId,
		children: [{ text: '' }],
	});
};

export default transformToTyleBlock;
