import React, { ReactElement, ReactNode } from 'react';
import './SidebarLayout.scss';
import styled from 'styled-components';

export interface SidebarLayoutProps {
	children: ReactNode;
}

const StyledSidebarLayoutDiv = styled.div`
	display: flex;
	width: 100%;
	height: auto;
	flex: 1 1 auto;

	.sidebar,
	.content {
		height: 100%;
		overflow-y: auto;
	}

	.sidebar {
		padding: 42px 0 0 0;
	}

	.content {
		margin: 0;
		padding: 0 35px;
		grid-column: 2;
		display: flex;
		flex-direction: column;
		min-height: 658px;

		.growing {
			flex: 1;
			margin-top: 42px;
		}
	}
`;

const SidebarLayout = ({ children }: SidebarLayoutProps): ReactElement => {
	return <StyledSidebarLayoutDiv>{children}</StyledSidebarLayoutDiv>;
};

export default SidebarLayout;
