import { Element, Node, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { ELEMENT_PARAGRAPH } from '@udecode/plate';
import tylesState from 'state/TylesState';
import { CustomElement } from 'types/slate';
import { CustomEditor } from '../types/slate';
import { ELEMENT_TYLE_INLINE, ELEMENT_TYLE_BLOCK } from '../types/tyleTypes';
import selectByElement from '../utils/selectByElement';
import { InlineTypes } from './transformations';

interface TransformationData {
	editor: CustomEditor;
	view?: InlineTypes;
	elementToTransform: CustomElement;
}

const transformToTyleInline = ({ editor, view, elementToTransform }: TransformationData): void => {
	if (!('tyleId' in elementToTransform) || !elementToTransform.tyleId) {
		return;
	}

	const conversion: Element = {
		type: ELEMENT_TYLE_INLINE,
		tyleId: elementToTransform.tyleId,
		view: view || InlineTypes.TITLE,
		children: [{ text: '' }],
	};
	const options = {
		match: (node: Node | CustomElement) => {
			return 'tyleId' in node && !!node.tyleId;
		},
	};

	// not converting from block, so just convert to inline with new view
	if (elementToTransform.type !== ELEMENT_TYLE_BLOCK) {
		Transforms.setNodes(editor, conversion, options);
		return;
	}

	const path = ReactEditor.findPath(editor, elementToTransform);
	selectByElement({ editor, element: elementToTransform });
	Transforms.removeNodes(editor);

	Transforms.insertNodes(
		editor,
		{
			type: ELEMENT_PARAGRAPH,
			children: [{ text: '' }, conversion, { text: ' ' }],
		},
		{ at: path },
	);

	tylesState.isTyleTransformed = true;
};

export default transformToTyleInline;
