import React, { ReactElement, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import addChatToDom from '../../helpers/addChatToDom';
import userState from '../../state/UserState';

const SupportChat = (): ReactElement => {
	const user = useSnapshot(userState.user);

	useEffect(() => {
		if (user) {
			addChatToDom(user);
		}
	}, [user]);

	return <></>;
};

export default SupportChat;
