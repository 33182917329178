import { ReactElement, useEffect, useState } from 'react';
import { TyleConnectionInterface, TyleInterface } from 'interfaces/TyleInterface';
import TyleCard from 'components/TyleCard/TyleCard';
import Headline from 'components/headline';
import tylesState from 'state/TylesState';
import { TyleWindowActionEnum } from 'interfaces/TyleWindowInterface';
import useCanvasHooks from 'hooks/useCanvasHooks';
import useTyleHooks from 'hooks/useTyleHooks';
import { StyledLinksBacklinksView, StyledLinksBacklinksViewHeader } from '../../TyleWindowContent.styled';
import canvasState from '../../../../../state/CanvasState';
import sharedState from '../../../../../state/SharedState';

interface BacklinksViewProps {
	windowId: string;
	tyleId?: string;
	backlinkConnections: Array<TyleConnectionInterface>;
	changeView: (action: TyleWindowActionEnum) => void;
	createWindowForTyle: (tyle: TyleInterface) => void;
}

export const BacklinksViewTestId = 'test-backlinks-view';
const BacklinksView = ({
	windowId,
	tyleId,
	backlinkConnections,
	createWindowForTyle,
	changeView,
}: BacklinksViewProps): ReactElement | null => {
	const { addTyleToNavigation } = useCanvasHooks();
	const [backlinks, setBacklinks] = useState<TyleInterface[]>([]);
	const { getBacklinkedTylesById } = useTyleHooks();

	// Refresh backlinks when tyle is updated
	useEffect(() => {
		if (!tyleId) {
			return;
		}
		if (backlinkConnections?.length) {
			getBacklinkedTylesById(tyleId)
				.then((tyles) => {
					setBacklinks(tyles);
				})
				.catch((err) => {
					console.error(err);
					setBacklinks([]);
				});
		} else {
			setBacklinks([]);
		}
	}, [tyleId]);
	const renderItem = (tyleBacklink: TyleInterface) => {
		if (!tyleBacklink.id) {
			return null;
		}
		return (
			<TyleCard
				key={tyleBacklink.id}
				tyle={tyleBacklink}
				selected={false}
				onSingleClick={() => {
					if (!tyleBacklink?.id) {
						return;
					}
					tylesState.clickedTyle = { windowId, tyleId: tyleBacklink.id };
					changeView(TyleWindowActionEnum.VIEW_TYLE);
					addTyleToNavigation({
						windowId,
						destinationTyleId: tyleBacklink.id,
						isPublicTyle: !!(canvasState.isPublicView && sharedState.publicWindowEntity),
					});
				}}
				onDoubleClick={() => {
					setTimeout(() => {
						if (createWindowForTyle && tyleId) {
							const tyle = tylesState.tylesObjects[tyleId];
							createWindowForTyle({ ...tyle });
						}
					}, 100);
				}}
			/>
		);
	};

	return (
		<StyledLinksBacklinksView data-testid={BacklinksViewTestId}>
			<StyledLinksBacklinksViewHeader>
				<Headline>Backlinks</Headline>
			</StyledLinksBacklinksViewHeader>
			{backlinks.map((tyleBacklink) => {
				return renderItem(tyleBacklink);
			})}
		</StyledLinksBacklinksView>
	);
};

export default BacklinksView;
