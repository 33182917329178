import React, { LegacyRef, ReactElement, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/icon';
import Input from 'components/input';
import { detectEnvironment, detectOperatingSystem } from 'helpers';
import { EnvironmentEnum } from 'helpers/detectEnvironment';
import { OperatingSystemPlatformEnum } from 'helpers/detectOperatingSystem';
import './CustomDropdown.scss';

// Dropdown needs access to the DOM node in order to position the Menu
// eslint-disable-next-line react/prop-types
const CustomToggle = React.forwardRef<HTMLDivElement | null>(({ children, onClick }: any, ref) => (
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
	<div
		ref={ref as LegacyRef<HTMLDivElement>}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</div>
));

// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef<HTMLDivElement | null>(
	// eslint-disable-next-line react/prop-types
	({ children, style, className, 'aria-labelledby': labeledBy }: any, ref) => {
		return (
			<div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
				<ul className="list-unstyled">{children}</ul>
			</div>
		);
	},
);

export interface Props {
	items: string[];
	label?: string;
	placeholder?: string;
	onChange: (value: string, set: (val: string) => void, show: (bool: boolean) => void) => void;
	onInputChange: (value: string) => void;
	darkmode?: boolean;
	initialValue?: string;
}

const CustomDropdown = ({
	items,
	label,
	placeholder,
	onChange,
	onInputChange,
	darkmode = false,
	initialValue,
}: Props): ReactElement => {
	const [value, setValue] = useState<string>(initialValue ?? '');
	const [show, setShow] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(false);

	return (
		<>
			<Dropdown show={openDropdown} onSelect={() => setOpenDropdown(false)}>
				<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
					<div className={`custom-dropdown ${darkmode ? 'darkmode' : 'lightmode'}`}>
						<p className="label">{label}</p>
						<div className="custom-dropdown_input" role="menu" tabIndex={0} onFocus={(e) => setOpenDropdown(true)}>
							<p className={`custom-dropdown_${value ? 'text' : 'placeholder'}`}>{value ?? placeholder}</p>
							<Icon className="custom-dropdown_icon" type="double-chevron" />
						</div>
					</div>
				</Dropdown.Toggle>

				<Dropdown.Menu as={CustomMenu} className={`custom-dropdown ${darkmode && 'darkmode'}`}>
					{items.map((item, i) => (
						<Dropdown.Item
							className={`custom-dropdown ${darkmode && 'darkmode'}`}
							eventKey={i}
							key={item}
							onClick={() => onChange(item, setValue, setShow)}
						>
							{item}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>

			{show && (
				<div className={`custom-dropdown ${darkmode && 'darkmode'}`} style={{ paddingTop: '1.5rem' }}>
					<Input
						style={{
							backgroundColor: '#1E1E1E',
							borderColor: '#343434',
							color: '#ededed',
							width: '100%',
							fontSize: '13px',
						}}
						label="Please specify 'Other'"
						onChange={(val: string) => onInputChange(val)}
					/>
				</div>
			)}
		</>
	);
};

export default CustomDropdown;
